import { format } from 'date-fns'
import { useLayoutEffect, useRef } from 'react'
import { DayPacing } from '../../utility/BudgetCalculations'
import { classNames } from '../../utility/Components'
import {
    dollarChangeFormat,
    dollarFormat,
    percentageFormat,
} from '../../utility/Formatting'
import { PacingCell } from './PacingCell'

const PacingByDayTable = ({
    pacingDetails,
}: {
    pacingDetails: DayPacing[]
}) => {
    const scrollRef = useRef<HTMLDivElement>()
    useLayoutEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTo({
                top: scrollRef.current.scrollHeight,
                behavior: 'auto',
            })
        }
    }, [])

    const spacings = {
        Day: 'w-8',
        Total: 'flex-1 pr-1',
        'Pacing Amount': 'flex-1 pr-1',
        'Pacing %': 'w-16',
        Change: 'w-12',
    }
    const columns = Object.keys(spacings)
    const rows = pacingDetails

    return (
        <div
            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 sm:rounded-b-lg"
            role="table"
            aria-label="Pacing by Day"
        >
            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                <div
                    className="flex-none bg-gray-50 pl-2 pr-4"
                    style={{ width: 'calc(100% - 0.9em)' }}
                    role="rowgroup"
                >
                    <div className="flex gap-10" role="row">
                        {columns.map((column) => {
                            const justification = [
                                'Total',
                                'Pacing Amount',
                                'Change',
                            ].includes(column)
                                ? 'text-right'
                                : column === 'Pacing %'
                                ? 'text-center'
                                : 'text-left'
                            const spacing = spacings[column]
                            return (
                                <div
                                    key={column}
                                    className={classNames(
                                        'py-2 text-xs font-medium uppercase text-gray-700',
                                        justification,
                                        spacing,
                                    )}
                                    role="columnheader"
                                >
                                    {column}
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div
                    className="flex-1 overflow-y-scroll bg-white pt-1"
                    ref={scrollRef}
                    role="rowgroup"
                >
                    {rows.length > 0 ? (
                        rows.map((row, index, list) => (
                            <div
                                key={row.date.toString()}
                                className="flex items-center gap-10 pl-2 pr-4 pb-1 text-xs text-gray-700"
                                role="row"
                            >
                                {!row.isAdvertisingDay ? (
                                    <div className="w-full border-b border-gray-300" />
                                ) : (
                                    <>
                                        <div
                                            className="w-8 font-medium"
                                            role="rowheader"
                                        >
                                            {/* Format the date as an ordinal day (2022-07-01 => 1st) */}
                                            {format(row.date, 'do')}
                                        </div>
                                        <div
                                            className="flex-1 pr-1 text-right"
                                            role="cell"
                                        >
                                            {dollarFormat(row.total)}
                                        </div>
                                        <div
                                            className="flex-1 pr-1 text-right"
                                            role="cell"
                                        >
                                            {dollarChangeFormat(
                                                row.pacingAmount,
                                            )}
                                        </div>
                                        <div
                                            className="w-16 text-center"
                                            role="cell"
                                        >
                                            <PacingCell
                                                percentage={row.pacingPercent}
                                                isSelected={false}
                                                isSmall={true}
                                            />
                                        </div>
                                        <div
                                            className="w-12 text-right"
                                            role="cell"
                                        >
                                            {/* Return an "empty" delta value on the first ad day */}
                                            {index === 0 ||
                                            ((index === 1 || index === 2) &&
                                                !list[index - 1]
                                                    .isAdvertisingDay)
                                                ? '—'
                                                : percentageFormat(
                                                      row.changeInPercent,
                                                      true,
                                                  )}
                                        </div>
                                    </>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="block w-full" role="row">
                            <div className="block w-full">
                                <div className="p-16 text-center text-lg">
                                    There's no data yet.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PacingByDayTable
