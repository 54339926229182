import { ReactNode } from 'react'
import { classNames } from '../utility/Components'
import { percentageFormat } from '../utility/Formatting'

/**
 *
 * @param percentage number Any positive real number or 0 as a percentage out of 100.
 * @returns ReactElement Progress bar and text description of percentage.
 */
const ProgressBar = ({ percentage = 0, children = false as ReactNode }) => {
    const formattedPercentage = percentageFormat(percentage, false)
    const calculatedPercentage =
        percentage <= 100
            ? percentageFormat(percentage, false)
            : percentageFormat((100 / percentage) * 100, false)
    return (
        <div>
            {children && (
                <p className="truncate text-sm font-medium text-gray-900">
                    {children}
                </p>
            )}
            <div className="flex items-baseline gap-2">
                <div
                    className={classNames(
                        percentage <= 100 ? 'bg-gray-100' : 'bg-gray-500',
                        'mt-2 h-2 flex-auto rounded-full border border-gray-500',
                    )}
                    aria-hidden="true"
                >
                    <div
                        className={classNames(
                            percentage <= 100 ? ' bg-gray-500' : 'bg-gray-100',
                            'h-full rounded-full',
                        )}
                        style={{ width: calculatedPercentage }}
                    />
                </div>
                <div
                    className={classNames(
                        'min-h-0 flex-initial text-sm font-medium text-gray-800',
                    )}
                >
                    {formattedPercentage}
                </div>
            </div>
        </div>
    )
}

export default ProgressBar
