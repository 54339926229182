import React, { useEffect } from 'react'

const Notification = ({ message, type, show, onDismiss }) => {
    useEffect(() => {
        let timer
        if (show) {
            timer = setTimeout(() => {
                onDismiss() // Callback to parent component
            }, 3000)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [show, onDismiss])

    return (
        <div
            className={`fixed bottom-5 right-5 z-50 rounded-lg p-4 shadow-lg ${
                type === 'error'
                    ? 'bg-rose-100 text-rose-900'
                    : 'bg-green-100 text-green-900'
            }`}
        >
            <span className={`text-${type === 'error' ? 'red' : 'green'}-500`}>
                {message}
            </span>
        </div>
    )
}

export default Notification
