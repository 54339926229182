import winterHat from '../../images/emoji-addon-winter-hat.svg'
import sunglasses from '../../images/emoji-addon-sunglasses.svg'
import jackolantern from '../../images/emoji-addon-jack-o-lantern.svg'
import pearEmoji from '../../images/pear-emoji.png'

export const renderSeasonPear = (season) => {
    switch (season) {
        case 'cool':
            return <span className="text-3xl">🍐</span>
        case 'cold':
            return (
                <>
                    <img
                        className="h-9 px-1"
                        src={pearEmoji}
                        alt="Pear with winter hat"
                    />
                    <img
                        className="absolute left-[.57em] top-[-.4em] w-4 -rotate-6"
                        src={winterHat}
                        alt=""
                        aria-hidden="true"
                    />{' '}
                </>
            )
        case 'hot':
            return (
                <>
                    <img
                        className="h-9 px-1"
                        src={pearEmoji}
                        alt="Pear with sunglasses"
                    />
                    <img
                        className="absolute left-[.5em] top-[0.1em] w-7 -rotate-6"
                        src={sunglasses}
                        alt=""
                        aria-hidden="true"
                    />{' '}
                </>
            )
        case 'spooky':
            return (
                <>
                    <img
                        className="h-9 px-1"
                        src={pearEmoji}
                        alt="Pear with Jack-O-Lantern face"
                    />
                    <img
                        className="absolute left-[.5em] top-[0.45em] w-7 -rotate-6"
                        src={jackolantern}
                        alt=""
                        aria-hidden="true"
                    />{' '}
                </>
            )
    }
}
