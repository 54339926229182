export const LoadingIcon = () => (
    <div className="flex h-full w-full items-center justify-center">
        <span className="sr-only">Loading</span>
        <div className="w-max p-4">
            <i className="inline-block animate-spin text-3xl not-italic opacity-90">
                🍐
            </i>
        </div>
    </div>
)

export const ErrorIcon = () => (
    <div className="flex h-full items-center justify-center">
        <span className="sr-only">Error</span>
        <div className="w-max p-4">
            <div className="relative flex h-16 w-16 items-center justify-center rounded-full border-6 border-orange-700">
                <div className="text-3xl not-italic">🍐</div>
                <div className="absolute -left-1 top-1/2 h-1.5 w-over -rotate-45 border-r-8 border-orange-700 bg-orange-700" />
            </div>
        </div>
    </div>
)

export const IdleIcon = () => (
    <div className="flex h-full items-center justify-center">
        <span className="sr-only">Idle</span>
        <div className="m-auto w-max p-4">
            <div className="relative h-20 w-16">
                <i className="transfrom absolute left-0 bottom-0 inline-block -rotate-45 text-3xl not-italic opacity-90">
                    🍐
                </i>
                <div className="absolute top-5 right-8 animate-stagger-1-slow">
                    z
                </div>
                <div className="absolute top-3.5 right-5 animate-stagger-2-slow font-semibold text-gray-900">
                    Z
                </div>
                <div className="absolute top-0 right-0 animate-stagger-3-slow text-2xl font-semibold text-gray-800">
                    Z
                </div>
            </div>
        </div>
    </div>
)
