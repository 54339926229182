import { createRef, CSSProperties, memo, useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTable, useSortBy } from 'react-table'
import { FixedSizeGrid as Grid, FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid'
import { differenceInCalendarDays, parseISO } from 'date-fns'
import { classNames } from '../utility/Components'
import { SectionCloud } from '../components/Layout'
import StatusDisplay from '../components/StatusDisplay'
import { useClientWithCars } from '../hooks/apiHooks'
import { Car } from '../apis/pearApi'
import { dollarFormat } from '../utility/Formatting'
import { GenericSelect } from '../components/elements/GenericSelect'
import { StatelessTextBox } from '../components/elements/TextBoxes'
import { rowValuesByColumnId } from '../utility/ReactTable'
import { StatelessSelect } from '../components/elements/StatelessSelect'
import { TintedImage } from '../components/Utilitly'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import { Button } from '../components/elements/Buttons'
import { onlyUnique } from '../utility/Arrays'

const calculatedCarInfo = ({
    condition,
    certified,
    price,
    msrp,
    inStockDate,
    images,
    ...otherInfo
}: Car) => ({
    ...otherInfo,
    price,
    msrp,
    fullCondition:
        condition === 'NEW'
            ? 'New'
            : condition === 'USED' && certified === true
            ? 'Certified'
            : 'Used',
    discount: price !== null && msrp !== null ? msrp - price : 0,
    daysOnLot: differenceInCalendarDays(new Date(), parseISO(inStockDate)),
    // A lot of cars come with a first image that's an ad so we'll take the
    // second image if it exists or the first one if not.
    imageUrl: images?.[1] ?? images?.[0] ?? '',
})

const conditionOrder = ['New', 'Certified', 'Used']
const conditionSort = (row1, row2, columnId) => {
    const [condition1, condition2] = rowValuesByColumnId(row1, row2, columnId)

    if (condition1 === condition2) return 0

    const condition1Rank = conditionOrder.findIndex((c) => c === condition1)
    const condition2Rank = conditionOrder.findIndex((c) => c === condition2)
    return condition1Rank < condition2Rank ? -1 : 1
}

const columns = [
    {
        Header: 'Year',
        accessor: 'year',
    },
    {
        Header: 'Make',
        accessor: 'make',
    },
    {
        Header: 'Model',
        accessor: 'model',
    },
    {
        Header: 'Trim',
        accessor: 'trim',
    },
    {
        Header: 'Condition',
        accessor: 'fullCondition',
        sortType: conditionSort,
    },
    {
        Header: 'Price',
        accessor: 'price',
        Cell: ({ value: price }) =>
            price === null ? '-' : dollarFormat(price, 0),
    },
    {
        Header: 'MSRP',
        accessor: 'msrp',
        Cell: ({ value: msrp }) =>
            msrp === null ? '-' : dollarFormat(msrp, 0),
    },
    {
        Header: 'Discount',
        accessor: 'discount',
        Cell: ({ value }) => (value === 0 ? '-' : dollarFormat(value, 0)),
    },
    {
        Header: 'Days Old',
        accessor: 'daysOnLot',
    },
    {
        Header: 'VIN',
        accessor: 'vin',
        Cell: ({
            value: vin,
            row: {
                values: { url },
            },
        }) => {
            return url ? (
                <a
                    className="rounded-r-md pr-1 text-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500"
                    href={url}
                >
                    {vin}
                </a>
            ) : (
                <span className="">{vin}</span>
            )
        },
    },
    {
        Header: 'URL',
        accessor: 'url',
    },
    {
        Header: 'ImageUrl',
        accessor: 'imageUrl',
    },
]
const conditions = ['All', 'New', 'Certified', 'Used']
interface Sort {
    id: string
    desc?: boolean
}
const defaultSort: Sort[] = [
    { id: 'make' },
    { id: 'model' },
    { id: 'trim' },
    { id: 'fullCondition' },
    { id: 'year', desc: true },
    { id: 'price' },
    { id: 'discount', desc: true },
    { id: 'daysOnLot', desc: true },
    { id: 'vin' },
]

const integratedSort = (columnId, descending = false, previousSort) => {
    const otherSorts = [...previousSort]
    const redundantSort = previousSort.findIndex((sort) => sort.id === columnId)
    if (redundantSort !== -1) {
        otherSorts.splice(redundantSort, 1)
    }
    const newSort = [{ id: columnId, desc: descending }, ...otherSorts]
    return newSort
}

const ClientCars = () => {
    const { clientId }: { [key: string]: string } = useParams()

    const { data: clientWithCars, status } = useClientWithCars(clientId)

    const {
        cars,
        name: clientName,
        fourbotId,
    } = clientWithCars ?? {
        cars: [],
        name: '',
        fourbotId: undefined,
    }
    const carsWithCalculatedInfo = cars.map(calculatedCarInfo)

    const [conditionFilter, setConditionFilter] = useUrlQueryParameter(
        'condition',
        'All',
    )

    const [locationFilter, setLocationFilter] = useUrlQueryParameter(
        'location',
        'All',
    )

    const carLocations = cars
        .map((car) => car.location)
        .filter(onlyUnique)
        .sort()
    const locations = ['All', ...carLocations]

    const carsByFilter = carsWithCalculatedInfo.filter(
        ({ fullCondition, location }) =>
            (conditionFilter === 'All' ||
                conditionFilter.toLowerCase() ===
                    fullCondition.toLowerCase()) &&
            (locationFilter === 'All' ||
                locationFilter.toLowerCase() === location.toLowerCase()),
    )

    const [search, setSearch] = useUrlQueryParameter('search', '')
    const filteredCars = carsByFilter.filter(
        ({ year, make, model, trim, vin }) => {
            if (search === '') return true
            const loweredSearch = search.toLowerCase()
            const mainInfo = `${year.toString(10)} ${make} ${model}`
            const trimInfo = trim ? ' ' + trim + ' ' : ''
            const loweredInfo = `${mainInfo}${trimInfo}${vin}`.toLowerCase()

            return loweredInfo.includes(loweredSearch)
        },
    )

    const [urlSort, setUrlSort] = useUrlQueryParameter('sort', 'make')
    const [urlDescString, setUrlDescString] = useUrlQueryParameter(
        'desc',
        'false',
    )
    const urlDesc = urlDescString === 'true'
    const setUrlDesc = (newValue) => setUrlDescString(newValue.toString())
    const startingSort = integratedSort(urlSort, urlDesc, defaultSort)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setSortBy,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: filteredCars,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: startingSort,
                hiddenColumns: ['url', 'imageUrl'],
            },
        },
        useSortBy,
    )

    // Stuff for scrolling to top
    interface ReactWindowElement {
        scrollToItem: (number) => void
    }
    const listRef: React.RefObject<ReactWindowElement> = createRef()
    const [view, setView] = useState('grid')
    const scrollToTop = () => {
        const destination =
            view === 'list' ? 0 : { columnIndex: 0, rowIndex: 0 }
        listRef?.current?.scrollToItem(destination)
    }

    const changeSort = (columnId, descending = false) => {
        const newSort = integratedSort(columnId, descending, defaultSort)
        setSortBy(newSort)
        scrollToTop()
        setUrlDesc(descending)
        setUrlSort(columnId)
    }

    const sortColumn = sortBy[0].id
    const sortIsDescending = sortBy[0].desc

    const toggleSortColumn = (columnId) => {
        const currentSort = [...sortBy]
        const currentDesc =
            currentSort[0].id === columnId ? currentSort[0].desc : false

        changeSort(columnId, !currentDesc)
    }

    return (
        <SectionCloud className="flex h-full flex-col px-8 py-6">
            <div className="flex items-baseline justify-between">
                <h1 className="mb-4 flex-initial text-3xl font-extrabold tracking-tight text-gray-600">
                    Inventory for {clientName}
                </h1>
                <span className="ml-5 flex-grow text-gray-700">
                    Fourbot ID: {fourbotId}
                </span>
                <Button
                    onClick={() => setView(view === 'list' ? 'grid' : 'list')}
                >
                    Switch to {view === 'list' ? 'Grid View' : 'List View'}
                </Button>
            </div>
            <div className="flex gap-8 pb-4">
                <div className="w-32 font-medium">
                    <GenericSelect
                        className="mt-1"
                        onChange={setConditionFilter}
                        options={conditions}
                        label="Condition"
                        initialSelection={conditionFilter}
                    />
                </div>
                {locations.length > 2 && (
                    <div className="w-1/4">
                        <GenericSelect
                            className="mt-1"
                            onChange={setLocationFilter}
                            options={locations}
                            label="Location"
                            initialSelection={locationFilter}
                        />
                    </div>
                )}
                {view === 'grid' && (
                    <div className="items-bottom flex flex-initial">
                        <div className="w-48">
                            <StatelessSelect
                                className="mt-1"
                                onChange={({ id, label }) => {
                                    changeSort(id, sortIsDescending)
                                }}
                                options={columns
                                    .filter(
                                        ({ accessor }) =>
                                            !['url', 'imageUrl'].includes(
                                                accessor,
                                            ),
                                    )
                                    .map(({ accessor, Header }) => ({
                                        id: accessor,
                                        label: Header,
                                    }))}
                                label="Sort By"
                                value={{
                                    id: sortColumn,
                                    label: columns.find(
                                        ({ accessor }) =>
                                            accessor === sortColumn,
                                    ).Header,
                                }}
                            />
                        </div>
                        <button
                            title="Sort Order"
                            className="group mt-6 ml-2 w-10 rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-700 hover:bg-gray-200 focus:text-cyan-900 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1"
                            onClick={() => {
                                changeSort(sortColumn, !sortIsDescending)
                            }}
                        >
                            {sortIsDescending ? (
                                <>
                                    <span className="sr-only"> descending</span>
                                    <ArrowDownIcon
                                        className="inline-block h-4 w-4 shrink-0 align-bottom  group-focus:text-cyan-700 "
                                        aria-hidden="true"
                                    />
                                </>
                            ) : (
                                <>
                                    <span className="sr-only"> ascending</span>
                                    <ArrowUpIcon
                                        className="inline-block h-4 w-4 align-bottom group-focus:text-cyan-700"
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </button>
                    </div>
                )}
                <div className="min-w-0 flex-auto">
                    <StatelessTextBox
                        classNameInput="mt-1"
                        label="Search"
                        text={search}
                        onChange={setSearch}
                    />
                </div>
                <div className="flex w-32 flex-col justify-end text-right text-xl font-medium leading-[38px] text-gray-700">
                    {rows.length} {rows.length === 1 ? 'vehicle' : 'vehicles'}
                </div>
            </div>
            {view === 'list' ? (
                <CarList
                    {...{
                        status,
                        getTableProps,
                        getTableBodyProps,
                        headerGroups,
                        rows,
                        prepareRow,
                        toggleSortColumn,
                        sortBy,
                        listRef,
                    }}
                />
            ) : (
                <CarGrid
                    {...{
                        status,
                        getTableProps,
                        getTableBodyProps,
                        rows,
                        prepareRow,
                        sortBy,
                        listRef,
                    }}
                />
            )}
        </SectionCloud>
    )
}

const CarList = ({
    status,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleSortColumn,
    sortBy,
    listRef,
}) => {
    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return <Row row={row} {...row.getRowProps()} style={style} />
        },
        [prepareRow, rows],
    )

    return (
        <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                <div
                    className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                    {...getTableProps()}
                >
                    <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                        <div
                            className="flex-none bg-gray-50 pl-2"
                            style={{ width: 'calc(100% - 0.9em)' }}
                        >
                            {headerGroups.map((headerGroup) => (
                                <div
                                    className="flex gap-3"
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column) => {
                                        const { id } = column ?? {}
                                        const justification = [
                                            'price',
                                            'msrp',
                                            'discount',
                                            'daysOnLot',
                                        ].includes(id)
                                            ? 'text-right'
                                            : 'text-left'
                                        const spacing = [
                                            'trim',
                                            'model',
                                            'vin',
                                        ].includes(id)
                                            ? 'w-1/6'
                                            : id === 'year'
                                            ? 'w-20'
                                            : 'w-1/12'
                                        const header = column.render('Header')
                                        return (
                                            <button
                                                className={classNames(
                                                    'group py-2 text-xs font-medium uppercase tracking-wider text-gray-700 focus:font-bold focus:text-cyan-900 focus:outline-none',
                                                    justification,
                                                    spacing,
                                                )}
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps(),
                                                )}
                                                onClick={() =>
                                                    toggleSortColumn(column.id)
                                                }
                                                title={undefined}
                                            >
                                                <span className="">
                                                    {header === 'Condition'
                                                        ? 'Cond.'
                                                        : header}
                                                </span>
                                                {sortBy[0].id === column.id ? (
                                                    column.isSortedDesc ? (
                                                        <>
                                                            <span className="sr-only">
                                                                sorted
                                                                descending
                                                            </span>
                                                            <ArrowDownIcon
                                                                className="ml-2 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-gray-700 "
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="sr-only">
                                                                sorted ascending
                                                            </span>
                                                            <ArrowUpIcon
                                                                className="ml-2 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-gray-700"
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )
                                                ) : (
                                                    <ArrowDownIcon
                                                        className="ml-2 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </button>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className="flex-1 bg-white">
                            {rows.length > 0 ? (
                                <div
                                    className="h-full"
                                    {...getTableBodyProps()}
                                >
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                width={width}
                                                itemCount={rows.length}
                                                itemSize={28}
                                                ref={listRef}
                                                style={{
                                                    overflowX: 'hidden',
                                                }}
                                            >
                                                {RenderRow}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>
                            ) : status !== 'success' ? (
                                <div className="h-full">
                                    <StatusDisplay status={status} />
                                </div>
                            ) : (
                                <div className="block w-full">
                                    <div className="block w-full">
                                        <div className="p-16 text-center text-lg">
                                            There's nothing here.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Row = memo(
    ({ row, style, ...otherProps }: { row: any; style: CSSProperties }) => {
        return (
            <div
                className="flex gap-3 rounded-md pl-2 pt-1 text-sm"
                {...otherProps}
                style={style}
            >
                {row.cells.map((cell) => {
                    const { id } = cell?.column ?? {}
                    const justification = [
                        'price',
                        'msrp',
                        'discount',
                        'daysOnLot',
                    ].includes(id)
                        ? 'text-right'
                        : ''
                    const spacing = ['trim', 'model', 'vin'].includes(id)
                        ? 'w-1/6'
                        : id === 'year'
                        ? 'w-20'
                        : 'w-1/12'

                    return (
                        <div
                            className={classNames(
                                'truncate text-gray-800',
                                justification,
                                spacing,
                            )}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell')}
                        </div>
                    )
                })}
            </div>
        )
    },
)

const CarGrid = ({
    status,
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    sortBy,
    listRef,
}) => {
    const RenderCell = useCallback(
        ({ columnIndex, rowIndex, style }) => {
            // If we're at the end of an uneven row, render blanks if necessary
            const possibleRowIndex = rowIndex * 6 + columnIndex
            if (possibleRowIndex < rows.length) {
                const row = rows[possibleRowIndex]
                prepareRow(row)
                return <Card row={row} {...row.getRowProps()} style={style} />
            }
            return null
        },
        [prepareRow, rows],
    )

    return (
        <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                <div
                    className="h-full overflow-hidden rounded-md border-b border-t border-gray-200 bg-gray-100 shadow"
                    {...getTableProps()}
                >
                    <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                        <div className="flex-1 bg-white">
                            {rows.length > 0 ? (
                                <div
                                    className="h-full bg-gray-100 pl-2"
                                    {...getTableBodyProps()}
                                >
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <Grid
                                                height={height}
                                                width={width}
                                                columnCount={6}
                                                columnWidth={230}
                                                rowCount={Math.ceil(
                                                    rows.length / 6,
                                                )}
                                                rowHeight={280}
                                                ref={listRef}
                                            >
                                                {RenderCell}
                                            </Grid>
                                        )}
                                    </AutoSizer>
                                </div>
                            ) : status !== 'success' ? (
                                <div className="h-full">
                                    <StatusDisplay status={status} />
                                </div>
                            ) : (
                                <div className="h-full">
                                    <div className="p-16 text-center text-lg">
                                        There's nothing here.
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Card = memo(
    ({ row, style, ...otherProps }: { row: any; style: CSSProperties }) => {
        const {
            values: {
                vin,
                url,
                make,
                model,
                year,
                trim,
                fullCondition,
                price,
                msrp,
                discount,
                daysOnLot,
                imageUrl,
            },
        } = row
        return (
            <li
                key={vin}
                className="list-none p-2"
                {...otherProps}
                style={style}
            >
                <div className="relative flex h-64 flex-col overflow-hidden rounded-lg bg-white shadow">
                    <TintedImage className="h-28" src={imageUrl} />
                    <div className="h-1 bg-cyan-400"> </div>
                    <div className="flex min-h-0 flex-auto flex-col px-4 py-3">
                        <h3 className="truncate font-medium text-gray-900">
                            {year} {make} {model}
                        </h3>
                        <dl className="flex flex-auto flex-col justify-between">
                            <div className="flex-1">
                                <dt className="sr-only">Trim</dt>
                                <dd className="truncate text-sm text-gray-700">
                                    {trim}
                                </dd>
                                <dt className="sr-only">VIN</dt>
                                <dd className="text-xs">
                                    {url ? (
                                        <a
                                            className="rounded text-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-1"
                                            href={url}
                                        >
                                            {vin}
                                        </a>
                                    ) : (
                                        <span className="text-gray-700">
                                            {url}
                                        </span>
                                    )}
                                </dd>
                            </div>
                            <div className="flex flex-1 items-end">
                                <div className="flex-1">
                                    <dt className="sr-only">Condition</dt>
                                    <dd className="truncate text-sm text-gray-700">
                                        {fullCondition}
                                    </dd>
                                    {daysOnLot && (
                                        <>
                                            <dt className="sr-only">
                                                Discount
                                            </dt>
                                            <dd className="text-xs text-gray-700">
                                                {daysOnLot}{' '}
                                                {daysOnLot === 1
                                                    ? 'day'
                                                    : 'days'}{' '}
                                                old
                                            </dd>
                                        </>
                                    )}
                                </div>
                                <div className="flex-1 text-right">
                                    {price ? (
                                        <>
                                            <dt className="sr-only">Price</dt>
                                            <dd className="text-xl text-gray-900">
                                                {dollarFormat(price, 0)}
                                            </dd>
                                            {discount > 0 && (
                                                <>
                                                    <dt className="sr-only">
                                                        Discount
                                                    </dt>
                                                    <dd className="text-xs text-gray-700">
                                                        {dollarFormat(
                                                            discount,
                                                            0,
                                                        )}{' '}
                                                        off
                                                    </dd>
                                                </>
                                            )}
                                        </>
                                    ) : msrp ? (
                                        <>
                                            <dt className="sr-only">MSRP</dt>
                                            <dd className="text-xl text-gray-900">
                                                {dollarFormat(msrp, 0)}
                                            </dd>
                                            <div className="text-xs tracking-wide text-gray-700">
                                                (MSRP)
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </li>
        )
    },
)

export default ClientCars
