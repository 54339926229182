// Based on an example from Headless UI (headlessui.dev).
import { Switch } from '@headlessui/react'
import { classNames } from '../../utility/Components'

export const Toggle = ({
    label,
    enabled,
    setEnabled,
    showLabel = true,
    className,
}: {
    label: string
    enabled: boolean
    setEnabled: (enabled: boolean) => void
    showLabel?: boolean
    className?: string
}) => (
    <div className={classNames('flex items-center', className)}>
        {showLabel && (
            <span className="mr-3 min-w-0 flex-auto text-sm">{label}</span>
        )}
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={classNames(
                'min-w-0 flex-initial',
                enabled ? 'bg-gray-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 items-center justify-self-end rounded-full focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1',
            )}
        >
            <span className="sr-only">{label}</span>
            <span
                className={classNames(
                    enabled ? 'translate-x-6' : 'translate-x-1',
                    'inline-block h-4 w-4 rounded-full bg-white',
                )}
            />
        </Switch>
    </div>
)
