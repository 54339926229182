import { useEffect } from 'react'
import { prefetchBudgetList } from '../hooks/apiHooks'
import { latestMonth } from '../utility/BudgetCalculations'

const Colors = () => {
    useEffect(() => {
        const currentMonth = latestMonth()
        prefetchBudgetList(currentMonth)
    }, [])
    return (
        <>
            <div className="flex h-16 ">
                <div className="h-full flex-1 bg-gray-50 text-center text-gray-700">
                    50
                </div>
                <div className="h-full flex-1 bg-gray-100 text-center text-gray-800">
                    100
                </div>
                <div className="h-full flex-1 bg-gray-200 text-center text-gray-800">
                    200
                </div>
                <div className="h-full flex-1 bg-gray-300 text-center text-gray-900">
                    300
                </div>
                <div className="relative h-full flex-1">
                    <div className="absolute left-0 top-0 h-full w-2/3 bg-gray-400"></div>
                    <div className="absolute right-0 top-0 h-full w-1/3 bg-gray-450"></div>
                    <div className="absolute inset-0 text-center text-gray-900">
                        400
                    </div>
                </div>
                <div className="h-full flex-1 bg-gray-500 text-center text-white">
                    500
                </div>
                <div className="h-full flex-1 bg-gray-600 text-center text-gray-50">
                    600
                </div>
                <div className="h-full flex-1 bg-gray-700 text-center text-gray-100">
                    700
                </div>
                <div className="h-full flex-1 bg-gray-800 text-center text-gray-100">
                    800
                </div>
                <div className="h-full flex-1 bg-gray-900 text-center text-gray-200">
                    900
                </div>
            </div>
            <div className="flex h-24">
                <div className="h-full flex-1 bg-cyan-50"> </div>
                <div className="h-full flex-1 bg-cyan-100"> </div>
                <div className="h-full flex-1 bg-cyan-200"> </div>
                <div className="h-full flex-1 bg-cyan-300"> </div>
                <div className="h-full flex-1 bg-cyan-400"> </div>
                <div className="h-full flex-1 bg-cyan-500"> </div>
                <div className="h-full flex-1 bg-cyan-600"> </div>
                <div className="h-full flex-1 bg-cyan-700"> </div>
                <div className="h-full flex-1 bg-cyan-800"> </div>
                <div className="h-full flex-1 bg-cyan-900"> </div>
            </div>
            <div className="flex h-24">
                <div className="h-full flex-1 bg-green-50"> </div>
                <div className="h-full flex-1 bg-green-100"> </div>
                <div className="h-full flex-1 bg-green-200"> </div>
                <div className="h-full flex-1 bg-green-300"> </div>
                <div className="h-full flex-1 bg-green-400"> </div>
                <div className="h-full flex-1 bg-green-500"> </div>
                <div className="h-full flex-1 bg-green-600"> </div>
                <div className="h-full flex-1 bg-green-700"> </div>
                <div className="h-full flex-1 bg-green-800"> </div>
                <div className="h-full flex-1 bg-green-900"> </div>
            </div>
            <div className="flex h-24">
                <div className="h-full flex-1 bg-lime-50"> </div>
                <div className="h-full flex-1 bg-lime-100"> </div>
                <div className="h-full flex-1 bg-lime-200"> </div>
                <div className="h-full flex-1 bg-lime-300"> </div>
                <div className="h-full flex-1 bg-lime-400"> </div>
                <div className="h-full flex-1 bg-lime-500"> </div>
                <div className="h-full flex-1 bg-lime-600"> </div>
                <div className="h-full flex-1 bg-lime-700"> </div>
                <div className="h-full flex-1 bg-lime-800"> </div>
                <div className="h-full flex-1 bg-lime-900"> </div>
            </div>
            <div className="flex h-24">
                <div className="h-full flex-1 bg-orange-50"> </div>
                <div className="h-full flex-1 bg-orange-100"> </div>
                <div className="h-full flex-1 bg-orange-200"> </div>
                <div className="h-full flex-1 bg-orange-300"> </div>
                <div className="h-full flex-1 bg-orange-400"> </div>
                <div className="h-full flex-1 bg-orange-500"> </div>
                <div className="h-full flex-1 bg-orange-600"> </div>
                <div className="h-full flex-1 bg-orange-700"> </div>
                <div className="h-full flex-1 bg-orange-800"> </div>
                <div className="h-full flex-1 bg-orange-900"> </div>
            </div>
            <div className="flex h-24">
                <div className="h-full flex-1 bg-rose-50"> </div>
                <div className="h-full flex-1 bg-rose-100"> </div>
                <div className="h-full flex-1 bg-rose-200"> </div>
                <div className="h-full flex-1 bg-rose-300"> </div>
                <div className="h-full flex-1 bg-rose-400"> </div>
                <div className="h-full flex-1 bg-rose-500"> </div>
                <div className="h-full flex-1 bg-rose-600"> </div>
                <div className="h-full flex-1 bg-rose-700"> </div>
                <div className="h-full flex-1 bg-rose-800"> </div>
                <div className="h-full flex-1 bg-rose-900"> </div>
            </div>
        </>
    )
}

export default Colors
