import { Cell, PieChart, Pie } from 'recharts'
import { classNames } from '../../utility/Components'
import { pacingColorAndBorder } from '../Budgeting/DisplayFormatting'
import { pacingColorName } from '../Budgeting/PacingCell'
import StatusDisplay, { Status } from '../StatusDisplay'
import { percentageFormat } from '../../utility/Formatting'
import theme from '../../theme'

const pacingBreakdownChartColors = [
    theme.colors.green[600],
    theme.colors.lime[500],
    theme.colors.orange[500],
    theme.colors.rose[500],
]

const pacingBreakdownChartFillColors = [
    theme.colors.green[200],
    theme.colors.lime[200],
    theme.colors.orange[200],
    theme.colors.rose[200],
]

const legendColors = [
    classNames(pacingColorAndBorder(1), 'rounded-full'),
    classNames(pacingColorAndBorder(6), 'rounded-lg'),
    classNames(pacingColorAndBorder(16), 'rounded-md'),
    classNames(pacingColorAndBorder(26), ''),
]

const legendColorNames = [
    pacingColorName(1),
    pacingColorName(6),
    pacingColorName(16),
    pacingColorName(26),
]

export const PacingBreakdownChart = ({
    data,
    status = 'success' as Status,
}) => {
    const total = data.reduce((total, item) => total + item.amount, 0)
    return (
        <StatusDisplay status={status}>
            <div className="flex flex-col items-center gap-4 lg:flex-row">
                <PieChart
                    className="flex-initial"
                    width={200}
                    height={200}
                    aria-hidden="true"
                >
                    <Pie
                        data={data}
                        cx={100}
                        innerRadius={40}
                        outerRadius={75}
                        startAngle={90}
                        endAngle={-270}
                        dataKey="amount"
                        legendType="circle"
                        paddingAngle={2}
                        minAngle={5}
                        animationDuration={500}
                    >
                        {data.map((datum, i) => (
                            <Cell
                                key={`cell-${i}`}
                                fill={pacingBreakdownChartFillColors[i]}
                                stroke={pacingBreakdownChartColors[i]}
                            />
                        ))}
                    </Pie>
                </PieChart>
                <div className="flex w-full flex-col items-center px-4 lg:pr-8">
                    <table className="w-full">
                        <tbody className="">
                            <tr className="pb-2 text-sm text-gray-600">
                                <th className="text-left font-medium">Range</th>
                                <th className="text-right font-medium">
                                    Percent
                                </th>
                                <th className="text-right font-medium">
                                    Count
                                </th>
                            </tr>
                            {data.map((datum, i) => (
                                <tr className="align-baseline" key={datum.name}>
                                    <td className="">
                                        <div
                                            className={classNames(
                                                legendColors[i],
                                                'border px-2 text-center text-sm',
                                            )}
                                        >
                                            <span className="sr-only">
                                                {legendColorNames[i]}{' '}
                                            </span>
                                            {datum.name}
                                        </div>
                                    </td>
                                    <td className="text-right">
                                        {percentageFormat(
                                            total <= 0
                                                ? 0
                                                : (datum.amount * 100) / total,
                                            false,
                                        )}
                                    </td>
                                    <td className="text-right">
                                        {datum.amount}
                                    </td>
                                </tr>
                            ))}
                            <tr className="align-baseline">
                                <td className="text-sm font-medium text-gray-600">
                                    Total
                                </td>
                                <td></td>
                                <td className="text-right font-medium">
                                    {total}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </StatusDisplay>
    )
}
