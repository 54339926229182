import { useCallback, memo, CSSProperties, useState } from 'react'
import { useMutation } from 'react-query'
import { useTable, useSortBy } from 'react-table'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid'
import { SectionCloud } from '../components/Layout'
import StatusDisplay from '../components/StatusDisplay'
import { classNames } from '../utility/Components'
import {
    useBudgetSpendWatchdogs,
    invalidateBudgetSpendWatchdogs,
} from '../hooks/apiHooks'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import { StatelessSelect } from '../components/elements/StatelessSelect'
import {
    CompactStatelessNumberBox,
    StatelessNumberBox,
    StatelessTextBox,
} from '../components/elements/TextBoxes'
import { Toggle } from '../components/elements/Toggle'
import { dollarFormat, percentageFormat, isoMonth } from '../utility/Formatting'
import PearApi from '../apis/pearApi'
import { months, yearsSince } from '../utility/Time'
import { rowValuesByColumnId } from '../utility/ReactTable'
import PopupDialog from '../components/elements/PopupDialog'
import ButtonGroup from '../components/elements/Buttons'
import CsvLink from '../components/CsvLink'
const oems = [
    { id: 'All', label: 'All' },
    { id: 'Acura', label: 'Acura' },
    { id: 'Audi', label: 'Audi' },
    { id: 'Stellantis', label: 'Stellantis' },
]
const convertWatchdog = (watchdog) => {
    const {
        id,
        percentBudgetSpent,
        budgetSpendDifference,
        ignore,
        allCampaignsPaused,
        overspend,
        oem,
        clientSourceName,
        categorizedCampaignSpends,
    } = watchdog
    const {
        clientName,
        clientDealerCode = '',
        adSpendBudget,
        monthToDateSpend,
        salesforceProduct,
        campaigns,
    } = categorizedCampaignSpends || {}
    const { productName, promoName, adSpendPlatform } = salesforceProduct || {}

    const simplifiedData = {
        id,
        clientName,
        clientDealerCode,
        oem,
        clientSourceName,
        spendPercent: percentBudgetSpent,
        spendDifference: budgetSpendDifference,
        ignore,
        bcdf: promoName?.toLowerCase().includes('bcdf'),
        overspend,
        budget: adSpendBudget,
        spend: monthToDateSpend,
        sourceName: adSpendPlatform ? adSpendPlatform : 'Unmatched',
        campaigns,
        allCampaignsPaused,
        productName,
        promoName,
    }

    return simplifiedData
}

const campaignsPausedSort = (row1, row2, columnId) => {
    const [campaigns1, campaigns2] = rowValuesByColumnId(row1, row2, columnId)
    const row1Paused = row1.original.allCampaignsPaused
    const row2Paused = row2.original.allCampaignsPaused
    if (!row1Paused && row2Paused) {
        return -1
    }
    if (row1Paused && !row2Paused) {
        return 1
    }
    if (campaigns1.length < campaigns2.length) {
        return -1
    }
    if (campaigns1.length > campaigns2.length) {
        return 1
    }
    return 0
}

const columns = [
    {
        Header: 'Client',
        accessor: 'clientName',
        Cell: ({
            value: clientName,
            row: {
                original: { clientDealerCode },
            },
        }) => {
            return (
                <>
                    <div className="flex gap-4">
                        <span className="min-w-0 flex-1 truncate font-semibold">
                            {clientName || 'Unmatched'}
                        </span>
                        {clientDealerCode && (
                            <span className="w-12 truncate font-light text-gray-800">
                                {clientDealerCode}
                            </span>
                        )}
                    </div>
                </>
            )
        },
    },
    {
        Header: 'Source',
        accessor: 'sourceName',
        Cell: ({ value }) => (
            <span className="whitespace-pre-wrap break-all">{value}</span>
        ),
    },
    {
        Header: 'Budget',
        accessor: 'budget',
        Cell: ({ value }) => dollarFormat(value),
    },
    {
        Header: 'Spend',
        accessor: 'spend',
        Cell: ({ value }) => dollarFormat(value),
    },
    {
        Header: 'Percent',
        accessor: 'spendPercent',
        Cell: ({ value: percent }) =>
            percent > 0 ? percentageFormat(percent, false) : '',
    },
    {
        Header: 'Under',
        accessor: 'spendDifference',
        Cell: ({ value }) => (value > 0 ? dollarFormat(value) : ''),
    },
    {
        Header: 'Status',
        accessor: 'campaigns',
        Cell: () => 'campaigns',
        sortType: campaignsPausedSort,
    },
    {
        Header: 'Ignore',
        accessor: 'ignore',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        disableSortBy: true,
    },
    {
        Header: 'Overspend',
        accessor: 'overspend',
        disableSortBy: true,
    },
]

const columnWidths = {
    clientName: 'flex-1 mr-2',
    sourceName: 'w-20',
    budget: 'w-20',
    spend: 'w-20',
    spendPercent: 'w-20',
    spendDifference: 'w-20',
    campaigns: 'w-20',
    ignore: 'w-16',
    overspend: 'w-40',
}

const firstYearForWatchdog = 2021
const years = yearsSince(firstYearForWatchdog)

const bcdfOptions = [
    { value: 'all', name: 'All' },
    { value: 'only', name: 'BCDF' },
    { value: 'non', name: 'Non-BCDF' },
] as const

const csvHeaders = [
    {
        label: 'Client',
        key: 'clientName',
    },
    {
        label: 'Source',
        key: 'sourceName',
    },
    {
        label: 'Budget',
        key: 'budget',
    },
    {
        label: 'Spend',
        key: 'spend',
    },
    {
        label: 'Percent',
        key: 'spendPercent',
    },
    {
        label: 'Under',
        key: 'spendDifference',
    },
    {
        label: 'Campaigns',
        key: 'campaigns',
    },
]

const BudgetSpendWatchdog = () => {
    const currentMonth = isoMonth(new Date())
    const [month, setMonth] = useUrlQueryParameter('month', currentMonth)

    const [urlOem, setOem] = useUrlQueryParameter('oem', 'All')
    const showOem = ['All', 'Acura', 'Audi', 'Stellantis'].includes(urlOem)
        ? urlOem
        : 'All'
    const setShowOem = (showOem) =>
        setOem(
            ['All', 'Acura', 'Audi', 'Stellantis'].includes(showOem)
                ? showOem
                : 'All',
        )

    const [urlMinPercent, setUrlMinPercent] = useUrlQueryParameter(
        'minPercent',
        '90',
    )
    const minPercent = parseFloat(urlMinPercent)
    const setMinPercent = (percent: number) =>
        setUrlMinPercent(percent.toString(10))

    const [urlIgnore, setUrlIgnore] = useUrlQueryParameter('ignore', 'false')
    const showIgnored = urlIgnore === 'true'
    const setShowIgnored = (showIgnored) =>
        setUrlIgnore(showIgnored ? 'true' : 'false')

    const [urlBcdf, setBcdf] = useUrlQueryParameter('bcdf', 'all')
    const showBcdf = ['all', 'only', 'non'].includes(urlBcdf) ? urlBcdf : 'all'
    const setShowBcdf = (showBcdf) =>
        setBcdf(['all', 'only', 'non'].includes(showBcdf) ? showBcdf : 'all')

    const [search, setSearch] = useUrlQueryParameter('search', '')

    const { data, status } = useBudgetSpendWatchdogs(month)
    const watchdogs = data ? data.map(convertWatchdog) : []
    const loweredSearch = search.toLowerCase()
    const filteredWatchdogs = watchdogs.filter(
        ({
            ignore,
            bcdf,
            oem,
            clientName,
            clientDealerCode,
            sourceName,
            spendPercent,
        }) => {
            if (ignore !== showIgnored) {
                return false
            }
            if (
                (showBcdf === 'only' && !bcdf) ||
                (showBcdf === 'non' && bcdf)
            ) {
                return false
            }
            if (showOem !== 'All' && !oem.includes(showOem)) {
                return false
            }
            if (spendPercent < minPercent) {
                return false
            }
            if (
                !clientDealerCode.toLowerCase().includes(loweredSearch) &&
                !clientName.toLowerCase().includes(loweredSearch) &&
                !sourceName.toLowerCase().includes(loweredSearch)
            ) {
                return false
            }
            return true
        },
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: filteredWatchdogs,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: [{ id: 'clientName', desc: false }],
            },
        },
        useSortBy,
    )

    const NO_POPUP_ID = -1
    const [currentPopupId, setCurrentPopupId] = useState<number>(NO_POPUP_ID)
    const RenderRow = useCallback(
        ({ index, style }) => {
            const invalidateWatchdogs = () =>
                invalidateBudgetSpendWatchdogs(month)
            const row = rows[index]
            prepareRow(row)
            return (
                <Row
                    row={row}
                    {...row.getRowProps()}
                    style={style}
                    invalidateWatchdogs={invalidateWatchdogs}
                    currentPopupId={currentPopupId}
                    setCurrentPopupId={setCurrentPopupId}
                />
            )
        },
        [prepareRow, rows, month, currentPopupId],
    )

    const changeMonth = (value) => setMonth(month.substring(0, 5) + value.id)
    const changeOem = (value) => setShowOem(value.id)
    const changeYear = (value) => setMonth(value.id + month.substring(4, 7))

    return (
        <SectionCloud className="flex h-full flex-col px-8 py-6">
            <div className="flex flex-none items-baseline justify-between">
                <h1 className="mb-3 flex-initial text-3xl font-extrabold tracking-tight text-gray-600">
                    Budget Spend Watchdog 🐶
                </h1>
                <CsvLink
                    className={
                        'rounded-md bg-cyan-600 px-3 py-1 text-white ring-cyan-500 ring-offset-1 hover:bg-cyan-700 focus:outline-none focus:ring'
                    }
                    headers={csvHeaders}
                    data={() =>
                        filteredWatchdogs.map((watchdog) => ({
                            ...watchdog,
                            campaigns: watchdog.campaigns
                                .map((c) => c.name)
                                .join(';'),
                        }))
                    }
                    filename="Pear - Budget Watchdogs.csv"
                >
                    Export CSV
                </CsvLink>
            </div>
            <div className="flex flex-none gap-8">
                <div className="flex w-80 gap-6">
                    <div className="w-28">
                        <StatelessSelect
                            className="mt-1"
                            label="Year"
                            value={years.find(
                                ({ id }) => id === month.substring(0, 4),
                            )}
                            options={years}
                            onChange={changeYear}
                        />
                    </div>
                    <div className="w-40">
                        <StatelessSelect
                            className="mt-1"
                            label="Month"
                            options={months}
                            value={months.find(
                                ({ id }) => id === month.substring(5, 7),
                            )}
                            onChange={changeMonth}
                        />
                    </div>
                </div>
                <div className="w-40">
                    <StatelessSelect
                        className="mt-1"
                        label="OEM"
                        options={oems}
                        value={oems.find(({ id }) => id === showOem)}
                        onChange={changeOem}
                    />
                </div>
                <div className=" w-64">
                    <StatelessTextBox
                        classNameInput="mt-1"
                        label="Search"
                        text={search}
                        onChange={setSearch}
                    />
                </div>
                <div className="w-40">
                    <StatelessNumberBox
                        className=""
                        label="Minimum Percent"
                        value={minPercent}
                        min={0}
                        max={100}
                        onChange={setMinPercent}
                    />
                </div>
                <div className="-mr-1.5 w-44 pt-8">
                    <Toggle
                        className=""
                        label="Unignored/Ignored"
                        enabled={showIgnored}
                        setEnabled={setShowIgnored}
                    />
                </div>
                <div className="w-64 pl-2">
                    <ButtonGroup
                        className="w-full"
                        label="BCDF Filter"
                        choices={bcdfOptions}
                        value={showBcdf}
                        setValue={setShowBcdf}
                    />
                </div>
            </div>
            <div className="mt-4 min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50 pl-2"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className="flex gap-4"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const justification = [
                                                        'budget',
                                                        'spend',
                                                        'spendPercent',
                                                        'spendDifference',
                                                    ].includes(id)
                                                        ? 'text-right'
                                                        : 'text-left'
                                                    const spacing =
                                                        columnWidths[id]
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <button
                                                            className={classNames(
                                                                'track group py-2 text-xs font-medium uppercase text-gray-700 focus:font-semibold focus:text-cyan-900 focus:outline-none',
                                                                justification,
                                                                spacing,
                                                                [
                                                                    'ignore',
                                                                    'overspend',
                                                                ].includes(
                                                                    id,
                                                                ) && 'pl-1',
                                                                column.disableSortBy &&
                                                                    'cursor-default',
                                                            )}
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}
                                                            title={undefined}
                                                            disabled={
                                                                column.disableSortBy
                                                            }
                                                        >
                                                            <span className="">
                                                                {header ===
                                                                'Condition'
                                                                    ? 'Cond.'
                                                                    : header}
                                                            </span>
                                                            {sortBy[0].id ===
                                                            column.id ? (
                                                                column.isSortedDesc ? (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            descending
                                                                        </span>
                                                                        <ArrowDownIcon
                                                                            className="ml-2 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-gray-700 "
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            ascending
                                                                        </span>
                                                                        <ArrowUpIcon
                                                                            className="ml-2 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-gray-700"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )
                                                            ) : !column.disableSortBy ? (
                                                                <ArrowDownIcon
                                                                    className="ml-2 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : null}
                                                        </button>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex-1 bg-white">
                                    {rows.length > 0 ? (
                                        <div
                                            className="h-full"
                                            {...getTableBodyProps()}
                                        >
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <List
                                                        height={height}
                                                        width={width}
                                                        itemCount={rows.length}
                                                        itemSize={36}
                                                        style={{
                                                            overflowX: 'hidden',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        {RenderRow}
                                                    </List>
                                                )}
                                            </AutoSizer>
                                        </div>
                                    ) : status === 'loading' ||
                                      status === 'idle' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    No watchdogs were found for
                                                    the current filter settings.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}

const Row = memo(
    ({
        row,
        showDetails,
        style,
        invalidateWatchdogs,
        currentPopupId,
        setCurrentPopupId,
        ...otherProps
    }: {
        row: any
        showDetails: (campaigns: [], sourceName) => void
        style: CSSProperties
        invalidateWatchdogs: () => void
        currentPopupId: number
        setCurrentPopupId: (id: number) => void
    }) => {
        return (
            <div
                className="flex items-center gap-4 pl-2"
                {...otherProps}
                style={style}
            >
                {row.cells.map((cell) => {
                    const { value } = cell
                    const { id } = cell?.column ?? {}
                    const justification = [
                        'budget',
                        'spend',
                        'spendPercent',
                        'spendDifference',
                    ].includes(id)
                        ? 'text-right pr-1'
                        : ['campaigns'].includes(id)
                        ? 'pl-6'
                        : ''
                    const spacing = columnWidths[id]
                    const color =
                        id === 'sourceName' ? 'text-gray-700' : 'text-gray-900'

                    return (
                        <div
                            className={classNames(
                                'truncate text-xs ',
                                justification,
                                spacing,
                                color,
                            )}
                            {...cell.getCellProps()}
                        >
                            {id === 'campaigns' ? (
                                <div className="flex h-8 items-center">
                                    <PopupDialog
                                        popupId={row.index}
                                        currentPopupId={currentPopupId}
                                        setCurrentPopupId={setCurrentPopupId}
                                        placement="left"
                                        render={({ close, labelId }) => (
                                            <div className="overflow-hidden rounded-lg border border-gray-100 bg-white px-3 py-3 text-left align-bottom shadow-lg outline-none sm:my-8 sm:w-full sm:max-w-3xl sm:p-8 sm:align-middle">
                                                <div className="h-full">
                                                    <div className="flex items-start justify-between">
                                                        <h2
                                                            id={labelId}
                                                            className="text-lg font-medium tracking-tight text-gray-900"
                                                        >
                                                            Campaigns
                                                        </h2>
                                                    </div>
                                                    {row.original
                                                        .productName !==
                                                    'undefined' ? (
                                                        <p className="text-sm">
                                                            {
                                                                row.original
                                                                    .productName
                                                            }
                                                        </p>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                    {row.original.promoName !==
                                                    'undefined' ? (
                                                        <p className="text-sm">
                                                            {
                                                                row.original
                                                                    .promoName
                                                            }
                                                        </p>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                    {row.original
                                                        .clientSourceName !==
                                                    'undefined' ? (
                                                        <p className="text-sm">
                                                            Source:&nbsp;
                                                            {
                                                                row.original
                                                                    .clientSourceName
                                                            }
                                                        </p>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                                <div className="relative mt-2 max-h-64 overflow-y-scroll border-y-2 border-cyan-500 text-xs">
                                                    {value.length > 0 ? (
                                                        <ul className="h-full py-1 pr-2">
                                                            {value.map(
                                                                ({
                                                                    id,
                                                                    name,
                                                                    status,
                                                                }) => (
                                                                    <li
                                                                        key={id}
                                                                        className="flex gap-6 py-1 text-gray-700"
                                                                    >
                                                                        <div className="flex-1">
                                                                            {
                                                                                name
                                                                            }
                                                                        </div>
                                                                        <div className="w-14">
                                                                            {
                                                                                status
                                                                            }
                                                                        </div>
                                                                    </li>
                                                                ),
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <div className="h-full py-4 px-8 text-center text-gray-700">
                                                            No matched
                                                            campaigns.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    >
                                        <button
                                            type="button"
                                            className="rounded p-1 text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500"
                                        >
                                            {row.original.campaigns.reduce(
                                                (total, campaign) =>
                                                    campaign.status === 'PAUSED'
                                                        ? total + 1
                                                        : total,
                                                0,
                                            ) +
                                                '/' +
                                                row.original.campaigns.length}
                                        </button>
                                    </PopupDialog>
                                </div>
                            ) : id === 'ignore' ? (
                                <IgnoreSwitch
                                    ignore={cell.value}
                                    invalidateWatchdogs={invalidateWatchdogs}
                                    watchdogId={cell.row.original.id}
                                />
                            ) : id === 'overspend' ? (
                                <OverspendEntry
                                    initialOverspend={cell.value}
                                    invalidateWatchdogs={invalidateWatchdogs}
                                    watchdogId={cell.row.original.id}
                                />
                            ) : (
                                cell.render('Cell')
                            )}
                        </div>
                    )
                })}
            </div>
        )
    },
)

const IgnoreSwitch = ({ invalidateWatchdogs, watchdogId, ignore }) => {
    const [ignoreInternal, setIgnoreInternal] = useState(ignore)
    const { mutate: updateIgnore } = useMutation(
        (ignore: boolean) =>
            PearApi.updateBudgetSpendWatchdog(watchdogId, { ignore }),
        {
            onSettled: () => {
                invalidateWatchdogs()
            },
        },
    )
    const change = (value) => {
        setIgnoreInternal(value)
        updateIgnore(value)
    }

    return (
        <Toggle
            className="h-8 items-center pl-1"
            label="Ignore"
            enabled={ignoreInternal}
            setEnabled={change}
            showLabel={false}
        />
    )
}

const OverspendEntry = ({
    invalidateWatchdogs,
    initialOverspend,
    watchdogId,
}) => {
    const [newOverspend, setNewOverspend] = useState(initialOverspend)

    const {
        mutate: updateOverspend,
        isLoading,
        isPaused,
    } = useMutation(
        (overspend: number) =>
            PearApi.updateBudgetSpendWatchdog(watchdogId, { overspend }),
        {
            onSettled: () => {
                invalidateWatchdogs()
            },
        },
    )

    return (
        <div className="flex h-11 items-baseline pl-1 pt-0.5">
            <CompactStatelessNumberBox
                className="w-24"
                min={0}
                max={9999}
                value={newOverspend}
                onChange={setNewOverspend}
            />
            <button
                className="ml-1 rounded px-1 text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1 disabled:pointer-events-none disabled:text-gray-600"
                onClick={() => updateOverspend(newOverspend)}
                disabled={isLoading || isPaused}
            >
                Save
            </button>
        </div>
    )
}

export default BudgetSpendWatchdog
