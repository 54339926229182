import { RadioGroup } from '@headlessui/react'
import { PlusIcon, TrashIcon, ArrowPathIcon } from '@heroicons/react/20/solid'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'
import { classNames } from '../../utility/Components'

interface ButtonProps {
    className: string
    type: 'button' | 'submit'
    disabled: boolean
    children: React.ReactNode
    // otherProps
    [name: string]: any
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (props, ref) => {
        const {
            className = '',
            type = 'button',
            disabled = false,
            children,
            ...otherProps
        } = props
        return (
            <button
                ref={ref}
                className={classNames(
                    'rounded-md px-3 py-1 focus:outline-none',
                    disabled
                        ? 'pointer-events-none bg-gray-200 text-gray-600'
                        : 'bg-cyan-600 text-white hover:bg-cyan-700 focus:ring focus:ring-cyan-500 focus:ring-offset-1',
                    className,
                )}
                disabled={disabled}
                type={type}
                {...otherProps}
            >
                {children}
            </button>
        )
    },
)

export const LinkButton = ({
    className = '',
    type = 'link' as 'link' | 'a',
    to,
    children,
    ...otherProps
}) =>
    type === 'link' ? (
        <Link
            className={classNames(
                'rounded-md bg-cyan-600 px-3 py-1 text-white hover:bg-cyan-700 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1',
                className,
            )}
            to={to}
            {...otherProps}
        >
            {children}
        </Link>
    ) : (
        <a
            className={classNames(
                'rounded-md bg-cyan-600 px-3 py-1 text-white hover:bg-cyan-700 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1',
                className,
            )}
            href={to}
            {...otherProps}
        >
            {children}
        </a>
    )

export const TextButton = ({
    className = '',
    type = 'button' as 'button' | 'submit',
    disabled = false,
    children,
    ...otherProps
}) => (
    <button
        className={classNames(
            'rounded-md px-3 py-1 focus:outline-none',
            disabled
                ? 'pointer-events-none text-gray-400'
                : 'text-cyan-600 hover:font-semibold focus:ring focus:ring-cyan-500',
            className,
        )}
        disabled={disabled}
        type={type}
        {...otherProps}
    >
        {children}
    </button>
)

export const CirclePlusButton = ({
    type = 'button' as 'button' | 'submit' | 'reset',
    className = '',
    alt,
    onClick = undefined,
    ...otherProps
}) => (
    <button
        type={type}
        onClick={onClick}
        className={classNames(
            'inline-flex flex-none items-center rounded-full border border-transparent bg-cyan-700 p-2 text-white shadow-sm hover:bg-cyan-800 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1 disabled:bg-gray-300 disabled:text-white',
            className,
        )}
        {...otherProps}
    >
        <span className="sr-only">{alt}</span>
        <PlusIcon className="h-5 w-5" aria-hidden="true" />
    </button>
)

export const ReloadButton = ({
    type = 'button' as 'button' | 'submit' | 'reset',
    className = '',
    alt = 'Reload',
    onClick = () => {},
    isReloading = false,
    ...otherProps
}) => (
    <button
        type={type}
        onClick={onClick}
        className={classNames(
            'inline-flex flex-none items-center rounded-full border border-transparent p-1 text-gray-450 hover:text-gray-800 focus:bg-white focus:outline-none focus:ring focus:ring-cyan-500 disabled:cursor-not-allowed disabled:text-gray-300',
            className,
        )}
        disabled={isReloading}
        {...otherProps}
    >
        <span className="sr-only">{alt}</span>
        <ArrowPathIcon
            className={classNames('h-5 w-5', isReloading && 'animate-spin')}
            aria-hidden="true"
        />
    </button>
)

export const TrashButton = ({
    type = 'button' as 'button' | 'submit' | 'reset',
    className = '',
    alt,
    onClick,
    ...otherProps
}) => (
    <button
        type={type}
        onClick={onClick}
        className={classNames(
            'inline-flex flex-none items-center rounded-full border border-transparent p-2 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring focus:ring-cyan-500',
            className,
        )}
        {...otherProps}
    >
        <span className="sr-only">{alt}</span>
        <TrashIcon className="h-5 w-5" aria-hidden="true" />
    </button>
)

export interface ButtonGroupChoice {
    value: string
    name: string
}

/**
 * Renders a group of on/off buttons where only one is selectable at a given time.
 * @param props label, choices ({value, name} objects), initialValue (value of choice
 *  to be selected initially), onChange (function that takes a value)
 * @returns Element that is a button group.
 */
const ButtonGroup = ({
    label,
    choices,
    value,
    setValue,
    className = '',
}: {
    label: string
    choices: Readonly<ButtonGroupChoice[]>
    value: typeof choices[number]['value']
    setValue: (value: typeof choices[number]['value']) => void
    className?: string
}) => {
    return (
        <RadioGroup
            value={value}
            onChange={setValue}
            className={classNames('-mt-1', className)}
        >
            <RadioGroup.Label className="text-sm font-medium text-gray-700">
                {label}
            </RadioGroup.Label>
            <div className="relative z-0 mt-1 flex rounded-md shadow-sm">
                {choices.map(({ value, name }, index) => (
                    <RadioGroup.Option
                        key={value}
                        value={value}
                        as="span"
                        className={({ checked }) =>
                            classNames(
                                'relative inline-flex flex-grow cursor-pointer items-center justify-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 outline-none hover:bg-gray-50',
                                checked
                                    ? 'z-10 border-gray-500 ring-1 ring-gray-700 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-500'
                                    : '',
                                index === 0 && 'rounded-l-md',
                                index === choices.length - 1 && 'rounded-r-md',
                            )
                        }
                    >
                        {name}
                    </RadioGroup.Option>
                ))}
            </div>
        </RadioGroup>
    )
}

export default ButtonGroup
