import { useState } from 'react'
import { Button } from '../components/elements/Buttons'
import { SectionCloud } from '../components/Layout'
import { usePromoAdBuilderClients, usePromoAds } from '../hooks/apiHooks'
import Step1 from '../components/PromoAdBuilder/Step1'
import Step2 from '../components/PromoAdBuilder/Step2'
import Step3 from '../components/PromoAdBuilder/Step3'

export const NO_PROMO_AD_SELECTION = {
    id: 0,
    name: 'None',
    image: '',
}

const PromoAdBuilder = () => {
    const [step, setStep] = useState(1)

    const { data: promoAdData, status: promoAdStatus } = usePromoAds()
    const promoAds = promoAdData ?? []

    // This is the actual promo object. A null object is used for no selection yet.
    const [selectedPromoAd, setSelectedPromoAd] = useState(
        NO_PROMO_AD_SELECTION,
    )

    const { data: clientData, status: clientsStatus } =
        usePromoAdBuilderClients()
    const clients = clientData ?? []

    const [selectedClients, setSelectedClients] = useState(new Set<number>())

    const clientListLength = selectedClients.size

    return (
        <SectionCloud className="flex h-full flex-col gap-2 px-8 py-6">
            <div className="flex flex-none items-end justify-between">
                <h1 className="flex-initial text-3xl font-extrabold tracking-tight text-gray-600">
                    Promo Ad Builder
                </h1>
                <div className="flex flex-none justify-between gap-8">
                    <div>
                        {
                            <Button
                                onClick={() => {
                                    setStep((step) => step - 1)
                                }}
                                disabled={step <= 1}
                            >
                                <span aria-hidden="true">{'< '}</span>Previous
                                <span className="sr-only">Step</span>
                            </Button>
                        }
                    </div>
                    <div>
                        {
                            <Button
                                onClick={() => {
                                    setStep((step) => step + 1)
                                }}
                                disabled={
                                    step >= 3 ||
                                    (step === 1 &&
                                        selectedPromoAd ===
                                            NO_PROMO_AD_SELECTION) ||
                                    (step === 2 && clientListLength <= 0)
                                }
                            >
                                Next
                                <span className="sr-only">Step</span>
                                <span aria-hidden="true">{' >'}</span>
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {step === 1 ? (
                <Step1
                    {...{
                        promoAds,
                        promoAdStatus,
                        selectedPromoAd,
                        setSelectedPromoAd,
                    }}
                ></Step1>
            ) : step === 2 ? (
                <Step2
                    {...{
                        clients,
                        status: clientsStatus,
                        selectionList: selectedClients,
                        setSelectionList: setSelectedClients,
                    }}
                ></Step2>
            ) : step === 3 ? (
                <Step3
                    {...{
                        selectedPromoAd,
                        selectedClients,
                    }}
                ></Step3>
            ) : null}
        </SectionCloud>
    )
}

export default PromoAdBuilder
