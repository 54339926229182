import { useState, useMemo } from 'react'

/** Hook to provide ways to move through a list. Takes an initial index from the
 *  list which is assumed to be -1 for no selection or a valid index in the list.
 *  Returns the index and an object with functions to move the index through the list.
 *  The list length is required to create the index setting functions. */
const useListIndex = (initialIndex: number = -1) => {
    const [index, unsafelySetIndex] = useState(initialIndex)

    /** Safely increase or decrease the index by an amount (wrap to other end if necessary) */
    const dependantHandlers = useMemo(
        () => ({
            adjustIndex: (listLength: number) => (amount) => {
                const possibleNewIndex = amount + index
                const newIndex =
                    possibleNewIndex < 0
                        ? listLength - 1
                        : possibleNewIndex >= listLength
                        ? 0
                        : possibleNewIndex
                unsafelySetIndex(newIndex)
            },

            /** Safely set the index (nearest valid index) */
            setIndex: (listLength: number) => (possibleNewIndex) => {
                const newIndex =
                    possibleNewIndex < 0
                        ? 0
                        : possibleNewIndex >= listLength
                        ? listLength - 1
                        : possibleNewIndex
                unsafelySetIndex(newIndex)
            },
        }),
        [index],
    )

    const independantHandlers = useMemo(
        () => ({
            /** Set index to guard value for no index */
            unsetIndex: () => unsafelySetIndex(-1),
        }),
        [],
    )

    return [index, { ...dependantHandlers, ...independantHandlers }] as const
}

export default useListIndex
