import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpDownIcon,
} from '@heroicons/react/20/solid'
import { months } from '../../utility/Time'
import { getYear, parseISO } from 'date-fns'
import { classNames } from '../../utility/Components'

const paddedMonthNumber = (num) => (num < 10 && num > 0 ? `0${num}` : `${num}`)

/**
 * Displays the currently selected year and month and allows for quickly incrementing
 * or decrementing the month or selecting a new year and month efficiently.
 * @param props.month ISO string significant to the month like "2023-01" (format for all ISO string props)
 * @param props.setMonth A function that receives a newly selected month as an ISO string
 * @param props.firstMonth ISO string for the first valid month to select (oldest)
 * @param props.lastMonth ISO string for the last valid month to select (most recent)
 * @returns Date display and selection element
 */
const MonthDropdownWidget = ({
    month,
    setMonth,
    firstMonth,
    lastMonth,
}: {
    month: string
    setMonth: (month: string) => void
    firstMonth: string
    lastMonth: string
}) => {
    const monthAlone = month.substring(5, 7)
    const yearAlone = month.substring(0, 4)

    const [selectorYear, setSelectorYear] = useState(
        parseInt(month.substring(0, 4), 10),
    )
    useEffect(() => {
        setSelectorYear(parseInt(month.substring(0, 4), 10))
    }, [month])

    const changeMonth = (monthsToAdd) => {
        const monthNum = parseInt(monthAlone, 10)
        const yearNum = parseInt(yearAlone, 10)

        const potentialNewMonth = monthNum + monthsToAdd
        const sameYear = potentialNewMonth >= 1 && potentialNewMonth <= 12

        if (sameYear) {
            setMonth(`${yearNum}-${paddedMonthNumber(potentialNewMonth)}`)
        }
        // Going back a year
        else if (!sameYear && potentialNewMonth < 1) {
            setMonth(
                `${yearNum - 1}-${paddedMonthNumber(potentialNewMonth + 12)}`,
            )
        }
        // Going forward a year (!sameYear && potentialNewMonth > 12)
        else {
            setMonth(
                `${yearNum + 1}-${paddedMonthNumber(potentialNewMonth - 12)}`,
            )
        }
    }

    const currentDate = parseISO(month)
    const firstDate = parseISO(firstMonth)
    const lastDate = parseISO(lastMonth)

    return (
        <div className="flex flex-col">
            <label className="block text-sm font-medium text-gray-700">
                Month
            </label>

            <Popover>
                <div className="relative mt-1 flex gap-1">
                    <button
                        className={classNames(
                            !(currentDate > firstDate)
                                ? 'cursor-not-allowed'
                                : 'hover:border-gray-100 hover:bg-gray-100',
                            'group flex-none rounded-md border border-gray-50 bg-gray-50 px-0.5  focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
                        )}
                        aria-label="Previous Month"
                        onClick={() => changeMonth(-1)}
                        disabled={!(currentDate > firstDate)}
                    >
                        <ChevronLeftIcon
                            className={classNames(
                                !(currentDate > firstDate)
                                    ? 'text-gray-300'
                                    : 'text-gray-600 group-hover:text-gray-700',
                                'h-8 w-6',
                            )}
                            aria-hidden="true"
                        />
                    </button>
                    <Popover.Button
                        as="button"
                        className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                    >
                        <span className="block truncate">
                            {month} (
                            {
                                months.find((m) => m.id === monthAlone)
                                    .abbreviation
                            }
                            )
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-500"
                                aria-hidden="true"
                            />
                        </span>
                    </Popover.Button>
                    <button
                        className={classNames(
                            !(currentDate < lastDate)
                                ? 'cursor-not-allowed'
                                : 'hover:border-gray-100 hover:bg-gray-100',
                            'group flex-none rounded-md border border-gray-50 bg-gray-50 px-0.5 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
                        )}
                        aria-label="Next Month"
                        onClick={() => changeMonth(1)}
                        disabled={!(currentDate < lastDate)}
                    >
                        <ChevronRightIcon
                            className={classNames(
                                !(currentDate < lastDate)
                                    ? 'text-gray-300'
                                    : 'text-gray-600 group-hover:text-gray-700',
                                'h-8 w-6',
                            )}
                            aria-hidden="true"
                        />
                    </button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel className="absolute left-0 top-[2.75rem] z-10 w-full origin-top-right rounded-md bg-white p-4 py-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="grid h-10 grid-cols-3 gap-x-2">
                                <button
                                    className={classNames(
                                        selectorYear <= getYear(firstDate)
                                            ? 'cursor-not-allowed'
                                            : 'hover:bg-gray-100',
                                        'group flex flex-none items-center justify-center rounded-md border border-gray-200 bg-gray-50 px-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
                                    )}
                                    aria-label="Previous Year"
                                    onClick={() =>
                                        setSelectorYear(selectorYear - 1)
                                    }
                                    disabled={
                                        selectorYear <= getYear(firstDate)
                                    }
                                >
                                    <ChevronLeftIcon
                                        className={classNames(
                                            selectorYear <= getYear(firstDate)
                                                ? 'text-gray-300'
                                                : 'text-gray-500 group-hover:text-gray-600',
                                            'h-6 w-6',
                                        )}
                                        aria-hidden="true"
                                    />
                                </button>
                                <span className="flex w-full items-center justify-center text-center text-xl font-medium text-gray-600">
                                    {selectorYear}
                                </span>
                                <button
                                    className={classNames(
                                        selectorYear >= getYear(lastDate)
                                            ? 'cursor-not-allowed'
                                            : 'hover:bg-gray-100',
                                        'group flex flex-none items-center justify-center rounded-md border border-gray-200 bg-gray-50 px-1 focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
                                    )}
                                    aria-label="Next Year"
                                    onClick={() =>
                                        setSelectorYear(selectorYear + 1)
                                    }
                                    disabled={selectorYear >= getYear(lastDate)}
                                >
                                    <ChevronRightIcon
                                        className={classNames(
                                            selectorYear >= getYear(lastDate)
                                                ? 'text-gray-300'
                                                : 'text-gray-500 group-hover:text-gray-600',
                                            'h-6 w-6',
                                        )}
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div className="mt-6 grid grid-cols-3 grid-rows-4 gap-2">
                                {months.map((month) => {
                                    const { id, label, abbreviation } = month

                                    const isDisabled =
                                        parseISO(`${selectorYear}-${id}`) <
                                            firstDate ||
                                        parseISO(`${selectorYear}-${id}`) >
                                            lastDate

                                    return (
                                        <Popover.Button
                                            key={label}
                                            className={classNames(
                                                isDisabled
                                                    ? 'cursor-not-allowed border-gray-100 text-gray-300'
                                                    : 'border-gray-200 text-gray-700 hover:bg-gray-100 hover:text-gray-800',
                                                'flex flex-col items-center rounded-md border bg-gray-50 py-1 px-0  focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
                                            )}
                                            onClick={() =>
                                                setMonth(
                                                    `${selectorYear}-${id}`,
                                                )
                                            }
                                            disabled={isDisabled}
                                        >
                                            <span className="text-lg font-bold">
                                                {parseInt(id)}
                                            </span>
                                            <span className="text-sm">
                                                {abbreviation}
                                            </span>
                                        </Popover.Button>
                                    )
                                })}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </div>
            </Popover>
        </div>
    )
}

export default MonthDropdownWidget
