import { dollarFormat } from '../utility/Formatting'

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce(
            (error, validate) => error || validate(value),
            undefined,
        )

export const notRequired = (validate) => (value) =>
    value !== undefined ? validate(value) : undefined

export const minDollars = (min) => (value) =>
    value < min ? `No less than ${dollarFormat(min, 2)}` : undefined

export const maxDollars = (max) => (value) =>
    value > max ? `No more than ${dollarFormat(max, 2)}` : undefined

export const notEmpty = (value) =>
    value === undefined || value === '' ? 'Please fill this out.' : undefined

export const maxLength = (lengthInChars: number) => (value) =>
    typeof value === 'string' && value.length > lengthInChars
        ? `Must be less than ${lengthInChars} total characters.`
        : undefined

export const isANumber = (value) =>
    isNaN(parseFloat(value)) ? 'Please enter a number.' : undefined

export const trimWhitespace =
    (validate: (value: any) => undefined | string) => (value: any) =>
        typeof value === 'string' ? validate(value.trim()) : validate(value)
