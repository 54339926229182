import {
    createRef,
    CSSProperties,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTable, useSortBy } from 'react-table'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid'
import StatusDisplay from '../StatusDisplay'
import { dollarFormat, timeZoneNameFormat } from '../../utility/Formatting'
import Icon from '../Icon'
import useListIndex from '../../hooks/useListIndex'
import { useUrlQueryParameter } from '../../hooks/useUrlQueryParameter'
import {
    rowOriginalsByPropertyId,
    rowValuesByColumnId,
} from '../../utility/ReactTable'
import {
    prefetchClientNote,
    prefetchSpendBreakdown,
} from '../../hooks/apiHooks'
import { classNames } from '../../utility/Components'
import { timeZoneOffset, timeOffsetFromUser } from '../../utility/Time'
import { PacingCell } from './PacingCell'
import { SortTypes, SortValue } from '../../pages/Budgeting'
import TruncatableLiteralText from '../elements/TruncatableLiteralText'

const clientInfoSort = (row1, row2, columnId) => {
    const [clientName1, clientName2] = rowValuesByColumnId(row1, row2, columnId)
    if (clientName1 < clientName2) {
        return -1
    }
    if (clientName1 > clientName2) {
        return 1
    }
    const [source1, source2] = rowOriginalsByPropertyId(row1, row2, 'source')
    if (source1 < source2) {
        return -1
    }
    if (source1 > source2) {
        return 1
    }
    const [label1, label2] = rowOriginalsByPropertyId(row1, row2, 'label')
    const firstLabel = 'Else'
    if (label1 === firstLabel) {
        return -1
    }
    if (label2 === firstLabel) {
        return 1
    }
    if (label1 < label2) {
        return -1
    }
    if (label1 > label2) {
        return 1
    }
    return 0
}

const alphabeticalSort = (row1, row2, columnId) => {
    const [property1, property2] = rowValuesByColumnId(row1, row2, columnId)
    if (property1 < property2) {
        return -1
    }
    if (property1 > property2) {
        return 1
    }
    return 0
}

const pacingPercentSort = (row1, row2, columnId) => {
    const [percent1, percent2] = rowValuesByColumnId(row1, row2, columnId)
    return Math.abs(percent1) === Math.abs(percent2)
        ? 0
        : Math.abs(percent1) < Math.abs(percent2)
        ? -1
        : 1
}

// Sort higher offsets first so Eastern time sorts before Pacific.
const timeZoneSort = (row1, row2, columnId) => {
    const [timeZone1, timeZone2] = rowValuesByColumnId(row1, row2, columnId)
    return timeZoneOffset(timeZone2) - timeZoneOffset(timeZone1)
}

export const BudgetListAccessors = {
    CLIENT_INFO: 'clientName',
    TIME_ZONE: 'timeZone',
    OEM_TYPE: 'sortType',
    SPEND: 'total',
    BUDGET: 'budget',
    PACING_PERCENT: 'latestPercent',
    DAY_BEFORE_PERCENT: 'dayBeforePercent',
} as const

const columnGrid = 'flex pl-4'
const columnWidths = {
    [BudgetListAccessors.CLIENT_INFO]: 'min-w-0 flex-1 mr-3',
    [BudgetListAccessors.SPEND]: 'w-20 mr-4',
    [BudgetListAccessors.BUDGET]: 'w-20 mr-5',
    [BudgetListAccessors.PACING_PERCENT]: 'w-[5.75rem] mr-1',
    [BudgetListAccessors.DAY_BEFORE_PERCENT]: 'w-[5.75rem]',
}
const rightAlignColumns = [
    BudgetListAccessors.SPEND,
    BudgetListAccessors.BUDGET,
]

const columns = [
    {
        Header: 'Client Info',
        accessor: BudgetListAccessors.CLIENT_INFO,
        Cell: ({
            cell: {
                value: clientName,
                row: { id },
            },
            data,
            isSelected,
            sort,
        }) => {
            const { code, source, label, timeZone, sortTypeLabel, endDate } =
                data[id]
            const timeZoneSortEnabled = sort === SortTypes.TIME_ZONE
            const typeSortEnabled = sort === SortTypes.OEM_TYPE
            return (
                <div className="">
                    <div className="grid grid-cols-4 gap-1">
                        <div
                            className={classNames(
                                'col-span-2 truncate pt-1 text-xs font-medium leading-3',
                                isSelected ? 'text-gray-900' : 'text-gray-800',
                            )}
                        >
                            {clientName}
                        </div>
                        {endDate && (
                            <div className="col-span-2 inline text-right align-baseline text-xs text-gray-700">
                                End Date: {endDate}
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-4 gap-1">
                        <div
                            className={classNames(
                                'truncate',
                                code && (timeZoneSortEnabled || typeSortEnabled)
                                    ? 'col-span-2'
                                    : code ||
                                      timeZoneSortEnabled ||
                                      typeSortEnabled
                                    ? 'col-span-3'
                                    : 'col-span-4',
                            )}
                        >
                            <span
                                className={classNames(
                                    'mr-2 h-4 align-baseline',
                                    isSelected
                                        ? 'text-gray-700'
                                        : 'text-gray-600',
                                )}
                            >
                                <Icon type={source} />
                            </span>
                            <TruncatableLiteralText
                                className={classNames(
                                    'inline align-baseline text-xs text-gray-700',
                                    isSelected ? 'font-medium' : '',
                                )}
                            >
                                {label}
                            </TruncatableLiteralText>
                        </div>
                        {code && (
                            <div className="truncate text-right">
                                <span
                                    className={classNames(
                                        'align-baseline text-xs text-gray-700',
                                        isSelected ? 'font-semibold' : '',
                                    )}
                                    onClick={(event) => {
                                        event.stopPropagation()
                                    }}
                                >
                                    {code}
                                </span>
                            </div>
                        )}
                        {timeZoneSortEnabled && (
                            <div className="text-right">
                                <span
                                    className={classNames(
                                        `align-baseline text-xs text-gray-700`,
                                        isSelected ? 'font-semibold' : '',
                                    )}
                                >
                                    {timeZoneNameFormat(timeZone)}
                                </span>
                                <span
                                    className={classNames(
                                        `align-baseline text-xs font-light`,
                                        isSelected
                                            ? 'text-gray-800'
                                            : ' text-gray-700',
                                    )}
                                >
                                    {' '}
                                    {timeOffsetFromUser(timeZone) === '+0'
                                        ? ''
                                        : `(${timeOffsetFromUser(timeZone)}h)`}
                                </span>
                            </div>
                        )}
                        {typeSortEnabled && !timeZoneSortEnabled && (
                            <div className="truncate text-right">
                                <span
                                    className={classNames(
                                        `align-baseline text-xs text-gray-700`,
                                        isSelected ? 'font-semibold' : '',
                                    )}
                                >
                                    {sortTypeLabel}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            )
        },
        sortType: clientInfoSort,
    },
    {
        Header: 'Time Zone',
        accessor: BudgetListAccessors.TIME_ZONE,
        sortType: timeZoneSort,
    },
    {
        Header: 'Type',
        accessor: BudgetListAccessors.OEM_TYPE,
        sortType: alphabeticalSort,
    },
    {
        Header: 'Spend',
        accessor: BudgetListAccessors.SPEND,
        Cell: ({ cell: { value: total }, isSelected }) => (
            <div className="h-full text-right">
                <span
                    className={classNames(
                        'text-xs font-medium',
                        isSelected ? 'text-gray-900' : 'text-gray-700',
                    )}
                >
                    {dollarFormat(total)}
                </span>
            </div>
        ),
    },
    {
        Header: 'Budget',
        accessor: BudgetListAccessors.BUDGET,
        Cell: ({ cell: { value: budget }, isSelected }) => (
            <div className="h-full text-right">
                <span
                    className={classNames(
                        'text-xs font-medium',
                        isSelected ? 'text-gray-900' : 'text-gray-700',
                    )}
                >
                    {dollarFormat(budget)}
                </span>
            </div>
        ),
    },
    {
        Header: 'Pacing',
        accessor: BudgetListAccessors.PACING_PERCENT,
        Cell: ({ cell: { value: latestPercent }, isSelected }) => (
            <div className="pr-1">
                <PacingCell
                    percentage={latestPercent}
                    isSelected={isSelected}
                />
            </div>
        ),
        sortType: pacingPercentSort,
    },
    {
        Header: 'Pacing -1',
        accessor: BudgetListAccessors.DAY_BEFORE_PERCENT,
        Cell: ({ cell: { value: dayBeforePercent }, isSelected }) => (
            <div className="pr-1">
                <PacingCell
                    percentage={dayBeforePercent}
                    isSelected={isSelected}
                />
            </div>
        ),
        sortType: pacingPercentSort,
    },
]

interface ReactWindowElement {
    scrollToItem: (number) => void
}

export const BudgetingItemList = ({
    data = [],
    status,
    isFetching,
    sort = SortTypes.OEM_TYPE as string,
    setSort = (sortType: string, desc?: boolean) => {},
    setSelectedId,
    yearMonth,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setSortBy,
        state: tableState,
    } = useTable(
        {
            columns,
            data,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: [
                    {
                        id: 'latestPercent',
                        desc: true,
                    },
                ],
                hiddenColumns: ['timeZone', 'sortType'],
            },
        },
        useSortBy,
    )

    // Stuff for URL paramter for current line item ID.
    const [urlSelectedLineItemId, setUrlSelectedLineItemId] =
        useUrlQueryParameter('lineItemId', '')

    // Stuff to setup row selection
    const [selectedRow, { adjustIndex, setIndex, unsetIndex: unselectRows }] =
        useListIndex(-1)

    const rowLength = rows.length
    const moveSelectedRow = useMemo(
        () => adjustIndex(rowLength),
        [rowLength, adjustIndex],
    )
    const selectRow = useMemo(() => setIndex(rowLength), [rowLength, setIndex])

    const setUrlByRow = useCallback(
        (rowId: number, rows: any) => {
            const lineItemId =
                rowId >= 0 && rowId < rows.length ? rows[rowId].original.id : 0
            setUrlSelectedLineItemId(lineItemId)
        },
        [setUrlSelectedLineItemId],
    )

    const setSelectedIdByRow = useCallback(
        (rowId: number, rows: any) => {
            const lineItemId =
                rowId >= 0 && rowId < rows.length ? rows[rowId].original.id : 0
            setSelectedId(lineItemId)
        },
        [setSelectedId],
    )

    const [userHasSelectedARow, setUserHasSelectedARow] = useState(false)
    const moveSelectedRowAsUser = (amount: number) => {
        setUserHasSelectedARow(true)
        moveSelectedRow(amount)
    }
    const selectRowAsUser = useCallback(
        (row: number) => {
            setUserHasSelectedARow(true)
            selectRow(row)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setUserHasSelectedARow, selectRow],
    )
    const unselectRowsAsUser = useCallback(() => {
        setUserHasSelectedARow(true)
        unselectRows()
    }, [unselectRows, setUserHasSelectedARow])

    const moveToPrevItem = () => moveSelectedRowAsUser(-1)
    const moveToNextItem = () => moveSelectedRowAsUser(1)
    const pageSize = 5
    const moveUpAPage = () => moveSelectedRowAsUser(-pageSize)
    const moveDownAPage = () => moveSelectedRowAsUser(pageSize)
    const selectFirstRow = () => selectRowAsUser(0)

    const handleKeyEvent = ({ code: keyCode }) => {
        switch (keyCode) {
            case 'ArrowLeft':
            // fall through to Arrow Up
            case 'ArrowUp':
                moveToPrevItem()
                break
            case 'ArrowRight':
            // fall through to ArrowDown
            case 'ArrowDown':
                moveToNextItem()
                break
            case 'PageUp':
                moveUpAPage()
                break
            case 'PageDown':
                moveDownAPage()
                break
            case 'Enter':
            // fall through to Space
            case 'Space':
                selectedRow === -1 ? selectFirstRow() : unselectRowsAsUser()
                break
        }
    }

    // Stuff for scrolling to selected row
    const listRef: React.RefObject<ReactWindowElement> = createRef()
    useEffect(() => {
        if (selectedRow !== -1) {
            listRef?.current?.scrollToItem(selectedRow)
        }
    }, [listRef, selectedRow])

    useEffect(() => {
        const sortParts = sort.split('-')
        const id = sortParts[0]
        const desc = sortParts.length > 1 ? sortParts[1] === 'desc' : false

        // Ensure it's a valid sort or default to pacing percent
        // TODO: Maybe fix TS here? Not sure if this as is the right way to do it.
        if (
            Object.values(SortTypes).includes(
                id as typeof SortTypes.CLIENT_INFO,
            )
        ) {
            setSortBy([{ id, desc }])
        } else {
            setSortBy([{ id: SortTypes.PACING_PERCENT, desc: true }])
        }
    }, [sort, setSortBy])

    const setSorting = (column) => {
        const { id: accessor, isSorted, isSortedDesc } = column

        if (isSorted) {
            setSort(accessor, !isSortedDesc)
        } else {
            setSort(accessor)
        }
    }

    // When sorting is changed, select the first row, but ignore the initial sort.
    const { sortBy } = tableState
    const sortingMethod =
        sortBy.length === 0 ? 'none' : sortBy[0].id + '-' + sortBy[0].desc
    useEffect(() => {
        if (rows.length > 0 && userHasSelectedARow) {
            selectRow(0)
        }
        // We only want to run this if the sorting method has changed or the data
        // has been recently sorted before getting to here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortingMethod])

    // If we're still initializing, use the URL id to set the selected row.
    useEffect(() => {
        if (!userHasSelectedARow) {
            const possibleRowIndex = rows.findIndex(
                (row) => row.original.id === urlSelectedLineItemId,
            )
            if (possibleRowIndex !== -1) {
                selectRow(possibleRowIndex)
            }
        }
        // Only check this when the rows change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    const setSelections = (rowId: number) => {
        selectRow(rowId)
        const lineItemId = rows[rowId].original.id
        setSelectedId(lineItemId)
    }

    const removeSelections = () => {
        selectRow(0)
        setSelectedId(0)
    }

    // When filtering changes, we want to deactivate the current row
    // selection unless the current selection is in the new data, but keep the
    // URL parameter until the user makes a selection.
    useEffect(() => {
        if (userHasSelectedARow) {
            if (rows.length > 0) {
                const possibleSelection = rows.findIndex(
                    (row) => row.original.id === urlSelectedLineItemId,
                )
                if (possibleSelection !== -1) {
                    setSelections(possibleSelection)
                    return
                } else {
                    removeSelections()
                }
            } else {
                removeSelections()
            }
        }
        // We only want to update when new data comes in.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const firstRow = rows.length > 0 ? rows[0]['id'] : 'a'
    useEffect(() => {
        // After the initial data load, when the rows or selection changes, update the selected URL.
        if (userHasSelectedARow) {
            setUrlByRow(selectedRow, rows)
        }

        // At all times, when the rows or selection changes, update the selected ID.
        setSelectedIdByRow(selectedRow, rows)
        // We only want to update when rows or the selection changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstRow, selectedRow])

    const RenderRow = useCallback(
        ({ index, isScrolling, style }) => {
            const isSelected = selectedRow === index
            const row = rows[index]

            prepareRow(row)
            return (
                <Row
                    row={row}
                    changeSelection={
                        isSelected
                            ? unselectRowsAsUser
                            : () => selectRowAsUser(index)
                    }
                    {...row.getRowProps()}
                    style={style}
                    isSelected={isSelected}
                    sort={sort}
                    isScrolling={isScrolling}
                    month={yearMonth}
                />
            )
        },
        [
            prepareRow,
            rows,
            unselectRowsAsUser,
            selectRowAsUser,
            selectedRow,
            sort,
            yearMonth,
        ],
    )

    return (
        <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                <div
                    className="group h-full overflow-hidden border-t border-gray-200 bg-gray-50 shadow focus:border-cyan-700 focus:bg-cyan-100 focus:outline-none sm:rounded-b-lg"
                    tabIndex={0}
                    onKeyDown={handleKeyEvent}
                    {...getTableProps()}
                >
                    <div className="flex h-full min-w-full flex-col divide-y divide-gray-200 group-focus:divide-cyan-700">
                        <div
                            className="flex-none bg-gray-50 group-focus:bg-cyan-100"
                            style={{ width: 'calc(100% - 0.9em)' }}
                        >
                            {headerGroups.map((headerGroup) => (
                                <div
                                    className={columnGrid}
                                    {...headerGroup.getHeaderGroupProps()}
                                >
                                    {headerGroup.headers.map((column) => {
                                        const { id: columnId } = column
                                        const header = column.render('Header')
                                        return (
                                            <div
                                                className={classNames(
                                                    'group py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-700 group-focus:text-cyan-900',
                                                    rightAlignColumns.includes(
                                                        columnId,
                                                    ) && 'text-right',
                                                    columnWidths[columnId],
                                                )}
                                                {...column.getHeaderProps(
                                                    column.getSortByToggleProps(),
                                                )}
                                                title={undefined}
                                                onClick={() =>
                                                    setSorting(column)
                                                }
                                            >
                                                <span className="">
                                                    {header}
                                                </span>
                                                {column.isSorted ? (
                                                    column.isSortedDesc ? (
                                                        <>
                                                            <span className="sr-only">
                                                                sorted
                                                                descending
                                                            </span>
                                                            <ArrowDownIcon
                                                                className={`-mr-1 ml-1.5 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-cyan-700`}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="sr-only">
                                                                sorted ascending
                                                            </span>
                                                            <ArrowUpIcon
                                                                className={`-mr-1 ml-1.5 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-cyan-700`}
                                                                aria-hidden="true"
                                                            />
                                                        </>
                                                    )
                                                ) : (
                                                    <ArrowDownIcon
                                                        className={`-mr-1 ml-1.5 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-cyan-400`}
                                                        aria-hidden="true"
                                                    />
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                        <div className="block flex-1 bg-white">
                            {data.length > 0 && !isFetching ? (
                                <div
                                    className="h-full"
                                    {...getTableBodyProps()}
                                >
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                width={width}
                                                itemCount={rows.length}
                                                itemSize={50}
                                                ref={listRef}
                                                style={{
                                                    overflowX: 'hidden',
                                                    overflowY: 'scroll',
                                                }}
                                                useIsScrolling={true}
                                            >
                                                {RenderRow}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>
                            ) : status !== 'success' || isFetching ? (
                                <div className="h-full">
                                    <StatusDisplay status="loading" />
                                </div>
                            ) : (
                                <div className="block w-full">
                                    <div className="block w-full">
                                        <div className="p-16 text-center text-lg">
                                            There's nothing here.
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Row = memo(
    ({
        row,
        changeSelection,
        isSelected,
        sort,
        style,
        isScrolling,
        month,
        ...otherProps
    }: {
        row: any
        changeSelection: () => void
        isSelected: boolean
        sort: SortValue
        style: CSSProperties
        isScrolling: boolean
        month: string
    }) => {
        const { clientId, id } = row?.original || {}
        useEffect(() => {
            if (!isScrolling) {
                prefetchClientNote(clientId)
                prefetchSpendBreakdown({ id }, month)
            }
        }, [isScrolling, clientId, id, month])
        return (
            <div
                onClick={() => changeSelection()}
                className={classNames(
                    columnGrid,
                    'flex cursor-pointer list-none items-center pl-4',
                    isSelected && ' bg-gray-100',
                )}
                {...otherProps}
                style={style}
            >
                {row.cells.map((cell) => {
                    const columnId = cell.column.id
                    const alignment =
                        rightAlignColumns.includes(columnId) && 'text-right'
                    const width = columnWidths[columnId]

                    return (
                        <div
                            className={classNames(alignment, width)}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell', {
                                isSelected,
                                sort,
                            })}
                        </div>
                    )
                })}
            </div>
        )
    },
)
