import { createContext, useContext } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getMonth, parseISO } from 'date-fns'

type Season = 'cool' | 'cold' | 'hot'

const currentDate = new Date()
// const currentDate = parseISO('2022-01-01')

// +1 because library returns values for months from [0, ... ,11] array of values
const currentMonth = getMonth(currentDate) + 1

// Seasons => Cold: Nov,Dec,Jan,Feb,Mar // Cool: Apr,May,Sep,Oct // Hot: Jun,Jul,Aug
const isColdSeason = currentMonth >= 11 || currentMonth <= 3
const isHotSeason = currentMonth >= 6 && currentMonth <= 8
const isSpookySeason = currentMonth === 10
const currentSeason = isSpookySeason
    ? 'spooky'
    : isColdSeason
    ? 'cold'
    : isHotSeason
    ? 'hot'
    : 'cool'

const currentTheme = {
    season: currentSeason as Season,
}

// This provides a custom context and hook for the theme. The ThemeProvider
// is rendered high up in the app and all elements rendered below it can access
// the theme info by using the useTheme hook.
const Theme = createContext(currentTheme)

export const useTheme = () => useContext(Theme)

export const ThemeProvider = ({ children }) => {
    return (
        <Theme.Provider value={{ ...currentTheme }}>{children}</Theme.Provider>
    )
}
