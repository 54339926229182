import { useAutomaticPricingModels } from '../hooks/apiHooks'
import { FeedTemplate } from '../components/Feeds/Models'

const AutomaticPricingModels = () => {
    const { data, status } = useAutomaticPricingModels()

    return FeedTemplate('Automatic Pricing Models', data, status)
}

export default AutomaticPricingModels
