/**
 * Function to return axis ticks based on a given max value and desired number
 * of ticks. Largest tick always equals or exceeds the max value and tick spacings
 * are "nice" - that is, "round" numbers based on the magnitude of the data:
 * numbers like 500, 20, .1, .025, etc. Assumes zero is the lowest
 * value in the dataset. Inspiration and code ideas from
 * https://stackoverflow.com/questions/8506881/nice-label-algorithm-for-charts-with-minimum-ticks/16363437#16363437
 * and
 * https://stackoverflow.com/questions/326679/choosing-an-attractive-linear-scale-for-a-graphs-y-axis .
 *
 * @param spaces How many tick gaps are desired. Integer. At least one, but no defined upper limit.
 * @param max Largest number in the data set. (must be greater than 0)
 * @return An array of numbers representing the "nice" ticks. ex. [0, 1], [0, 20, 40, 80, 100], [0, 0.5, 1]
 */
export const ticksFromMaxValue = (spaces, max) => {
    if (spaces < 1 || spaces % 1 !== 0) {
        throw new Error('Number of ticks must be at least 1 and an integer.')
    }
    if (max <= 0) {
        // This limitation could be removed with a few changes to this function
        // if that became useful.
        throw new Error('Dataset must contain a max higher than zero.')
    }

    const unadjustedTickSpacing = max / spaces

    // Calculate the rough magnitude of the data. (2 for data in the 100's)
    const dataMagnitudePower = Math.floor(Math.log10(unadjustedTickSpacing))
    const dataMagnitude = Math.pow(10, dataMagnitudePower)

    // Get the spacing to the 1's place for easy rounding.
    const spacingBase = unadjustedTickSpacing / dataMagnitude
    const fullyRoundedSpacingBase = Math.ceil(spacingBase)

    // Check if a 0.5 rounded number works better than full rounding.
    const halfRoundedSpacingBase = fullyRoundedSpacingBase - 0.5
    const bestSpacingBase =
        halfRoundedSpacingBase > spacingBase
            ? halfRoundedSpacingBase
            : fullyRoundedSpacingBase

    const adjustedTickSpacing = bestSpacingBase * dataMagnitude

    // Turn spacing into actual ticks
    const emptyTicks = new Array(spaces + 1).fill(0)
    const ticks = emptyTicks.map((_tick, index) => index * adjustedTickSpacing)
    return ticks
}
