import { createElement } from 'react'
import NumberInput from './NumberInput'
import Icon from '../Icon'
import { classNames } from '../../utility/Components'

// Subcomponents for the components in this file. Don't use outside this file for now.
// (It's hard enough making sure any changes to these subcomponents will work for
// all of the components here - let's not expand that any more for now.)

const Label = ({
    children,
    as: ElementType = 'div',
    className = '',
    ...otherProps
}) => {
    return createElement(
        ElementType,
        {
            className: classNames(
                className,
                'text-sm font-medium text-gray-700',
            ),
            ...otherProps,
        },
        children,
    )
}

const Input = ({ className = '', value, label, onChange, ...otherProps }) => (
    <input
        {...otherProps}
        className={classNames(
            className,
            'rounded-md border border-gray-300 bg-white pl-3 pr-10 text-sm shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500',
        )}
        value={value}
        name={label}
        id={label}
        onChange={onChange}
        aria-label={label}
    />
)

const ClearButton = ({ onChange }) => (
    <button
        className="absolute inset-y-0 right-0 ml-3 flex items-center pl-1 pr-3 text-gray-500 focus:text-cyan-600 focus:outline-none"
        onClick={onChange}
    >
        <span className="sr-only">Clear</span>
        <Icon type="X" aria-hidden="true" />
    </button>
)

// Components for use outside this file.
export const StatelessButtonTextBox = ({
    className = '',
    label = '',
    text,
    onChange,
    buttonText,
    buttonOnClick,
    ...otherProps
}) => {
    return (
        <div className={className}>
            <Label
                as="label"
                className="block leading-6 text-gray-900"
                htmlFor={label}
            >
                {label}
            </Label>
            <div className="relative flex rounded-md shadow-sm">
                <button
                    type="button"
                    className="z-10 inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-700 outline-none  hover:bg-gray-50 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-500"
                    onClick={buttonOnClick}
                >
                    {buttonText}
                </button>
                <input
                    {...otherProps}
                    className="block w-full rounded-none rounded-r-md border-y border-r border-l-0 border-gray-300 py-2 pr-10 text-sm text-gray-900 focus:border-cyan-500 focus:ring-2 focus:ring-inset focus:ring-cyan-500"
                    type="text"
                    name={label}
                    id={label}
                    value={text}
                    onChange={(event) => onChange(event.target.value)}
                />
                <ClearButton onChange={() => onChange('')} />
            </div>
        </div>
    )
}

export const SlimTextBox = ({
    onChange,
    label = '',
    text,
    className = '',
    inputClassName = '',
}) => {
    return (
        <div className={className || 'flex gap-4'}>
            <div className="relative">
                <Input
                    type="text"
                    className={classNames(inputClassName, 'max-w-lg py-2')}
                    value={text}
                    label={label}
                    onChange={(event) => onChange(event.target.value)}
                />
                <ClearButton onChange={() => onChange('')} />
            </div>
        </div>
    )
}

export const StatelessTextBox = ({
    onChange,
    label = '',
    text,
    className = '',
    classNameInput = '',
    ...otherProps
}) => {
    return (
        <div className={className}>
            <Label>{label}</Label>
            <div className={classNames(classNameInput, 'relative')}>
                <Input
                    {...otherProps}
                    type="text"
                    className="relative w-full py-2"
                    value={text}
                    label={label}
                    onChange={(event) => onChange(event.target.value)}
                />
                <ClearButton onChange={() => onChange('')} />
            </div>
        </div>
    )
}

export const CompactStatelessTextBox = ({
    onChange,
    label = '',
    text,
    className = '',
}) => {
    return (
        <div className={className}>
            <Label>{label}</Label>
            <div className="relative mt-1">
                <Input
                    type="text"
                    className="relative w-full py-2 text-black"
                    value={text}
                    label={label}
                    onChange={(event) => onChange(event.target.value)}
                />
                <ClearButton onChange={() => onChange('')} />
            </div>
        </div>
    )
}

const boundedChangeFunction = (min, max, onChange) => (event) => {
    const number = parseFloat(event.target.value)
    const newNumber =
        number > max ? max : number < min ? min : isNaN(number) ? min : number
    onChange(newNumber)
}

export const StatelessNumberBox = ({
    onChange,
    label = '',
    value,
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    className = '',
    ...otherProps
}) => {
    const onChangeWithBounds = boundedChangeFunction(min, max, onChange)

    return (
        <div className={className}>
            <Label>{label}</Label>
            <div className="relative mt-1">
                <NumberInput
                    className="relative w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                    value={value.toString(10)}
                    onChange={onChangeWithBounds}
                    {...otherProps}
                />
                <ClearButton onChange={onChangeWithBounds} />
            </div>
        </div>
    )
}

export const CompactStatelessNumberBox = ({
    onChange,
    label = '',
    value,
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY,
    className = '',
    ...otherProps
}) => {
    const onChangeWithBounds = boundedChangeFunction(min, max, onChange)

    return (
        <div className={className}>
            <Label>{label}</Label>
            <div className="relative mt-1">
                <NumberInput
                    className="relative w-full rounded-md border border-gray-300 bg-white py-1 pl-3 pr-10 text-left shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                    value={value.toString(10)}
                    onChange={onChangeWithBounds}
                    {...otherProps}
                />
                <ClearButton onChange={onChangeWithBounds} />
            </div>
        </div>
    )
}
