// This file lives in two different places because no one's found a way to
// reference between them. You can edit either, but make sure to copy the values
// to the other location to ensure they both match. (Hopefully a temporary solution.)
// The two locations are the root folder and the src/ folder.
const defaultTheme = require('tailwindcss/defaultTheme')
const plugin = require('tailwindcss/plugin')

// These are taken from official Adpearance brand guidelines.
const adpearanceBrand = {
    white: '#fff',
    black: '#101010',
    feBlueLight: '#52c1ec', // Base for cyan.300
}

/**
 * The goal of the color palette in this app is to provide a refreshing, and calm
 * atmosphere for users while still projecting a modern aesthetic.
 *
 * It was desired to have some basis in the company brand colors so one of the
 * cyan colors is based on the FourEyes light blue color. This was a color in
 * the branding that best fit with the calm, refreshing vibe.
 *
 * The rest of the colors are based on green, line, and brown colors taken from
 * the Pear emoji that's become the theme/logo for the app. These colors were
 * placed in appropriate places in the palette based on their luminosity and then
 * subtly altered toward teal to unify them into a refreshing palette.
 *
 * Finally, red is not used at all to maintain the calmness desired.
 */

const tailwindConfig = {
    content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
    theme: {
        colors: {
            current: 'currentColor',
            transparent: 'transparent',
            white: adpearanceBrand.white,
            black: adpearanceBrand.black,
            gray: {
                50: 'hsl(210, 30%, 97%)',
                100: 'hsl(210, 25%, 90%)',
                200: 'hsl(210, 20%, 81%)',
                300: 'hsl(210, 15%, 71%)',
                400: 'hsl(210, 10%, 61%)',
                500: 'hsl(210, 8%, 47%)',
                600: 'hsl(210, 10%, 41%)',
                700: 'hsl(210, 15%, 31%)',
                800: 'hsl(210, 20%, 21%)',
                900: 'hsl(210, 25%, 11%)',
            },
            cyan: {
                50: 'hsl(193, 100%, 96%)',
                100: 'hsl(194, 96%, 89%)',
                200: 'hsl(195, 89%, 78%)',
                300: 'hsl(196, 85%, 67%)', // Based on feBlueLight (#52c1ec)
                400: 'hsl(197, 80%, 58%)',
                500: 'hsl(198, 83%, 45%)',
                600: 'hsl(199, 87%, 37%)',
                700: 'hsl(200, 91%, 30%)',
                800: 'hsl(201, 94%, 20%)',
                900: 'hsl(202, 100%, 11%)',
            },
            green: {
                50: 'hsl(132, 100%, 97%)',
                100: 'hsl(131, 100%, 88%)',
                200: 'hsl(130, 95%, 75%)',
                300: 'hsl(129, 92%, 63%)',
                400: 'hsl(128, 90%, 51%)',
                500: 'hsl(127, 88%, 43%)',
                600: 'hsl(126, 90%, 34%)',
                700: 'hsl(125, 92%, 26%)',
                800: 'hsl(124, 95%, 17%)',
                900: 'hsl(123, 100%, 9%)',
            },
            lime: {
                50: 'hsl(64, 91%, 96%)',
                100: 'hsl(64, 91%, 89%)',
                200: 'hsl(64, 91%, 77%)',
                300: 'hsl(64, 91%, 67%)',
                400: 'hsl(64, 91%, 45%)',
                500: 'hsl(65, 92%, 38%)',
                600: 'hsl(66, 93%, 30%)',
                700: 'hsl(67, 95%, 23%)',
                800: 'hsl(68, 97%, 15%)',
                900: 'hsl(69, 100%, 8%)',
            },
            orange: {
                50: 'hsl(32, 100%, 96%)',
                100: 'hsl(31, 100%, 87%)',
                200: 'hsl(30, 93%, 76%)',
                300: 'hsl(29, 83%, 66%)',
                400: 'hsl(28, 72%, 55%)',
                500: 'hsl(27, 63%, 47%)',
                600: 'hsl(26, 56%, 38%)',
                700: 'hsl(25, 51%, 29%)',
                800: 'hsl(24, 57%, 19%)',
                900: 'hsl(23, 65%, 10%)',
            },
            rose: {
                50: 'hsl(323, 73%, 96%)',
                100: 'hsl(324, 92%, 92%)',
                200: 'hsl(325, 78%, 84%)',
                300: 'hsl(326, 78%, 76%)',
                400: 'hsl(327, 70%, 66%)',
                500: 'hsl(328, 65%, 58%)',
                600: 'hsl(329, 60%, 47%)',
                700: 'hsl(330, 60%, 37%)',
                800: 'hsl(331, 65%, 25%)',
                900: 'hsl(332, 75%, 13%)',
            },
        },
        extend: {
            animation: {
                'stagger-1-slow': 'stagger-1 4s ease-in-out infinite',
                'stagger-2-slow': 'stagger-2 4s ease-in-out infinite',
                'stagger-3-slow': 'stagger-3 4s ease-in-out infinite',
            },
            keyframes: {
                'stagger-1': {
                    '0%, 40%, 100%': { opacity: 0 },
                    '10%, 30%': { opacity: '100%' },
                },
                'stagger-2': {
                    '0%, 10%, 50%, 100%': { opacity: 0 },
                    '20%, 40%': { opacity: '100%' },
                },
                'stagger-3': {
                    '0%, 20%, 60%, 100%': { opacity: 0 },
                    '30%, 50%': { opacity: '100%' },
                },
            },
            borderWidth: {
                6: '6px',
            },
            boxShadow: {
                cloud: '0 0px 20px 15px rgba(255, 255, 255, 1), 0 7px 15px 0px rgba(0, 0, 0, 0.5)',
            },
            brightness: {
                105: '105%',
                115: '115%',
            },
            fontFamily: {
                sans: ['"Work Sans"', ...defaultTheme.fontFamily.sans],
                heading: ['"Work Sans"', 'sans-serif'],
            },
            grayscale: {
                15: '15%',
            },
            minWidth: {
                '1/2': '50%',
            },
            width: {
                over: '112%',
                '2/1': '200%',
            },
        },
    },
    plugins: [
        require('@tailwindcss/forms'),
        // Add 'peer' class to a sibling to make these variants from v2.2 work
        plugin(({ addVariant, e }) => {
            // Add peer-focus variant
            addVariant('peer-focus', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    // e is for escaping strings used as class names
                    const eClassName = e(`peer-focus${separator}${className}`)

                    // the selector for the peer to use for focusing
                    const peerSelector = '.peer'

                    // the CSS to activate when the peer is in focus
                    return `${peerSelector}:focus ~ .${eClassName}`
                })
            })
            // Add peer-hover variant
            addVariant('peer-hover', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    // e is for escaping strings used as class names
                    const eClassName = e(`peer-hover${separator}${className}`)

                    // the selector for the peer to use for focusing
                    const peerSelector = '.peer'

                    // the CSS to activate when the peer is being hovered
                    return `${peerSelector}:hover ~ .${eClassName}`
                })
            })
        }),
    ],
}
export default tailwindConfig
