import { useEffect, useState } from 'react'
import PearApi from '../apis/pearApi'
import ProgressBar from './ProgressBar'

export const BatchProgress = ({
    batchId,
    setBatchId,
    className = '',
    jobName = 'Batch',
}) => {
    const [progressPercentage, setProgressPercentage] = useState(0)
    const incrementProgressPercentage = (newProgressPercentage) => {
        if (newProgressPercentage > progressPercentage) {
            setProgressPercentage(newProgressPercentage)
        }
    }

    const [message, setMessage] = useState('')

    const [isMounted, setIsMounted] = useState(true)
    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    useEffect(
        () => {
            let interval = null
            const getBatch = async () => {
                const batch = await PearApi.batch(batchId)
                if (isMounted) {
                    incrementProgressPercentage(batch.progress)
                }

                if (batch.finishedAt) {
                    clearInterval(interval)
                    setBatchId('')
                    setMessage(`${jobName} complete!`)
                }
            }
            if (batchId !== '') {
                interval = setInterval(getBatch, 7000)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [batchId],
    )

    return batchId !== '' ? (
        <div className={className}>
            <p>
                <i
                    className="mr-2 inline-block animate-spin text-sm not-italic opacity-90"
                    aria-hidden="true"
                >
                    🍐
                </i>
                Processing {jobName}...
            </p>
            <ProgressBar percentage={progressPercentage} />
        </div>
    ) : (
        <p className={className}>{message}</p>
    )
}
