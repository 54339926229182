import { useMemo } from 'react'
import { useMutation } from 'react-query'
import { classNames } from '../../utility/Components'
import { useImagePreloading } from '../../hooks/useImagePreloading'
import { NO_PROMO_AD_SELECTION } from '../../pages/PromoAdBuilder'
import PearApi, { PromoAd, PromoAdInfo } from '../../apis/pearApi'
import { FileField, TextField } from '../forms/Fields'
import { Form } from 'react-final-form'
import { Button } from '../elements/Buttons'
import { invalidatePromoAds } from '../../hooks/apiHooks'

const Step1 = ({
    promoAds,
    selectedPromoAd,
    setSelectedPromoAd,
}: {
    promoAds: PromoAd[]
    selectedPromoAd: PromoAd
    setSelectedPromoAd: (ad: PromoAd) => void
}) => {
    // Memoizing this image array makes sure we only preload once each time
    // the promoAds array changes.
    const adImages = useMemo(
        () => promoAds.map(({ image }) => image),
        [promoAds],
    )
    useImagePreloading(adImages)

    const creatingNewAd = selectedPromoAd === NO_PROMO_AD_SELECTION

    const { mutate: createAd, isLoading: isSaving } = useMutation(
        (adInfo: PromoAdInfo) => PearApi.createPromoAd(adInfo),
        {
            onSuccess: (data) => {
                invalidatePromoAds()

                // Select newly created ad
                const { promoAd } = data?.data || {}
                if (promoAd) {
                    setSelectedPromoAd(promoAd)
                }
            },
        },
    )

    return (
        <div className="flex min-h-0 flex-1 flex-col pb-5">
            <h2 className="mt-3.5 flex-none text-3xl font-medium text-gray-700">
                <span className="sr-only">Step</span>
                <span className="mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-700 text-white">
                    1
                </span>
                Select or Create Promo Ad
            </h2>
            <div className="min-h-0 flex-1">
                <div className="mt-3 flex h-full gap-8 px-16">
                    <div className="min-h-0 flex-1 overflow-hidden rounded-lg border border-gray-300 focus-within:border-cyan-500 focus-within:outline-none focus-within:ring focus-within:ring-cyan-500">
                        <ul
                            className="h-full overflow-y-auto pt-2 focus:outline-none"
                            aria-label={`Ad list with ${promoAds.length + 1} ${
                                promoAds.length === 0 ? ' item' : ' items'
                            }`}
                        >
                            <li
                                key={0}
                                className={classNames(
                                    'w-full truncate py-1 px-4 text-left text-gray-800 focus:outline-none',
                                    creatingNewAd
                                        ? 'bg-cyan-600 font-semibold text-white'
                                        : 'font-bold text-gray-800 hover:bg-cyan-500 hover:text-white focus:bg-cyan-500 focus:text-white',
                                )}
                                onClick={() => {
                                    setSelectedPromoAd(NO_PROMO_AD_SELECTION)
                                }}
                            >
                                Create New Ad
                            </li>
                            {promoAds.sort((a, b) =>
                                a.name.localeCompare(b.name),
                            ).length > 0 &&
                                promoAds.map((promoAd) => {
                                    const { id, name } = promoAd
                                    const isSelected = id === selectedPromoAd.id
                                    return (
                                        <li
                                            key={id}
                                            className={classNames(
                                                'w-full truncate py-1 px-4 text-left focus:outline-none',
                                                isSelected
                                                    ? 'bg-cyan-600 font-semibold text-white'
                                                    : 'text-gray-800 hover:bg-cyan-500 hover:text-white focus:bg-cyan-500 focus:font-bold focus:text-white',
                                            )}
                                            onClick={() => {
                                                setSelectedPromoAd(promoAd)
                                            }}
                                        >
                                            {name}{' '}
                                        </li>
                                    )
                                })}
                        </ul>
                    </div>
                    <div className="flex-1">
                        {creatingNewAd ? (
                            <>
                                <Form
                                    onSubmit={(values) =>
                                        createAd({
                                            name: values.name,
                                            image: values.image,
                                        })
                                    }
                                    render={({
                                        handleSubmit,
                                        invalid,
                                        pristine,
                                        submitting,
                                    }) => (
                                        <form
                                            noValidate={true}
                                            onSubmit={handleSubmit}
                                            className={`flex flex-col gap-2`}
                                        >
                                            <TextField
                                                className=""
                                                label="Name"
                                                name="name"
                                            />
                                            <FileField
                                                label="Image"
                                                name="image"
                                            />
                                            <div className="">
                                                <Button
                                                    disabled={
                                                        submitting ||
                                                        invalid ||
                                                        pristine ||
                                                        isSaving
                                                    }
                                                    type="submit"
                                                >
                                                    Create
                                                </Button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </>
                        ) : (
                            <>
                                <div className="mb-3 truncate text-2xl font-bold text-gray-700">
                                    {selectedPromoAd.name}
                                </div>
                                <div className="">
                                    <img
                                        src={selectedPromoAd.image}
                                        alt=""
                                        className="max-h-full max-w-full"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step1
