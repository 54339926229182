import { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import AppRoutes from './routes'
import { BackgroundSky } from '../components/Layout'
import { Budgeting } from './Budgeting'
import Colors from './Colors'
import { Login, Logout } from '../components/Login'
import { useLoginInfo, LoginStatus } from '../hooks/LoginInfo'
import { classNames } from '../utility/Components'
import Clients from './Clients'
import ClientCars from './ClientCars'
import ClientBudgetEditing from './ClientBudgetEditing'
import Salesforce from './Salesforce'
import PageNotFound from './PageNotFound'
import ErrorBoundary from '../components/ErrorBoundary'
import BudgetSpendWatchdog from './BudgetSpendWatchdog'
import AutomaticPricing from './AutomaticPricing'
import AutomaticPricingClients from './AutomaticPricingClients'
import AutomaticPricingModels from './AutomaticPricingModels'
import UsedToolSettings from './UsedToolSettings'
import InventoryTranslations from './InventoryTranslations'
import UsedToolStatus from './UsedToolStatus'
import ClientCoopReport from './ClientCoopReport'
import LogoutPage from './Logout'
import Safeguard from './Safeguard'
import { useSafeguardAlerts } from '../hooks/apiHooks'
import PromoAdBuilder from './PromoAdBuilder'
import { renderSeasonPear } from '../components/Navigation/PearEmoji'
import { useTheme } from '../hooks/Theme'

const navLinks = [
    {
        to: AppRoutes.BUDGETING,
        name: 'Budgeting',
    },
    {
        to: AppRoutes.CLIENTS,
        name: 'Clients',
    },
    {
        to: AppRoutes.BUDGET_SPEND_WATCHDOG,
        name: 'Watchdog',
    },
    {
        to: AppRoutes.SALESFORCE_PROMOS,
        name: 'System',
    },
    {
        to: '/',
        name: 'Feeds',
    },
    {
        to: AppRoutes.SAFEGUARD,
        name: 'Safeguard',
    },
    {
        to: AppRoutes.PROMO_AD_BUILDER,
        name: 'Promo Ad Builder',
    },
]
const feedsLinks = [
    {
        to: AppRoutes.USED_TOOL_STATUS,
        name: 'Used Tool Status',
    },
    {
        to: AppRoutes.AUTOMATIC_PRICING_CLIENTS,
        name: 'Automatic Pricing Clients',
    },
    {
        to: AppRoutes.AUTOMATIC_PRICING_MODELS,
        name: 'Automatic Pricing Models',
    },
    {
        to: AppRoutes.INVENTORY_TRANSLATIONS,
        name: 'Inventory Translations',
    },
]

const toggleFullscreen = () => {
    !document.fullscreenElement
        ? document.documentElement.requestFullscreen()
        : document.exitFullscreen()
}

const Navigation = () => {
    const loginInfo = useLoginInfo()
    const {
        user: { admin: isAdmin },
    } = loginInfo

    const { data: alertData, status: alertsStatus } = useSafeguardAlerts(
        !!isAdmin,
    )
    const alerts = alertData ?? []
    const alertCount = alerts.filter(({ ignoredAt }) => !ignoredAt).length

    const { season } = useTheme()

    return (
        <Router>
            <Transition
                as="div"
                className="flex h-full flex-col"
                show={true}
                appear={true}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <nav className="z-20 flex-none bg-white shadow-lg">
                    <div className="mx-auto max-w-screen-2xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="relative shrink-0">
                                    {/* Pear Emoji Logo */}
                                    {renderSeasonPear(season)}
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-4 flex items-center space-x-4">
                                        {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
                                        {navLinks.map(({ to, name }) =>
                                            to === AppRoutes.SAFEGUARD &&
                                            isAdmin &&
                                            alertsStatus === 'success' &&
                                            alertCount > 0 ? (
                                                <Link
                                                    key={name}
                                                    to={to}
                                                    className="group rounded-md px-3 py-2 text-center text-sm font-medium tracking-wide text-gray-600 hover:bg-cyan-700 hover:text-white focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1"
                                                >
                                                    {name}{' '}
                                                    <span className="ml-0.5 rounded-full bg-gray-500 p-1 text-white group-hover:bg-white group-hover:text-cyan-700">
                                                        {alertCount}
                                                    </span>
                                                    <span className="sr-only">
                                                        alerts
                                                    </span>
                                                </Link>
                                            ) : name === 'Feeds' ? (
                                                <Menu
                                                    key={name}
                                                    as="div"
                                                    className="relative ml-3"
                                                >
                                                    {() => (
                                                        <>
                                                            <div>
                                                                <Menu.Button className="rounded-md px-3 py-2 text-sm font-medium tracking-wide text-gray-600 hover:bg-cyan-700 hover:text-white focus:outline-none focus:ring focus:ring-cyan-500     focus:ring-offset-1">
                                                                    Feeds
                                                                </Menu.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                enter="transition ease-out duration-100"
                                                                enterFrom="opacity-0 scale-95"
                                                                enterTo="opacity-100 scale-100"
                                                                leave="transition ease-in duration-75"
                                                                leaveFrom="opacity-100 scale-100"
                                                                leaveTo="opacity-0 scale-95"
                                                            >
                                                                <Menu.Items className="absolute left-0 z-10 mt-2 w-52 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {feedsLinks.map(
                                                                        ({
                                                                            name,
                                                                            to,
                                                                        }) => (
                                                                            <Menu.Item
                                                                                key={
                                                                                    name
                                                                                }
                                                                            >
                                                                                {({
                                                                                    active,
                                                                                }) => (
                                                                                    <Link
                                                                                        key={
                                                                                            name
                                                                                        }
                                                                                        to={
                                                                                            to
                                                                                        }
                                                                                        className={classNames(
                                                                                            'block rounded-md px-3 py-2 text-sm tracking-wide',
                                                                                            active
                                                                                                ? 'bg-cyan-700 font-bold text-white'
                                                                                                : 'font-medium text-gray-600 ',
                                                                                        )}
                                                                                    >
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </Link>
                                                                                )}
                                                                            </Menu.Item>
                                                                        ),
                                                                    )}
                                                                </Menu.Items>
                                                            </Transition>
                                                        </>
                                                    )}
                                                </Menu>
                                            ) : (
                                                <Link
                                                    key={name}
                                                    to={to}
                                                    className="rounded-md px-3 py-2 text-center text-sm font-medium tracking-wide text-gray-600 hover:bg-cyan-700 hover:text-white focus:outline-none focus:ring focus:ring-cyan-500     focus:ring-offset-1"
                                                >
                                                    {name}
                                                </Link>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center text-gray-700 md:ml-6">
                                    {loginInfo.status ===
                                        LoginStatus.loggedIn && (
                                        <span>{loginInfo.user.name}</span>
                                    )}
                                    {/* Profile menu dropdown */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex max-w-xs items-center rounded-full bg-transparent text-sm focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1">
                                                <span className="sr-only">
                                                    Open settings menu
                                                </span>
                                                {loginInfo.status ===
                                                LoginStatus.loggedIn ? (
                                                    <div>
                                                        <img
                                                            className="h-8 w-8 rounded-full"
                                                            src={
                                                                loginInfo.user
                                                                    .imageUrl
                                                            }
                                                            alt=""
                                                            referrerPolicy="no-referrer"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="h-8 w-8 bg-transparent text-xl">
                                                        🍐
                                                    </div>
                                                )}
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <span
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-500 text-white'
                                                                    : 'text-gray-700',
                                                                'block px-4 py-2 text-sm',
                                                            )}
                                                            onClick={
                                                                toggleFullscreen
                                                            }
                                                        >
                                                            Fullscreen View
                                                        </span>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-500 text-white'
                                                                    : 'text-gray-700',
                                                                'block px-4 py-2 text-sm',
                                                            )}
                                                            href="https://docs.google.com/forms/d/e/1FAIpQLScIa_zgBhRv_fbRqLjfchfQq1GuEQR3jc0eDIW2XGwuDTI0gQ/viewform"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            Bugs & Feedback
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <div
                                                            className={classNames(
                                                                active
                                                                    ? 'bg-gray-500 text-white'
                                                                    : 'text-gray-700',
                                                                'px-4 py-2 text-sm',
                                                            )}
                                                        >
                                                            {loginInfo.status ===
                                                            LoginStatus.loggedIn ? (
                                                                <Logout
                                                                    setLoginInfo={
                                                                        loginInfo.setLoginInfo
                                                                    }
                                                                    logout={
                                                                        loginInfo.logout
                                                                    }
                                                                >
                                                                    Sign Out
                                                                </Logout>
                                                            ) : (
                                                                <Login
                                                                    setLoginInfo={
                                                                        loginInfo.setLoginInfo
                                                                    }
                                                                >
                                                                    Sign In
                                                                </Login>
                                                            )}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <main className="min-h-0 flex-auto">
                    <BackgroundSky>
                        <div className="mx-auto h-full max-w-screen-2xl py-8 sm:px-6 lg:px-8">
                            <ErrorBoundary>
                                <Routes>
                                    <Route
                                        path={AppRoutes.BUDGETING}
                                        element={<Budgeting />}
                                    />
                                    <Route
                                        path={AppRoutes.CLIENTS}
                                        element={<Clients />}
                                    />
                                    <Route
                                        path={AppRoutes.CLIENT_CARS}
                                        element={<ClientCars />}
                                    />
                                    <Route
                                        path={AppRoutes.EDIT_BUDGETS}
                                        element={<ClientBudgetEditing />}
                                    />
                                    <Route
                                        path={AppRoutes.SALESFORCE + '/:tab'}
                                        element={<Salesforce />}
                                    />
                                    <Route
                                        path={AppRoutes.BUDGET_SPEND_WATCHDOG}
                                        element={<BudgetSpendWatchdog />}
                                    />
                                    <Route
                                        path={AppRoutes.AUTOMATIC_PRICING}
                                        element={<AutomaticPricing />}
                                    />
                                    <Route
                                        path={
                                            AppRoutes.AUTOMATIC_PRICING_CLIENTS
                                        }
                                        element={<AutomaticPricingClients />}
                                    />
                                    <Route
                                        path={
                                            AppRoutes.AUTOMATIC_PRICING_MODELS
                                        }
                                        element={<AutomaticPricingModels />}
                                    />
                                    <Route
                                        path={AppRoutes.USED_TOOL_SETTINGS}
                                        element={<UsedToolSettings />}
                                    />
                                    <Route
                                        path={AppRoutes.USED_TOOL_STATUS}
                                        element={<UsedToolStatus />}
                                    />
                                    <Route
                                        path={AppRoutes.CLIENT_COOP_REPORT}
                                        element={<ClientCoopReport />}
                                    />
                                    <Route
                                        path={AppRoutes.INVENTORY_TRANSLATIONS}
                                        element={<InventoryTranslations />}
                                    />
                                    <Route
                                        path={AppRoutes.SAFEGUARD}
                                        element={<Safeguard />}
                                    />
                                    <Route
                                        path={AppRoutes.PROMO_AD_BUILDER}
                                        element={<PromoAdBuilder />}
                                    />
                                    <Route
                                        path={AppRoutes.LOGOUT}
                                        element={<LogoutPage />}
                                    />
                                    <Route path="/" element={<Colors />} />
                                    <Route
                                        path="*"
                                        element={<PageNotFound />}
                                    />
                                </Routes>
                            </ErrorBoundary>
                        </div>
                    </BackgroundSky>
                </main>
            </Transition>
        </Router>
    )
}

export default Navigation
