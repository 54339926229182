import { memo, CSSProperties } from 'react'
import { useCallback, useState } from 'react'
import { useTable, useSortBy } from 'react-table'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid'
import { SectionCloud } from '../../components/Layout'
import StatusDisplay from '../../components/StatusDisplay'
import { classNames } from '../../utility/Components'
import { useUrlQueryParameter } from '../../hooks/useUrlQueryParameter'
import { SlimTextBox } from '../../components/elements/TextBoxes'
import CsvLink from '../../components/CsvLink'
import { percentageFormat } from '../../utility/Formatting'
import { format, parseISO } from 'date-fns'
import PopupDialog from '../../components/elements/PopupDialog'

export const columns = [
    {
        Header: 'Dealer Code',
        accessor: 'client.dealerCode',
    },
    {
        Header: 'Client Name',
        accessor: 'client.name',
    },
    {
        Header: 'Inventory',
        accessor: 'inventory',
    },
    {
        Header: 'Last Run',
        accessor: 'time',
        Cell: ({ value }) => format(parseISO(value), 'yyyy-MM-dd hh:mm aa'),
    },
    {
        Header: 'Hit %',
        accessor: ({ hits, misses }) => hitPercent(hits, misses),
        id: 'hitPercent',
        Cell: ({ value }) => percentageFormat(value, false, 1),
    },
]

export const columnWidths = {
    'client.dealerCode': 'w-32',
    'client.name': 'flex-1',
    inventory: 'w-28',
    time: 'w-44',
    hitPercent: 'w-20',
}
export const columnGap = 'gap-8'
export const rightAlignColumns = ['inventory', 'hitPercent']

export const csvHeaders = [
    {
        label: 'Dealer Code',
        key: 'client.dealerCode',
    },
    {
        label: 'Client Name',
        key: 'client.name',
    },
    {
        label: 'Inventory',
        key: 'inventory',
    },
    {
        label: 'Last Run',
        key: 'time',
    },
    {
        label: 'Hit %',
        key: 'hitPercent',
    },
    {
        label: 'Misses',
        key: 'missesList',
    },
]

export const hitPercent = (hitCount: number, misses: any[]) =>
    (hitCount * 100) / (hitCount + misses.length)

export const Row = memo(
    ({
        row,
        showDetails,
        style,
        currentPopupId,
        setCurrentPopupId,
        ...otherProps
    }: {
        row: any
        showDetails: (campaigns: [], lineItemName) => void
        style: CSSProperties
        currentPopupId: number
        setCurrentPopupId: (id: number) => void
    }) => {
        return (
            <div
                className={classNames(
                    'flex items-center hover:bg-gray-50',
                    columnGap,
                )}
                {...otherProps}
                style={style}
            >
                {row.cells.map((cell) => {
                    const misses = cell.row.original.misses ?? []
                    const { id } = cell.column
                    const justification = rightAlignColumns.includes(id)
                        ? 'text-right pr-1'
                        : ''
                    const spacing = columnWidths[id]
                    const color =
                        id === 'client.name' ? 'text-gray-900' : 'text-gray-700'

                    return (
                        <div
                            className={classNames(
                                'truncate pl-2 pr-3',
                                justification,
                                spacing,
                                color,
                            )}
                            {...cell.getCellProps()}
                        >
                            {id === 'hitPercent' ? (
                                <div className="flex h-8 items-center justify-end">
                                    <PopupDialog
                                        popupId={row.index}
                                        currentPopupId={currentPopupId}
                                        setCurrentPopupId={setCurrentPopupId}
                                        placement="left"
                                        render={({ labelId }) => (
                                            <div className="w-auto overflow-hidden rounded-lg border border-gray-100 bg-white px-2 text-left align-bottom shadow-lg outline-none sm:w-full sm:max-w-3xl sm:p-4 sm:align-middle">
                                                <div className="h-full">
                                                    <div className="flex items-start justify-between">
                                                        <h2
                                                            id={labelId}
                                                            className="text-lg font-medium tracking-tight text-gray-900"
                                                        >
                                                            Misses
                                                        </h2>
                                                    </div>
                                                </div>
                                                <div className="relative mt-2 border-y-2 border-cyan-500 text-xs">
                                                    <div className="my-2 max-h-64 overflow-y-auto">
                                                        {misses.length > 0 ? (
                                                            <ul className="h-full py-1 pr-2">
                                                                {misses.map(
                                                                    ({
                                                                        value,
                                                                        stock,
                                                                    }) => (
                                                                        <li
                                                                            key={
                                                                                value
                                                                            }
                                                                            className="flex items-center gap-4 py-1 pr-2 text-gray-700"
                                                                        >
                                                                            <div className="min-w-0 flex-auto">
                                                                                {
                                                                                    value
                                                                                }
                                                                            </div>
                                                                            <div className="w-8 text-right">
                                                                                {
                                                                                    stock
                                                                                }
                                                                            </div>
                                                                        </li>
                                                                    ),
                                                                )}
                                                            </ul>
                                                        ) : (
                                                            <div className="h-full py-4 px-8 text-center text-gray-700">
                                                                No misses. Yay!
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    >
                                        <button
                                            type="button"
                                            className="rounded p-1 text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500"
                                        >
                                            {cell.render('Cell')}
                                        </button>
                                    </PopupDialog>
                                </div>
                            ) : (
                                cell.render('Cell')
                            )}
                        </div>
                    )
                })}
            </div>
        )
    },
)

export const FeedTemplate = (feedName, data, status) => {
    const [search, setSearch] = useUrlQueryParameter('search', '')

    const loweredSearch = search.toLowerCase()
    const filteredClientStatuses =
        data?.filter(({ client: { dealerCode, name } }) => {
            if (
                !dealerCode?.toLowerCase().includes(loweredSearch) &&
                !name.toLowerCase().includes(loweredSearch)
            ) {
                return false
            }
            return true
        }) ?? []

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: filteredClientStatuses,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: [{ id: 'client.name', desc: false }],
            },
        },
        useSortBy,
    )

    const NO_POPUP_ID = -1
    const [currentPopupId, setCurrentPopupId] = useState<number>(NO_POPUP_ID)
    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <Row
                    row={row}
                    {...row.getRowProps()}
                    style={style}
                    currentPopupId={currentPopupId}
                    setCurrentPopupId={setCurrentPopupId}
                />
            )
        },
        [prepareRow, rows, currentPopupId],
    )

    return (
        <SectionCloud className="flex h-full flex-col px-8 py-6">
            <div className="flex flex-row items-center justify-between">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-600">
                    {feedName}
                </h1>
                <div className="flex flex-row items-center gap-4">
                    <div className="">
                        <SlimTextBox
                            label="Search"
                            text={search}
                            onChange={setSearch}
                            inputClassName="w-72"
                        />
                    </div>
                    <div className="">
                        <CsvLink
                            className={
                                'rounded-md bg-cyan-600 px-4 py-2 text-white ring-cyan-500 ring-offset-1 hover:bg-cyan-700 focus:outline-none focus:ring'
                            }
                            headers={csvHeaders}
                            data={() =>
                                filteredClientStatuses.map((item) => ({
                                    ...item,
                                    hitPercent: hitPercent(
                                        item.hits,
                                        item.misses,
                                    ),
                                    missesList: item.misses
                                        .map((miss) => miss.value)
                                        .join(';'),
                                }))
                            }
                            filename={`Pear - ${feedName}.csv`}
                        >
                            Export CSV
                        </CsvLink>
                    </div>
                </div>
            </div>
            <div className="mt-4 min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className={classNames(
                                                'flex',
                                                columnGap,
                                            )}
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const justification =
                                                        rightAlignColumns.includes(
                                                            id,
                                                        )
                                                            ? 'text-right'
                                                            : 'text-left'
                                                    const spacing =
                                                        columnWidths[id]
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <button
                                                            className={classNames(
                                                                'track group p-2 text-xs font-medium uppercase text-gray-700 focus:font-semibold focus:text-cyan-900 focus:outline-none',
                                                                justification,
                                                                spacing,
                                                                column.disableSortBy &&
                                                                    'cursor-default',
                                                            )}
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}
                                                            title={undefined}
                                                            disabled={
                                                                column.disableSortBy
                                                            }
                                                        >
                                                            <span className="">
                                                                {header}
                                                            </span>
                                                            {sortBy[0].id ===
                                                            column.id ? (
                                                                column.isSortedDesc ? (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            descending
                                                                        </span>
                                                                        <ArrowDownIcon
                                                                            className="ml-2 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-gray-700 "
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            ascending
                                                                        </span>
                                                                        <ArrowUpIcon
                                                                            className="ml-2 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-gray-700"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )
                                                            ) : !column.disableSortBy ? (
                                                                <ArrowDownIcon
                                                                    className="ml-2 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : null}
                                                        </button>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex-1 bg-white">
                                    {rows.length > 0 ? (
                                        <div
                                            className="h-full"
                                            {...getTableBodyProps()}
                                        >
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <List
                                                        height={height}
                                                        width={width}
                                                        itemCount={rows.length}
                                                        itemSize={36}
                                                        style={{
                                                            overflowX: 'hidden',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        {RenderRow}
                                                    </List>
                                                )}
                                            </AutoSizer>
                                        </div>
                                    ) : status === 'loading' ||
                                      status === 'idle' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    No status records were found
                                                    for the current search term
                                                    or there was an error.
                                                    Please double-check your
                                                    search and try again.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}
