import { useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import useKeypress from './hooks/useKeypress'
import Navigation from './pages/Navigation'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LoginInfoProvider } from './hooks/LoginInfo'
import LoginCheck from './components/LoginCheck'
import { ThemeProvider } from './hooks/Theme'

export const queryClient = new QueryClient({
    defaultOptions: { queries: { staleTime: 5 * 60 * 1000, retry: 1 } },
})

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

const App = () => {
    const [showTools, setShowTools] = useState(false)
    useKeypress('}', () => setShowTools(!showTools))

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <GoogleOAuthProvider clientId={clientId}>
                    <LoginInfoProvider>
                        <LoginCheck>
                            <Navigation />
                        </LoginCheck>
                    </LoginInfoProvider>
                </GoogleOAuthProvider>
            </ThemeProvider>
            {showTools && <ReactQueryDevtools initialIsOpen={true} />}
        </QueryClientProvider>
    )
}

export default App
