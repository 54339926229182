import { useEffect, useRef } from 'react'
import { classNames } from '../../utility/Components'

// Normal input with type = number, but without the default mouse wheel and
// arrows/spinner functionalities to increment or decrement the value.
const NumberInput = ({ className = '', ...otherProps }) => {
    const inputRef = useRef(null)

    useEffect(() => {
        const preventScroll = (event) => {
            // If the current input was focused, return that focus in a moment.
            if (document.activeElement.id === event.target.id) {
                setTimeout(() => event.target.focus(), 50)
            }

            // Remove focus to prevent the number increment/decrement via the
            // mouse wheel event.
            event.target.blur()
        }
        let refCopy = null

        if (inputRef?.current) {
            inputRef.current.addEventListener('wheel', preventScroll)
            refCopy = inputRef.current
        }

        return () => refCopy?.removeEventListener('wheel', preventScroll)
    }, [inputRef])

    return (
        <input
            {...otherProps}
            className={classNames('no-arrows', className)}
            ref={inputRef}
            type="number"
        />
    )
}

export default NumberInput
