import { useEffect, useState } from 'react'
import PearApi from '../apis/pearApi'
import { LoginStatus, useLoginInfo } from '../hooks/LoginInfo'
import Welcome, { WelcomeStatus } from '../pages/Welcome'

const LoginCheck = ({ children }) => {
    const { setLoginInfo, status, message } = useLoginInfo()
    const [finishedLoginCheck, setFinishedLoginCheck] = useState(false)

    useEffect(() => {
        // Async functions can't be used directly in a useEffect, but they can
        // be run from within a useEffect function.
        async function checkLoginData() {
            const currentInfo = sessionStorage.getItem('loginInfo')
            if (currentInfo !== null) {
                setLoginInfo(JSON.parse(currentInfo))
            } else {
                try {
                    const currentUser = await PearApi.currentUser()
                    const { name, avatar: imageUrl, id, admin } = currentUser
                    setLoginInfo({
                        user: { name, imageUrl, id, admin },
                        status: LoginStatus.loggedIn,
                    })
                } catch {}
            }
            setFinishedLoginCheck(true)
        }
        checkLoginData()
        // We only want to run this on the initial mount of the app so we're
        // not including setLoginInfo in the dependency array. (If it changes
        // after that, we don't want to run this again.)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return !finishedLoginCheck ? (
        <Welcome setLoginInfo={setLoginInfo} status={WelcomeStatus.loading} />
    ) : status === LoginStatus.error ? (
        <Welcome
            setLoginInfo={setLoginInfo}
            status={WelcomeStatus.error}
            message={message}
        />
    ) : status !== LoginStatus.loggedIn ? (
        <Welcome setLoginInfo={setLoginInfo} status={WelcomeStatus.signIn} />
    ) : (
        <>{children}</>
    )
}

export default LoginCheck
