import React, { cloneElement, useCallback, useState } from 'react'
import {
    Placement,
    offset,
    shift,
    autoUpdate,
    useFloating,
    useInteractions,
    useRole,
    useDismiss,
    useId,
    useHover,
    useClick,
    safePolygon,
    FloatingFocusManager,
    FloatingPortal,
} from '@floating-ui/react'

interface Props {
    render: (data: {
        close: () => void
        labelId: string
        descriptionId: string
    }) => React.ReactNode
    placement?: Placement
    children: JSX.Element
    /** Unique id for this popup (for coordinating multiple, exclusive popups). */
    popupId?: number
    /** Id fo currently activated popup (for coordinating multiple, exclusive popups). */
    currentPopupId?: number
    /** Function to set currently activated popup (for coordinating multiple, exclusive popups). */
    setCurrentPopupId?: (id: number) => void
}

/**
 * Dialog that can be temporarily shown on hover/focus or made sticky by a click.
 */
const PopupDialog = ({
    children,
    render,
    placement,
    popupId = 1,
    currentPopupId = 1,
    setCurrentPopupId,
}: Props) => {
    const [open, setOpen] = useState(false)
    const setOpenAndBlockOthers = useCallback(
        (open) => {
            if (open) {
                setCurrentPopupId(popupId)
                setOpen(true)
            } else {
                setOpen(false)
            }
        },
        [popupId, setCurrentPopupId],
    )

    const { context, refs, x, y, strategy } = useFloating({
        open: open,
        onOpenChange: setOpenAndBlockOthers,
        middleware: [offset(10), shift()],
        placement,
        whileElementsMounted: autoUpdate,
    })

    const id = useId()
    const labelId = `${id}-label`
    const descriptionId = `${id}-description`

    const { getReferenceProps, getFloatingProps } = useInteractions([
        useHover(context, {
            handleClose: safePolygon(),
        }),
        useClick(context, {
            ignoreMouse: true,
        }),
        // This sets up 'role: dialog' by default. (what we want here)
        useRole(context),
        useDismiss(context),
    ])

    return (
        <>
            {cloneElement(
                children,
                getReferenceProps({
                    ref: refs.setReference,
                    ...children.props,
                }),
            )}
            <FloatingPortal>
                {open && currentPopupId === popupId && (
                    <FloatingFocusManager context={context}>
                        <div
                            className="outline-none"
                            {...getFloatingProps({
                                ref: refs.setFloating,
                                style: {
                                    position: strategy,
                                    top: y ?? 0,
                                    left: x ?? 0,
                                },
                                'aria-labelledby': labelId,
                            })}
                        >
                            {render({
                                labelId,
                                descriptionId,
                                close: () => {
                                    setOpenAndBlockOthers(false)
                                },
                            })}
                        </div>
                    </FloatingFocusManager>
                )}
            </FloatingPortal>
        </>
    )
}

export default PopupDialog
