import React, { ReactNode } from 'react'
import pearWorm from '../images/pear-worm.png'

class ErrorBoundary extends React.Component<
    { children: ReactNode },
    { hasError: boolean }
> {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI (error message).
        return { hasError: true }
    }

    render() {
        const { hasError } = this.state
        if (hasError) {
            return (
                <div className="flex h-full w-full items-center gap-8 rounded-lg bg-white px-8 pt-8 pb-12">
                    <div className="min-w-0 flex-1">
                        <img
                            className="ml-auto max-h-96"
                            src={pearWorm}
                            alt="Worm eating a pear"
                        />
                    </div>
                    <div className="mt-8 flex-1">
                        <p className="mb-2 text-left text-3xl font-semibold text-gray-700 sm:text-4xl md:text-5xl">
                            Oops!
                        </p>
                        <p className="mb-8 text-left text-lg text-gray-700 sm:text-lg md:hidden">
                            We've got a bug.
                        </p>
                        <p className="mb-8 hidden text-left text-xl text-gray-700 md:inline-block lg:text-2xl">
                            It looks like there's a bug in our system.
                        </p>
                        <p className="text-normal hidden text-left text-gray-700 lg:inline-block lg:text-lg">
                            Please try again later. If the problem persists,
                            please reach out to Pear devs on Slack.
                        </p>
                    </div>
                </div>
            )
        }
        const { children } = this.props
        return <>{children}</>
    }
}

export default ErrorBoundary
