import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { SectionCloud } from '../components/Layout'
import {
    invalidateClientExcludedTerms,
    invalidateClientUsedToolUrls,
    useClientExcludedTerms,
    useClientUsedToolUrls,
} from '../hooks/apiHooks'
import PearApi, { UsedToolUrlSet } from '../apis/pearApi'
import { TextInput } from '../components/elements/Inputs'
import { BatchProgress } from '../components/BatchProgress'

const UsedToolSettings = () => {
    const { clientId }: { [key: string]: string } = useParams()
    const { data: { name = 'Unknown', urls: rawUrls } = {} } =
        useClientUsedToolUrls(clientId)
    const urls = useMemo(
        () => ({
            makeUrl: rawUrls?.makeUrl ?? '',
            makeModelUrl: rawUrls?.makeModelUrl ?? '',
            yearMakeModelUrl: rawUrls?.yearMakeModelUrl ?? '',
        }),
        [rawUrls],
    )

    const [batchId, setBatchId] = useState('')
    useEffect(() => {
        setIsSavingUrls(batchId !== '')
    }, [batchId])

    const [isSavingUrls, setIsSavingUrls] = useState(false)

    const [userUrls, setUserUrls] = useState(urls)
    useEffect(() => {
        setUserUrls(urls)
    }, [urls])

    const changeUrls = (type, url) => {
        const newUrls = { ...userUrls, [type]: url }
        setUserUrls(newUrls)
    }

    const { mutate: updateUrls } = useMutation(
        (userUrls: UsedToolUrlSet) =>
            PearApi.updateClientUsedToolUrls(clientId, userUrls),
        {
            onSettled: (data) => {
                setBatchId(data?.data?.id)
                invalidateClientUsedToolUrls(clientId)
                setUserUrls(urls)
            },
        },
    )
    const saveUrls = () => {
        setIsSavingUrls(true)
        updateUrls(userUrls)
    }

    const { data: { terms = [] } = {} } = useClientExcludedTerms(clientId)
    const { mutate: saveTerms, isLoading: isSavingTerms } = useMutation(
        (terms: string[]) => PearApi.updateClientExcludedTerms(clientId, terms),
        {
            onSettled: () => {
                invalidateClientExcludedTerms(clientId)
            },
        },
    )

    const addTerm = (newTerm: string) => {
        if (!terms.includes(newTerm)) {
            const newTerms = terms.concat(newTerm)
            saveTerms(newTerms)
        }
    }

    const removeTerm = (termToRemove: string) => {
        if (terms.includes(termToRemove)) {
            const newTerms = terms.filter((term) => term !== termToRemove)
            saveTerms(newTerms)
        }
    }

    const [termToAdd, setTermToAdd] = useState('')

    const handleEnterAndEsc = (event) => {
        if (event.key === 'Enter' && !terms.includes(termToAdd)) {
            addTerm(termToAdd)
            setTermToAdd('')
        } else if (event.key === 'Escape') {
            setTermToAdd('')
        }
    }

    return (
        <SectionCloud className="flex h-full flex-col py-6 px-8">
            <h1 className="mb-12 flex-none text-3xl font-extrabold tracking-tight text-gray-600">
                Used Tool Settings for {name}
            </h1>
            <div className="grid min-h-0 flex-auto grid-cols-3 gap-24">
                <div className="flex flex-col">
                    <h2 className="flex-none text-lg font-medium leading-6 text-gray-900">
                        Excluded Terms
                    </h2>
                    <p className=" mt-1 flex-none text-sm text-gray-700">
                        {
                            'Terms must be unique and at least one character long.'
                        }
                    </p>
                    <div className="relative min-h-0 flex-auto">
                        <ul className="absolute inset-0 -m-0.5 mt-5 space-y-5 overflow-y-auto border-t border-gray-200 p-0.5 pt-4">
                            {terms.length > 0 ? (
                                terms.map((term, index) => (
                                    <li key={term} className="flex space-x-2">
                                        <div className="inline-flex items-center rounded-full bg-gray-100 px-4 text-gray-900">
                                            {term}
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => removeTerm(term)}
                                            className="inline-flex flex-none items-center rounded-full border border-transparent p-2 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1"
                                        >
                                            <span className="sr-only">
                                                Remove Term
                                            </span>
                                            <TrashIcon
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </li>
                                ))
                            ) : (
                                <li className="py-2 indent-8 italic text-gray-900">
                                    Add some terms using the input below.
                                </li>
                            )}
                            <li
                                key="add-term-input-section"
                                className="sticky bottom-0 flex space-x-3 pr-1"
                            >
                                <TextInput
                                    value={termToAdd}
                                    onChange={(e) =>
                                        setTermToAdd(e.target.value)
                                    }
                                    className="min-w-0 flex-auto"
                                    onKeyDown={handleEnterAndEsc}
                                />
                                <button
                                    type="button"
                                    disabled={
                                        termToAdd === '' ||
                                        terms.includes(termToAdd) ||
                                        isSavingTerms
                                    }
                                    onClick={() => {
                                        addTerm(termToAdd)
                                        setTermToAdd('')
                                    }}
                                    className="inline-flex flex-none items-center rounded-full border border-transparent bg-cyan-700 p-2 text-white shadow-sm hover:bg-cyan-800 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1 disabled:bg-gray-300 disabled:text-white"
                                >
                                    <span className="sr-only">Add Term</span>
                                    <PlusIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-span-2">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                        URLs
                    </h2>
                    <p className="mt-1 text-sm text-gray-700">
                        {
                            'Use <<<MAKE>>>, <<<MODEL>>>, and <<<YEAR>>> as placeholders for feed values. (required)'
                        }
                    </p>
                    <div className="mt-5 space-y-5 border-t border-gray-200 pt-5 text-gray-900">
                        <div className="flex">
                            <label
                                htmlFor="make-url"
                                className="block w-60 font-medium text-gray-700 sm:mt-px sm:pt-1.5"
                            >
                                Make URL
                            </label>
                            <TextInput
                                name="makeUrl"
                                id="make-url"
                                value={userUrls.makeUrl}
                                onChange={(e) =>
                                    changeUrls('makeUrl', e.target.value)
                                }
                                className="block min-w-0 flex-auto"
                            />
                        </div>
                        <div className="flex">
                            <label
                                htmlFor="make-model-url"
                                className="block w-60 font-medium text-gray-700 sm:mt-px sm:pt-1.5"
                            >
                                Make-Model URL
                            </label>
                            <TextInput
                                name="makeModelUrl"
                                id="make-model-url"
                                value={userUrls.makeModelUrl}
                                onChange={(e) =>
                                    changeUrls('makeModelUrl', e.target.value)
                                }
                                className="block min-w-0 flex-auto"
                            />
                        </div>
                        <div className="flex">
                            <label
                                htmlFor="year-make-model-url"
                                className="block w-60 font-medium text-gray-700 sm:mt-px sm:pt-1.5"
                            >
                                Year-Make-Model URL
                            </label>
                            <TextInput
                                name="yearMakeModelUrl"
                                id="year-make-model-url"
                                value={userUrls.yearMakeModelUrl}
                                onChange={(e) =>
                                    changeUrls(
                                        'yearMakeModelUrl',
                                        e.target.value,
                                    )
                                }
                                className="block min-w-0 flex-auto"
                            />
                        </div>
                        <div className="flex">
                            <div className="w-60"></div>
                            <div className="flex-auto">
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-md border border-transparent bg-cyan-700 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-800 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1 disabled:bg-gray-500"
                                    onClick={saveUrls}
                                    disabled={isSavingUrls}
                                >
                                    Upload URLs to Google Ads
                                </button>
                                <BatchProgress
                                    batchId={batchId}
                                    className="mt-12"
                                    setBatchId={setBatchId}
                                    jobName="Used URL Upload"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}

export default UsedToolSettings
