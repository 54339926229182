import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faCheck,
    faExternalLinkAlt,
    faLongArrowAltUp,
} from '@fortawesome/free-solid-svg-icons'
import {
    faFacebookF,
    faGoogle,
    faLinkedin,
    faMicrosoft,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { classNames } from '../utility/Components'

const StackAdaptIcon = ({ className }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 192 220"
        role="img"
        aria-hidden="true"
        className={classNames('fill-current', className)}
    >
        <title>StackAdapt Icon</title>
        <path d="M132.9 45.85L154.28 0 76.08 0.02C46.51 0.03 19.63 17.17 7.13 43.96l0 0c-12.5 26.8-8.34 58.41 10.66 81.06l41.2 49.13L37.61 220l78.21-0.02c29.57-0.01 56.45-17.14 68.95-43.94 12.5-26.8 8.34-58.41-10.66-81.06L132.9 45.85zM161.64 165.26c-2.43 5.2-7.65 8.53-13.39 8.53l-75-0.02 16.35-35.06L32.32 70.48c-3.69-4.4-4.5-10.53-2.07-15.73 2.43-5.2 7.65-8.53 13.39-8.53l75 0.02 -16.35 35.06 57.28 68.23C163.26 153.92 164.07 160.06 161.64 165.26z" />
    </svg>
)

const faMap = {
    Facebook: faFacebookF,
    Google: faGoogle,
    Microsoft: faMicrosoft,
    LinkedIn: faLinkedin,
    TikTok: faTiktok,
    X: faTimes,
    Check: faCheck,
    ExternalLink: faExternalLinkAlt,
    ArrowUp: faLongArrowAltUp,
}

const Icon = ({
    type,
    className,
}: {
    type: keyof typeof faMap | 'StackAdapt'
    className?: string
}) =>
    type === 'StackAdapt' ? (
        <StackAdaptIcon className={classNames('inline-block h-4', className)} />
    ) : (
        <span className={className}>
            <FontAwesomeIcon icon={faMap[type]} />
        </span>
    )
export default Icon
