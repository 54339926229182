import { Fragment, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { FieldArray } from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'
import AppRoutes from './routes'
import { SectionCloud } from '../components/Layout'
import { Menu, Transition } from '@headlessui/react'
import { ArrowDownTrayIcon, ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import FormWindow from '../components/forms/FormWindow'
import StatusDisplay from '../components/StatusDisplay'
import { LoadingIcon } from '../components/StateIcons'
import {
    SelectField,
    TextDisplayField,
    UnlabeledTextField,
} from '../components/forms/Fields'
import { useProductLineItems, usePromoLineItems } from '../hooks/apiHooks'
import PearApi, { Sources } from '../apis/pearApi'
import { trimObjectValuesWhitespace } from '../utility/Objects'
import { classNames } from '../utility/Components'
import { Toggle } from '../components/elements/Toggle'
import FileDialog from '../components/elements/FileDialog'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import CustomDropzone from '../components/elements/Dropzone'
import { useQueryClient } from 'react-query'

const Tabs = {
    PROMOS: 'promos',
    PRODUCTS: 'products',
}

const Salesforce = () => {
    const { tab: selectedTab } = useParams()
    const navigate = useNavigate()

    const queryClient = useQueryClient()

    const setSelectedTab = (newTab) => {
        newTab === Tabs.PROMOS
            ? navigate(AppRoutes.SALESFORCE_PROMOS)
            : navigate(AppRoutes.SALESFORCE_PRODUCTS)
    }

    const moveToOtherTab = () =>
        selectedTab === Tabs.PROMOS
            ? setSelectedTab(Tabs.PRODUCTS)
            : setSelectedTab(Tabs.PROMOS)
    const handleTabKeyEvents = ({ currentTarget, target, code: keyCode }) =>
        currentTarget !== target
            ? null
            : keyCode === 'ArrowLeft' ||
              keyCode === 'ArrowUp' ||
              keyCode === 'ArrowRight' ||
              keyCode === 'ArrowDown'
            ? moveToOtherTab()
            : null

    const isProductsTabActive = selectedTab === Tabs.PRODUCTS
    const isPromosTabActive = selectedTab === Tabs.PROMOS

    const { data: productLineItems, status: productStatus } =
        useProductLineItems(isProductsTabActive)
    const {
        data: promoLineItems,
        status: promoStatus,
        refetch: refetchPromos,
    } = usePromoLineItems(isPromosTabActive)

    const [productSaveMessage, setProductSaveMessage] = useState({
        type: 'idle',
        message: '',
    })
    const saveProductLineItems = useMutation(
        (updatedLineItems: object) =>
            PearApi.updateProductLineItems(updatedLineItems),
        {
            onSuccess: () => {
                setProductSaveMessage({
                    type: 'success',
                    message: 'Mappings saved.',
                })
            },
            onError: () => {
                setProductSaveMessage({
                    type: 'error',
                    message: 'There was a problem saving the mappings.',
                })
            },
        },
    )

    const [promoSaveMessage, setPromoSaveMessage] = useState({
        type: 'idle',
        message: '',
    })
    const savePromoLineItems = useMutation(
        (updatedLineItems: object) =>
            PearApi.updatePromoLineItems(updatedLineItems),
        {
            onSuccess: () => {
                setPromoSaveMessage({
                    type: 'success',
                    message: 'Mappings saved.',
                })
            },
            onError: () => {
                setPromoSaveMessage({
                    type: 'error',
                    message: 'There was a problem saving the mappings.',
                })
            },
        },
    )

    const [urlActive, setUrlActive] = useUrlQueryParameter('active', 'true')
    const showActive = urlActive !== 'false'
    const setShowActive = (showActive) =>
        setUrlActive(showActive ? 'true' : 'false')

    const filteredPromoLineItems = promoLineItems.filter(({ deletedAt }) => {
        if (showActive) {
            return deletedAt === undefined
        } else {
            return deletedAt !== undefined
        }
    })
    const filteredProductLineItems = productLineItems.filter(
        ({ deletedAt }) => {
            if (showActive) {
                return deletedAt === undefined
            } else {
                return deletedAt !== undefined
            }
        },
    )

    const [isOpen, setIsOpen] = useState(false)
    const [isFileDialogOpen, setIsFileDialogOpen] = useState(false)

    const openFileDialog = () => setIsFileDialogOpen(true)
    const closeFileDialog = () => setIsFileDialogOpen(false)

    const handleDownloadRequest = () => {
        fetchDownloadData()
        return false
    }

    const fetchDownloadData = async () => {
        try {
            const response = await PearApi.downloadMappingsFile(
                selectedTab,
                showActive,
            )

            const blob = new Blob([response], {
                type: 'text/csv;charset=utf-8;',
            })
            const link = document.createElement('a')
            const url = URL.createObjectURL(blob)

            link.setAttribute('href', url)
            link.setAttribute(
                'download',
                'Pear - Salesforce Mappings ' +
                    selectedTab.toUpperCase() +
                    (showActive ? ' (active)' : '') +
                    '.csv',
            )
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            console.error('Error: Could not generate CSV file')
        }
    }

    const [csvFile, setCsvFile] = useState(null)

    const handleUploadRequest = (file) => {
        setCsvFile(file)
        return false
    }

    const handleUploadFile = async () => {
        try {
            let response = []
            if (csvFile) {
                // Clear data in screen
                queryClient.setQueryData(['Promo Line Items'], [])

                // Hide the dialog
                setIsFileDialogOpen(false)

                response = await PearApi.uploadMappingsFile(csvFile)

                // Refresh page and reset file data
                setCsvFile(null)
                refetchPromos()

                if (response) {
                    // Show success message
                    setPromoSaveMessage({
                        type: 'success',
                        message:
                            response.length + ' mapping labels were updated.',
                    })
                } else {
                    // Show error message
                    setPromoSaveMessage({
                        type: 'error',
                        message: 'There was an error updating the mappings.',
                    })
                }
            }
        } catch (error) {
            // Reset the dialog
            setCsvFile(null)
            setIsFileDialogOpen(false)

            // Show error message
            setPromoSaveMessage({
                type: 'error',
                message: 'There was an error updating the mappings.',
            })
        }
    }

    return (
        <SectionCloud className="flex h-full flex-col px-8 py-6">
            <FileDialog
                isOpen={isFileDialogOpen}
                closeModal={closeFileDialog}
                title="Upload Mappings"
                msg=""
            >
                <CustomDropzone onFileSelect={handleUploadRequest} />
                <div className="mt-4 flex flex-row justify-end">
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-500 px-6 py-2 text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500 focus-visible:ring-offset-2 disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500"
                        onClick={handleUploadFile}
                        disabled={!csvFile}
                    >
                        Upload
                    </button>
                </div>
            </FileDialog>

            <div className="flex flex-row justify-between">
                <h1 className="mb-4 flex-initial text-3xl font-extrabold tracking-tight text-gray-600">
                    Salesforce Mappings
                </h1>
                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex w-full select-none justify-center rounded-md border border-gray-100 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500"
                        >
                            <DocumentDuplicateIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                            />
                            Manage Data
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                            <div className="px-1 py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={`${
                                                active
                                                    ? 'bg-cyan-500 text-white'
                                                    : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={handleDownloadRequest}
                                        >
                                            <ArrowDownTrayIcon
                                                className="mr-2 h-5 w-5"
                                                aria-hidden="true"
                                            />
                                            Download CSV File
                                        </button>
                                    )}
                                </Menu.Item>
                                {selectedTab === Tabs.PROMOS ? (
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                className={`${
                                                    active
                                                        ? 'bg-cyan-500 text-white'
                                                        : 'text-gray-900'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                onClick={openFileDialog}
                                            >
                                                <ArrowUpTrayIcon
                                                    className="mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                Upload CSV File
                                            </button>
                                        )}
                                    </Menu.Item>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div
                className="group z-10 flex min-h-0 w-full flex-auto flex-col focus:outline-none"
                tabIndex={0}
                onKeyDown={handleTabKeyEvents}
            >
                <div className="mb-2 flex flex-initial items-center justify-between border-b border-gray-200">
                    <div className="flex">
                        {Object.values(Tabs).map((tab) => {
                            const isSelected = selectedTab === tab
                            return (
                                <div
                                    key={tab}
                                    className={classNames(
                                        'mr-2 flex h-10 flex-col justify-center rounded-t-md border-b-2 px-6 font-medium capitalize transition-colors hover:border-cyan-600 hover:text-cyan-600',
                                        isSelected
                                            ? 'cursor-default border-cyan-500 text-cyan-600'
                                            : 'cursor-pointer border-transparent text-gray-600',
                                    )}
                                    onClick={
                                        isSelected
                                            ? () => {}
                                            : () => setSelectedTab(tab)
                                    }
                                >
                                    <div className="leading-none">{tab}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="pt-1">
                        <Toggle
                            className=""
                            label="Active"
                            enabled={showActive}
                            setEnabled={setShowActive}
                        />
                    </div>
                </div>
                <div className="min-h-0 flex-auto">
                    {selectedTab === Tabs.PROMOS ? (
                        <PromoMappings
                            currentMappings={filteredPromoLineItems}
                            saveMappings={(values) => {
                                savePromoLineItems.mutate(values)
                            }}
                            saveMessage={promoSaveMessage}
                            status={promoStatus}
                            isSaving={savePromoLineItems.isLoading}
                        />
                    ) : (
                        <ProductMappings
                            currentMappings={filteredProductLineItems}
                            saveMappings={(formValues) => {
                                const updates = Object.values(formValues)
                                saveProductLineItems.mutate(updates)
                            }}
                            saveMessage={productSaveMessage}
                            status={productStatus}
                            isSaving={saveProductLineItems.isLoading}
                        />
                    )}
                </div>
            </div>
        </SectionCloud>
    )
}

export default Salesforce

export const ProductMappings = ({
    currentMappings,
    saveMappings,
    saveMessage,
    status,
    isSaving,
}) => {
    if (!currentMappings.length) {
        return (
            <div className="h-full">
                <LoadingIcon />
            </div>
        )
    } else {
        return (
            <FormWindow
                mutators={{
                    ...arrayMutators,
                }}
                onSubmit={(values) =>
                    saveMappings(trimObjectValuesWhitespace(values.mappings))
                }
                isSaving={isSaving}
                saveMessage={saveMessage}
            >
                <div className="h-full w-full">
                    <div className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg">
                        <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                            <div
                                className="flex-none bg-gray-50"
                                style={{ width: 'calc(100% - 0.9em)' }}
                            >
                                <div className="flex py-2">
                                    <div className="w-1/2 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Name
                                    </div>
                                    <div className="w-1/6 px-4 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Source
                                    </div>
                                    <div className="w-1/3 px-4 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Label
                                    </div>
                                </div>
                            </div>
                            <div className="block flex-1 overflow-y-scroll bg-white">
                                <StatusDisplay status={status}>
                                    <FieldArray
                                        name="mappings"
                                        initialValue={currentMappings}
                                    >
                                        {({ fields }) => (
                                            <div>
                                                {fields.map((name, index) => (
                                                    <div
                                                        className={classNames(
                                                            'flex items-baseline',
                                                            index === 0
                                                                ? 'mt-2'
                                                                : '',
                                                        )}
                                                        key={`${name}-${index}`}
                                                    >
                                                        <div className="w-1/2 px-6">
                                                            <div className="truncate text-base font-medium text-gray-700">
                                                                <TextDisplayField
                                                                    name={`${name}.name`}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-1/6 pl-1 pr-4">
                                                            <div className="text-sm font-semibold text-gray-700">
                                                                <SelectField
                                                                    label=""
                                                                    name={`${name}.source`}
                                                                    options={Object.values(
                                                                        Sources,
                                                                    ).map(
                                                                        (
                                                                            sourceName,
                                                                        ) => ({
                                                                            name: sourceName,
                                                                            id: sourceName,
                                                                        }),
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-1/3 pl-1 pr-4">
                                                            <div className="text-sm font-semibold text-gray-700">
                                                                <UnlabeledTextField
                                                                    name={`${name}.label`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </FieldArray>
                                </StatusDisplay>
                            </div>
                        </div>
                    </div>
                </div>
            </FormWindow>
        )
    }
}

export const PromoMappings = ({
    currentMappings = [],
    saveMappings,
    saveMessage,
    status,
    isSaving,
}) => {
    const initialValues = currentMappings.map((mapping) => {
        const googleLabel =
            mapping.lineItems?.find((item) => item.source === Sources.GOOGLE)
                ?.label || ''
        const facebookLabel =
            mapping.lineItems?.find((item) => item.source === Sources.FACEBOOK)
                ?.label || ''
        const stackAdaptLabel =
            mapping.lineItems?.find(
                (item) => item.source === Sources.STACK_ADAPT,
            )?.label || ''
        const tikTokLabel =
            mapping.lineItems?.find((item) => item.source === Sources.TIKTOK)
                ?.label || ''
        const { id, name } = mapping
        return {
            id,
            name,
            googleLabel,
            facebookLabel,
            stackAdaptLabel,
            tikTokLabel,
        }
    })

    if (!currentMappings.length) {
        return (
            <div className="h-full">
                <LoadingIcon />
            </div>
        )
    } else {
        return (
            <FormWindow
                mutators={{
                    ...arrayMutators,
                }}
                onSubmit={(values) => {
                    const trimmedInfo = trimObjectValuesWhitespace(
                        values.mappings,
                    )
                    const translatedInfo = Object.values(trimmedInfo).map(
                        ({
                            id,
                            name,
                            googleLabel,
                            facebookLabel,
                            stackAdaptLabel,
                            tikTokLabel,
                        }) => {
                            const google = {
                                source: Sources.GOOGLE,
                                label: googleLabel,
                            }
                            const facebook = {
                                source: Sources.FACEBOOK,
                                label: facebookLabel,
                            }
                            const stackadapt = {
                                source: Sources.STACK_ADAPT,
                                label: stackAdaptLabel,
                            }
                            const tiktok = {
                                source: Sources.TIKTOK,
                                label: tikTokLabel,
                            }
                            const lineItems = [
                                google,
                                facebook,
                                stackadapt,
                                tiktok,
                            ]
                            return { id, name, lineItems }
                        },
                    )
                    saveMappings(translatedInfo)
                }}
                isSaving={isSaving}
                saveMessage={saveMessage}
            >
                <div className="h-full w-full">
                    <div className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg">
                        <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                            <div className="flex w-full flex-col gap-4 bg-gray-50 p-2 pr-6">
                                <div className="flex gap-4">
                                    <div className="flex-[2_2_0%] text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Name
                                    </div>
                                    <div className="flex-1 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Google Label
                                    </div>
                                    <div className="flex-1 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        Facebook Label
                                    </div>
                                    <div className="flex-1 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        StackAdapt Label
                                    </div>
                                    <div className="flex-1 pr-2 text-left text-xs font-medium uppercase tracking-wider text-gray-700">
                                        TikTok Label
                                    </div>
                                </div>
                            </div>
                            <div className="block flex-1 overflow-y-scroll bg-white">
                                <StatusDisplay status={status}>
                                    <FieldArray
                                        name="mappings"
                                        initialValue={initialValues}
                                    >
                                        {({ fields }) => (
                                            <div>
                                                {fields.map((name, index) => (
                                                    <div
                                                        className={classNames(
                                                            'flex items-baseline justify-between gap-4 px-2 pb-3',
                                                            index === 0
                                                                ? 'mt-2'
                                                                : '',
                                                        )}
                                                        key={`${name}-${index}`}
                                                    >
                                                        <div className="mt-1 flex-[2_2_0%] truncate text-base font-medium text-gray-700">
                                                            <TextDisplayField
                                                                className=""
                                                                innerClass="truncate"
                                                                name={`${name}.name`}
                                                            />
                                                        </div>
                                                        <div className="flex-1 text-sm font-semibold text-gray-700">
                                                            <UnlabeledTextField
                                                                name={`${name}.googleLabel`}
                                                            />
                                                        </div>
                                                        <div className="flex-1 text-sm font-semibold text-gray-700">
                                                            <UnlabeledTextField
                                                                name={`${name}.facebookLabel`}
                                                            />
                                                        </div>
                                                        <div className="flex-1 text-sm font-semibold text-gray-700">
                                                            <UnlabeledTextField
                                                                name={`${name}.stackAdaptLabel`}
                                                            />
                                                        </div>
                                                        <div className="flex-1 pr-2 text-sm font-semibold text-gray-700">
                                                            <UnlabeledTextField
                                                                name={`${name}.tikTokLabel`}
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </FieldArray>
                                </StatusDisplay>
                            </div>
                        </div>
                    </div>
                </div>
            </FormWindow>
        )
    }
}
