import { ReactNode } from 'react'
import { Transition } from '@headlessui/react'
import { LoadingIcon, ErrorIcon, IdleIcon } from './StateIcons'

export type Status = 'error' | 'loading' | 'success' | 'idle'
export interface StatusDisplayProps {
    status: Status
    children?: ReactNode
}

const StatusDisplay: React.FC<StatusDisplayProps> = ({ status, children }) => (
    <div className="relative h-full">
        <Transition
            as="div"
            className="h-full"
            appear={true}
            show={status === 'success'}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            {children}
        </Transition>
        <Transition
            as="div"
            className="absolute top-0 left-0 h-full w-full"
            appear={true}
            show={status === 'loading'}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <LoadingIcon />
        </Transition>
        <Transition
            as="div"
            className="absolute top-0 left-0 h-full w-full"
            appear={true}
            show={status === 'idle'}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <IdleIcon />
        </Transition>
        <Transition
            as="div"
            className="absolute top-0 left-0 h-full w-full"
            appear={true}
            show={!['loading', 'idle', 'success'].includes(status)}
            enter="transition-opacity duration-300 ease-in-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300 ease-in-out"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <ErrorIcon />
        </Transition>
    </div>
)

export default StatusDisplay
