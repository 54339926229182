import { Status } from '../components/StatusDisplay'

const statusHierarchy = ['error', 'loading', 'idle', 'success']

const higherStatus = (status1, status2) => {
    const status1Level = statusHierarchy.indexOf(status1)
    const status2Level = statusHierarchy.indexOf(status2)

    return status1Level >= status2Level ? status1 : status2
}

export const combinedStatus = (...statuses: Status[]) =>
    statuses.reduce(higherStatus, 'idle')
