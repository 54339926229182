import { Transition } from '@headlessui/react'
import { Login } from '../components/Login'
import { useTheme } from '../hooks/Theme'
import { classNames } from '../utility/Components'
import orchardImage from '../images/welcome-orchard-summer.webp'
import frostyOrchardImage from '../images/welcome-orchard-winter.webp'
import pearImage from '../images/pear-illustration.svg'

export const WelcomeStatus = {
    error: 'Error',
    loading: 'Loading',
    signIn: 'Sign-in',
}

const Welcome = ({
    setLoginInfo,
    status = WelcomeStatus.loading,
    message = '',
}) => {
    const { season } = useTheme()
    return (
        <Transition
            as="div"
            className="fixed top-0 -left-1/2 h-full w-2/1 bg-cyan-200"
            show={true}
            appear={true}
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <img
                className="absolute inset-0 m-auto min-h-full"
                src={season === 'cold' ? frostyOrchardImage : orchardImage}
                alt="Orchard"
            />
            {season === 'cold' && (
                <div className="absolute inset-0 m-auto min-h-full bg-cyan-900 opacity-5"></div>
            )}
            {status !== WelcomeStatus.loading && (
                <div className="relative z-10 m-auto flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
                    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                                <img
                                    className="mx-auto h-32 w-32"
                                    src={pearImage}
                                    alt="Pear"
                                />
                                <h2 className="mt-6 mb-8 text-center text-3xl font-extrabold text-gray-900">
                                    Welcome to Pear!
                                </h2>
                            </div>
                            <div>
                                <Login setLoginInfo={setLoginInfo}>
                                    <button
                                        type="submit"
                                        className={classNames(
                                            'z-10 flex w-full justify-center rounded-md border border-transparent py-2 px-4 font-semibold text-white shadow-sm focus:outline-none focus:ring focus:ring-offset-1',
                                            season === 'cold'
                                                ? 'bg-cyan-600  hover:bg-cyan-700 focus:ring-cyan-600'
                                                : 'bg-green-600  hover:bg-green-700 focus:ring-green-600',
                                        )}
                                    >
                                        Sign in
                                    </button>
                                </Login>
                            </div>
                            {status === WelcomeStatus.error ? (
                                <div className="mt-2">
                                    <p className="text-orange-600">
                                        {message ||
                                            'Sorry, there was an error while signing in.'}
                                    </p>
                                </div>
                            ) : (
                                <div className="mt-2">
                                    {/* This should be 'invisible' to screen-reader users also. */}
                                    <p className="invisible">
                                        Error placeholder
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Transition>
    )
}

export default Welcome
