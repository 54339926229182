import {
    ComposedChart,
    Bar,
    Line,
    Cell,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts'
import {
    formatISO,
    getDate,
    getDaysInMonth,
    parseISO,
    differenceInCalendarDays,
    set,
    isWeekend,
    isSameMonth,
} from 'date-fns'
import { LineItem } from '../../apis/pearApi'
import { dollarFormat } from '../../utility/Formatting'
import {
    advertisingDaysInInterval,
    advertisingDaysLeftInInterval,
    budgetInterval,
    dateToUse,
    latestWeekdayInInterval,
    today,
} from '../../utility/BudgetCalculations'
import theme from '../../theme'
import { isWithin } from '../../utility/Time'
import { range } from '../../utility/Functions'
import { dayOnly, NO_DAY_SELECTED } from './SpendsAndPacing'
import { classNames } from '../../utility/Components'
import { ticksFromMaxValue } from '../../utility/Charts'

const isSameDay = (day: Date, dateString: string) => {
    const then = parseISO(dateString)
    return differenceInCalendarDays(day, then) === 0
}

export const SpendsByDateChart = ({
    lineItem,
    month,
    selectedDay,
    setSelectedDay,
}: {
    lineItem: LineItem
    month: string
    selectedDay: number
    setSelectedDay: (day: number) => void
}) => {
    const {
        dailySpends: spends,
        budget,
        adsOnWeekends,
        startDate,
        endDate,
    } = lineItem
    const [firstDate, lastDate] = budgetInterval(month, startDate, endDate)
    const dayToUse = dateToUse(adsOnWeekends, firstDate, lastDate)
    const day = adsOnWeekends
        ? dayToUse
        : latestWeekdayInInterval(firstDate, lastDate, dayToUse)

    const recentSpends = spends.filter(isWithin(firstDate, day))
    const spendDays = advertisingDaysInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
    )
    const baselineAverage = budget / spendDays

    const totalSpent = recentSpends.reduce((acc, spend) => acc + spend.spend, 0)
    const spendDaysLeft = advertisingDaysLeftInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
        day,
    )
    const suggestedGoal =
        totalSpent >= budget ? 0 : (budget - totalSpent) / spendDaysLeft

    // Determine if we need to fill future days or not.
    const dayNumberToUse = getDate(day)
    const daysInMonth = getDaysInMonth(day)
    const datesToChart = range(daysInMonth, 1)

    // Data Setup
    const spendsAndBaselineAverages = datesToChart.map((dayNumber) => {
        const thisDaysSpend = recentSpends.find(
            (spend) => getDate(parseISO(spend.date)) === dayNumber,
        )

        return thisDaysSpend
            ? {
                  x: dayOnly(thisDaysSpend.date),
                  y: thisDaysSpend.spend,
                  color: isSameDay(day, thisDaysSpend.date)
                      ? theme.colors.cyan[300]
                      : dayOnly(thisDaysSpend.date) === selectedDay
                      ? theme.colors.gray[500]
                      : theme.colors.gray[300],
                  stroke: isSameDay(day, thisDaysSpend.date)
                      ? theme.colors.cyan[700]
                      : theme.colors.gray[500],
                  baselineAverage:
                      !adsOnWeekends && isWeekend(parseISO(thisDaysSpend.date))
                          ? 0
                          : baselineAverage,
              }
            : {
                  x: dayOnly(
                      formatISO(set(day, { date: dayNumber }), {
                          representation: 'date',
                      }),
                  ),
                  y:
                      (dayNumber > dayNumberToUse ||
                          (dayNumber === getDate(today()) &&
                              isSameMonth(today(), firstDate))) &&
                      dayNumber <= getDate(lastDate)
                          ? !adsOnWeekends &&
                            isWeekend(set(day, { date: dayNumber }))
                              ? 0
                              : suggestedGoal
                          : 0,
                  color:
                      dayOnly(
                          formatISO(set(day, { date: dayNumber }), {
                              representation: 'date',
                          }),
                      ) === selectedDay
                          ? theme.colors.gray[200]
                          : theme.colors.gray[100],
                  stroke: theme.colors.gray[300],
                  baselineAverage:
                      !adsOnWeekends && isWeekend(set(day, { date: dayNumber }))
                          ? 0
                          : baselineAverage,
              }
    })

    const maxSpend = spendsAndBaselineAverages.reduce(
        (max, spend) => Math.max(spend.y, max),
        0,
    )
    const chartMax = Math.max(maxSpend, baselineAverage)

    const XAxisLabelWithoutTick = ({ x, y, payload: { value } }) => (
        <g transform={'translate( ' + x + ',' + y + ' )'}>
            <text
                x={4}
                y={0}
                className={classNames(
                    'fill-current font-sans',
                    value === dayNumberToUse
                        ? 'font-semibold text-cyan-700'
                        : 'text-gray-600',
                )}
                fontSize="11px"
                textAnchor="end"
            >
                {value}
            </text>
        </g>
    )

    const yTicks = ticksFromMaxValue(4, Math.max(chartMax, 0.9))
    const showDecimals = yTicks.some((tick) => tick % 1 !== 0)
    const YAxisLabelWithoutTick = ({ x, y, payload: { value } }) => (
        <g transform={'translate( ' + x + ',' + y + ' )'}>
            <text
                x={0}
                y={4}
                className="fill-current font-sans text-gray-600"
                fontSize="11px"
                textAnchor="end"
            >
                {dollarFormat(value, showDecimals ? 2 : 0)}
            </text>
        </g>
    )

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={spendsAndBaselineAverages}
                margin={{ top: 0, right: 0, bottom: 5, left: 0 }}
                onMouseLeave={() => {
                    setSelectedDay(NO_DAY_SELECTED)
                }}
            >
                <XAxis
                    dataKey="x"
                    type="category"
                    orientation="top"
                    domain={[1, daysInMonth]}
                    interval={0}
                    tick={XAxisLabelWithoutTick}
                    axisLine={false}
                    tickLine={false}
                />
                <YAxis
                    width={40}
                    type="number"
                    allowDecimals={false}
                    domain={[0, yTicks[yTicks.length - 1]]}
                    tick={YAxisLabelWithoutTick}
                    ticks={yTicks}
                    interval={0}
                    axisLine={false}
                    tickLine={false}
                />
                <Tooltip
                    content={({ label: day, active }) => {
                        if (active) {
                            setSelectedDay(day)
                        }
                        return null
                    }}
                />
                {selectedDay !== NO_DAY_SELECTED && (
                    <ReferenceLine
                        x={selectedDay}
                        stroke={theme.colors.gray[500]}
                        strokeWidth={1}
                    />
                )}
                <Bar
                    isAnimationActive={false}
                    type="monotoneX"
                    dataKey="y"
                    strokeWidth={1}
                    barSize={8}
                >
                    {spendsAndBaselineAverages.map((day, index) => (
                        <Cell
                            key={`bar-${day.x}`}
                            fill={day.color}
                            stroke={day.stroke}
                        />
                    ))}
                </Bar>
                <Line
                    isAnimationActive={false}
                    type="monotoneX"
                    dataKey="baselineAverage"
                    stroke={theme.colors.cyan[600]}
                    strokeWidth={2}
                    dot={false}
                    activeDot={false}
                />
            </ComposedChart>
        </ResponsiveContainer>
    )
}
