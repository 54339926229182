import { useCallback, memo, CSSProperties, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useTable, useSortBy } from 'react-table'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid'
import { SectionCloud } from '../components/Layout'
import StatusDisplay from '../components/StatusDisplay'
import { classNames } from '../utility/Components'
import {
    invalidateSafeguardAlerts,
    useSafeguardAlerts,
} from '../hooks/apiHooks'
import { StatelessSelect } from '../components/elements/StatelessSelect'
import { StatelessTextBox } from '../components/elements/TextBoxes'
import { Toggle } from '../components/elements/Toggle'
import { dollarFormat } from '../utility/Formatting'
import PearApi, { SafeguardAlertTypes } from '../apis/pearApi'
import AppRoutes from './routes'
import { rowValuesByColumnId } from '../utility/ReactTable'
import PopupDialog from '../components/elements/PopupDialog'
import ButtonGroup from '../components/elements/Buttons'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import CsvLink from '../components/CsvLink'
import { onlyUnique } from '../utility/Arrays'

const campaignSort = (row1, row2, columnId) => {
    const [campaigns1, campaigns2] = rowValuesByColumnId(row1, row2, columnId)
    if (campaigns1.length < campaigns2.length) {
        return -1
    }
    if (campaigns1.length > campaigns2.length) {
        return 1
    }
    return 0
}

const columns = [
    {
        Header: 'Alert Type',
        accessor: 'type',
    },
    {
        Header: 'Source',
        accessor: 'adAccount.source',
    },
    {
        Header: 'Client Type',
        accessor: 'client.type',
    },
    {
        Header: 'Budgeter',
        accessor: 'budgeter.name',
    },
    {
        Header: 'Ad Account Id',
        accessor: 'adAccount.id',
    },
    {
        Header: 'Ad Account Name',
        accessor: 'adAccount.name',
    },
    {
        Header: 'Campaigns',
        accessor: 'campaigns',
        Cell: () => 'campaigns',
        sortType: campaignSort,
    },
    {
        Header: 'Spend',
        accessor: 'spend',
        Cell: ({ value: total }) => (total > 0 ? dollarFormat(total) : ''),
    },
    {
        Header: 'Ignore',
        accessor: 'ignoredAt',
        Cell: ({ value }) => (value !== undefined ? 'ignored' : 'unignored'),
        disableSortBy: true,
    },
    {
        Header: 'Action',
        accessor: 'client.id',
        disableSortBy: true,
    },
]

const columnSpacing = {
    type: 'w-36',
    'adAccount.source': 'w-24',
    'client.type': 'w-36',
    'budgeter.name': 'w-24',
    'adAccount.id': 'w-32',
    'adAccount.name': 'flex-1',
    'campaign.name': 'w-30',
    spend: 'w-20',
    ignoredAt: 'w-16',
    'client.id': 'w-28',
}
const csvHeaders = [
    {
        label: 'Alert Type',
        key: 'type',
    },
    {
        label: 'Source',
        key: 'adAccount.source',
    },
    {
        label: 'Budgeter',
        key: 'budgeter.name',
    },
    {
        label: 'Ad Account Id',
        key: 'adAccount.id',
    },
    {
        label: 'Ad Account Name',
        key: 'adAccount.name',
    },
    {
        label: 'Client Type',
        key: 'client.type',
    },
    {
        label: 'Spend',
        key: 'spend',
    },
    {
        label: 'Campaigns',
        key: 'campaigns',
    },
]

const ALL_TYPES = 'Any'
const types = [ALL_TYPES]
    .concat(Object.values(SafeguardAlertTypes))
    .map((type) => ({ id: type, label: type }))

const bcdfOptions = [
    { value: 'all', name: 'All' },
    { value: 'only', name: 'BCDF' },
    { value: 'non', name: 'Non-BCDF' },
] as const

const Safeguard = () => {
    const [selectedType, setSelectedType] = useState(ALL_TYPES)
    const [selectedBudgeter, setSelectedBudgeter] = useState(ALL_TYPES)
    const [selectedClientType, setSelectedClientType] = useState(ALL_TYPES)
    const [search, setSearch] = useState('')
    const [showIgnored, setShowIgnored] = useState(false)

    const [urlBcdf, setBcdf] = useUrlQueryParameter('bcdf', 'all')
    const showBcdf = ['all', 'only', 'non'].includes(urlBcdf) ? urlBcdf : 'all'
    const setShowBcdf = (showBcdf) =>
        setBcdf(['all', 'only', 'non'].includes(showBcdf) ? showBcdf : 'all')

    const { data: alerts, status } = useSafeguardAlerts()
    const budgeterNames = alerts
        .map((alert) => alert.budgeter.name)
        .filter(onlyUnique)
        .sort()
    const budgeters = [ALL_TYPES, ...budgeterNames].map((type) => ({
        id: type,
        label: type,
    }))

    const clientTypesNames = alerts
        .map((alert) => alert.client?.type)
        .filter((type) => type !== undefined)
        .filter(onlyUnique)
        .sort()
    const clientTypes = [ALL_TYPES, ...clientTypesNames].map((type) => ({
        id: type,
        label: type,
    }))

    const filteredAlerts = useMemo(() => {
        const loweredSearch = search.toLowerCase()
        return alerts.filter(
            ({ ignoredAt, campaigns, adAccount, type, budgeter, client }) => {
                const campaignNames = campaigns
                    .map((campaign) => campaign.name)
                    .join(',')
                const { name: adAccountName, source } = adAccount
                const ignore = ignoredAt !== undefined
                if (ignore !== showIgnored) {
                    return false
                }
                const isBcdf = campaignNames.toLowerCase().includes('bcdf')
                if (
                    (showBcdf === 'only' && !isBcdf) ||
                    (showBcdf === 'non' && isBcdf)
                ) {
                    return false
                }
                if (selectedType !== ALL_TYPES && type !== selectedType) {
                    return false
                }
                if (
                    selectedBudgeter !== ALL_TYPES &&
                    budgeter.name !== selectedBudgeter
                ) {
                    return false
                }
                if (
                    selectedClientType !== ALL_TYPES &&
                    client?.type !== selectedClientType
                ) {
                    return false
                }
                if (
                    !source?.toLowerCase().includes(loweredSearch) &&
                    !campaignNames?.toLowerCase().includes(loweredSearch) &&
                    !adAccountName?.toLowerCase().includes(loweredSearch)
                ) {
                    return false
                }
                return true
            },
        )
    }, [
        alerts,
        selectedType,
        selectedBudgeter,
        selectedClientType,
        search,
        showIgnored,
        showBcdf,
    ])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: filteredAlerts,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: [{ id: 'adAccount.name', desc: false }],
            },
        },
        useSortBy,
    )

    const NO_POPUP_ID = -1
    const [currentPopupId, setCurrentPopupId] = useState<number>(NO_POPUP_ID)
    const RenderRow = useCallback(
        ({ index, style }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <Row
                    row={row}
                    {...row.getRowProps()}
                    style={style}
                    currentPopupId={currentPopupId}
                    setCurrentPopupId={setCurrentPopupId}
                />
            )
        },
        [prepareRow, rows, currentPopupId],
    )

    return (
        <SectionCloud className="flex h-full flex-col px-8 py-6">
            <div className="flex flex-none items-baseline justify-between">
                <h1 className="mb-3 flex-initial text-3xl font-extrabold tracking-tight text-gray-600">
                    Safeguard 🦺
                </h1>
                <CsvLink
                    className={
                        'rounded-md bg-cyan-600 px-3 py-1 text-white ring-cyan-500 ring-offset-1 hover:bg-cyan-700 focus:outline-none focus:ring'
                    }
                    headers={csvHeaders}
                    data={() =>
                        filteredAlerts.map((alert) => ({
                            ...alert,
                            campaigns: alert.campaigns
                                .map((c) => c.name)
                                .join(';'),
                        }))
                    }
                    filename="Pear - Safeguard Alerts.csv"
                >
                    Export CSV
                </CsvLink>
            </div>
            <div className="flex flex-none gap-12">
                <div className="w-52 pr-1">
                    <StatelessSelect
                        className="mt-1"
                        label="Alert Type"
                        value={types.find(
                            ({ label }) => label === selectedType,
                        )}
                        options={types}
                        onChange={({ label }) => setSelectedType(label)}
                    />
                </div>
                <div className="w-52 pr-1">
                    <StatelessSelect
                        className="mt-1"
                        label="Client Type"
                        value={clientTypes.find(
                            ({ label }) => label === selectedClientType,
                        )}
                        options={clientTypes}
                        onChange={({ label }) => setSelectedClientType(label)}
                    />
                </div>
                <div className="w-44 pr-1">
                    <StatelessSelect
                        className="mt-1"
                        label="Budgeter"
                        value={budgeters.find(
                            ({ label }) => label === selectedBudgeter,
                        )}
                        options={budgeters}
                        onChange={({ label }) => setSelectedBudgeter(label)}
                    />
                </div>
                <div className="flex-auto pl-2">
                    <StatelessTextBox
                        classNameInput="mt-1"
                        label="Search"
                        text={search}
                        onChange={setSearch}
                    />
                </div>
                <div className="-mr-3.5 w-48 pt-8">
                    <Toggle
                        className=""
                        label="Unignored/Ignored"
                        enabled={showIgnored}
                        setEnabled={setShowIgnored}
                    />
                </div>
                <div className="w-72 pl-1">
                    <ButtonGroup
                        className="w-full"
                        label="BCDF Filter"
                        choices={bcdfOptions}
                        value={showBcdf}
                        setValue={setShowBcdf}
                    />
                </div>
            </div>
            <div className="mt-4 min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50 pl-2"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className="flex gap-4"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const justification = [
                                                        'spend',
                                                    ].includes(id)
                                                        ? 'text-right'
                                                        : 'text-left'
                                                    const spacing =
                                                        columnSpacing[id]
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <button
                                                            className={classNames(
                                                                'track group py-2 text-xs font-medium uppercase text-gray-700 focus:font-semibold focus:text-cyan-900 focus:outline-none',
                                                                justification,
                                                                spacing,
                                                                [
                                                                    'ignoredAt',
                                                                ].includes(
                                                                    id,
                                                                ) && 'pl-1',
                                                                [
                                                                    'client.id',
                                                                ].includes(
                                                                    id,
                                                                ) && 'pl-2',
                                                                column.disableSortBy &&
                                                                    'cursor-default',
                                                            )}
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}
                                                            title={undefined}
                                                            disabled={
                                                                column.disableSortBy
                                                            }
                                                        >
                                                            <span className="">
                                                                {header}
                                                            </span>
                                                            {sortBy[0].id ===
                                                            column.id ? (
                                                                column.isSortedDesc ? (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            descending
                                                                        </span>
                                                                        <ArrowDownIcon
                                                                            className="ml-2 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-gray-700 "
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            ascending
                                                                        </span>
                                                                        <ArrowUpIcon
                                                                            className="ml-2 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-gray-700"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )
                                                            ) : !column.disableSortBy ? (
                                                                <ArrowDownIcon
                                                                    className="ml-2 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : null}
                                                        </button>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="flex-1 bg-white">
                                    {rows.length > 0 ? (
                                        <div
                                            className="h-full"
                                            {...getTableBodyProps()}
                                        >
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <List
                                                        height={height}
                                                        width={width}
                                                        itemCount={rows.length}
                                                        itemSize={36}
                                                        style={{
                                                            overflowX: 'hidden',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        {RenderRow}
                                                    </List>
                                                )}
                                            </AutoSizer>
                                        </div>
                                    ) : status === 'loading' ||
                                      status === 'idle' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    No alerts were found for the
                                                    current filter settings.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}

const Row = memo(
    ({
        row,
        showDetails,
        style,
        invalidateWatchdogs,
        currentPopupId,
        setCurrentPopupId,
        ...otherProps
    }: {
        row: any
        showDetails: (campaigns: [], lineItemName) => void
        style: CSSProperties
        invalidateWatchdogs: () => void
        currentPopupId: number
        setCurrentPopupId: (id: number) => void
    }) => {
        return (
            <div
                className="flex items-center gap-4 pl-2"
                {...otherProps}
                style={style}
            >
                {row.cells.map((cell) => {
                    const { value } = cell
                    const { id } = cell?.column ?? {}
                    const justification = ['spend'].includes(id)
                        ? 'text-right pr-1'
                        : ''
                    const spacing = columnSpacing[id]
                    const color = 'text-gray-900'

                    return id === 'ignoredAt' ? (
                        <div
                            className={classNames(
                                'flex h-full items-center',
                                spacing,
                            )}
                            {...cell.getCellProps()}
                        >
                            {cell.row.original.permanentlyIgnore ? (
                                <span> </span>
                            ) : (
                                <IgnoreSwitch
                                    ignore={value}
                                    invalidateSafeguardAlerts={
                                        invalidateSafeguardAlerts
                                    }
                                    alertId={cell.row.original.id}
                                />
                            )}
                        </div>
                    ) : id === 'client.id' ? (
                        <div
                            className={classNames(
                                'flex h-full items-center',
                                spacing,
                            )}
                            {...cell.getCellProps()}
                        >
                            <ActionButton
                                clientId={value}
                                type={cell.row.values.type}
                            />
                        </div>
                    ) : id === 'campaigns' ? (
                        <div
                            className="flex h-8 items-center"
                            {...cell.getCellProps()}
                        >
                            <PopupDialog
                                popupId={row.index}
                                currentPopupId={currentPopupId}
                                setCurrentPopupId={setCurrentPopupId}
                                placement="left"
                                render={({ close, labelId }) => (
                                    <div className="overflow-hidden rounded-lg border border-gray-100 bg-white px-3 py-3 text-left align-bottom shadow-lg outline-none sm:my-8 sm:w-full sm:max-w-3xl sm:p-8 sm:align-middle">
                                        <div className="h-full">
                                            <div className="flex items-start justify-between">
                                                <h2
                                                    id={labelId}
                                                    className="text-lg font-medium tracking-tight text-gray-900"
                                                >
                                                    Campaigns
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="relative mt-2 max-h-64 overflow-y-scroll border-y-2 border-cyan-500 text-xs">
                                            {value.length > 0 ? (
                                                <ul className="h-full py-1 pr-2">
                                                    {value.map(
                                                        ({
                                                            id,
                                                            name,
                                                            status,
                                                        }) => (
                                                            <li
                                                                key={id}
                                                                className="flex gap-6 py-1 text-gray-700"
                                                            >
                                                                <div className="flex-1">
                                                                    {name}
                                                                </div>
                                                                <div className="w-14">
                                                                    {status}
                                                                </div>
                                                            </li>
                                                        ),
                                                    )}
                                                </ul>
                                            ) : (
                                                <div className="h-full py-4 px-8 text-center text-gray-700">
                                                    No matched campaigns.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            >
                                <button
                                    type="button"
                                    className="rounded p-1 text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500"
                                >
                                    {row.original.campaigns.length}
                                </button>
                            </PopupDialog>
                        </div>
                    ) : (
                        <div
                            className={classNames(
                                'truncate text-xs ',
                                justification,
                                spacing,
                                color,
                            )}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell')}
                        </div>
                    )
                })}
            </div>
        )
    },
)

const IgnoreSwitch = ({ invalidateSafeguardAlerts, alertId, ignore }) => {
    const [ignoreInternal, setIgnoreInternal] = useState(ignore)
    const { mutate: updateIgnore } = useMutation(
        (ignore: boolean) => PearApi.setSafeguardAlertIgnore(alertId, ignore),
        {
            onSettled: () => {
                invalidateSafeguardAlerts()
            },
        },
    )
    const change = (value) => {
        setIgnoreInternal(value)
        updateIgnore(value)
    }

    return (
        <Toggle
            className="h-8 items-center pl-1"
            label="Ignore"
            enabled={ignoreInternal}
            setEnabled={change}
            showLabel={false}
        />
    )
}

const ActionButton = ({ clientId, type }) => {
    const info =
        type === SafeguardAlertTypes.NO_CLIENT || !clientId
            ? { to: AppRoutes.CLIENTS, label: 'Setup Client' }
            : [
                  SafeguardAlertTypes.NO_LINE_ITEM,
                  SafeguardAlertTypes.NO_SALESFORCE_BUDGET,
                  SafeguardAlertTypes.BUDGET_MISMATCH,
                  SafeguardAlertTypes.SPENDING_AFTER_END_DATE,
              ].includes(type)
            ? {
                  to: AppRoutes.CLIENTS + `/${clientId}/edit-budgets`,
                  label: 'Edit Budgets',
              }
            : {
                  to: AppRoutes.CLIENTS + `?id=${clientId}`,
                  label: 'Assign Client',
              }

    return (
        <Link
            className="my-2 ml-1 rounded px-1 text-sm text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1 disabled:pointer-events-none disabled:text-gray-600"
            to={info.to}
        >
            {info.label}
        </Link>
    )
}

export default Safeguard
