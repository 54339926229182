import { useState } from 'react'
import { Field } from 'react-final-form'
import { ExclamationCircleIcon, CalendarIcon } from '@heroicons/react/20/solid'
import { Tooltip } from 'react-tooltip'
import { classNames } from '../../utility/Components'
import NumberInput from '../elements/NumberInput'
import { useToggle } from '../../hooks/useToggle'
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    useDismiss,
    useRole,
    useClick,
    useInteractions,
    FloatingFocusManager,
    useId,
} from '@floating-ui/react'
import DatePicker from 'react-datepicker'
import { parseISO } from 'date-fns'
import { DateTime } from 'luxon'
import { isoDay } from '../../utility/Formatting'
import 'react-datepicker/dist/react-datepicker.css'

const BudgetInput = ({ input, meta, className = '' }) => {
    const isError = !!meta.error && meta.touched

    return (
        <div className={className}>
            <label htmlFor={input.name} className="sr-only">
                Budget
            </label>
            <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                    value={input.value}
                    onChange={input.onChange}
                    max={100000}
                    min={0}
                    name="budget"
                    id={input.name}
                    className={classNames(
                        isError
                            ? 'border-2 border-orange-600 pr-8 focus:border-orange-600 focus:outline-none focus:ring-orange-600'
                            : 'border border-gray-300 pr-3 focus:border-cyan-500 focus:ring-cyan-500',
                        'peer block w-full rounded-md py-1 pl-7 focus:ring-2 sm:text-sm',
                    )}
                />
                {isError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-orange-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
                <p
                    className={classNames(
                        'block opacity-0',
                        isError
                            ? 'visible peer-focus:block peer-focus:opacity-100 peer-hover:block peer-hover:opacity-100'
                            : 'invisible',
                        'pointer-events-none absolute -bottom-4 left-4 z-10 w-full truncate rounded-md border border-orange-300 bg-orange-200 px-2 py-0.5 text-xs text-orange-900 transition-all duration-150 ease-in-out',
                    )}
                >
                    {meta.error}
                </p>
            </div>
        </div>
    )
}

export const BudgetField = ({ name, className = '' }) => {
    return <Field className={className} component={BudgetInput} name={name} />
}

const PackageInput = ({ input, meta, onChange, className = '' }) => {
    const isError = !!meta.error && meta.touched

    const handleInputChange = (value) => {
        input.onChange(value)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <div className={className}>
            <label htmlFor={input.name} className="sr-only">
                Package
            </label>
            <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <NumberInput
                    value={input.value}
                    onChange={handleInputChange}
                    max={100000}
                    min={0}
                    name="pkg"
                    id={input.name}
                    className={classNames(
                        isError
                            ? 'border-2 border-orange-600 pr-8 focus:border-orange-600 focus:outline-none focus:ring-orange-600'
                            : 'border border-gray-300 pr-3 focus:border-cyan-500 focus:ring-cyan-500',
                        'peer block w-full rounded-md py-1 pl-7 focus:ring-2 sm:text-sm',
                    )}
                />
                {isError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-orange-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
                <p
                    className={classNames(
                        'block opacity-0',
                        isError
                            ? 'visible peer-focus:block peer-focus:opacity-100 peer-hover:block peer-hover:opacity-100'
                            : 'invisible',
                        'pointer-events-none absolute -bottom-4 left-4 z-10 w-full truncate rounded-md border border-orange-300 bg-orange-200 px-2 py-0.5 text-xs text-orange-900 transition-all duration-150 ease-in-out',
                    )}
                >
                    {meta.error}
                </p>
            </div>
        </div>
    )
}

export const PackageField = ({ name, className = '', onChange }) => {
    return (
        <Field
            className={className}
            component={PackageInput}
            name={name}
            onChange={onChange}
        />
    )
}

const AdjustmentInput = ({ input, meta, className = '' }) => {
    const isError = !!meta.error && meta.touched
    const flag = input && input.value ? true : false
    const [toggle, setToggle] = useToggle(flag)

    const triggerAdjustment = (event) => {
        event.stopPropagation()
        setToggle()
        input.onChange('')
    }

    return toggle ? (
        <>
            <div className="flex-initial text-2xl text-gray-500">+</div>
            <div className={className + ' flex'}>
                <div className="w-full">
                    <div className="relative flex-1 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <label htmlFor={input.name} className="sr-only">
                            Adjustment
                        </label>
                        <NumberInput
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={input.onChange}
                            max={100000}
                            min={-100000}
                            name="adjustment"
                            id={input.name}
                            pattern="^-?\d+$"
                            autoFocus
                            className={classNames(
                                isError
                                    ? 'border-2 border-orange-600 pr-8 focus:border-orange-600 focus:ring-orange-600'
                                    : 'border border-gray-300 pr-3 focus:border-cyan-500 focus:ring-cyan-500',
                                'peer block w-full rounded-md py-1 pl-7 focus:outline-none focus:ring-2 sm:text-sm',
                            )}
                        />
                        {isError && (
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-orange-500"
                                    aria-hidden="true"
                                />
                            </div>
                        )}
                        <p
                            className={classNames(
                                'block opacity-0',
                                isError
                                    ? 'visible peer-focus:block peer-focus:opacity-100 peer-hover:block peer-hover:opacity-100'
                                    : 'invisible',
                                'pointer-events-none absolute -bottom-4 left-4 z-10 w-full truncate rounded-md border border-orange-300 bg-orange-200 px-2 py-0.5 text-xs text-orange-900 transition-all duration-150 ease-in-out',
                            )}
                        >
                            {meta.error}
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex-initial pl-3">
                <label htmlFor="remove" className="sr-only">
                    Remove adjustments
                </label>
                <button
                    className="h-8 w-8 rounded-full bg-gray-200 text-xl font-bold leading-3 text-gray-700 hover:bg-gray-600 hover:text-gray-200 focus:bg-gray-600 focus:text-gray-200 focus:outline-none"
                    type="button"
                    id="remove"
                    onClick={triggerAdjustment}
                >
                    =
                </button>
            </div>
        </>
    ) : (
        <div className="flex-initial pl-3" onClick={triggerAdjustment}>
            <label htmlFor="add" className="sr-only">
                Add adjustments
            </label>
            <button
                className="h-8 w-8 rounded-full bg-gray-200 text-xl font-bold leading-3 text-gray-600 hover:bg-gray-600 hover:text-gray-200 focus:bg-gray-600 focus:text-gray-200 focus:outline-none"
                type="button"
                title="Add adjustments"
                id="add"
                onClick={triggerAdjustment}
            >
                ±
            </button>
        </div>
    )
}

export const AdjustmentField = ({ name, className }) => {
    return (
        <Field className={className} name={name} component={AdjustmentInput} />
    )
}

const CalendarInput = ({
    input,
    meta,
    className = '',
    startDate,
    endDate,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const triggerCalendar = (event) => {
        setIsOpen((isOpen) => !isOpen)
    }

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            offset(10),
            flip({ fallbackAxisSideDirection: 'end' }),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)
    const role = useRole(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
        role,
    ])

    const headingId = useId()

    const onDatesChange = (value) => {
        const [start, end] = value
        setStartDate(start)
        setEndDate(end)

        // Update line item dates
        if (start && end) {
            triggerCalendar(null)
            input.onChange(isoDay(start))
            onChange(value)
        }
    }

    // Get basic month dates
    const currentDate = DateTime.local()
    const firstDayMonth = new Date(currentDate.startOf('month'))
    const lastDayMonth = new Date(currentDate.endOf('month'))

    const doWeHaveDates = startDate ? true : false

    const [startDateState, setStartDate] = useState(
        startDate ? parseISO(startDate) : null,
    )
    const [endDateState, setEndDate] = useState(
        endDate ? parseISO(endDate) : null,
    )

    return (
        <>
            <div className="relative flex-initial pl-3">
                <Tooltip
                    anchorSelect={`#tooltip-${input.name}`}
                    place="top"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                        fontFamily: '"Roboto", sans-serif',
                        padding: '6px 10px 4px',
                        borderRadius: '8px',
                    }}
                >
                    <div>
                        {startDateState
                            ? 'Start Date: ' + isoDay(startDateState)
                            : 'Select Dates'}
                    </div>
                    <div>
                        {endDateState
                            ? 'End Date: ' + isoDay(endDateState)
                            : ''}
                    </div>
                </Tooltip>
                <button
                    id={`tooltip-${input.name}`}
                    ref={refs.setReference}
                    {...getReferenceProps()}
                    className={classNames(
                        'h-8 w-8 rounded-full text-xl font-bold leading-3 hover:text-gray-200 focus:outline-none',
                        doWeHaveDates
                            ? 'bg-cyan-200 text-cyan-700 hover:bg-cyan-600'
                            : 'bg-gray-200 text-gray-700 hover:bg-gray-600',
                    )}
                    type="button"
                    onClick={triggerCalendar}
                >
                    <CalendarIcon
                        className="m-auto h-5 w-5"
                        aria-hidden="true"
                    />
                </button>
                {isOpen && (
                    <FloatingFocusManager context={context} modal={false}>
                        <div
                            className="Popover z-50"
                            ref={refs.setFloating}
                            style={floatingStyles}
                            aria-labelledby={headingId}
                            {...getFloatingProps()}
                        >
                            <div className="rounded-md bg-white pl-4 pt-4 pr-4 pb-2 shadow-lg">
                                <div className="border-y-8 border-l-8 border-r-0 border-solid border-y-transparent border-l-white"></div>
                                <DatePicker
                                    value={input.value}
                                    wrapperClassName="datePickerInput"
                                    id="lineItemDate"
                                    dateFormat="yyyy-MM-dd"
                                    selected={startDateState}
                                    onChange={onDatesChange}
                                    minDate={firstDayMonth}
                                    maxDate={lastDayMonth}
                                    startDate={startDateState}
                                    endDate={endDateState}
                                    selectsRange
                                    inline
                                    showDisabledMonthNavigation
                                />
                            </div>
                        </div>
                    </FloatingFocusManager>
                )}
            </div>
        </>
    )
}

export const CalendarField = ({
    name,
    className,
    startDate,
    endDate,
    onChange,
}) => {
    return (
        <Field
            className={className}
            name={name}
            component={CalendarInput}
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
        />
    )
}

const LabelInput = ({ input, meta, className = '', onFocus }) => {
    const isError = !!meta.error

    return (
        <div className={classNames(className)}>
            <label htmlFor={input.name} className="sr-only">
                Label
            </label>
            <div className="relative rounded-md shadow-sm">
                <input
                    value={input.value}
                    onFocus={onFocus}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    type="text"
                    name={input.name}
                    id={input.name}
                    className={classNames(
                        isError
                            ? 'border-2 border-orange-600 pr-8 focus:border-orange-600 focus:outline-none focus:ring-orange-600'
                            : 'border border-gray-300 pr-3 transition-all focus:border-cyan-500 focus:ring-cyan-500',
                        'peer block w-full rounded-md py-[6px] pl-2 hover:w-[125%] focus:w-[125%] focus:ring-2 sm:text-xs',
                    )}
                />
                {isError && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ExclamationCircleIcon
                            className="h-5 w-5 text-orange-500"
                            aria-hidden="true"
                        />
                    </div>
                )}
                <p
                    className={classNames(
                        'block opacity-0',
                        isError
                            ? 'visible peer-focus:block peer-focus:opacity-100 peer-hover:block peer-hover:opacity-100'
                            : 'invisible',
                        'pointer-events-none absolute -bottom-4 left-4 z-10 w-full truncate rounded-md border border-orange-300 bg-orange-200 px-2 py-0.5 text-xs text-orange-900 transition-all duration-150 ease-in-out',
                    )}
                >
                    {meta.error}
                </p>
            </div>
        </div>
    )
}

export const LabelField = ({ name, className, onFocus }) => {
    return (
        <Field
            className={className}
            name={name}
            component={LabelInput}
            onFocus={onFocus}
        />
    )
}
