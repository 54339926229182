import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useTable, useSortBy } from 'react-table'
import PearApi, { PromoAdBuilderClient } from '../../apis/pearApi'
import { invalidatePromoAdBuilderClients } from '../../hooks/apiHooks'
import { classNames } from '../../utility/Components'
import { Button } from '../elements/Buttons'
import { StatelessSelect } from '../elements/StatelessSelect'
import {
    CompactStatelessTextBox,
    StatelessTextBox,
} from '../elements/TextBoxes'
import StatusDisplay, { Status } from '../StatusDisplay'

const ALL_MAKES = 'All'

const columns = [
    {
        Header: 'Client Name',
        accessor: 'name',
        Cell: ({ value: name, row }) => (
            <div className="flex gap-4">
                <span className="flex-1">{name}</span>
                {row.original.dealerCode && (
                    <span className="w-12 truncate font-light">
                        {row.original.dealerCode}
                    </span>
                )}
            </div>
        ),
    },
    {
        Header: 'Short Name',
        accessor: 'shortName',
        disableSortBy: true,
    },
    {
        Header: 'Website',
        accessor: 'website',
        disableSortBy: true,
    },
    {
        Header: 'Makes',
        accessor: 'makes',
        disableSortBy: true,
        Cell: ({ value }) => value.split(';').join(', '),
    },
]

const columnSpacing = {
    name: 'flex-1',
    shortName: 'w-80',
    website: 'w-96',
    makes: 'w-56',
}

const ShortNameCell = ({
    spacing,
    cell,
    edits,
    clientId,
    value,
    row,
    setEdits,
}) => (
    <div
        className={classNames(spacing)}
        onClick={(e) => {
            e.stopPropagation()
        }}
        {...cell.getCellProps()}
    >
        <CompactStatelessTextBox
            className="w-full"
            text={edits[clientId]?.shortName ?? value}
            onChange={(value) => {
                if (value !== row.original.shortName) {
                    setEdits((prevEdits) => ({
                        ...prevEdits,
                        [clientId]: {
                            ...prevEdits[clientId],
                            shortName: value,
                        },
                    }))
                } else {
                    setEdits((prevEdits) => ({
                        ...prevEdits,
                        [clientId]: prevEdits[clientId]?.website
                            ? {
                                  website: prevEdits[clientId].website,
                              }
                            : undefined,
                    }))
                }
            }}
        />
    </div>
)

const WebsiteCell = ({
    spacing,
    cell,
    edits,
    clientId,
    value,
    row,
    setEdits,
}) => (
    <div
        className={classNames(spacing)}
        onClick={(e) => {
            e.stopPropagation()
        }}
        {...cell.getCellProps()}
    >
        <CompactStatelessTextBox
            text={edits[clientId]?.website ?? value}
            onChange={(value) => {
                if (value !== row.original.website) {
                    setEdits((prevEdits) => ({
                        ...prevEdits,
                        [clientId]: {
                            ...prevEdits[clientId],
                            website: value,
                        },
                    }))
                } else {
                    setEdits((prevEdits) => ({
                        ...prevEdits,
                        [clientId]: prevEdits[clientId]?.shortName
                            ? {
                                  shortName: prevEdits[clientId].shortName,
                              }
                            : undefined,
                    }))
                }
            }}
        />
    </div>
)

const ActionButtonsCell = ({
    spacing,
    cell,
    updatesInProgress,
    isSelected,
    row,
    edits,
    clientId,
    updatePromoInfo,
    setEdits,
}) => {
    return (
        <div
            className={classNames(
                spacing,
                'mt-1 flex h-full items-center py-1',
            )}
            {...cell.getCellProps()}
        >
            <button
                className={classNames(
                    'inline rounded-md px-3 py-1 text-sm  focus:outline-none focus:ring-offset-1',
                    updatesInProgress
                        ? 'pointer-events-none bg-gray-200 text-gray-600'
                        : isSelected
                        ? 'bg-white text-cyan-700 hover:bg-cyan-50 hover:text-cyan-900 focus:border focus:border-cyan-500 focus:ring-1 focus:ring-white'
                        : 'bg-cyan-600 text-white hover:bg-cyan-700 focus:ring focus:ring-cyan-500',
                )}
                disabled={updatesInProgress}
                onClick={(e) => {
                    e.stopPropagation()
                    const clientInfo = {
                        id: row.original.id,
                    } as {
                        id: number
                        shortName?: string
                        website?: string
                    }
                    if (edits[clientId]?.shortName) {
                        clientInfo.shortName = edits[clientId].shortName
                    }
                    if (edits[clientId]?.website) {
                        clientInfo.website = edits[clientId].website
                    }
                    updatePromoInfo(clientInfo)
                }}
            >
                Save Changes
            </button>
            <button
                className={classNames(
                    'ml-4 rounded-md bg-gray-100 px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring focus:ring-offset-1',
                    isSelected
                        ? 'focus:border focus:border-cyan-600 focus:ring-1 focus:ring-white'
                        : 'focus:ring focus:ring-cyan-500',
                )}
                onClick={(e) => {
                    e.stopPropagation()
                    setEdits((prevEdits) => ({
                        ...prevEdits,
                        [clientId]: undefined,
                    }))
                }}
            >
                Reset
            </button>
        </div>
    )
}

const Row = ({
    row,
    edits,
    setEdits,
    selectionList,
    toggleClientSelection,
    prepareRow,
    updatePromoInfo,
    updatesInProgress,
}) => {
    const { id: clientId } = row.original

    const thisRowsEdits = edits[clientId]
    const hasEdits = thisRowsEdits !== undefined
    const shortNameSaved =
        !hasEdits ||
        thisRowsEdits?.shortName === undefined ||
        thisRowsEdits?.shortName === row.values.shortName
    const websiteSaved =
        !hasEdits ||
        thisRowsEdits?.website === undefined ||
        thisRowsEdits?.website === row.values.website
    const changesAreSaved = shortNameSaved && websiteSaved

    const isSelected = selectionList.has(clientId)

    prepareRow(row)

    return (
        <li
            key={clientId}
            className={classNames(
                'flex items-center gap-4 pb-1 pl-2 text-gray-900',
                isSelected
                    ? 'bg-cyan-600 font-bold text-white'
                    : 'hover:bg-cyan-500 hover:text-white',
            )}
            {...row.getRowProps()}
            onClick={() => {
                if (isSelected) {
                    toggleClientSelection(clientId)
                } else {
                    toggleClientSelection(clientId)
                }
            }}
        >
            {row.cells.map((cell) => {
                const { value } = cell
                const { id: columnId } = cell.column
                const spacing = columnSpacing[columnId]

                return columnId === 'shortName' ? (
                    <ShortNameCell
                        key={columnId}
                        {...{
                            spacing,
                            cell,
                            edits,
                            clientId,
                            value,
                            row,
                            setEdits,
                        }}
                    />
                ) : columnId === 'website' ? (
                    <WebsiteCell
                        key={columnId}
                        {...{
                            spacing,
                            cell,
                            edits,
                            clientId,
                            value,
                            row,
                            setEdits,
                        }}
                    />
                ) : columnId === 'makes' && !changesAreSaved ? (
                    <ActionButtonsCell
                        key={columnId}
                        {...{
                            spacing,
                            cell,
                            updatesInProgress,
                            isSelected,
                            row,
                            edits,
                            clientId,
                            updatePromoInfo,
                            setEdits,
                        }}
                    />
                ) : (
                    <div
                        className={classNames(
                            'cursor-pointer truncate pt-2 pb-1 text-sm',
                            spacing,
                        )}
                        {...cell.getCellProps()}
                    >
                        {cell.render('Cell')}
                    </div>
                )
            })}
        </li>
    )
}

const Step2 = ({
    clients,
    status,
    selectionList,
    setSelectionList,
}: {
    clients: PromoAdBuilderClient[]
    status: Status
    selectionList: Set<number>
    setSelectionList: (set: Set<number>) => void
}) => {
    const toggleClientSelection = (clientId) => {
        if (selectionList.has(clientId)) {
            selectionList.delete(clientId)
            setSelectionList(new Set(selectionList))
        } else {
            setSelectionList(new Set(selectionList.add(clientId)))
        }
    }
    const selectionListLength = selectionList.size

    const [selectedMake, setSelectedMake] = useState(ALL_MAKES)
    const setMake = ({ id: make }) => setSelectedMake(make)

    const uniqueMakes = clients.reduce((uniqueMakes, { makes }) => {
        const list = makes.split(';')
        list.forEach((make) => {
            if (make !== '') {
                uniqueMakes.add(make)
            }
        })
        return uniqueMakes
    }, new Set<string>())
    const makesList = Array.from(uniqueMakes)
    makesList.unshift(ALL_MAKES)
    const makeOptions = makesList.map((make) => ({ id: make, label: make }))

    const [search, setSearch] = useState('')

    const makeFilteredClients =
        selectedMake === ALL_MAKES
            ? clients
            : clients.filter(({ makes }) =>
                  makes.split(';').includes(selectedMake),
              )
    const filteredClients =
        search === ''
            ? makeFilteredClients
            : makeFilteredClients.filter(({ name, dealerCode, makes }) => {
                  const query = search.toLowerCase()
                  return (
                      name.toLowerCase().includes(query) ||
                      dealerCode.toLowerCase().includes(query) ||
                      makes.toLowerCase().includes(query)
                  )
              })

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns,
            data: filteredClients,
            disableMultiSort: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                sortBy: [{ id: 'name', desc: false }],
            },
        },
        useSortBy,
    )

    const [edits, setEdits] = useState({})

    const { mutate: updatePromoInfo, isLoading: updatesInProgress } =
        useMutation(
            (clientInfo: {
                id: number
                shortName?: string
                website?: string
            }) =>
                PearApi.updatePromoAdBuilderInfo(clientInfo.id, {
                    shortName: clientInfo.shortName,
                    website: clientInfo.website,
                }),
            {
                onSettled: () => {
                    invalidatePromoAdBuilderClients()
                },
            },
        )

    // Selects all clients in current search/filters
    const selectAllVisible = () => {
        const allIds = rows.map((row) => row.original.id)
        setSelectionList(new Set(allIds))
    }
    const selectNone = () => {
        setSelectionList(new Set())
    }

    return (
        <div className="flex min-h-0 flex-1 flex-col">
            <div
                className="mb-3 flex flex-none items-end gap-4"
                style={{ width: 'calc(100% - 0.9em)' }}
            >
                <h2
                    className={classNames(
                        columnSpacing.name,
                        'text-3xl font-medium text-gray-700',
                    )}
                >
                    <span className="sr-only">Step</span>
                    <span className="mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-700 text-white">
                        2
                    </span>
                    Select Clients
                </h2>
                <div className={classNames(columnSpacing.shortName, 'pr-12')}>
                    <StatelessSelect
                        className="mt-1"
                        label="Make"
                        value={makeOptions.find(
                            ({ id }) => id === selectedMake,
                        )}
                        options={makeOptions}
                        onChange={setMake}
                    />
                </div>
                <div className={classNames(columnSpacing.website)}>
                    <StatelessTextBox
                        classNameInput="mt-1"
                        label="Search"
                        text={search}
                        onChange={setSearch}
                    />
                </div>
                <div
                    className={classNames(
                        columnSpacing.makes,
                        'flex items-center justify-end',
                    )}
                >
                    <span className="mr-1.5 pb-1 text-2xl font-bold text-gray-700">
                        {selectionListLength}
                    </span>
                    <span className="font-medium text-gray-600">selected</span>
                    {selectionListLength < rows.length ? (
                        <Button className="ml-4" onClick={selectAllVisible}>
                            All
                        </Button>
                    ) : (
                        <Button className="ml-4" onClick={selectNone}>
                            None
                        </Button>
                    )}
                </div>
            </div>
            <div className="min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-b-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50 pl-2"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className="flex gap-4"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const spacing =
                                                        columnSpacing[id]
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <button
                                                            className={classNames(
                                                                'track group py-2 text-left text-xs font-medium uppercase text-gray-700 focus:font-semibold focus:text-cyan-900 focus:outline-none',
                                                                spacing,
                                                                column.disableSortBy &&
                                                                    'cursor-default',
                                                            )}
                                                            {...column.getHeaderProps(
                                                                column.getSortByToggleProps(),
                                                            )}
                                                            title={undefined}
                                                            disabled={
                                                                column.disableSortBy
                                                            }
                                                        >
                                                            <span className="">
                                                                {header}
                                                            </span>
                                                            {sortBy[0].id ===
                                                            column.id ? (
                                                                column.isSortedDesc ? (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            descending
                                                                        </span>
                                                                        <ArrowDownIcon
                                                                            className="ml-2 inline-block h-4 w-4 shrink-0 align-bottom text-gray-600 group-focus:text-gray-700 "
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="sr-only">
                                                                            sorted
                                                                            ascending
                                                                        </span>
                                                                        <ArrowUpIcon
                                                                            className="ml-2 inline-block h-4 w-4 align-bottom text-gray-600 group-focus:text-gray-700"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </>
                                                                )
                                                            ) : !column.disableSortBy ? (
                                                                <ArrowDownIcon
                                                                    className="ml-2 inline-block h-4 w-4 align-bottom text-gray-300 group-focus:text-gray-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : null}
                                                        </button>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="min-h-0 flex-1 bg-white">
                                    {rows.length > 0 ? (
                                        <div
                                            className="relative h-full"
                                            {...getTableBodyProps()}
                                        >
                                            <ul className="h-full w-full overflow-y-scroll py-1.5">
                                                {rows.map((row) => (
                                                    <Row
                                                        key={row.id}
                                                        {...{
                                                            row,
                                                            edits,
                                                            setEdits,
                                                            selectionList,
                                                            toggleClientSelection,
                                                            prepareRow,
                                                            updatePromoInfo,
                                                            updatesInProgress,
                                                        }}
                                                    />
                                                ))}
                                            </ul>
                                        </div>
                                    ) : status === 'loading' ||
                                      status === 'idle' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    No clients were found with
                                                    the current make selection
                                                    and search terms.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Step2
