import { useAutomaticPricingClients } from '../hooks/apiHooks'
import { FeedTemplate } from '../components/Feeds/Clients'

const AutomaticPricingClients = () => {
    const { data, status } = useAutomaticPricingClients()

    return FeedTemplate('Automatic Pricing Clients', data, status)
}

export default AutomaticPricingClients
