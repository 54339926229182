import { getYear, isSameDay, isWithinInterval, parseISO } from 'date-fns'
import { getTimezoneOffset, format, utcToZonedTime } from 'date-fns-tz'

export const timeOffsetFromUser = (timeZone: string): string => {
    const now = new Date()

    // This function returns the offset in minutes, but with the opposite sign
    // from what we'd expect so we multiply by -1.
    const userOffsetInMinutes = now.getTimezoneOffset() * -1

    // This function return the offset in ms so we're converting it to minutes.
    const otherOffsetInMinutes = getTimezoneOffset(timeZone) / 60000

    const offsetFromUserInMinutes = otherOffsetInMinutes - userOffsetInMinutes
    const offsetFromUserInHours = offsetFromUserInMinutes / 60

    return offsetFromUserInHours >= 0
        ? '+' + offsetFromUserInHours
        : offsetFromUserInHours.toString()
}

// This stuff is for memoizing the calculation of the time zone offset.
const timeZoneOffsetCache = {}
export const timeZoneOffset = (timeZone) => {
    if (timeZone in timeZoneOffsetCache) {
        return timeZoneOffsetCache[timeZone]
    } else {
        const newValue = getTimezoneOffset(timeZone)
        timeZoneOffsetCache[timeZone] = newValue
        return newValue
    }
}

export const formatInTimeZone = (date, fmt, tz) =>
    format(utcToZonedTime(date, tz), fmt, { timeZone: tz })

// A list of months in dropdown object format (id = month number, label is month name)
export const months = [
    { id: '01', label: 'January', abbreviation: 'Jan' },
    { id: '02', label: 'February', abbreviation: 'Feb' },
    { id: '03', label: 'March', abbreviation: 'Mar' },
    { id: '04', label: 'April', abbreviation: 'Apr' },
    { id: '05', label: 'May', abbreviation: 'May' },
    { id: '06', label: 'June', abbreviation: 'Jun' },
    { id: '07', label: 'July', abbreviation: 'Jul' },
    { id: '08', label: 'August', abbreviation: 'Aug' },
    { id: '09', label: 'September', abbreviation: 'Sep' },
    { id: '10', label: 'October', abbreviation: 'Oct' },
    { id: '11', label: 'November', abbreviation: 'Nov' },
    { id: '12', label: 'December', abbreviation: 'Dec' },
]

/**
 * Creates an array of year objects from a given first year to the current year
 * for use in dropdowns.
 *
 * @param firstYear 4 digit year, defaults to 2022
 * @returns An array of objects with id and label properties (both a 4 digit year as a string)
 */
export const yearsSince = (firstYear: number = 2022) => {
    const currentYear = getYear(new Date())
    const yearSpan = currentYear - firstYear
    const years = new Array(yearSpan + 1).fill('temp').map((_v, index) => {
        const thisYear = currentYear - index
        return {
            id: thisYear.toString(10),
            label: thisYear.toString(10),
        }
    })
    return years
}

interface ObjectWithDate {
    /** ISO 8601 date string */
    date: string
    /** other stuff */
    [key: string]: any
}

/**
 *
 * @param start First date in range (inclusive)
 * @param end Last date in range (inclusive)
 * @returns Function that takes an ObjectWithDate and returns a boolean.
 */
export const isWithin = (start: Date, end: Date) => (obj: ObjectWithDate) => {
    const date = parseISO(obj.date)
    return isWithinInterval(date, { start, end })
}

/** Determine if an ObjectWithDate occurs on or before a day.
 *
 * @param day ISO 8601 date string with at least day precision.
 * @return Function that take an ObjectWithDate and returns a boolean.
 */
export const isOnOrBefore = (day: string) => (obj: ObjectWithDate) => {
    const now = parseISO(day)
    const then = parseISO(obj.date)
    return isSameDay(now, then) || now > then
}
