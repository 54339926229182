import { classNames } from '../../utility/Components'

// Badges based on TailwindUI.

/**
 * Base badge styling (no text size, padding, or colors).
 */
export const BlankBadge = ({ children, className, ...otherProps }) => (
    <span
        className={classNames(
            'inline-flex items-center rounded-full font-medium',
            className,
        )}
        {...otherProps}
    >
        {children}
    </span>
)

/**
 * Small badge with no colors. Set the text and background colors via className.
 * @param props Pass children and className property with text and background color classes. Other props are forwarded.
 * @returns
 */
export const SmallBadge = ({ children, className, ...otherProps }) => (
    <BlankBadge
        className={classNames('px-2.5 py-0.5 text-xs', className)}
        {...otherProps}
    >
        {children}
    </BlankBadge>
)

/**
 * Large badge with no colors. Set the text and background colors via className.
 * @param props Pass children and className property with text and background color classes. Other props are forwarded.
 * @returns
 */
export const LargeBadge = ({ children, className, ...otherProps }) => (
    <BlankBadge
        className={classNames('px-3 py-1 text-sm', className)}
        {...otherProps}
    >
        {children}
    </BlankBadge>
)
