import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis as Y,
    Tooltip,
    ResponsiveContainer,
    ReferenceLine,
    ReferenceDot,
} from 'recharts'
import { getDate, getDaysInMonth } from 'date-fns'
import { percentageFormat } from '../../utility/Formatting'
import {
    budgetInterval,
    dateToUse,
    DayPacing,
    latestWeekdayInInterval,
} from '../../utility/BudgetCalculations'
import theme from '../../theme'
import { range } from '../../utility/Functions'
import { LineItem } from '../../apis/pearApi'
import { NO_DAY_SELECTED } from './SpendsAndPacing'

export const PacingByDateChart = ({
    pacingDetails,
    month,
    lineItem,
    selectedDay,
    setSelectedDay,
}: {
    pacingDetails: DayPacing[]
    month: string
    lineItem: LineItem
    selectedDay: number | undefined
    setSelectedDay: (day: number | undefined) => void
}) => {
    const { adsOnWeekends, startDate, endDate } = lineItem
    const [firstDate, lastDate] = budgetInterval(month, startDate, endDate)
    const dayToUse = dateToUse(adsOnWeekends, firstDate, lastDate)
    const day = adsOnWeekends
        ? dayToUse
        : latestWeekdayInInterval(firstDate, lastDate, dayToUse)

    // Determine if we need to fill future days or not.
    const daysInMonth = getDaysInMonth(day)
    const datesToChart = range(daysInMonth, 1)

    const pacings = datesToChart.map((dayNumber) => {
        const thisDaysPacing = pacingDetails.find(
            (pacing) => getDate(pacing.date) === dayNumber,
        )
        if (thisDaysPacing) {
            const { date, pacingPercent } = thisDaysPacing
            return {
                x: getDate(date),
                y: pacingPercent,
                y0: 0,
            }
        }
        return {
            x: dayNumber,
            y: null,
            y0: 0,
        }
    })
    const limits = { minMax: 30, ticks: [-25, -15, -5, 5, 15, 25] }

    const AxisLabelWithoutTick = ({ x, y, payload: { value } }) => (
        <g transform={'translate( ' + x + ',' + y + ' )'}>
            <text
                x={0}
                y={0}
                dy={5}
                dx={-8}
                className="fill-current font-sans text-gray-600"
                fontSize="11px"
                textAnchor="end"
            >
                {percentageFormat(value, true, 0)}
            </text>
        </g>
    )

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={pacings}
                margin={{ top: 0, right: 8, bottom: 0, left: 8 }}
                onMouseLeave={() => {
                    setSelectedDay(NO_DAY_SELECTED)
                }}
            >
                <XAxis
                    dataKey="x"
                    type="category"
                    allowDecimals={false}
                    domain={[1, daysInMonth]}
                    hide={true}
                />
                <Y
                    width={40}
                    type="number"
                    allowDecimals={false}
                    allowDataOverflow={true}
                    domain={[-limits.minMax, limits.minMax]}
                    interval={0}
                    ticks={limits.ticks}
                    tick={AxisLabelWithoutTick}
                    axisLine={false}
                    tickLine={false}
                />
                <Tooltip
                    content={({ label: day, active }) => {
                        if (active) {
                            setSelectedDay(day)
                        }
                        return null
                    }}
                />
                <Area
                    isAnimationActive={false}
                    type="monotoneX"
                    dataKey="y"
                    fill={theme.colors.cyan[100]}
                    dot={false}
                />
                <ReferenceLine y={0} stroke={theme.colors.gray[500]} />
                {limits.ticks.map((tickY) => (
                    <ReferenceLine
                        key={`ref-line-${tickY}`}
                        y={tickY}
                        stroke={theme.colors.white}
                    />
                ))}
                {selectedDay !== NO_DAY_SELECTED && (
                    <ReferenceLine
                        x={selectedDay}
                        stroke={theme.colors.gray[500]}
                        strokeWidth={1}
                    />
                )}
                <Line
                    isAnimationActive={false}
                    type="monotoneX"
                    dataKey="y"
                    stroke={theme.colors.cyan[600]}
                    strokeWidth={2}
                    dot={false}
                    activeDot={{
                        stroke: theme.colors.transparent,
                        strokeWidth: 0,
                        r: 0,
                    }}
                />
                {selectedDay !== NO_DAY_SELECTED && (
                    <ReferenceDot
                        x={selectedDay}
                        y={pacings.find((pacing) => pacing.x === selectedDay).y}
                        fill={theme.colors.cyan[600]}
                        stroke={theme.colors.cyan[600]}
                        r={3}
                        strokeWidth={2}
                    />
                )}
            </ComposedChart>
        </ResponsiveContainer>
    )
}
