import { classNames } from '../utility/Components'
import { includesOneOf } from '../utility/Strings'

const nonRelativePositions = ['absolute', 'fixed', 'static', 'sticky']

export const TintedImage = ({
    className = '',
    src,
    alt = '',
    strength = 'medium',
}) =>
    strength === 'light' ? (
        <div
            className={classNames(
                className,
                'flex items-center justify-center overflow-hidden',
                includesOneOf(nonRelativePositions, className)
                    ? ''
                    : 'relative',
            )}
        >
            <div className="absolute top-0 left-0 z-10 h-full w-full bg-cyan-500 opacity-10">
                {' '}
            </div>
            <img
                className="min-h-full min-w-full object-cover brightness-105 filter"
                src={src}
                alt={alt}
            />
        </div>
    ) : (
        <div
            className={classNames(
                className,
                'relative flex items-center justify-center overflow-hidden',
            )}
        >
            <div className="absolute top-0 left-0 z-10 h-full w-full bg-cyan-500 opacity-10">
                {' '}
            </div>
            <img
                className="min-h-full min-w-full object-cover brightness-115 contrast-75 grayscale-15 filter"
                src={src}
                alt={alt}
            />
        </div>
    )
