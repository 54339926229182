export const trimObjectValuesWhitespace = (obj) => {
    const newObject = { ...obj }
    const keys = Object.keys(newObject)
    for (const key of keys) {
        if (typeof newObject[key] === 'string') {
            newObject[key] = newObject[key].trim()
        } else if (typeof newObject[key] === 'object') {
            newObject[key] = trimObjectValuesWhitespace(newObject[key])
        }
    }
    return newObject
}
