import PearApi from '../apis/pearApi.ts'

const TOKEN_EXPIRATION_BUFFER = 5 * 60 // 5 minutes in seconds (to avoid expiration)
const DEFAULT_EXPIRATION_TIME = 3600 // Default expiration time in seconds (1 hour)

export const refreshTokenSetup = (email) => {
    let refreshTimer

    const calculateRefreshTiming = (expiresIn) => {
        return (
            ((expiresIn || DEFAULT_EXPIRATION_TIME) - TOKEN_EXPIRATION_BUFFER) *
            1000
        )
    }

    const refreshToken = async () => {
        try {
            const response = await PearApi.refreshToken(email)
            const { accessToken, expiresIn } = response

            if (accessToken) {
                sessionStorage.setItem('googleTokenId', accessToken)
                const refreshTiming = calculateRefreshTiming(expiresIn)

                clearTimeout(refreshTimer)
                refreshTimer = setTimeout(refreshToken, refreshTiming)
            } else {
                console.error('No access token received')
            }
        } catch (error) {
            console.error('Error during token refresh:', error)
        }
    }

    const initialRefreshTiming = calculateRefreshTiming()
    refreshTimer = setTimeout(refreshToken, initialRefreshTiming)
}
