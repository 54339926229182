import { SpendPortion } from '../../apis/pearApi'
import StatusDisplay, { Status } from '../StatusDisplay'
import { dollarFormat } from '../../utility/Formatting'
import { classNames } from '../../utility/Components'
import { Well } from '../Layout'

const stopEventBubbling = (event) => event.stopPropagation()

const SpendsByCampaign = ({
    data,
    status,
}: {
    data: SpendPortion[]
    status: Status
}) => {
    return (
        <StatusDisplay status={status}>
            <Well
                tabIndex={0}
                onKeyDown={stopEventBubbling}
                className="rounded-t-none"
            >
                {data.map(({ type, campaigns }) => (
                    <div key={type} className="pb-4">
                        <div className="pb-1 text-sm font-medium text-gray-700">
                            {type}
                        </div>
                        <div className="rounded-lg bg-white pt-2 shadow">
                            {campaigns.map(({ name, spend }, index) => (
                                <div
                                    key={`${name}-index`}
                                    className={classNames(
                                        'flex py-0.5 px-3',
                                        index % 2 === 1
                                            ? 'bg-gray-50 text-gray-800'
                                            : 'text-gray-700',
                                        index === campaigns.length - 1
                                            ? 'rounded-b-lg pb-2'
                                            : '',
                                    )}
                                >
                                    <div className="flex-auto truncate pr-3 text-sm">
                                        {name}
                                    </div>
                                    <div className="flex-none text-sm">
                                        {dollarFormat(spend)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Well>
        </StatusDisplay>
    )
}

export default SpendsByCampaign
