import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PearApi from '../apis/pearApi'
import { useLoginInfo } from '../hooks/LoginInfo'

const Logout = () => {
    const { errorOut } = useLoginInfo()
    const navigate = useNavigate()

    useEffect(() => {
        const doLogout = async () => {
            try {
                await PearApi.logout()
            } catch {
                // eslint-disable-next-line no-console
                console.warn('Logout failed.')
            }
            errorOut('Your session has expired. Please sign in again.')
            navigate('/')
        }
        doLogout()
    }, [errorOut, navigate])

    return null
}

export default Logout
