import { Sources } from './pearApi'

export const SALESFORCE_URL_TEMPLATE =
    'https://adpearance.lightning.force.com/lightning/r/Account/:salesforceId/view'

export const PlatformLinks = {
    Foureyes: 'https://vue.foureyes.io/accounts/:accountId',
    [Sources.FACEBOOK]:
        'https://business.facebook.com/adsmanager/manage/campaigns?act=:accountId',
    [Sources.GOOGLE]: 'https://ads.google.com/aw/overview',
    [Sources.LINKEDIN]:
        'https://www.linkedin.com/campaignmanager/accounts/:accountId/campaign-groups',
    [Sources.MICROSOFT]:
        'https://ui.ads.microsoft.com/campaign/vnext/overview?aid=:accountId&cid=414173&uid=746132',
    [Sources.STACK_ADAPT]:
        'https://www.stackadapt.com/overview/campaigns?advertiser=:accountId',
    [Sources.TIKTOK]:
        'https://ads.tiktok.com/i18n/perf/creative?aadvid=:accountId',
}
