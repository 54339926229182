import { Fragment, useState } from 'react'
import { Field } from 'react-final-form'
import { Listbox, Transition, Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Toggle } from '../elements/Toggle'
import { classNames } from '../../utility/Components'
import { User } from '../../apis/pearApi'
import PearApi from '../../apis/pearApi'
import { queryClient } from '../../App'
import {
    notEmpty,
    maxLength,
    notRequired,
    trimWhitespace,
} from '../../utility/Validators'
import Icon from '../Icon'

const thirtySecondsInMilliseconds = 30 * 1000
const isValidSourceId = async (sourceData) => {
    const { valid } = await queryClient.fetchQuery(
        ['Valid Source', sourceData.source, sourceData.accountId],
        () => PearApi.validateSourceId(sourceData),
        {
            staleTime: thirtySecondsInMilliseconds,
        },
    )
    return valid ? undefined : 'Not a valid account.'
}

/** This component is a way to subscribe to another input's value and
 *  render certain elements if the value matches a specific value. It's
 *  also possible to render alternate elements when the condition isn't met.
 */
export const Condition = ({
    when,
    is,
    children = null,
    childrenIfNot = null,
}) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : childrenIfNot)}
    </Field>
)

/** This component is for showing the value of fields that shouldn't be edited.
 *  (Usually, a temporary condition based on other form inputs.)
 */
export const DisabledField = ({
    name,
    label,
    className = '',
    showValue = true,
    // Need to show something to preserve height so dash is a good default
    emptyValue = '-',
    // Allow for an optional message when this field is empty.
    emptyMessage = null,
}) => {
    return (
        <Field
            className={className}
            label={label}
            name={name}
            component={({ input, className, label }) => (
                <div className={className}>
                    <label
                        htmlFor={input.name}
                        className="block text-sm font-medium text-gray-700"
                    >
                        {label}
                    </label>
                    <div className="relative mt-1 rounded-md shadow-sm">
                        <div
                            id={input.name}
                            className={
                                'pointer-events-none block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-700 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-500 sm:text-sm'
                            }
                        >
                            {/* If the value is empty, the height isn't correctly calculated.
                                Using a dash in that case prevents the issue. */}
                            {showValue
                                ? input.value === ''
                                    ? emptyValue
                                    : input.value
                                : emptyValue}
                        </div>
                    </div>
                    {/* This next element is mainly here to take up space
                        to match the other inputs, but can be used for a message. */}
                    <p className="h-5 w-full truncate text-xs text-orange-600">
                        {input.value === '' ? emptyMessage : null}
                    </p>
                </div>
            )}
        />
    )
}

const TextInput = ({ input, meta, disableOnElse = true, innerClass = '' }) => {
    const isError = !!meta.error && meta.touched

    return (
        <>
            <div className="relative mt-1 rounded-md shadow-sm">
                <input
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    type="text"
                    name={input.name}
                    id={input.name}
                    className={classNames(
                        innerClass,
                        isError
                            ? 'border-2 border-orange-600 focus:border-orange-600 focus:ring-orange-500'
                            : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500',
                        'block w-full rounded-md px-3 focus:outline-none focus:ring-2 sm:text-sm',
                    )}
                    disabled={input.value === 'Else' && disableOnElse}
                />
            </div>
            {isError && meta.error ? (
                <p className="h-5 w-full truncate text-xs text-orange-600">
                    {isError && meta.error}
                </p>
            ) : (
                ''
            )}
        </>
    )
}

export const TextDisplayField = ({ name, className = '', innerClass = '' }) => {
    return (
        <div className={className}>
            <Field
                name={name}
                component={({ input: { value } }) => (
                    <div className={innerClass}>{value}</div>
                )}
            />
        </div>
    )
}

export const TextField = ({ label, name, className = '', ...fieldProps }) => {
    return (
        <div className={className}>
            <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <Field
                name={name}
                component={TextInput}
                validate={notEmpty}
                {...fieldProps}
            />
        </div>
    )
}

const FileInput = ({ input, meta, name }) => {
    const isError = !!meta.error && meta.touched

    return (
        <div>
            <div className="mt-1 flex rounded-md shadow-sm">
                <input
                    value={input.value.pathname}
                    onChange={(event) =>
                        input.onChange(event.target.files?.[0])
                    }
                    onBlur={input.onBlur}
                    name={input.name}
                    id={input.name}
                    type="file"
                    className={classNames(
                        ' block w-full cursor-pointer appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 text-sm text-gray-900 focus:border-cyan-500 focus:outline-none focus:ring-cyan-500',
                        isError
                            ? 'border-2 border-orange-600 text-orange-600 focus:border-orange-600 focus:ring-orange-500'
                            : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500',
                    )}
                />
            </div>
            <p className="h-5 w-full truncate text-xs text-orange-600">
                {isError && meta.error}
            </p>
        </div>
    )
}

export const FileField = ({ label, name, className = '', ...fieldProps }) => {
    return (
        <div className={className}>
            <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700"
            >
                {label}
                <Field
                    name={name}
                    component={FileInput}
                    validate={notEmpty}
                    {...fieldProps}
                />
            </label>
        </div>
    )
}

const TextAreaInput = ({ input, meta, className }) => {
    const isError = !!meta.error

    return (
        <div className={classNames('flex h-full flex-col', className)}>
            <div className="relative mt-1 min-h-0 flex-auto rounded-md shadow-sm">
                <textarea
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    name={input.name}
                    id={input.name}
                    className={classNames(
                        isError
                            ? 'border-2 border-orange-600 focus:border-orange-600 focus:outline-none focus:ring-orange-600'
                            : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500',
                        'block h-full w-full resize-none rounded-md px-3 focus:ring-2 sm:text-sm',
                    )}
                />
            </div>
            <p className="h-5 w-full truncate text-xs text-orange-600">
                {meta.error}
            </p>
        </div>
    )
}

export const TextAreaField = ({ name, className = '' }) => {
    return (
        <Field
            name={name}
            component={TextAreaInput}
            className={className}
            validate={maxLength(65000)}
        />
    )
}

export const OptionalTextField = ({
    label,
    name,
    className = '',
    ...fieldProps
}) => {
    return (
        <div className={className}>
            <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700"
            >
                {label}
            </label>
            <Field
                name={name}
                component={TextInput}
                validate={notRequired(notEmpty)}
                {...fieldProps}
            />
        </div>
    )
}

export const UnlabeledTextField = ({ name, className = '' }) => {
    return (
        <div className={className}>
            <Field
                name={name}
                render={(props) => (
                    <TextInput {...props} disableOnElse={false} />
                )}
                validate={trimWhitespace(notEmpty)}
            />
        </div>
    )
}

const sourceFromFieldName = (fieldName) => {
    const sourceName = fieldName.slice(0, -4)
    const source = sourceName.charAt(0).toUpperCase() + sourceName.slice(1)
    return source
}

export const AccountIdField = ({
    label,
    name,
    className = '',
    ...fieldProps
}) => {
    return (
        <div className={className}>
            <label
                htmlFor={name}
                className="block text-sm font-medium text-gray-700"
            >
                <span className="mr-1.5">{label}</span>{' '}
                <Icon type={label} className="text-gray-600" />
            </label>
            <Field
                name={name}
                component={TextInput}
                validate={(value, _values, meta) =>
                    !meta.modified
                        ? undefined
                        : notRequired((value) =>
                              isValidSourceId({
                                  source: sourceFromFieldName(name),
                                  accountId: value ?? '',
                              }),
                          )(value)
                }
                {...fieldProps}
            />
        </div>
    )
}

export const ToggleField = ({ label, name, className = '', ...fieldProps }) => {
    return (
        <div className={className}>
            <Field
                name={name}
                type="checkbox"
                render={({ input }) => (
                    <Toggle
                        className="pb-1"
                        label={label}
                        enabled={input.checked}
                        setEnabled={input.onChange}
                    />
                )}
                {...fieldProps}
            />
        </div>
    )
}

/** Type for select option arrays. Id is required because React needs a unique
 *  identifier. It can be the same as name if that's guaranteed to be unique.
 */
export interface Option {
    id: string | number | null | undefined
    name: string
}

export const SelectField = ({
    label,
    name,
    className = '',
    options = [],
    useIdAsValue = false,
}: {
    label: string
    name: string
    className?: string
    options?: Option[]
    useIdAsValue?: boolean
}) => {
    return (
        <div className={className}>
            <Field
                name={name}
                validate={notEmpty}
                render={({ input, meta }) => {
                    const isError = !!meta.error && meta.touched

                    return (
                        <>
                            <div onBlur={input.onBlur}>
                                <div id={input.name}>
                                    <Listbox
                                        value={input.value}
                                        onChange={input.onChange}
                                    >
                                        {({ open }) => (
                                            <>
                                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                                    {label}
                                                </Listbox.Label>
                                                <div className="relative mt-1">
                                                    <Listbox.Button
                                                        className={`relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus:ring-2 ${
                                                            isError
                                                                ? 'border-2 border-orange-600 focus:border-orange-600 focus:outline-none focus:ring-orange-600'
                                                                : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500'
                                                        } sm:text-sm`}
                                                    >
                                                        {input.value !== '' &&
                                                        options.find(
                                                            (option) =>
                                                                option.id ===
                                                                input.value,
                                                        ) !== undefined ? (
                                                            <span className="block truncate">
                                                                {useIdAsValue
                                                                    ? options.find(
                                                                          (
                                                                              option,
                                                                          ) =>
                                                                              option.id ===
                                                                              input.value,
                                                                      )?.name
                                                                    : input.value}
                                                            </span>
                                                        ) : (
                                                            <span className="text-gray-700">
                                                                Choose...
                                                            </span>
                                                        )}
                                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                                            <ChevronUpDownIcon
                                                                className="h-5 w-5 text-gray-500"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    </Listbox.Button>

                                                    <Transition
                                                        show={open}
                                                        as={Fragment}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <Listbox.Options
                                                            static
                                                            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                                                        >
                                                            {options.map(
                                                                (option) => (
                                                                    <Listbox.Option
                                                                        key={
                                                                            option.id
                                                                        }
                                                                        className={({
                                                                            active,
                                                                        }) =>
                                                                            classNames(
                                                                                active
                                                                                    ? 'bg-cyan-600 text-white'
                                                                                    : 'text-gray-900',
                                                                                'relative cursor-default select-none py-2 px-3',
                                                                            )
                                                                        }
                                                                        value={
                                                                            useIdAsValue
                                                                                ? option.id
                                                                                : option.name
                                                                        }
                                                                    >
                                                                        {({
                                                                            selected,
                                                                            active,
                                                                        }) => (
                                                                            <>
                                                                                <span
                                                                                    className={classNames(
                                                                                        selected
                                                                                            ? 'pr-6 font-semibold'
                                                                                            : 'font-normal',
                                                                                        'block truncate',
                                                                                    )}
                                                                                >
                                                                                    {
                                                                                        option.name
                                                                                    }
                                                                                </span>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active
                                                                                                ? 'text-white'
                                                                                                : 'text-cyan-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon
                                                                                            className="h-5 w-5"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ),
                                                            )}
                                                        </Listbox.Options>
                                                    </Transition>
                                                </div>
                                            </>
                                        )}
                                    </Listbox>
                                </div>
                            </div>
                            <p className="h-5 w-full truncate text-xs text-orange-600">
                                {isError && meta.error}
                            </p>
                        </>
                    )
                }}
            />
        </div>
    )
}

const noSelectionOption = { id: undefined, name: 'No Selection' }

/** Expects an array of objects for options with id and name properties. */
export const SelectFieldWithSearch = ({
    label,
    name,
    className = '',
    options = [],
    required = false,
    alwaysOption,
    ...fieldProps
}: {
    label: string
    name: string
    className?: string
    options?: Option[]
    required?: boolean
    alwaysOption?: Option
    [name: string]: any
}) => {
    const [search, setSearch] = useState('')

    const filteredOptions =
        search === ''
            ? options
            : options.filter((option) =>
                  option.name.toLowerCase().includes(search),
              )
    return (
        <div className={className}>
            <Field
                name={name}
                render={({ input, meta }) => {
                    const isError = !!meta.error && meta.touched

                    // noSelectionOption and alwaysOption choices are outside the
                    // filtered list so they always display first, even if the
                    // filter wouldn't normally include them. This necessitates
                    // checking for them separately.
                    const possibleSeparateOption =
                        !required &&
                        [noSelectionOption.id, null, ''].includes(input.value)
                            ? noSelectionOption
                            : alwaysOption && input.value === alwaysOption.id
                            ? alwaysOption
                            : false
                    const possibleSelectedOption = possibleSeparateOption
                        ? possibleSeparateOption
                        : options.find((option) => {
                              return option.id === input.value
                          })
                    const selectedOption = possibleSelectedOption
                        ? possibleSelectedOption
                        : !required
                        ? noSelectionOption
                        : alwaysOption
                        ? alwaysOption
                        : options[0]

                    return (
                        <>
                            <div onBlur={input.onBlur}>
                                <div id={input.name}>
                                    <Combobox
                                        value={selectedOption}
                                        onChange={(option) =>
                                            input.onChange(option.id)
                                        }
                                    >
                                        <Combobox.Label className="block text-sm font-medium text-gray-700">
                                            {label}
                                        </Combobox.Label>
                                        <div className="relative mt-1">
                                            <Combobox.Input
                                                className={classNames(
                                                    'w-full rounded-md bg-white py-2 pl-3 pr-10 shadow-sm focus:outline-none focus:ring-2 sm:text-sm',
                                                    isError
                                                        ? 'border-2 border-orange-600 focus:border-orange-600 focus:ring-orange-500'
                                                        : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500',
                                                )}
                                                displayValue={(
                                                    option: Option,
                                                ) => {
                                                    return option.name
                                                }}
                                                onChange={(event) => {
                                                    setSearch(
                                                        event.target.value.toLowerCase(),
                                                    )
                                                }}
                                                onFocus={(event) => {
                                                    // Cause input text to be selected when clicked to
                                                    // allow for easy removal of existing text like
                                                    // the keyboard focus works by default. */}
                                                    requestAnimationFrame(
                                                        () => {
                                                            event.target.setSelectionRange(
                                                                0,
                                                                event.target
                                                                    .value
                                                                    .length,
                                                            )
                                                        },
                                                    )
                                                }}
                                            />
                                            <Combobox.Button
                                                className={`absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none`}
                                            >
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </Combobox.Button>

                                            <Transition
                                                as={Fragment}
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {!required && (
                                                        <Combobox.Option
                                                            key="0-no-selection"
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-cyan-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 px-3',
                                                                )
                                                            }
                                                            value={
                                                                noSelectionOption
                                                            }
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'pr-6 font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate',
                                                                        )}
                                                                    >
                                                                        {
                                                                            noSelectionOption.name
                                                                        }
                                                                    </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-cyan-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    )}
                                                    {alwaysOption && (
                                                        <Combobox.Option
                                                            key={
                                                                alwaysOption.id
                                                            }
                                                            className={({
                                                                active,
                                                            }) =>
                                                                classNames(
                                                                    active
                                                                        ? 'bg-cyan-600 text-white'
                                                                        : 'text-gray-900',
                                                                    'relative cursor-default select-none py-2 px-3',
                                                                )
                                                            }
                                                            value={alwaysOption}
                                                        >
                                                            {({
                                                                selected,
                                                                active,
                                                            }) => (
                                                                <>
                                                                    <span
                                                                        className={classNames(
                                                                            selected
                                                                                ? 'pr-6 font-semibold'
                                                                                : 'font-normal',
                                                                            'block truncate',
                                                                        )}
                                                                    >
                                                                        {
                                                                            alwaysOption.name
                                                                        }
                                                                    </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'text-white'
                                                                                    : 'text-cyan-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                            )}
                                                                        >
                                                                            <CheckIcon
                                                                                className="h-5 w-5"
                                                                                aria-hidden="true"
                                                                            />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Combobox.Option>
                                                    )}
                                                    {filteredOptions.map(
                                                        (option) => (
                                                            <Combobox.Option
                                                                key={option.id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-cyan-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 px-3',
                                                                    )
                                                                }
                                                                value={option}
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <span
                                                                            className={classNames(
                                                                                selected
                                                                                    ? 'pr-6 font-semibold'
                                                                                    : 'font-normal',
                                                                                'block truncate',
                                                                            )}
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </span>

                                                                        {selected ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-cyan-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ),
                                                    )}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            </div>
                            <p className="h-5 w-full truncate text-xs text-orange-600">
                                {isError && meta.error}
                            </p>
                        </>
                    )
                }}
                {...fieldProps}
            />
        </div>
    )
}

export const PersonSelectWithSearchField = ({
    label,
    name,
    className = '',
    people = [],
    ...fieldProps
}: {
    label: string
    name: string
    className?: string
    people?: User[]
    [name: string]: any
}) => {
    const [search, setSearch] = useState('')
    const filteredPeople = people.filter((person) =>
        person.name.toLowerCase().includes(search),
    )

    return (
        <div className={className}>
            <Field
                name={name}
                validate={notEmpty}
                render={({ input, meta }) => {
                    const isError = !!meta.error && meta.touched
                    const selected = people.find(
                        (option) => option.id === input.value,
                    )

                    return (
                        <>
                            <div onBlur={input.onBlur}>
                                <div id={input.name}>
                                    <Combobox
                                        as="div"
                                        className={className}
                                        value={selected}
                                        onChange={input.onChange}
                                    >
                                        <Combobox.Label className="block text-sm font-medium text-gray-700">
                                            {label}
                                        </Combobox.Label>
                                        <div className="relative mt-1">
                                            <div className="absolute inset-y-0 left-0 flex w-12 items-center justify-center">
                                                {!selected?.avatar ? (
                                                    <div
                                                        className="h-6 w-6 shrink-0 rounded-full bg-gray-300"
                                                        aria-hidden="true"
                                                    >
                                                        {' '}
                                                    </div>
                                                ) : (
                                                    <img
                                                        src={selected.avatar}
                                                        alt=""
                                                        className="h-6 w-6 shrink-0 rounded-full brightness-105 grayscale"
                                                    />
                                                )}
                                            </div>
                                            <Combobox.Input
                                                className={classNames(
                                                    'w-full rounded-md bg-white py-2 pl-12 pr-10 shadow-sm focus:outline-none focus:ring-2 sm:text-sm',
                                                    isError
                                                        ? 'border-2 border-orange-600 focus:border-orange-600 focus:ring-orange-600'
                                                        : 'border border-gray-300 focus:border-cyan-500 focus:ring-cyan-500',
                                                )}
                                                displayValue={(
                                                    option?: Option,
                                                ) => {
                                                    return option !== undefined
                                                        ? option.name
                                                        : 'Select someone'
                                                }}
                                                onChange={(event) => {
                                                    setSearch(
                                                        event.target.value.toLowerCase(),
                                                    )
                                                }}
                                                onFocus={(event) => {
                                                    // Cause input text to be selected when clicked to
                                                    // allow for easy removal of existing text like
                                                    // the keyboard focus works by default. */}
                                                    requestAnimationFrame(
                                                        () => {
                                                            event.target.setSelectionRange(
                                                                0,
                                                                event.target
                                                                    .value
                                                                    .length,
                                                            )
                                                        },
                                                    )
                                                }}
                                            />
                                            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-500"
                                                    aria-hidden="true"
                                                />
                                            </Combobox.Button>

                                            <Transition
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {filteredPeople.map(
                                                        (person) => (
                                                            <Combobox.Option
                                                                key={person.id}
                                                                className={({
                                                                    active,
                                                                }) =>
                                                                    classNames(
                                                                        active
                                                                            ? 'bg-cyan-600 text-white'
                                                                            : 'text-gray-900',
                                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                                    )
                                                                }
                                                                value={
                                                                    person.id
                                                                }
                                                            >
                                                                {({
                                                                    selected,
                                                                    active,
                                                                }) => (
                                                                    <>
                                                                        <div className="flex items-center">
                                                                            {!person?.avatar ? (
                                                                                <div
                                                                                    className="h-6 w-6 shrink-0 rounded-full bg-gray-300"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    {' '}
                                                                                </div>
                                                                            ) : (
                                                                                <img
                                                                                    src={
                                                                                        person.avatar
                                                                                    }
                                                                                    alt=""
                                                                                    className="h-6 w-6 shrink-0 rounded-full brightness-105 grayscale"
                                                                                />
                                                                            )}
                                                                            <span
                                                                                className={classNames(
                                                                                    selected
                                                                                        ? 'font-semibold'
                                                                                        : 'font-normal',
                                                                                    'ml-3 block truncate',
                                                                                )}
                                                                            >
                                                                                {
                                                                                    person.name
                                                                                }
                                                                            </span>
                                                                        </div>

                                                                        {selected ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active
                                                                                        ? 'text-white'
                                                                                        : 'text-cyan-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                                )}
                                                                            >
                                                                                <CheckIcon
                                                                                    className="h-5 w-5"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Combobox.Option>
                                                        ),
                                                    )}
                                                </Combobox.Options>
                                            </Transition>
                                        </div>
                                    </Combobox>
                                </div>
                            </div>
                            <p className="h-5 w-full truncate text-xs text-orange-600">
                                {isError && meta.error}
                            </p>
                        </>
                    )
                }}
                {...fieldProps}
            />
        </div>
    )
}
