import { format, isValid, parseISO } from 'date-fns'
import { latestMonth } from '../utility/BudgetCalculations'
import { useUrlQueryParameter } from './useUrlQueryParameter'

const validatedDate = (urlMonth: string) => {
    const currentMonth = latestMonth()
    let parsedDate
    try {
        parsedDate = parseISO(urlMonth)
    } catch {
        return currentMonth
    }
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM') : currentMonth
}

export const useMonthQueryParameter = () => {
    const currentMonth = latestMonth()
    const [validatedMonth, setMonth] = useUrlQueryParameter(
        'month',
        currentMonth,
        validatedDate,
    )

    return [validatedMonth, setMonth] as [string, (value: string) => void]
}
