import { useState } from 'react'

/**
 * Custom React hook that returns a state value and a function to toggle the state value.
 * This hook can be useful for implementing toggle functionality for menus, dropdowns, modals...
 * @param initialState Initial state value for the toggle, 'false' by default.
 * @returns 'toggleValue': a state value that can be toggled between 'true' and 'false',
 *          and 'toggler': a function that goggles the 'toggleValue' state.
 */

export const useToggle = (initialState?) => {
    const [toggleValue, setToggleValue] = useState(initialState)

    const toggler = () => {
        setToggleValue(!toggleValue)
    }
    return [toggleValue, toggler]
}

// Original obtained from https://www.freecodecamp.org/news/toggle-elements-in-react-using-hooks/
