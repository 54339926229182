import { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { classNames } from '../utility/Components'

export const BackgroundSky = ({ children }) => {
    const [useHiddenBlueBg, setUseHiddenBlueBg] = useState(false)

    useEffect(() => {
        const settings = localStorage.getItem('pear-settings')
        if (settings !== null) {
            const settingsJson = JSON.parse(settings)
            if (settingsJson?.blueBg === true) {
                setUseHiddenBlueBg(true)
            }
        }
    }, [])

    const bgColor = useHiddenBlueBg
        ? 'bg-gradient-to-b from-cyan-300 via-cyan-200 to-cyan-100'
        : 'bg-gradient-to-b from-gray-100 via-gray-100 to-gray-50'

    return (
        <div className={classNames(bgColor, 'h-full w-full overflow-hidden')}>
            {children}
        </div>
    )
}

export const SectionCloud = ({ children, className = '', show = true }) => (
    <Transition
        className={classNames(
            'rounded-lg border-b border-gray-200 bg-white shadow',
            className,
        )}
        show={show}
        appear={true}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
    >
        {children}
    </Transition>
)

export const Well = ({ children, className = '', ...otherProps }) => (
    <div
        className={classNames(
            'h-full overflow-hidden rounded-lg border border-gray-200 bg-gray-200 shadow-inner focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1',
            className,
        )}
        {...otherProps}
    >
        <div className="h-full overflow-y-auto px-4 pt-2 pb-4">{children}</div>
    </div>
)
