/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../utility/Components'

export function GenericSelect({
    onChange,
    options = ['Nothing'],
    label = '',
    initialSelection = null,
    className = '',
}) {
    const [selected, setSelected] = useState(
        options.find((option) => option === initialSelection) ?? options[0],
    )

    const changeIt = (item) => {
        setSelected(item)
        onChange(item)
    }
    return (
        <Listbox value={selected} onChange={changeIt}>
            {({ open }) => (
                <>
                    <Listbox.Label className="block text-sm text-gray-700">
                        {label}
                    </Listbox.Label>
                    <div className={classNames(className, 'relative')}>
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm">
                            <span className="block truncate">{selected}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-500"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                static
                                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                                {options.map((label) => (
                                    <Listbox.Option
                                        key={label}
                                        className={({ active }) =>
                                            classNames(
                                                active
                                                    ? 'bg-cyan-600 text-white'
                                                    : 'text-gray-900',
                                                'relative cursor-default select-none py-2 px-3',
                                            )
                                        }
                                        value={label}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        selected
                                                            ? 'pr-6 font-semibold'
                                                            : 'font-normal',
                                                        'block truncate',
                                                    )}
                                                >
                                                    {label}
                                                </span>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active
                                                                ? 'text-white'
                                                                : 'text-cyan-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        )}
                                                    >
                                                        <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
