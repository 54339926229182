import { Fragment, useEffect, useState } from 'react'
import { Tab, Menu, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-query'
import { Form } from 'react-final-form'
import PearApi, { ExtendedLineItem, User } from '../../apis/pearApi'
import {
    useClient,
    useClientNote,
    useClientBudgetSpendWatchdogs,
    invalidateClientNote,
    useSpendBreakdown,
} from '../../hooks/apiHooks'
import { PlatformLinks } from '../../apis/ExternalSites'
import {
    budgetingDetails,
    budgetInterval,
    dateToUse,
    itemAndRepTargetData,
    pacingDetails,
    percentsInRanges,
} from '../../utility/BudgetCalculations'
import { percentageFormat, dollarFormat } from '../../utility/Formatting'
import { formatInTimeZone } from '../../utility/Time'
import { SpendBreakdownChart } from '../Charts/SpendBreakdown'
import { PacingBreakdownChart } from '../Charts/PacingBreakdown'
import Icon from '../Icon'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import SpendsByCampaign from './SpendsByCampaign'
import { SectionCloud } from '../Layout'
import { TextAreaField } from '../forms/Fields'
import StatusDisplay from '../StatusDisplay'
import { classNames } from '../../utility/Components'
import ProgressBar from '../ProgressBar'
import ErrorBoundary from '../ErrorBoundary'
import AppRoutes from '../../pages/routes'
import { pacingTextColor } from './DisplayFormatting'
import { Button } from '../elements/Buttons'
import PacingByDayTable from './SpendDetailsByDayTable'
import SpendAndPacingCharts from '../Charts/SpendsAndPacing'
import TruncatableLiteralText from '../elements/TruncatableLiteralText'
import PopupDialog from '../elements/PopupDialog'

export const WatchdogActions = ({ actions }) => {
    const NO_POPUP_ID = -1
    const [currentPopupId, setCurrentPopupId] = useState<number>(NO_POPUP_ID)

    const display = () => {
        return actions.length ? (
            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                <div
                    className="flex-none bg-gray-50"
                    style={{ width: 'calc(100%)' }}
                >
                    <div className="flex items-baseline gap-4 px-2  py-2">
                        <div className="min-w-0 flex-auto  text-xs font-medium uppercase tracking-wider text-gray-700">
                            Date
                        </div>
                        <div className="w-32  text-xs font-medium uppercase tracking-wider text-gray-700">
                            Action Taken
                        </div>
                        <div className="w-32  text-xs font-medium uppercase tracking-wider text-gray-700">
                            Campaigns
                        </div>
                    </div>
                </div>
                <div className="block flex-1 overflow-y-scroll bg-white">
                    <div>
                        {actions.map((action, index) => (
                            <div
                                className={classNames(
                                    'flex items-baseline gap-4 px-2',
                                    index === 0 ? 'mt-2' : '',
                                )}
                                key={`${index}`}
                            >
                                <div className="min-w-0 flex-auto">
                                    <div className="truncate text-base font-medium text-gray-700">
                                        {formatInTimeZone(
                                            action.actionDate,
                                            'yyyy-MM-dd hh:mm aa zz',
                                            Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone,
                                        )}
                                    </div>
                                </div>
                                <div className="w-32">
                                    <div className="text-sm font-semibold text-gray-700">
                                        {action.actionTaken}
                                    </div>
                                </div>
                                <div className="w-24">
                                    <div className="text-sm font-semibold text-gray-700">
                                        <div className="flex h-8 items-center">
                                            <PopupDialog
                                                popupId={index}
                                                currentPopupId={currentPopupId}
                                                setCurrentPopupId={
                                                    setCurrentPopupId
                                                }
                                                placement="left"
                                                render={({
                                                    close,
                                                    labelId,
                                                }) => (
                                                    <div className="overflow-hidden rounded-lg border border-gray-100 bg-white px-3 py-3 text-left align-bottom shadow-lg outline-none sm:my-8 sm:w-full sm:max-w-3xl sm:p-8 sm:align-middle">
                                                        <div className="h-full">
                                                            <div className="flex items-start justify-between">
                                                                <h2
                                                                    id={labelId}
                                                                    className="text-lg font-medium tracking-tight text-gray-900"
                                                                >
                                                                    Campaigns
                                                                </h2>
                                                            </div>
                                                        </div>
                                                        <div className="relative mt-2 max-h-64 overflow-y-scroll border-y-2 border-cyan-500 text-xs">
                                                            {action.campaigns
                                                                .length > 0 ? (
                                                                <ul className="h-full py-1 pr-2">
                                                                    {action.campaigns.map(
                                                                        ({
                                                                            name,
                                                                        }) => (
                                                                            <li
                                                                                key={
                                                                                    name
                                                                                }
                                                                                className="py-1 text-gray-700"
                                                                            >
                                                                                {
                                                                                    name
                                                                                }
                                                                            </li>
                                                                        ),
                                                                    )}
                                                                </ul>
                                                            ) : (
                                                                <div className="h-full py-4 px-8 text-center text-gray-700">
                                                                    No matched
                                                                    campaigns.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            >
                                                <button
                                                    type="button"
                                                    className="rounded p-1 text-cyan-600 focus:outline-none focus:ring focus:ring-cyan-500"
                                                >
                                                    Campaigns
                                                </button>
                                            </PopupDialog>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200 py-4 text-center">
                No Watchdog Actions to display
            </div>
        )
    }

    return display()
}
export const Notes = ({ clientId }) => {
    const { data: clientNote, status: clientNoteStatus } =
        useClientNote(clientId)
    const [saveMessage, setSaveMessage] = useState({
        type: 'idle',
        message: '',
    })

    const status = clientNoteStatus === 'error' ? 'success' : clientNoteStatus
    const note =
        clientNoteStatus === 'error' ? { clientId, note: '' } : clientNote

    const save = useMutation(
        (newNote: object) => PearApi.updateClientNote(clientId, newNote),
        {
            onSuccess: () => {
                setSaveMessage({ type: 'success', message: 'Notes saved.' })
                invalidateClientNote(clientId)
            },
            onError: () => {
                setSaveMessage({
                    type: 'error',
                    message: 'There was a problem saving notes.',
                })
            },
        },
    )
    const isSaving = save.isLoading
    const onSubmit = (newNote) => {
        save.mutate(newNote)
    }

    return (
        <StatusDisplay status={status}>
            <Form
                onSubmit={onSubmit}
                initialValues={note}
                render={({
                    form,
                    handleSubmit,
                    invalid,
                    pristine,
                    submitting,
                    values,
                }) => (
                    <form
                        className="flex h-full flex-col"
                        noValidate={true}
                        onSubmit={handleSubmit}
                    >
                        <div className="min-h-0 flex-auto">
                            <TextAreaField name="note" />
                        </div>
                        <div className="min-h-0 flex-initial">
                            <Button
                                disabled={
                                    submitting ||
                                    invalid ||
                                    pristine ||
                                    isSaving
                                }
                                type="submit"
                            >
                                Save
                            </Button>
                            {saveMessage.message && (
                                <span
                                    className={classNames(
                                        'ml-3 rounded-full px-3 py-1',
                                        saveMessage.type === 'success'
                                            ? 'bg-green-100 text-green-900'
                                            : 'bg-orange-200 text-orange-900',
                                    )}
                                >
                                    {saveMessage.message}
                                </span>
                            )}
                        </div>
                    </form>
                )}
            />
        </StatusDisplay>
    )
}

const PacingInfo = ({ pacingPercent, dayBeforePercent }) => {
    return (
        <div className="grid grid-cols-2 gap-4">
            <div className="">
                <div>
                    <p className="truncate text-sm text-gray-700">Pacing</p>
                </div>
                <div className="flex items-baseline">
                    <p
                        className={classNames(
                            'text-xl font-semibold',
                            pacingTextColor(pacingPercent),
                        )}
                    >
                        {percentageFormat(pacingPercent)}
                    </p>
                </div>
            </div>
            {pacingPercent < 1000 && (
                <div className="">
                    <div>
                        <p className="truncate text-sm text-gray-700">
                            Pacing -1d
                        </p>
                    </div>
                    <div className="flex items-baseline">
                        <p
                            className={classNames(
                                'text-xl font-semibold',
                                pacingTextColor(dayBeforePercent),
                            )}
                        >
                            {percentageFormat(dayBeforePercent)}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )
}

export const LineItemDetails = ({
    item,
    rep,
    show,
    yearMonth,
}: {
    item: ExtendedLineItem
    rep: User | undefined
    show: boolean
    yearMonth: string
}) => {
    const {
        clientName,
        budget,
        adsOnWeekends,
        clientId,
        code,
        startDate,
        endDate,
        source,
        clientSourceId,
        adAccountId,
        productName,
        promoName,
    } = item

    const { data: client } = useClient(clientId)
    const { pendingBudgetChanges = 0 } = client ?? {}

    const [firstDate, lastDate] = budgetInterval(yearMonth, startDate, endDate)
    const { pacingPercent, dayBeforePercent, suggestedGoal, total } =
        budgetingDetails(item, dateToUse(adsOnWeekends, firstDate, lastDate))

    const { data: spendBreakdownData = [], status: spendBreakdownStatus } =
        useSpendBreakdown(item, yearMonth)

    const [chartsAreVisible, setChartsAreVisible] = useState(false)

    const pacingByDay = pacingDetails(item, yearMonth)

    const { data: clientWatchdogs, status: watchdogsStatus } =
        useClientBudgetSpendWatchdogs(yearMonth, clientSourceId + '')

    const watchdogs = watchdogsStatus === 'error' ? [] : clientWatchdogs
    const watchdogActions = watchdogs
        ? watchdogs.flatMap((watchdog) => watchdog.actions)
        : []
    const hasWatchdogActions = watchdogActions.length > 0 ? true : false

    const budgetingDetailTabs = hasWatchdogActions
        ? ['By Date', 'By Campaign', 'Pacing', 'Notes', 'Watchdog']
        : ['By Date', 'By Campaign', 'Pacing', 'Notes']

    const actionLinks = [
        {
            name: 'Edit Budgets',
            to: AppRoutes.EDIT_BUDGETS.replace(
                ':clientId',
                clientId.toString(10),
            ),
        },
        {
            name: 'Edit Client',
            to: `${AppRoutes.CLIENTS}?id=${clientId}`,
        },
        {
            name: `Go to ${source}`,
            href:
                PlatformLinks?.[source]?.replace(':accountId', adAccountId) ??
                '',
        },
    ]

    return (
        <SectionCloud className="absolute inset-0" show={show}>
            <div className="flex h-full flex-col">
                <ErrorBoundary>
                    <div
                        className="
                        relative flex-initial rounded-t-lg bg-gray-50 px-6 py-4 text-left"
                    >
                        <div className="relative rounded-lg bg-white py-1 pl-4 pr-4 shadow">
                            <div className="flex items-baseline justify-between">
                                <div className="basis-4/5">
                                    <h2
                                        className={classNames(
                                            'inline truncate text-xl font-semibold text-gray-700',
                                        )}
                                        title={clientName}
                                    >
                                        {clientName}
                                    </h2>
                                    {code && (
                                        <span
                                            className="pl-1 text-left text-sm font-normal text-gray-700"
                                            title="Dealer Code"
                                        >
                                            {code}
                                        </span>
                                    )}
                                </div>
                                {adAccountId && (
                                    <div
                                        className="basis-1/5 text-right text-sm text-gray-700"
                                        title="Ad Account Id"
                                    >
                                        {adAccountId}
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-between">
                                <div className="flex min-w-0 flex-auto leading-tight">
                                    <span
                                        className={classNames(
                                            'inline-block h-4 flex-none text-gray-600',
                                        )}
                                    >
                                        <Icon type={item.source} />
                                    </span>
                                    <TruncatableLiteralText className="ml-2 flex-auto text-sm text-gray-700">
                                        {item.label}
                                    </TruncatableLiteralText>
                                    <span
                                        className={classNames(
                                            'ml-2 flex-none text-right text-sm text-gray-700',
                                        )}
                                    >
                                        {!adsOnWeekends && '(Weekdays Only)'}
                                    </span>
                                </div>
                                <div className="flex-none text-sm text-gray-700">
                                    {rep?.avatar && (
                                        <img
                                            className="mr-2 inline-block h-5 w-5 rounded-full brightness-105 grayscale"
                                            src={rep.avatar}
                                            alt=""
                                        />
                                    )}
                                    {rep?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex min-h-0 flex-auto flex-col gap-10 px-6 pt-4 pb-6">
                        <div className="flex flex-initial divide-x">
                            <div className="flex w-2/5 flex-col gap-4 pr-5">
                                <Transition.Child
                                    as="div"
                                    className="flex-initial"
                                    enter="transition-opacity delay-100 duration-500 ease-in-out"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-500 ease-in-out"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                    afterEnter={() => setChartsAreVisible(true)}
                                    beforeLeave={() =>
                                        setChartsAreVisible(false)
                                    }
                                >
                                    <PacingInfo
                                        {...{
                                            pacingPercent,
                                            dayBeforePercent,
                                        }}
                                    />
                                    {/* <div className="-mt-1 text-xl font-semibold text-gray-700">
                                        {dollarFormat(pacingAmount)}
                                    </div> */}
                                </Transition.Child>
                                <Transition.Child
                                    as="div"
                                    className="flex-initial"
                                    enter="transition-opacity delay-200 duration-500 ease-in-out"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-500 ease-in-out"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="truncate text-sm text-gray-700">
                                        Suggested Daily Budget
                                    </div>
                                    <div className="text-xl font-semibold text-gray-700">
                                        {dollarFormat(suggestedGoal)}
                                    </div>
                                    <ul className="mt-1 flex flex-row gap-1 text-xs font-semibold text-gray-600">
                                        <li>
                                            <span className="rounded bg-gray-100 px-1">
                                                &#247;2
                                            </span>{' '}
                                            {dollarFormat(suggestedGoal / 2)}
                                        </li>
                                        <li>
                                            <span className="rounded bg-gray-100 px-1">
                                                &#247;3
                                            </span>{' '}
                                            {dollarFormat(suggestedGoal / 3)}
                                        </li>
                                        <li>
                                            <span className="rounded bg-gray-100 px-1">
                                                &#247;4
                                            </span>{' '}
                                            {dollarFormat(suggestedGoal / 4)}
                                        </li>
                                    </ul>
                                </Transition.Child>
                                <Transition.Child
                                    as="div"
                                    className="flex-auto"
                                    enter="transition-opacity delay-300 duration-500 ease-in-out"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-500 ease-in-out"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="truncate text-sm text-gray-700">
                                        Spent So Far
                                    </div>
                                    <ProgressBar
                                        percentage={
                                            budget === 0
                                                ? 0
                                                : (total / budget) * 100
                                        }
                                    >
                                        <>
                                            <span className="text-xl font-semibold text-gray-700">
                                                {dollarFormat(total)}
                                            </span>
                                            <span className=" font-semibold text-gray-700">
                                                {' '}
                                                of {dollarFormat(budget)}
                                            </span>
                                        </>
                                    </ProgressBar>
                                </Transition.Child>
                            </div>
                            <div className="z=10 flex w-3/5 flex-col justify-between gap-y-2">
                                <div className="z-10 flex flex-auto flex-col">
                                    {chartsAreVisible && (
                                        <>
                                            <div className="flex-none px-5">
                                                <h3 className="text-sm text-gray-700">
                                                    Breakdown
                                                </h3>
                                            </div>
                                            <div className="min-h-0 flex-auto">
                                                <SpendBreakdownChart
                                                    data={spendBreakdownData}
                                                    status={
                                                        spendBreakdownStatus
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-col gap-y-1 px-5 pb-[0.125rem] text-xs">
                                    {productName && (
                                        <div className="flex align-baseline">
                                            <div className="w-[3.5rem] flex-none text-gray-600">
                                                Product:
                                            </div>
                                            <TruncatableLiteralText className="font-medium text-gray-800">
                                                {productName}
                                            </TruncatableLiteralText>
                                        </div>
                                    )}
                                    {promoName && (
                                        <div className="flex align-baseline">
                                            <div className="w-[3.5rem] flex-none text-gray-600">
                                                Promo:
                                            </div>
                                            <TruncatableLiteralText className="font-medium text-gray-800">
                                                {promoName}
                                            </TruncatableLiteralText>
                                        </div>
                                    )}
                                    {endDate && (
                                        <div className="flex align-baseline">
                                            <div className="w-[3.5rem] flex-none text-gray-600">
                                                End Date:
                                            </div>
                                            <TruncatableLiteralText className="font-medium text-gray-800">
                                                {endDate}
                                            </TruncatableLiteralText>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Tab.Group>
                            <div className="flex min-h-0 w-full flex-auto flex-col focus:outline-none">
                                <div className="flex items-baseline justify-between border-b border-gray-200">
                                    <Tab.List className="group flex flex-initial">
                                        {budgetingDetailTabs.map((tabName) => (
                                            <Tab key={tabName} as={Fragment}>
                                                {({ selected }) => (
                                                    <div
                                                        className={classNames(
                                                            'mr-2 flex-initial rounded-t-lg border-cyan-700 bg-transparent px-2 pt-1 pb-2 outline-none',
                                                            selected
                                                                ? 'cursor-default border-b-2 text-cyan-700 focus:bg-cyan-500 focus:text-white'
                                                                : 'cursor-pointer border-none text-gray-700',
                                                        )}
                                                    >
                                                        {tabName}
                                                    </div>
                                                )}
                                            </Tab>
                                        ))}
                                    </Tab.List>
                                    <div className="flex flex-none align-baseline">
                                        {pendingBudgetChanges > 0 && (
                                            <span className="ml-3 inline-flex flex-none items-center rounded-full bg-lime-200 px-2.5 py-1.5 text-xs font-medium text-lime-800">
                                                {pendingBudgetChanges}
                                            </span>
                                        )}
                                        <Menu
                                            as="div"
                                            className="relative ml-1.5 inline-block"
                                        >
                                            {() => (
                                                <>
                                                    <div>
                                                        <Menu.Button
                                                            as="button"
                                                            className="rounded-md bg-gray-100 px-3 py-1 pr-2.5 text-sm text-gray-700 hover:bg-gray-200 hover:text-gray-900 focus:outline-none focus:ring focus:ring-cyan-500 focus:ring-offset-1"
                                                        >
                                                            Actions
                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            {actionLinks.map(
                                                                (link) =>
                                                                    link.to !==
                                                                        '' &&
                                                                    link.href !==
                                                                        '' ? (
                                                                        <Menu.Item
                                                                            key={
                                                                                link.name
                                                                            }
                                                                        >
                                                                            {({
                                                                                active,
                                                                            }) =>
                                                                                link.href ? (
                                                                                    <a
                                                                                        className={classNames(
                                                                                            active
                                                                                                ? 'bg-gray-500 text-white'
                                                                                                : 'text-gray-700',
                                                                                            'block px-4 py-2 text-sm',
                                                                                        )}
                                                                                        href={
                                                                                            link.href
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        {
                                                                                            link.name
                                                                                        }
                                                                                    </a>
                                                                                ) : (
                                                                                    <Link
                                                                                        className={classNames(
                                                                                            active
                                                                                                ? 'bg-gray-500 text-white'
                                                                                                : 'text-gray-700',
                                                                                            'block px-4 py-2 text-sm',
                                                                                        )}
                                                                                        to={
                                                                                            link.to
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            link.name
                                                                                        }
                                                                                    </Link>
                                                                                )
                                                                            }
                                                                        </Menu.Item>
                                                                    ) : null,
                                                            )}
                                                        </Menu.Items>
                                                    </Transition>
                                                </>
                                            )}
                                        </Menu>
                                    </div>
                                </div>
                                <Tab.Panels
                                    className="min-h-0 flex-auto"
                                    as="div"
                                >
                                    <Tab.Panel className="h-full">
                                        {chartsAreVisible ? (
                                            <SpendAndPacingCharts
                                                pacingDetails={pacingByDay}
                                                lineItem={item}
                                                month={yearMonth}
                                            />
                                        ) : null}
                                    </Tab.Panel>
                                    <Tab.Panel as={Fragment}>
                                        <SpendsByCampaign
                                            data={spendBreakdownData}
                                            status={spendBreakdownStatus}
                                        />
                                    </Tab.Panel>
                                    <Tab.Panel as={Fragment}>
                                        <PacingByDayTable
                                            pacingDetails={pacingByDay}
                                        />
                                    </Tab.Panel>
                                    <Tab.Panel as={Fragment}>
                                        <Notes clientId={clientId} />
                                    </Tab.Panel>
                                    {hasWatchdogActions ? (
                                        <Tab.Panel as={Fragment}>
                                            <WatchdogActions
                                                actions={watchdogActions}
                                            />
                                        </Tab.Panel>
                                    ) : null}
                                </Tab.Panels>
                            </div>
                        </Tab.Group>
                    </div>
                </ErrorBoundary>
            </div>
        </SectionCloud>
    )
}

export const LineItemsOverview = ({
    data,
    csvDownloadButton,
    csvUploadButton,
    csvUploadBudgetsButton,
    show,
    yearMonth,
}) => {
    const [calcs, setCalcs] = useState({
        itemsOnTarget: 250,
        itemsOnTargetPercent: '50%',
        itemsOffTarget: 250,
        itemsOffTargetPercent: '50%',
        totalItems: 500,
        spendDifferencesData: [
            {
                name: '±0-5%',
                amount: 100,
            },
            {
                name: '±5-15%',
                amount: 100,
            },
            {
                name: '±15-25%',
                amount: 50,
            },
            {
                name: '±25%+',
                amount: 250,
            },
        ],
    })
    useEffect(() => {
        if (show) {
            const chartCalcs = percentsInRanges(0, 5, 15, 25, Infinity, data)
            const [goodCount, okCount, notSoGoodCount, badCount] = chartCalcs
            const spendDifferencesData = [
                {
                    name: '±0-5%',
                    amount: goodCount,
                },
                {
                    name: '±5-15%',
                    amount: okCount,
                },
                {
                    name: '±15-25%',
                    amount: notSoGoodCount,
                },
                {
                    name: '±25%+',
                    amount: badCount,
                },
            ]

            const targetData = itemAndRepTargetData(data)
            const { itemsOnTarget, itemsOffTarget, totalItems } = targetData
            const itemsOnTargetPercent = percentageFormat(
                totalItems <= 0 ? 0 : (itemsOnTarget / totalItems) * 100,
                false,
                0,
            )
            const itemsOffTargetPercent = percentageFormat(
                totalItems <= 0 ? 0 : (itemsOffTarget / totalItems) * 100,
                false,
                0,
            )
            setCalcs({
                itemsOnTarget,
                itemsOnTargetPercent,
                itemsOffTarget,
                itemsOffTargetPercent,
                totalItems,
                spendDifferencesData,
            })
        }
    }, [data, yearMonth, show])

    const [chartIsVisible, setChartIsVisible] = useState(false)

    const [isOpen, setIsOpen] = useState(false)

    return (
        <SectionCloud className="absolute inset-0" show={show}>
            <ErrorBoundary>
                <div className="flex h-full flex-col">
                    <div className="flex-initial rounded-t-md bg-gray-50 sm:rounded-t-lg">
                        <div className="flex items-center justify-between">
                            <h2 className="p-4 text-left text-xl font-semibold text-gray-600">
                                Overview
                            </h2>
                            <Menu
                                as="div"
                                className="relative mr-4 inline-block text-left"
                            >
                                <div>
                                    <Menu.Button
                                        onClick={() => setIsOpen(!isOpen)}
                                        className="inline-flex w-full select-none justify-center rounded-md bg-cyan-600 px-4 py-2 text-sm font-medium text-white hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500"
                                    >
                                        <DocumentDuplicateIcon
                                            className="mr-2 h-5 w-5"
                                            aria-hidden="true"
                                        />
                                        Manage Data
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-50 mt-2 w-60 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                        <div className="px-1 py-1">
                                            <Menu.Item>
                                                {csvDownloadButton}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {csvUploadButton}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {csvUploadBudgetsButton}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                        <div className="grid grid-cols-2 gap-4 px-4 pb-4">
                            <Transition.Child
                                as="div"
                                className="grid rounded-md border bg-white p-4 text-left shadow-sm"
                                enter="transition-opacity duration-500 delay-100 ease-in-out"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-500 ease-in-out"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterEnter={() => setChartIsVisible(true)}
                                beforeLeave={() => setChartIsVisible(false)}
                            >
                                <div className="pb-2 text-gray-700">
                                    Line Items
                                    <span className="text-900 font-bold">
                                        {' '}
                                        On Track
                                    </span>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <div className="text-center">
                                        <div className="inline-block text-center">
                                            <span className="text-3xl font-semibold text-cyan-600">
                                                {calcs.itemsOnTarget}
                                            </span>
                                            <span className="text-500 mr-2 text-lg font-light">
                                                {' '}
                                                of{' '}
                                            </span>
                                        </div>
                                        <span className="text-500 text-2xl">
                                            {calcs.totalItems}
                                        </span>
                                    </div>
                                    <div className="text-3xl font-semibold text-cyan-600">
                                        {calcs.itemsOnTargetPercent}
                                    </div>
                                </div>
                            </Transition.Child>
                            <Transition.Child
                                as="div"
                                className="grid rounded-md border bg-white p-4 text-left shadow-sm"
                                enter="transition-opacity duration-500 delay-200 ease-in-out"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-500 ease-in-out"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="pb-2 text-gray-700">
                                    Line Items
                                    <span className="text-900 font-bold">
                                        {' '}
                                        Off Track
                                    </span>
                                </div>
                                <div className="flex flex-col items-center gap-2">
                                    <div className="text-center">
                                        <div className="inline-block text-center">
                                            <span className="text-3xl font-semibold text-cyan-600">
                                                {calcs.itemsOffTarget}
                                            </span>
                                            <span className="text-500 mr-2 text-lg font-light">
                                                {' '}
                                                of{' '}
                                            </span>
                                        </div>
                                        <span className="text-500 text-2xl">
                                            {calcs.totalItems}
                                        </span>
                                    </div>
                                    <div className="text-3xl font-semibold text-cyan-600">
                                        {calcs.itemsOffTargetPercent}
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </div>
                    <div className="min-h-0 flex-auto">
                        <div>
                            {chartIsVisible && (
                                <div className="flex flex-col">
                                    <h3 className="p-4 pb-0 text-left text-xl font-semibold text-gray-600">
                                        Pacing Breakdown
                                    </h3>
                                    <PacingBreakdownChart
                                        data={calcs.spendDifferencesData}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        </SectionCloud>
    )
}
