import {
    addDays,
    eachDayOfInterval,
    eachWeekendOfInterval,
    getDate,
    getDaysInMonth,
    isSameDay,
    isSameMonth,
    isSaturday,
    isSunday,
    isWeekend,
    parseISO,
    setDate,
    subDays,
} from 'date-fns'
import { ExtendedLineItem, LineItem, Spend } from '../apis/pearApi'
import { range } from './Functions'
import { isWithin, isOnOrBefore } from './Time'
import { isoMonth, isoDay } from './Formatting'

// This should be used for all budgeting current date needs and allows for
// setting specific dates during testing.
// TESTING: Replace `new Date()` with `parseISO('2022-09-01')` or similar
export const today = () => new Date()
// export const today = () => parseISO('2023-06-01')

export const latestDate = () =>
    today().getDate() === 1 ? today() : subDays(today(), 1)

export const latestMonth = () => isoMonth(latestDate())

const latestWeekday = (date: Date) =>
    isSunday(date)
        ? subDays(date, 2)
        : isSaturday(date)
        ? subDays(date, 1)
        : date

export const latestWeekdayInInterval = (start: Date, end: Date, date: Date) => {
    const lastPossibleDay = date < end ? date : end
    const possibleWeekday = latestWeekday(lastPossibleDay)

    // Don't jump to previous months near the begininning of the month.
    const day = isSameMonth(possibleWeekday, date) ? possibleWeekday : date

    return day < start ? start : day > end ? end : day
}

export const dateToUse = (adsOnWeekends: boolean, first: Date, last: Date) => {
    const now = today()
    const isCurrentMonth = isSameMonth(first, now)

    // If today is the first day of the interval, don't go back a day like usual.
    const day = isCurrentMonth
        ? isSameDay(first, now)
            ? now
            : latestDate()
        : last

    // Ensure the date is within the date interval.
    const dayInInterval = day < first ? first : day > last ? last : day

    // Get the last weekday if there aren't ads on weekends.
    return adsOnWeekends
        ? dayInInterval
        : latestWeekdayInInterval(first, last, dayInInterval)
}

export const dayToUse = (adsOnWeekends: boolean, first: Date, last: Date) => {
    const day = dateToUse(adsOnWeekends, first, last)
    const formattedDay = isoDay(day)
    return formattedDay
}

/** Simply returns the first day of the month if the start date isn't provided. */
const firstDateToUse = (monthDate: Date, startDate: string) =>
    startDate ? parseISO(startDate) : setDate(monthDate, 1)

/** Simply returns the last day of the month if the end date isn't provided. */
const lastDateToUse = (monthDate: Date, endDate: string) =>
    endDate ? parseISO(endDate) : setDate(monthDate, getDaysInMonth(monthDate))

/** Returns a real start and end date for the given month even if neither date
 * is provided.
 * @param monthIso e.g. "2022-09-01"
 * @param startIso e.g. "2022-09-06" or undefined
 * @param endIso e.g. "2022-09-23" or undefined
 * @return An array of 2 Date objects, the start and end dates.
 */
export const budgetInterval = (
    // ISO 8601 format like "2022-09-01" (or even "2022-09")
    monthIso: string,
    startIso?: string,
    endIso?: string,
) => {
    const month = parseISO(monthIso)
    const start = firstDateToUse(month, startIso)
    const end = lastDateToUse(month, endIso)
    return [start, end]
}

/** Is today the first day of the interval and also the given date? */
export const isFirstDayOfInterval = (start: Date, given: Date) =>
    isSameDay(start, given) && isSameDay(today(), start)

export const weekdaysLeftInInterval = (start: Date, end: Date, date: Date) => {
    // Don't use the given date in calcs (intervals are inclusive)
    const dateForCalcs = addDays(date, 1)
    const first = date < start ? start : dateForCalcs > end ? end : dateForCalcs
    const interval = { start: first, end }
    const weekendDays = eachWeekendOfInterval(interval)
    const days = eachDayOfInterval(interval)
    const weekdaysLeft = days.length - weekendDays.length

    // If it's the first day of the interval and that's the day we're on, count today.
    const adjustedWeekdaysLeft = isFirstDayOfInterval(start, date)
        ? weekdaysLeft + 1
        : weekdaysLeft

    return Math.max(adjustedWeekdaysLeft, 0)
}

export const advertisingDaysLeftInInterval = (
    start: Date,
    end: Date,
    adsOnWeekends: boolean,
    date: Date,
) => {
    if (adsOnWeekends) {
        const daysLeft = getDate(end) - getDate(date)

        // If it's the first day of the interval and that's the day we're on, count today.
        const adjustedDaysLeft = isFirstDayOfInterval(start, date)
            ? daysLeft + 1
            : daysLeft
        return Math.max(adjustedDaysLeft, 0)
    }
    return weekdaysLeftInInterval(start, end, date)
}

/** Returns the number of advertising days between the dates given (inclusive). */
export const advertisingDaysInInterval = (
    firstDate: Date,
    lastDate: Date,
    adsOnWeekends: boolean,
) => {
    const firstDay = getDate(firstDate)
    const lastDay = getDate(lastDate)

    // Standard +1 when subtracting inclusive ends of a range.
    // (20 - 1 = 19, but 1 -> 20 is 20 numbers)
    const dayTotal = lastDay - firstDay + 1

    if (adsOnWeekends) {
        return Math.max(dayTotal, 0)
    }

    const allDatesBetweenLimits = range(dayTotal, firstDay)
    const isWeekdayInThisMonth = isWeekday(firstDate)
    const allWeekdays = allDatesBetweenLimits.filter(isWeekdayInThisMonth)
    const totalDays = allWeekdays.length
    return totalDays
}

/** Current pacing based on given info.
 *
 * @param budget In dollars; greater than 0.
 * @param total In dollars; 0 or greater.
 * @param spendDays Number of total days for this budget to be spent - at least 1.
 * @param spendDaysLeft Number of days left to spend - 0 or higher.
 */
export const pacingInfo = (budget, total, spendDays, spendDaysLeft) => {
    const daysSoFar = spendDays - spendDaysLeft
    const average = daysSoFar <= 0 ? total : total / daysSoFar
    const projectedTotal = average * spendDays
    const amount = projectedTotal - budget

    // Can't calculate percent when budget is zero so we'll call it
    // 100% pacing percent if there's spend, and 0 if not.
    const percent =
        budget <= 0 ? (total > 0 ? 100 : 0) : (amount / budget) * 100

    return { percent, amount, projectedTotal }
}

// Totals of spend array within interval and on or before given day.
const totalSpend = (spends: Spend[], first: Date, last: Date, dayIso: string) =>
    spends
        .filter(isWithin(first, last))
        .filter(isOnOrBefore(dayIso))
        .reduce((total, spend) => total + spend.spend, 0)

// Checks if percentage is heading toward 0 - 5% above or below goal.
export const isProjectedWithinFivePercent = (
    { dailySpends, budget, adsOnWeekends, startDate, endDate }: LineItem,
    monthIso: string,
) => {
    const [firstDate, lastDate] = budgetInterval(monthIso, startDate, endDate)
    const day = dayToUse(adsOnWeekends, firstDate, lastDate)
    const total = totalSpend(dailySpends, firstDate, lastDate, day)

    // If there's no budget, let's say we're only on target if we've spent nothing.
    if (budget === 0) {
        return total === 0
    }

    const FIVE_PERCENT = 5

    // If the spends have stopped (probably because the campaign was paused),
    // let's assume there'll be no more spends and calculate based on that.
    const daysDatum = dailySpends.find((datum) =>
        isSameDay(parseISO(datum.date), parseISO(day)),
    )
    if (daysDatum !== undefined && budget !== 0 && daysDatum.spend === 0) {
        const latestPercent = ((total - budget) / budget) * 100
        return Math.abs(latestPercent) <= FIVE_PERCENT
    }

    const daysLeft = advertisingDaysLeftInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
        parseISO(day),
    )
    const daysInInterval = advertisingDaysInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
    )
    const latestPercent = pacingInfo(
        budget,
        total,
        daysInInterval,
        daysLeft,
    ).percent
    return Math.abs(latestPercent) <= FIVE_PERCENT
}

export const itemAndRepTargetData = (items: ExtendedLineItem[]) => {
    let itemsOnTarget = 0
    items.forEach((item) => {
        if (item.isProjectedWithinFivePercent) {
            itemsOnTarget += 1
        }
    })
    return {
        itemsOnTarget,
        itemsOffTarget: items.length - itemsOnTarget,
        totalItems: items.length,
    }
}

export const percentsInRanges = (
    firstMin: number,
    secondMin: number,
    thirdMin: number,
    fourthMin: number,
    max: number,
    items: ExtendedLineItem[],
) => {
    const totals = Array(4).fill(0)
    items.forEach((item) => {
        const percent = item.latestPercent
        const percentSize = Math.abs(percent)
        const totalIndex =
            percentSize >= firstMin && percentSize <= secondMin
                ? 0
                : percentSize > secondMin && percentSize <= thirdMin
                ? 1
                : percentSize > thirdMin && percentSize <= fourthMin
                ? 2
                : percentSize > fourthMin && percentSize <= max
                ? 3
                : -1
        if (totalIndex !== -1) {
            totals[totalIndex] += 1
        }
    }, 0)
    return totals
}

const spendForDay = (dailySpends: Spend[], date: Date) => {
    return (
        dailySpends.find((day) => isSameDay(date, parseISO(day.date)))?.spend ??
        0
    )
}

/** Calculate budget info for a LineItem up to a given day. (Doesn't rely on current date.)
 *  Main budgeting calculations grouping function.
 */
export const budgetingDetails = (
    { dailySpends, budget, adsOnWeekends, startDate, endDate }: LineItem,
    givenDate: Date,
) => {
    const dayIso = isoDay(givenDate)
    const [firstDate, lastDate] = budgetInterval(dayIso, startDate, endDate)

    const daysInInterval = advertisingDaysInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
    )
    const daysLeft = advertisingDaysLeftInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
        givenDate,
    )
    const total = totalSpend(dailySpends, firstDate, lastDate, dayIso)

    // Current Pacing
    const { percent: pacingPercent, amount: pacingAmount } = pacingInfo(
        budget,
        total,
        daysInInterval,
        daysLeft,
    )

    // -1 Day Pacing
    const latestDaySpend = spendForDay(dailySpends, givenDate)
    const dayBeforeTotal = total - latestDaySpend
    const { percent: dayBeforePercent } = pacingInfo(
        budget,
        dayBeforeTotal,
        daysInInterval,
        daysLeft + 1,
    )

    const oneDayPercentDelta = pacingPercent - dayBeforePercent
    const potentialGoal = daysLeft > 0 ? (budget - total) / daysLeft : 0
    const suggestedGoal = Math.max(potentialGoal, 0)

    return {
        pacingAmount,
        pacingPercent,
        dayBeforePercent,
        oneDayPercentDelta,
        total,
        suggestedGoal,
    }
}

// This handles determining the day to use in calcs. (Tied to current date.)
export const budgetingOverviewDetails = (item: LineItem, monthIso: string) => {
    const { adsOnWeekends, startDate, endDate } = item
    const [first, last] = budgetInterval(monthIso, startDate, endDate)
    const date = dateToUse(adsOnWeekends, first, last)

    return budgetingOverviewDetailCalcs(item, date)
}

// These are calcs without any ties to the current date.
export const budgetingOverviewDetailCalcs = (item: LineItem, date: Date) => {
    const details = budgetingDetails(item, date)
    const { pacingPercent, dayBeforePercent, total } = details

    return {
        latestPercent: pacingPercent,
        total,
        dayBeforePercent,
    }
}

export const isWeekday = (dateInMonth: Date) => (dayOfTheMonth: number) => {
    const dayDate = setDate(dateInMonth, dayOfTheMonth)
    return !isWeekend(dayDate)
}

interface DayPair {
    day: number
    isAdvertising: boolean
}

/**
 * Creates an array of day and isAdvertising pairs.
 * @param date Date object for the current day
 * @param adsOnWeekends boolean setting for the client
 * @returns array of day number and isAdvertising boolean pairs
 */
export const listOfAdvertisingDaysInIntervalSoFar = (
    start: Date,
    end: Date,
    date: Date,
    adsOnWeekends: boolean,
): DayPair[] => {
    const firstDay = getDate(start)
    const lastDay = Math.min(getDate(end), getDate(date))
    const totalDays = Math.max(lastDay - firstDay + 1, 0)
    const everyDayInIntervalSoFar = range(totalDays, firstDay)
    return adsOnWeekends
        ? everyDayInIntervalSoFar.map((day) => ({
              day,
              isAdvertising: true,
          }))
        : everyDayInIntervalSoFar.map((day) => ({
              day,
              isAdvertising: isWeekday(date)(day),
          }))
}

export interface DayPacing {
    date: Date
    isAdvertisingDay: boolean
    total: number
    pacingAmount: number
    pacingPercent: number
    changeInPercent: number
}

/**
 * Creates an array of pacing info by date: total spend, pacing amount, pacing percent,
 * and change in percent from the previous day.
 * @param lineItem LineItem to calculate pacings on
 * @param month Current month in year-month format like "2022-09"
 * @returns
 */
export const pacingDetails = (
    lineItem: LineItem,
    month: string,
): DayPacing[] => {
    const { adsOnWeekends, startDate, endDate } = lineItem
    const [start, end] = budgetInterval(month, startDate, endDate)
    const date = dateToUse(adsOnWeekends, start, end)
    const dayPairs = listOfAdvertisingDaysInIntervalSoFar(
        start,
        end,
        date,
        adsOnWeekends,
    )
    return dayPairs.map((dayPair) => {
        const thisDate = setDate(date, dayPair.day)
        const { total, pacingAmount, pacingPercent, oneDayPercentDelta } =
            budgetingDetails(lineItem, thisDate)
        return {
            date: thisDate,
            isAdvertisingDay: dayPair.isAdvertising,
            total,
            pacingAmount,
            pacingPercent,
            changeInPercent: oneDayPercentDelta,
        }
    })
}
