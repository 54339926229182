import {
    CheckIcon,
    PauseIcon,
    PlayIcon,
    Square2StackIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import { classNames } from '../utility/Components'

export const CheckUIIcon = ({ className = '', size = '' }) => (
    <span
        className={classNames(
            'flex items-center justify-center rounded-full bg-green-600 stroke-current text-white',
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            className,
        )}
    >
        <CheckIcon
            className={classNames(
                size === 'medium' ? 'h-3 w-3' : 'h-2.5 w-2.5',
            )}
        />
    </span>
)

export const DuplicateUIIcon = ({ className = '', size = '' }) => (
    <span
        className={classNames(
            'inline-flex items-center justify-center rounded-full bg-cyan-600 stroke-current text-white',
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            className,
        )}
    >
        <Square2StackIcon
            className={classNames(size === 'medium' ? 'h-3 w-3' : 'h-2 w-2')}
        />
    </span>
)

export const PlayUIIcon = ({ className = '', size = '' }) => (
    <span
        className={classNames(
            'inline-flex items-center justify-center stroke-gray-900 text-gray-900',
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            className,
        )}
    >
        <PlayIcon />
    </span>
)

export const PauseUIIcon = ({ className = '', size = '' }) => (
    <span
        className={classNames(
            'inline-flex items-center justify-center stroke-gray-900 text-gray-900',
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            className,
        )}
    >
        <PauseIcon />
    </span>
)

export const XUIIcon = ({ className = '', size = '' }) => (
    <span
        className={classNames(
            'inline-flex items-center justify-center rounded-full bg-orange-500 stroke-current text-white',
            size === 'medium' ? 'h-5 w-5' : 'h-3 w-3',
            className,
        )}
    >
        <XMarkIcon
            className={classNames(
                size === 'medium' ? 'h-4 w-4' : 'h-2.5 w-2.5',
            )}
        />
    </span>
)
