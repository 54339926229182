import { classNames } from '../../utility/Components'

const TruncatableLiteralText = ({
    className = '',
    children,
}: {
    className?: string
    children: string
}) => {
    return (
        <div
            className={classNames(className, 'truncate whitespace-pre')}
            title={children}
        >
            {children}
        </div>
    )
}

export default TruncatableLiteralText
