import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import 'react-datepicker/dist/react-datepicker.css'

import PearApi from '../apis/pearApi'
import { useGetClientCoopReport } from '../hooks/apiHooks'
import { formatUTCDate, isoDay } from '../utility/Formatting'

import { SectionCloud } from '../components/Layout'
import { Form, Field } from 'react-final-form'
import ButtonGroup from '../components/elements/Buttons'
import { Button, TextButton } from '../components/elements/Buttons'
import { UnlabeledTextField, ToggleField } from '../components/forms/Fields'

const ClientCoopReport = () => {
    const { clientId }: { [key: string]: string } = useParams()
    const { data, status } = useGetClientCoopReport(clientId)

    const [initialValues, setInitialValues] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [saveMessage, setSaveMessage] = useState({
        type: 'idle',
        message: '',
    })

    let formBusy = false

    // Get prev month dates
    const currentDate = DateTime.now()
    const firstDayOfPreviousMonth = isoDay(
        new Date(currentDate.minus({ months: 1 }).startOf('month')),
    )
    const lastDayOfPreviousMonth = isoDay(
        new Date(currentDate.minus({ months: 1 }).endOf('month')),
    )

    const onSubmit = (formValues) => {
        formBusy = true
        const finalValues = formValues
        finalValues.startDate =
            typeof finalValues.startDate === 'string'
                ? finalValues.startDate
                : isoDay(finalValues.startDate)
        finalValues.endDate =
            typeof finalValues.endDate === 'string'
                ? finalValues.endDate
                : isoDay(finalValues.endDate)
        save.mutate(finalValues)
    }

    const save = useMutation(
        (finalValues: object) =>
            PearApi.saveClientCoopReport(clientId, finalValues),
        {
            onSuccess: () => {
                formBusy = false
                setSaveMessage({
                    type: 'success',
                    message: 'Report will be emailed to you shortly.',
                })
            },
            onError: () => {
                formBusy = false
                setSaveMessage({
                    type: 'error',
                    message: 'There was a problem saving the changes.',
                })
            },
        },
    )

    // Initial values
    useEffect(() => {
        if (data) {
            data.saveAsDefault = false
            data.startDate
                ? setStartDate(formatUTCDate(data.startDate))
                : setStartDate(formatUTCDate(firstDayOfPreviousMonth))
            data.endDate
                ? setEndDate(formatUTCDate(data.endDate))
                : setEndDate(formatUTCDate(lastDayOfPreviousMonth))
            setInitialValues(data)
        }
    }, [data, firstDayOfPreviousMonth, lastDayOfPreviousMonth])

    if (status === 'loading') {
        return <></>
    }

    if (status === 'error') {
        return <>Client Not Found</>
    }

    const filterOptions = [
        { value: 'INCLUDE', name: 'INCLUDE' },
        { value: 'EXCLUDE', name: 'EXCLUDE' },
    ]

    const handleCalendarPick = (event, string) => {
        const newDate = isoDay(new Date(event))
        if (string === 'start') {
            setStartDate(formatUTCDate(newDate))
        } else {
            setEndDate(formatUTCDate(newDate))
        }
    }

    const { name: clientName } = data.client ?? { name: '' }

    return (
        <SectionCloud className="flex h-full flex-col py-6 px-8">
            <h1 className="mb-12 flex-none text-3xl font-extrabold tracking-tight text-gray-600">
                Co-Op Report for {clientName}
            </h1>
            <div className="min-h-0 w-full">
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={({
                        form,
                        handleSubmit,
                        invalid,
                        pristine,
                        submitting,
                        values,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="flex flex-col justify-between gap-6 rounded-lg border border-gray-200 px-6 py-6 lg:flex-row lg:items-center lg:gap-12">
                                <div className="flex-1">
                                    <Field name="startDate">
                                        {({ input }) => (
                                            <div className="mb-2 flex flex-row items-center justify-between text-sm">
                                                <label htmlFor="startDate">
                                                    Start Date
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        wrapperClassName="datePickerInput"
                                                        id="startDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            input.onChange(date)
                                                            handleCalendarPick(
                                                                date,
                                                                'start',
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="endDate">
                                        {({ input }) => (
                                            <div className="mb-2 flex flex-row items-center justify-between text-sm">
                                                <label htmlFor="endDate">
                                                    End Date
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        wrapperClassName="datePickerInput"
                                                        id="endDate"
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={endDate}
                                                        onChange={(date) => {
                                                            input.onChange(date)
                                                            handleCalendarPick(
                                                                date,
                                                                'end',
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="flex-1">
                                    <Field name="filterType">
                                        {({ input }) => (
                                            <div className="mt-6">
                                                <label className="mb-2 block text-sm text-gray-700">
                                                    Filter Type
                                                </label>
                                                <ButtonGroup
                                                    label=""
                                                    choices={filterOptions}
                                                    value={input.value}
                                                    setValue={input.onChange}
                                                    className=""
                                                />
                                            </div>
                                        )}
                                    </Field>
                                    <div className="mt-4">
                                        <label
                                            htmlFor="filterTerms"
                                            className="block text-sm text-gray-700"
                                        >
                                            Filter Terms (comma separated)
                                        </label>
                                        <UnlabeledTextField
                                            className=""
                                            name="filterTerms"
                                        />
                                    </div>
                                </div>
                                <div className="flex-1">
                                    {data &&
                                        initialValues &&
                                        initialValues.includeGoogle !==
                                            null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include Google"
                                                name="includeGoogle"
                                            />
                                        )}
                                    {data &&
                                        initialValues &&
                                        initialValues.includeMicrosoft !==
                                            null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include Microsoft"
                                                name="includeMicrosoft"
                                            />
                                        )}
                                    {data &&
                                        initialValues &&
                                        initialValues.includeFacebook !==
                                            null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include Facebook"
                                                name="includeFacebook"
                                            />
                                        )}
                                    {data &&
                                        initialValues &&
                                        initialValues.includeYouTube !==
                                            null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include YouTube"
                                                name="includeYouTube"
                                            />
                                        )}
                                    {data &&
                                        initialValues &&
                                        initialValues.includeSeo !== null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include SEO"
                                                name="includeSeo"
                                            />
                                        )}
                                    {data &&
                                        initialValues &&
                                        initialValues.includeNotary !==
                                            null && (
                                            <ToggleField
                                                className="mb-2"
                                                label="Include Notary"
                                                name="includeNotary"
                                            />
                                        )}
                                    <ToggleField
                                        className="mt-8"
                                        label="Save as Default"
                                        name="saveAsDefault"
                                    />
                                </div>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    disabled={submitting || formBusy}
                                    className="mt-4"
                                >
                                    Generate Report
                                </Button>
                                <TextButton
                                    className="ml-4"
                                    onClick={form.reset}
                                    disabled={submitting || pristine}
                                >
                                    Reset
                                </TextButton>
                                {saveMessage.message && (
                                    <span
                                        className={`ml-3 rounded-full px-3 py-1 ${
                                            saveMessage.type === 'success'
                                                ? 'bg-green-100 text-green-900'
                                                : 'bg-orange-200 text-orange-900'
                                        }`}
                                    >
                                        {saveMessage.message}
                                    </span>
                                )}
                            </div>
                        </form>
                    )}
                />
            </div>
        </SectionCloud>
    )
}

export default ClientCoopReport
