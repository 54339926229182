import { useState, useEffect, useMemo, Fragment, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from 'react-query'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import AppRoutes from './routes'
import PearApi, { Client, Sources } from '../apis/pearApi'
import {
    useClients,
    invalidateClients,
    useFourbotClients,
    useUsers,
} from '../hooks/apiHooks'
import StatusDisplay, { Status } from '../components/StatusDisplay'
import FormWindow from '../components/forms/FormWindow'
import {
    Condition,
    DisabledField,
    PersonSelectWithSearchField,
    SelectFieldWithSearch,
    TextField,
    OptionalTextField,
    ToggleField,
    AccountIdField,
    SelectField,
} from '../components/forms/Fields'
import { combinedStatus } from '../utility/apis'
import { StatelessTextBox } from '../components/elements/TextBoxes'
import { SectionCloud, Well } from '../components/Layout'
import { useUrlQueryParameter } from '../hooks/useUrlQueryParameter'
import { Toggle } from '../components/elements/Toggle'
import { useLoginInfo, LoginStatus } from '../hooks/LoginInfo'
import {
    PeopleGrouping,
    PersonSelectWithSearch,
} from '../components/elements/PersonSelect'
import ErrorBoundary from '../components/ErrorBoundary'
import { classNames } from '../utility/Components'
import { Menu, Transition } from '@headlessui/react'
import { Button, LinkButton } from '../components/elements/Buttons'
import useDataList from '../hooks/useDataList'
import { Field } from 'react-final-form'
import { PlatformLinks, SALESFORCE_URL_TEMPLATE } from '../apis/ExternalSites'

const formValuesFromClient = (client: Client) => {
    const {
        sources,
        name,
        shortName,
        repId,
        dealerCode,
        fourbotId,
        foureyesId,
        salesforceId,
        type,
        config: {
            facebookPageId,
            automaticPricingEnabled,
            usedToolEnabled,
            usedToolCertifiedOnly,
            usedToolOnlyMyLocation,
            tikTokLeadsEnabled,
            partsServiceOnly,
            promoAdBuilderEnabled,
            fourbotPrivateSource,
        },
    } = client
    const googleCode = sources.find(
        (source) => source.source === 'Google',
    )?.accountId
    const facebookCode = sources.find(
        (source) => source.source === 'Facebook',
    )?.accountId
    const microsoftCode = sources.find(
        (source) => source.source === 'Microsoft',
    )?.accountId
    const stackAdaptCode = sources.find(
        (source) => source.source === 'StackAdapt',
    )?.accountId
    const linkedInCode = sources.find(
        (source) => source.source === 'LinkedIn',
    )?.accountId
    const tikTokCode = sources.find(
        (source) => source.source === 'TikTok',
    )?.accountId

    const formValues = {
        name,
        shortName,
        repId,
        salesforceId,
        dealerCode,
        fourbotId,
        foureyesId,
        type,
        facebookPageId,
        automaticPricingEnabled,
        usedToolEnabled,
        usedToolCertifiedOnly,
        usedToolOnlyMyLocation,
        tikTokLeadsEnabled,
        partsServiceOnly,
        promoAdBuilderEnabled,
        fourbotPrivateSource,
        googleCode,
        facebookCode,
        microsoftCode,
        stackAdaptCode,
        linkedInCode,
        tikTokCode,
    }

    return formValues
}

const formSources = {
    googleCode: 'Google',
    facebookCode: 'Facebook',
    microsoftCode: 'Microsoft',
    stackAdaptCode: 'StackAdapt',
    linkedInCode: 'LinkedIn',
    tikTokCode: 'TikTok',
}

const accountTypes = [
    { id: 'Auto', name: 'Auto' },
    { id: 'Capital Equipment', name: 'Capital Equipment' },
    { id: 'Custom', name: 'Custom' },
    { id: 'New Industries', name: 'New Industries' },
]

const newClientId = -1
const newClient: Client = {
    id: newClientId,
    name: '',
    salesforceId: '',
    timeZone: '',
    type: 'Auto',
    config: {
        facebookPageId: '',
        automaticPricingEnabled: false,
        usedToolEnabled: false,
        usedToolCertifiedOnly: false,
        usedToolOnlyMyLocation: true,
        tikTokLeadsEnabled: false,
        partsServiceOnly: false,
        promoAdBuilderEnabled: false,
        fourbotPrivateSource: false,
    },
    sources: [],
    pendingBudgetChanges: 0,
}

const PlatformLinkField = ({ name, platform }) => (
    <Field name={name}>
        {({ input: { value }, meta: { error } }) =>
            value && !error ? (
                <div className="flex h-full flex-none items-end pl-2 pb-6">
                    <a
                        className="block rounded-md text-cyan-500 ring-cyan-500 hover:text-cyan-700 focus:outline-none focus:ring-2"
                        href={PlatformLinks[platform]?.replace(
                            ':accountId',
                            value,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ArrowTopRightOnSquareIcon
                            className="h-8"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Go to {platform}</span>
                    </a>
                </div>
            ) : null
        }
    </Field>
)

const Clients = () => {
    const { data: clients = [], status: clientStatus } = useClients()
    const { data: users = [], status: usersStatus } = useUsers()
    const { data: fourbotClients, status: fourbotClientsStatus } =
        useFourbotClients()

    const settingsStatus = combinedStatus(
        clientStatus,
        usersStatus,
        fourbotClientsStatus,
    )

    const [searchValue, setSearchValue] = useUrlQueryParameter('search')

    const [selectedClientIdString, setSelectedClientIdString] =
        useUrlQueryParameter('id', '0')
    const selectedClientId = parseInt(selectedClientIdString, 10)
    const setSelectedClientId = useCallback(
        (idNumber) => setSelectedClientIdString('' + idNumber),
        [setSelectedClientIdString],
    )
    const initNewClient = useCallback(
        () => setSelectedClientIdString('' + newClientId),
        [setSelectedClientIdString],
    )

    const [onlyWithPendingBudgetChanges, setOnlyWithPendingBudgetChanges] =
        useUrlQueryParameter('pendingBudgetChanges', 'false')
    const filterToPendingBudgetChanges = 'true' === onlyWithPendingBudgetChanges

    const [onlySetupRequired, setOnlySetupRequired] = useUrlQueryParameter(
        'setupRequired',
        'false',
    )

    const [initialValues, setInitialValues] = useState(undefined)
    useEffect(() => {
        const selectedClient =
            selectedClientId !== newClientId
                ? clients.find((client) => client.id === selectedClientId)
                : newClient
        if (selectedClient) {
            setInitialValues(formValuesFromClient(selectedClient))
        }
    }, [selectedClientId, clients])

    const clientFromFormValues = (formValues): Client => {
        const selectedClient =
            selectedClientId !== newClientId
                ? clients.find((client) => client.id === selectedClientId)
                : newClient
        const sources = []
        Object.keys(formSources).forEach((key) => {
            if (formValues[key]) {
                sources.push({
                    source: formSources[key],
                    accountId: formValues[key],
                })
            }
        })
        const { id, timeZone } = selectedClient
        const {
            fourbotId,
            name,
            shortName,
            dealerCode,
            type,
            salesforceId,
            facebookPageId,
            automaticPricingEnabled,
            usedToolEnabled,
            usedToolCertifiedOnly,
            usedToolOnlyMyLocation,
            tikTokLeadsEnabled,
            partsServiceOnly,
            promoAdBuilderEnabled,
            fourbotPrivateSource,
            repId,
        } = formValues
        const clientInfo = {
            id,
            timeZone,
            salesforceId,
            config: {
                facebookPageId,
                automaticPricingEnabled,
                usedToolEnabled,
                usedToolCertifiedOnly,
                usedToolOnlyMyLocation,
                tikTokLeadsEnabled,
                partsServiceOnly,
                promoAdBuilderEnabled,
                fourbotPrivateSource,
            },
            name,
            shortName,
            repId,
            type,
            dealerCode,
            fourbotId,
            sources: sources,
            pendingBudgetChanges: 0,
        }
        if (type !== 'Auto') {
            delete clientInfo.dealerCode
            delete clientInfo.fourbotId
        }

        return clientInfo
    }

    const [saveMessage, setSaveMessage] = useState({
        type: 'idle' as Status,
        message: '',
    })
    const save = useMutation(
        (clientInfo: Client) =>
            clientInfo.id !== newClientId
                ? PearApi.saveClient(clientInfo.id, clientInfo)
                : PearApi.saveNewClient(clientInfo),
        {
            onSuccess: (data, clientInfo) => {
                setSaveMessage({ type: 'success', message: 'Settings saved.' })
                invalidateClients()
                const idAssignedToNewClient = data?.data?.client?.id
                if (
                    clientInfo.id === newClientId &&
                    idAssignedToNewClient !== undefined
                ) {
                    setSelectedClientId(idAssignedToNewClient)
                }
            },
            onError: () => {
                setSaveMessage({
                    type: 'error',
                    message: 'Save failed.',
                })
            },
        },
    )
    useEffect(() => {
        setSaveMessage({ type: 'idle', message: '' })
    }, [selectedClientId])

    const onSubmit = (formValues) => {
        const clientInfo = clientFromFormValues(formValues)
        save.mutate(clientInfo)
    }

    const reps = useMemo(
        () => users.filter(({ clientCount }) => clientCount > 0),
        [users],
    )
    const [urlRepId, setUrlRepId] = useUrlQueryParameter('repId', '-2')
    const repIdForFiltering = parseInt(urlRepId, 10)
    const setRepIdForFiltering = (repId: number) =>
        setUrlRepId(repId.toString(10))

    const filterToSetupRequired = 'true' === onlySetupRequired
    const filterToUnassigned = repIdForFiltering === PeopleGrouping.NONE.id

    const displayNewClient =
        !filterToSetupRequired &&
        !filterToPendingBudgetChanges &&
        !filterToUnassigned

    const { user, status } = useLoginInfo()
    const currentUserId =
        status === LoginStatus.loggedIn ? user.id : PeopleGrouping.ALL.id

    const [initialRepId] = useState(repIdForFiltering)
    const [initiallySelectedRepId, setInitiallySelectedRepId] = useState(
        PeopleGrouping.ALL.id,
    )
    useEffect(() => {
        // If initialRepId is the ALL selection, leave it. Otherwise...
        if (initialRepId === PeopleGrouping.NO_SELECTION.id) {
            if (reps.some(({ id }) => id === currentUserId)) {
                setRepIdForFiltering(currentUserId)
                setInitiallySelectedRepId(currentUserId)
            } else {
                setRepIdForFiltering(PeopleGrouping.ALL.id)
            }
        } else if (initialRepId !== PeopleGrouping.ALL.id) {
            if (reps.some(({ id }) => id === initialRepId)) {
                setRepIdForFiltering(initialRepId)
                setInitiallySelectedRepId(initialRepId)
            } else {
                setRepIdForFiltering(PeopleGrouping.ALL.id)
            }
        }
        // Since the setRepIdForFiltering function changes every render, don't include it in the list.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialRepId, reps])

    const selectedClient = clients.find(
        (client) => client.id === selectedClientId,
    )

    const feedAndInventoryLinks = [
        {
            to: AppRoutes.CLIENT_CARS,
            name: 'Vehicle Inventory',
        },
        {
            to: AppRoutes.AUTOMATIC_PRICING,
            name: 'Automatic Pricing',
        },
        {
            to: AppRoutes.CLIENT_COOP_REPORT,
            name: 'Co-Op Report',
        },
        {
            to: AppRoutes.USED_TOOL_SETTINGS,
            name: 'Used Tool',
        },
    ]

    const isNewClient = selectedClientId === newClientId

    const filteredClients = useMemo(
        () =>
            clients
                .filter((client) => {
                    const { name: clientName } = client
                    const isIncludedInPendingBudgetsChoice =
                        !filterToPendingBudgetChanges ||
                        client.pendingBudgetChanges > 0
                    if (!isIncludedInPendingBudgetsChoice) {
                        return false
                    }
                    const isIncludedInSetupRequiredChoice =
                        !filterToSetupRequired ||
                        client.sources?.length <= 0 ||
                        client.foureyesId === undefined ||
                        client.repId === undefined
                    if (!isIncludedInSetupRequiredChoice) {
                        return false
                    }
                    const isIncludedInRepChoice =
                        repIdForFiltering === PeopleGrouping.ALL.id ||
                        repIdForFiltering === PeopleGrouping.NO_SELECTION.id ||
                        client.repId === repIdForFiltering ||
                        (repIdForFiltering === PeopleGrouping.NONE.id &&
                            !client.repId)
                    if (!isIncludedInRepChoice) {
                        return false
                    }

                    const searchingForName = clientName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    if (searchingForName) {
                        return true
                    }

                    return !!client?.dealerCode
                        ?.toLowerCase()
                        .includes(searchValue.toLowerCase())
                })
                .sort((clientA, clientB) =>
                    clientA.name.localeCompare(clientB.name),
                ),
        [
            clients,
            filterToPendingBudgetChanges,
            filterToSetupRequired,
            repIdForFiltering,
            searchValue,
        ],
    )

    const onSelect = useCallback(
        (selection: number) => {
            // When the new client option is displayed, it's index 0 so
            // set the selected id to the new client id.
            if (selection === 0 && displayNewClient) {
                initNewClient()
            } else {
                const clientId =
                    filteredClients.find((_client, index) => {
                        const actualIndex = displayNewClient ? index + 1 : index
                        return actualIndex === selection
                    })?.id ?? 0
                setSelectedClientId(clientId)
            }
        },
        [setSelectedClientId, filteredClients, displayNewClient, initNewClient],
    )

    const itemLength = filteredClients.length
    const listLength = displayNewClient ? itemLength + 1 : itemLength
    const selectedClientIndex =
        filteredClients.findIndex(({ id }) => id === selectedClientId) +
        (displayNewClient ? 1 : 0)
    const { listProps, itemProps } = useDataList({
        length: listLength,
        initialSelection: selectedClientIndex,
        pageDistance: 20,
        onSelect,
    })

    return (
        <div className="grid h-full min-h-0 grid-cols-2 gap-8">
            <SectionCloud className="flex h-full min-h-0 flex-col">
                <div className="flex min-h-0 flex-auto flex-col px-8 pb-8 pt-6">
                    <div className="flex gap-4">
                        <h1 className="flex-1 text-3xl font-extrabold tracking-tight text-gray-600">
                            Clients
                        </h1>
                        <div className="flex-initial">
                            <Toggle
                                label="Budget Changes Only"
                                enabled={filterToPendingBudgetChanges}
                                setEnabled={(isEnabled) =>
                                    setOnlyWithPendingBudgetChanges(
                                        isEnabled ? 'true' : 'false',
                                    )
                                }
                            />
                            <Toggle
                                className="mt-1"
                                label="Setup Required"
                                enabled={filterToSetupRequired}
                                setEnabled={(isEnabled) =>
                                    setOnlySetupRequired(
                                        isEnabled ? 'true' : 'false',
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <PersonSelectWithSearch
                            className="flex-1"
                            onChange={(rep) => setRepIdForFiltering(rep.id)}
                            people={reps}
                            initiallySelectedId={initiallySelectedRepId}
                            includeNoOne={true}
                        />
                        <StatelessTextBox
                            className="flex-1"
                            classNameInput="mt-1"
                            text={searchValue}
                            label="Search"
                            onChange={(value) => setSearchValue(value)}
                        />
                    </div>
                    <div className="mt-4 min-h-0 flex-auto overflow-hidden rounded-lg border border-gray-300 focus-within:border-cyan-500 focus-within:outline-none focus-within:ring focus-within:ring-cyan-500">
                        <StatusDisplay status={clientStatus}>
                            <ul
                                className="h-full overflow-y-auto pt-2 focus:outline-none"
                                aria-label={
                                    'Client List with ' +
                                    listLength +
                                    (listLength === 1 ? ' item' : ' items')
                                }
                                {...listProps()}
                            >
                                {displayNewClient && (
                                    <li
                                        className={classNames(
                                            'w-full py-1 px-4 text-left text-gray-800 focus:outline-none',
                                            selectedClientId === newClientId
                                                ? 'bg-cyan-600 font-semibold text-white'
                                                : 'text-gray-800 hover:bg-cyan-500 hover:text-white focus:bg-cyan-500 focus:font-bold focus:text-white',
                                        )}
                                        key={newClientId}
                                        {...itemProps(0)}
                                    >
                                        <div className="flex items-baseline">
                                            <div className="flex min-w-0 flex-auto items-baseline">
                                                <span className="truncate font-bold">
                                                    New Client
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {filteredClients.map((client, index) => {
                                    const {
                                        id,
                                        name,
                                        dealerCode,
                                        pendingBudgetChanges,
                                        foureyesId,
                                        repId,
                                        sources,
                                    } = client
                                    const actualIndex = displayNewClient
                                        ? index + 1
                                        : index
                                    const isSelected = selectedClientId === id
                                    return (
                                        <li
                                            className={classNames(
                                                'group w-full py-1 px-4 text-left focus:outline-none',
                                                isSelected
                                                    ? 'bg-cyan-600 font-semibold text-white'
                                                    : 'text-gray-800 hover:bg-cyan-500 hover:text-white focus:bg-cyan-500 focus:font-bold focus:text-white',
                                            )}
                                            key={id}
                                            {...itemProps(actualIndex)}
                                        >
                                            <div className="flex items-baseline">
                                                <div className="flex min-w-0 flex-auto items-baseline">
                                                    <span className="truncate">
                                                        {name}{' '}
                                                    </span>
                                                    <span
                                                        className={`ml-2 ${
                                                            isSelected
                                                                ? ''
                                                                : 'text-gray-700 '
                                                        } text-sm font-normal tracking-wide group-hover:text-white group-focus:font-bold group-focus:text-white`}
                                                    >
                                                        {dealerCode ?? ''}
                                                    </span>
                                                </div>
                                                {pendingBudgetChanges > 0 ? (
                                                    <span className="ml-3 inline-flex flex-initial items-center rounded-full bg-lime-200 px-2.5 py-0.5 text-xs font-medium text-lime-700">
                                                        {pendingBudgetChanges}
                                                        <span className="sr-only">
                                                            {' '}
                                                            budget changes
                                                        </span>
                                                    </span>
                                                ) : null}
                                                <span
                                                    className={`ml-3 inline-flex flex-initial items-center rounded-full bg-orange-200 px-2.5 py-0.5 text-xs font-bold text-orange-700 ${
                                                        sources?.length <= 0 ||
                                                        foureyesId ===
                                                            undefined ||
                                                        repId === undefined
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    }`}
                                                >
                                                    <span aria-hidden="true">
                                                        !
                                                    </span>
                                                    <span className="sr-only">
                                                        setup required
                                                    </span>
                                                </span>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </StatusDisplay>
                    </div>
                </div>
            </SectionCloud>
            <ErrorBoundary>
                {selectedClientId !== 0 ? (
                    <SectionCloud className="flex h-full min-h-0 flex-col">
                        <div className="flex min-h-0 flex-auto flex-col px-8 pb-8 pt-6">
                            <div className="mb-2 flex flex-initial gap-2">
                                <h2 className="flex-auto text-lg text-gray-700">
                                    Settings
                                </h2>
                                {Number.isInteger(
                                    selectedClient?.fourbotId,
                                ) && (
                                    <Menu as="div" className="relative ml-3">
                                        {() => (
                                            <>
                                                <div>
                                                    <Menu.Button as={Button}>
                                                        Feeds & Reports
                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="opacity-0 scale-95"
                                                    enterTo="opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="opacity-100 scale-100"
                                                    leaveTo="opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {feedAndInventoryLinks.map(
                                                            (link) => (
                                                                <Menu.Item
                                                                    key={
                                                                        link.name
                                                                    }
                                                                >
                                                                    {({
                                                                        active,
                                                                    }) => (
                                                                        <Link
                                                                            className={classNames(
                                                                                active
                                                                                    ? 'bg-gray-500 text-white'
                                                                                    : 'text-gray-700',
                                                                                'block px-4 py-2 text-sm',
                                                                            )}
                                                                            to={link.to.replace(
                                                                                ':clientId',
                                                                                selectedClientId.toString(),
                                                                            )}
                                                                        >
                                                                            {
                                                                                link.name
                                                                            }
                                                                        </Link>
                                                                    )}
                                                                </Menu.Item>
                                                            ),
                                                        )}
                                                    </Menu.Items>
                                                </Transition>
                                            </>
                                        )}
                                    </Menu>
                                )}
                                {!isNewClient && (
                                    <LinkButton
                                        to={AppRoutes.EDIT_BUDGETS.replace(
                                            ':clientId',
                                            selectedClientId.toString(),
                                        )}
                                    >
                                        Edit Budgets
                                    </LinkButton>
                                )}
                            </div>
                            <div className="min-h-0 flex-1">
                                <FormWindow
                                    initialValues={initialValues}
                                    onSubmit={onSubmit}
                                    isSaving={save.isLoading}
                                    saveMessage={saveMessage}
                                    saveButtonText={
                                        isNewClient ? 'Create Client' : 'Save'
                                    }
                                >
                                    <Well>
                                        <StatusDisplay status={settingsStatus}>
                                            <div className="pb-4">
                                                <div className="pb-1 font-medium text-gray-800">
                                                    Client Info
                                                </div>
                                                <div className="grid grid-cols-3 gap-x-8 rounded-lg bg-white px-4 pt-4 pb-2 shadow">
                                                    <TextField
                                                        className="col-span-3 mb-5"
                                                        label="Client Name"
                                                        name="name"
                                                    />
                                                    <PersonSelectWithSearchField
                                                        className="col-span-3"
                                                        name="repId"
                                                        label="Budgeting Assignment"
                                                        people={users}
                                                    />
                                                    {isNewClient ? (
                                                        <TextField
                                                            className="col-span-3 mb-5"
                                                            label="18-Digit Salesforce ID or Account URL"
                                                            name="salesforceId"
                                                        />
                                                    ) : (
                                                        <div className="col-span-3 flex">
                                                            <DisabledField
                                                                className="flex-1"
                                                                name="salesforceId"
                                                                label="Salesforce ID"
                                                            />
                                                            <Field
                                                                name="salesforceId"
                                                                component={({
                                                                    input,
                                                                }) =>
                                                                    input.value ? (
                                                                        <div className="flex h-full flex-initial items-end pl-2 pb-6">
                                                                            <a
                                                                                className="block rounded-md text-cyan-500 ring-cyan-500 hover:text-cyan-700 focus:outline-none focus:ring-2"
                                                                                href={SALESFORCE_URL_TEMPLATE.replace(
                                                                                    ':salesforceId',
                                                                                    input.value,
                                                                                )}
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                <ArrowTopRightOnSquareIcon
                                                                                    className="h-8"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span className="sr-only">
                                                                                    Open
                                                                                    Salesforce
                                                                                    Client
                                                                                    Page
                                                                                </span>
                                                                            </a>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                    {!isNewClient ? (
                                                        <DisabledField
                                                            name="type"
                                                            label="Account Type"
                                                        />
                                                    ) : (
                                                        <SelectField
                                                            label="Account Type"
                                                            name="type"
                                                            options={
                                                                accountTypes
                                                            }
                                                            useIdAsValue={true}
                                                        />
                                                    )}
                                                    <Condition
                                                        when="type"
                                                        is="Auto"
                                                        children={
                                                            <>
                                                                <OptionalTextField
                                                                    className=""
                                                                    label="Dealer Code"
                                                                    name="dealerCode"
                                                                />
                                                                <div className="flex">
                                                                    <DisabledField
                                                                        className="flex-auto"
                                                                        label="Foureyes ID"
                                                                        name="foureyesId"
                                                                        emptyValue="Missing"
                                                                        emptyMessage="Add in Salesforce"
                                                                    />
                                                                    <PlatformLinkField
                                                                        name="foureyesId"
                                                                        platform="Foureyes"
                                                                    />
                                                                </div>
                                                                <SelectFieldWithSearch
                                                                    className="col-span-3"
                                                                    label={`Fourbot Mapping: ${
                                                                        selectedClient?.fourbotId ??
                                                                        ''
                                                                    }`}
                                                                    name="fourbotId"
                                                                    options={
                                                                        fourbotClients
                                                                    }
                                                                    useIdAsValue={
                                                                        true
                                                                    }
                                                                />
                                                            </>
                                                        }
                                                        childrenIfNot={
                                                            <>
                                                                <DisabledField
                                                                    className=""
                                                                    label="Dealer Code"
                                                                    name="dealerCode"
                                                                    showValue={
                                                                        false
                                                                    }
                                                                />
                                                                <div className="flex">
                                                                    <DisabledField
                                                                        className="flex-auto"
                                                                        label="Foureyes ID"
                                                                        name="foureyesId"
                                                                        emptyValue="Missing"
                                                                        emptyMessage="Add in Salesforce"
                                                                    />
                                                                    <PlatformLinkField
                                                                        name="foureyesId"
                                                                        platform="Foureyes"
                                                                    />
                                                                </div>
                                                                <DisabledField
                                                                    className="col-span-3"
                                                                    label="Fourbot Mapping"
                                                                    name="fourbotId"
                                                                    showValue={
                                                                        false
                                                                    }
                                                                />
                                                            </>
                                                        }
                                                    />
                                                </div>

                                                <div className="mt-6 pb-1 font-medium text-gray-800">
                                                    Account IDs
                                                </div>
                                                <div className="grid grid-cols-2 gap-x-8 rounded-lg bg-white px-4 pt-4 pb-2 shadow">
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="Google"
                                                            name="googleCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="googleCode"
                                                            platform={
                                                                Sources.GOOGLE
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="Facebook"
                                                            name="facebookCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="facebookCode"
                                                            platform={
                                                                Sources.FACEBOOK
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="Microsoft"
                                                            name="microsoftCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="microsoftCode"
                                                            platform={
                                                                Sources.MICROSOFT
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="StackAdapt"
                                                            name="stackAdaptCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="stackAdaptCode"
                                                            platform={
                                                                Sources.STACK_ADAPT
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="LinkedIn"
                                                            name="linkedInCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="linkedInCode"
                                                            platform={
                                                                Sources.LINKEDIN
                                                            }
                                                        />
                                                    </div>
                                                    <div className="flex">
                                                        <AccountIdField
                                                            className="mb-5 flex-auto"
                                                            label="TikTok"
                                                            name="tikTokCode"
                                                        />
                                                        <PlatformLinkField
                                                            name="tikTokCode"
                                                            platform={
                                                                Sources.TIKTOK
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {Number.isInteger(
                                                    selectedClient?.fourbotId,
                                                ) && (
                                                    <div className="grid grid-cols-2 gap-x-8">
                                                        <div>
                                                            <div className="mt-6 pb-1 font-medium text-gray-800">
                                                                Feeds
                                                            </div>
                                                            <div className="grid grid-cols-1 gap-x-8 gap-y-1 rounded-lg bg-white px-4 pt-4 pb-3 shadow">
                                                                <>
                                                                    <TextField
                                                                        className="col-span-2"
                                                                        label="Client Short Name"
                                                                        name="shortName"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2 mt-2"
                                                                        name="fourbotPrivateSource"
                                                                        label="Fourbot Private Source"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2 mt-2"
                                                                        name="automaticPricingEnabled"
                                                                        label="Automatic Pricing enabled"
                                                                    />
                                                                    <div className="mt-5 min-w-0 text-sm uppercase text-gray-500">
                                                                        Used
                                                                        Tool
                                                                    </div>
                                                                    <ToggleField
                                                                        className="col-span-2 pl-8"
                                                                        name="usedToolEnabled"
                                                                        label="Enabled"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2 pl-8"
                                                                        name="usedToolCertifiedOnly"
                                                                        label="Certified only"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2 pl-8"
                                                                        name="usedToolOnlyMyLocation"
                                                                        label="Only my location"
                                                                    />
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div className="mt-6 pb-1  font-medium text-gray-800">
                                                                Lead Handling
                                                            </div>
                                                            <div className="flex-auto rounded-lg bg-white px-4 pt-4 pb-3 shadow">
                                                                <div className="grid gap-y-1">
                                                                    <OptionalTextField
                                                                        className="col-span-2 mb-5"
                                                                        label="Facebook Page ID"
                                                                        name="facebookPageId"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2 -mt-2"
                                                                        name="tikTokLeadsEnabled"
                                                                        label="TikTok Leads enabled"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col">
                                                            <div className="mt-6 pb-1  font-medium text-gray-800">
                                                                Miscellaneous
                                                            </div>
                                                            <div className="flex-auto rounded-lg bg-white px-4 pt-4 pb-3 shadow">
                                                                <div className="grid gap-y-1">
                                                                    <ToggleField
                                                                        className="col-span-2"
                                                                        label="Parts/Service Only"
                                                                        name="partsServiceOnly"
                                                                    />
                                                                    <ToggleField
                                                                        className="col-span-2"
                                                                        label="Promo Ad Builder Enabled"
                                                                        name="promoAdBuilderEnabled"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </StatusDisplay>
                                    </Well>
                                </FormWindow>
                            </div>
                        </div>
                    </SectionCloud>
                ) : null}
            </ErrorBoundary>
        </div>
    )
}

export default Clients
