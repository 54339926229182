import { useGoogleLogin } from '@react-oauth/google'
import { refreshTokenSetup } from '../utility/Auth'
import { LoginStatus } from '../hooks/LoginInfo'
import PearApi from '../apis/pearApi'

const INVALID_ID_1 = -1
const INVALID_ID_2 = -2

// The child is expected to be some kind of button or thing to click.
export const Login = ({ setLoginInfo, children }) => {
    const onSuccess = async ({ code }) => {
        try {
            const userInfo = await PearApi.loginWithGoogleAuth(code)
            const { admin, avatar, email, id, name, team } = userInfo

            setLoginInfo({
                user: { admin, imageUrl: avatar, email, id, name, team },
                status: LoginStatus.loggedIn,
                googleTokenId: '',
            })

            // Refresh token functionality
            refreshTokenSetup(email)
        } catch (error) {
            console.error('Error during login: ', error)
            setLoginInfo({
                user: {
                    name: '',
                    email: '',
                    imageUrl: '',
                    id: INVALID_ID_2,
                    admin: false,
                },
                status: LoginStatus.error,
                googleTokenId: '',
            })
        }
    }

    const onFailure = (response) => {
        console.error('Login failed. Response:', response)
        setLoginInfo({
            user: {
                name: '',
                email: '',
                imageUrl: '',
                id: INVALID_ID_1,
                admin: false,
            },
            status: LoginStatus.error,
            googleTokenId: '',
        })
    }

    const googleLogin = useGoogleLogin({
        onSuccess,
        onError: onFailure,
        hosted_domain: 'adpearance.com',
        flow: 'auth-code',
    })

    return (
        <div
            onClick={() => googleLogin()}
            className="h-full w-full cursor-pointer"
        >
            {children}
        </div>
    )
}

export const Logout = ({ setLoginInfo, logout, children }) => {
    const onLogoutSuccess = () => {
        const runAsyncPart = async () => {
            try {
                await PearApi.logout()
                setLoginInfo({
                    user: undefined,
                    status: LoginStatus.loggedOut,
                    googleTokenId: '',
                })
            } catch {
                console.error('Logout failed.')
            }
            logout()
        }
        runAsyncPart()
    }

    const onLogoutClick = () => {
        onLogoutSuccess()
    }

    return (
        <div onClick={onLogoutClick} className="h-full w-full cursor-pointer">
            {children}
        </div>
    )
}
