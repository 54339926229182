import { createContext, useCallback, useContext, useState } from 'react'

export const LoginStatus = {
    loggedIn: 'Logged In',
    loggedOut: 'Logged Out',
    error: 'Error',
}

interface UserInfo {
    id: number
    name: string
    imageUrl: string
    admin: boolean
    team?: number[]
}

const defaultLoginInfo = {
    user: undefined as UserInfo,
    status: LoginStatus.loggedOut,
    message: '',
    setLoginInfo: (loginInfo: Partial<typeof defaultLoginInfo>) => {},
    logout: () => {},
    errorOut: (message) => {},
} as const

const loggedOutInfo = {
    user: undefined,
    status: LoginStatus.loggedOut,
    message: '',
}

const erroredOutInfo = {
    user: undefined,
    status: LoginStatus.error,
    message: 'Sorry, there was an authentication error.',
}

// This provides a custom context and hook for login info. The LoginInfoProvider
// is rendered high up in the app and all elements rendered below it can access
// the login info by using the useLoginInfo hook.
const LoginInfo = createContext(defaultLoginInfo)

export const useLoginInfo = () => useContext(LoginInfo)

export const LoginInfoProvider = ({ children }) => {
    const [loginInfo, setLoginInfo] = useState(defaultLoginInfo)

    const changeLoginInfo = useCallback(
        (newLoginInfo) => {
            const { googleTokenId, ...infoWithoutTokenId } = newLoginInfo
            if (googleTokenId) {
                sessionStorage.setItem('googleTokenId', googleTokenId)
            } else {
                sessionStorage.removeItem('googleTokenId')
            }
            const changedInfo = { ...loginInfo, ...infoWithoutTokenId }
            setLoginInfo(changedInfo)
            sessionStorage.setItem('loginInfo', JSON.stringify(changedInfo))
        },
        [loginInfo],
    )

    const logout = useCallback(() => {
        sessionStorage.removeItem('googleTokenId')
        const changedInfo = { ...loginInfo, ...loggedOutInfo }
        setLoginInfo(changedInfo)
        sessionStorage.setItem('loginInfo', JSON.stringify(changedInfo))
    }, [loginInfo])

    const errorOut = useCallback(
        (message = '') => {
            sessionStorage.removeItem('googleTokenId')
            const changedInfo = { ...loginInfo, ...erroredOutInfo }
            if (message !== '') {
                changedInfo.message = message
            }
            setLoginInfo(changedInfo)
            sessionStorage.setItem('loginInfo', JSON.stringify(changedInfo))
        },
        [loginInfo],
    )

    return (
        <LoginInfo.Provider
            value={{
                ...loginInfo,
                setLoginInfo: changeLoginInfo,
                logout,
                errorOut,
            }}
        >
            {children}
        </LoginInfo.Provider>
    )
}
