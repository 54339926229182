import { classNames } from '../../utility/Components'

export const pacingTextColor = (percentage, darker = false) => {
    return Math.abs(percentage) <= 5
        ? 'text-green-600'
        : Math.abs(percentage) <= 15
        ? 'text-lime-600'
        : Math.abs(percentage) <= 25
        ? 'text-orange-500'
        : 'text-rose-500'
}

const pacingColor = (percentage, darker = false) =>
    Math.abs(percentage) <= 5
        ? darker
            ? 'bg-green-200 text-green-800'
            : 'bg-green-100 text-green-700'
        : Math.abs(percentage) <= 15
        ? darker
            ? 'bg-lime-200 text-lime-800'
            : 'bg-lime-100 text-lime-800'
        : Math.abs(percentage) <= 25
        ? darker
            ? 'bg-orange-200 text-orange-800'
            : 'bg-orange-100 text-orange-700'
        : darker
        ? 'bg-rose-200 text-rose-800'
        : 'bg-rose-100 text-rose-700'

const pacingBorder = (percentage) =>
    Math.abs(percentage) <= 5
        ? 'border border-green-600'
        : Math.abs(percentage) <= 15
        ? 'border border-lime-600'
        : Math.abs(percentage) <= 25
        ? 'border border-orange-500'
        : 'border border-rose-500'

export const pacingColorAndBorder = (percentage, darker = false) =>
    classNames(pacingColor(percentage, darker), pacingBorder(percentage))

export const pacingColorAndShape = (
    percentage,
    darker = false,
    isSmall = false,
) => {
    const colors = pacingColor(percentage, darker)

    const borders = pacingBorder(percentage)

    const shapes =
        Math.abs(percentage) <= 5
            ? 'rounded-full'
            : Math.abs(percentage) <= 15
            ? isSmall
                ? 'rounded-xl'
                : 'rounded-2xl'
            : Math.abs(percentage) <= 25
            ? 'rounded-lg'
            : ''

    return classNames(colors, borders, shapes)
}
