import { useUsedToolStatus } from '../hooks/apiHooks'
import { FeedTemplate } from '../components/Feeds/Clients'

const UsedToolStatus = () => {
    const { data, status } = useUsedToolStatus()

    return FeedTemplate('Used Tool Status', data, status)
}

export default UsedToolStatus
