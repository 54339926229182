import { dollarFormat, percentageFormat } from '../../utility/Formatting'

export const csvHeaders = [
    {
        label: 'Budgeter',
        key: 'repName',
    },
    {
        label: 'Strategist',
        key: 'strategistName',
    },
    {
        label: 'RSM',
        key: 'salesManagerName',
    },
    {
        label: 'Dealer Code',
        key: 'code',
    },
    {
        label: 'Client Name',
        key: 'clientName',
    },
    {
        label: 'Type',
        key: 'type',
    },
    {
        label: 'OEM',
        key: 'oem',
    },
    {
        label: 'Salesforce Account Id',
        key: 'salesforceId',
    },
    {
        label: 'Product Name',
        key: 'productName',
    },
    {
        label: 'Promo Name',
        key: 'promoName',
    },
    {
        label: 'Source',
        key: 'source',
    },
    {
        label: 'Ad Account Id',
        key: 'adAccountId',
    },
    {
        label: 'Label',
        key: 'label',
    },
    {
        label: 'Spend',
        key: 'total',
    },
    {
        label: 'Asset ID',
        key: 'assetId',
    },
    {
        label: 'Total Budget',
        key: 'budget',
    },
    {
        label: 'Budget',
        key: 'budgetOnly',
    },
    {
        label: 'Rollover',
        key: 'adjustment',
    },
    {
        label: 'Pacing',
        key: 'latestPacingAmount',
    },
    {
        label: 'Pacing %',
        key: 'latestPercent',
    },
    {
        label: 'Pacing % (-1 day)',
        key: 'dayBeforePercent',
    },
    {
        label: 'Start Date',
        key: 'startDate',
    },
    {
        label: 'End Date',
        key: 'endDate',
    },
    {
        label: 'Ads On Weekends',
        key: 'adsOnWeekends',
    },
]

export const csvFormattedData = (budgets, userNamesById) => {
    const formattedBudgets = budgets.map((lineItem) => {
        const {
            adAccountId,
            repId,
            strategistId,
            salesManagerId,
            code,
            total,
            budget,
            budgetOnly,
            adjustment,
            assetId,
            latestPercent,
            dayBeforePercent,
            startDate,
            endDate,
            adsOnWeekends,
        } = lineItem
        const latestPacingAmount = ((100 + latestPercent) / 100) * budget

        return {
            ...lineItem,
            adAccountId: '=""' + adAccountId + '""',
            repName: userNamesById?.[repId] ?? '',
            strategistName: userNamesById?.[strategistId] ?? '',
            salesManagerName: userNamesById?.[salesManagerId] ?? '',
            code: code ?? '',
            assetId: assetId,
            total: dollarFormat(total),
            budget: dollarFormat(budget),
            budgetOnly: dollarFormat(budgetOnly),
            adjustment: dollarFormat(adjustment),
            latestPercent: percentageFormat(latestPercent),
            latestPacingAmount: dollarFormat(latestPacingAmount),
            dayBeforePercent: percentageFormat(dayBeforePercent),
            startDate: startDate ?? '',
            endDate: endDate ?? '',
            adsOnWeekends: adsOnWeekends ?? '',
        }
    })
    return formattedBudgets
}
