import { classNames } from '../../utility/Components'
import { percentageFormat } from '../../utility/Formatting'
import { pacingColorAndShape } from './DisplayFormatting'

export const pacingColorName = (percentage) =>
    Math.abs(percentage) <= 5
        ? 'green'
        : Math.abs(percentage) <= 15
        ? 'lime'
        : Math.abs(percentage) <= 25
        ? 'orange'
        : 'rose'

export const PacingCell = ({
    percentage,
    isSelected = false,
    isSmall = false,
}) => (
    <div
        className={classNames(
            'h-full w-full py-1.5 text-center',
            pacingColorAndShape(percentage, isSelected, isSmall),
        )}
    >
        <span className="sr-only">{pacingColorName(percentage)} </span>
        <span className={classNames('text-xs font-medium')}>
            {percentageFormat(percentage)}
        </span>
    </div>
)
