import { SectionCloud } from '../components/Layout'
import pearWorm from '../images/pear-worm.png'

const PageNotFound = () => (
    <div className="mx-auto flex h-full w-3/4 flex-col justify-center">
        <SectionCloud className="flex items-center px-8 pt-8 pb-12">
            <img className="h-96" src={pearWorm} alt="Worm eating a pear" />
            <div className="ml-8 mt-8">
                <p className="mb-2 text-left text-5xl font-semibold text-gray-700">
                    Whoops!
                </p>
                <p className="mb-8 text-left text-3xl text-gray-700">
                    It looks like there's a bug in our system.
                </p>
                <p className="text-left text-xl text-gray-700">
                    Sorry about that. Try going back or choosing one of the nav
                    items at the top.
                </p>
            </div>
        </SectionCloud>
    </div>
)

export default PageNotFound
