import { useState, useEffect, useRef, ReactNode } from 'react'
import { CSVLink } from 'react-csv'

interface HeaderType {
    label: string
    key: string
}
interface CsvLinkProps {
    className?: string
    children?: ReactNode
    data: string[] | [][] | object[] | Function
    filename?: string
    disable?: boolean
    headers?: HeaderType[]
    label?: string
}

/**
 * This component is a wrapper for CSVLink from react-csv. The library currently doesn't
 * fully support data provider functions as indicated in the docs. This component
 * works some magic to make that feature work. It has the same API as the library
 * component except that it also takes className, but it may not fully work with
 * all ways the original could (or should be able to) be used. Based on an example
 * from sebacampos here: https://github.com/react-csv/react-csv/issues/189#issuecomment-615955022.
 * If you need more props/options from the original component, add them as needed.
 */
const CsvLink = ({
    className,
    data,
    children,
    filename,
    headers,
}: CsvLinkProps) => {
    const [csvData, setCsvData]: any[] = useState([])
    const csvLinkInstance = useRef<any | null>(null)

    const getData = () => {
        setCsvData(typeof data === 'function' ? data() : data)
    }

    useEffect(() => {
        if (
            csvData.length > 0 &&
            csvLinkInstance &&
            csvLinkInstance.current &&
            csvLinkInstance.current.link
        ) {
            setTimeout(() => {
                csvLinkInstance.current.link.click()
                setCsvData([])
            })
        }
    }, [csvData])

    return (
        <>
            <button
                className={className}
                type="button"
                onClick={() => {
                    getData()
                }}
            >
                {children}
            </button>
            {csvData.length > 0 ? (
                <CSVLink
                    className="hidden"
                    data={csvData}
                    headers={headers}
                    filename={filename || 'Pear - Data.csv'}
                    ref={csvLinkInstance}
                    aria-hidden="true"
                />
            ) : undefined}
        </>
    )
}

export default CsvLink
