import { format } from 'date-fns-tz'
import { DateTime } from 'luxon'

export const dollarFormat = (dollars, decimalPlaces = 2) =>
    `$${dollars.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })}`

export const dollarChangeFormat = (dollars) => {
    const sign = dollars > 0 ? '+' : '-'
    return `${sign}${dollarFormat(Math.abs(dollars))}`
}

export const formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' Bytes'

    const kilobytes = bytes / 1024
    if (kilobytes < 1024) return kilobytes.toFixed(2) + ' KB'

    const megabytes = kilobytes / 1024
    return megabytes.toFixed(2) + ' MB'
}

export const percentageFormat = (
    percentage,
    displayPlusSign = true,
    decimalPlaces = 1,
) => {
    const displayValue = `${percentage.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })}%`
    return displayPlusSign && percentage > 0 ? '+' + displayValue : displayValue
}

export const numberFormat = (number, decimalPlaces = 0) =>
    number.toLocaleString('en-US', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    })

export const isoDay = (date: Date) => format(date, 'yyyy-MM-dd')

export const isoMonth = (date: Date) => format(date, 'yyyy-MM')

export const timeZoneNameFormat = (ianaTimeZone) =>
    format(new Date(), 'zzz', { timeZone: ianaTimeZone })

export const formatUTCDate = (dateString: String) => {
    // using 'yyyy-MM-dd'
    if (!dateString) return null
    const luxonDate = DateTime.fromISO(dateString)
    return new Date(luxonDate.toUTC())
}

export const typeSortFormat = (
    type:
        | 'Auto'
        | 'Capital Equipment'
        | 'Custom'
        | 'New Industries'
        | 'Jacuzzi',
    oem?: string,
) => {
    if (type === 'Custom' || type === 'New Industries') {
        return 'Custom'
    }
    if (type === 'Capital Equipment') {
        return 'Cap Eq'
    }
    if (type === 'Jacuzzi') {
        return 'AA_Jacuzzi'
    }

    const certifiedOems = {
        Acura: 'Acura',
        'Alfa Romeo': 'Alfa Romeo',
        Stellantis: 'Chrysler',
        Fiat: 'Fiat',
        Honda: 'Honda',
        Jaguar: 'JLR',
        'Land Rover': 'JLR',
        Kia: 'Kia',
        Maserati: 'Maserati',
        'Mercedes-Benz': 'Mercedes-Benz',
        Toyota: 'Toyota',
        Volkswagen: 'Volkswagen',
    }

    const oemLabel = certifiedOems[oem] ?? false
    if (oemLabel) {
        return `AA_${oemLabel}`
    }

    return `AAA_Auto`
}
