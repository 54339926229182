import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

/**
 * Allows reading and changing of a URL query parameter independently from
 * any other parameters. Useful for using the parameter as a source of truth (state).
 * @param {string} paramName Name of the parameter.
 * @param {string} nullValue Value to be returned if the parameter is not in the URL query and to be
 * used to remove the parameter if received as the new value. Defaults to the empty string.
 */
export const useUrlQueryParameter = (
    paramName: string,
    nullValue: string = '',
    validated?: (urlValue: string) => string,
) => {
    const [params, setParams] = useSearchParams()

    const safeParamName = paramName
    const thisParam = params.get(safeParamName) ?? nullValue
    const validParam = validated ? validated(thisParam) : thisParam

    const setThisParam = useCallback(
        (newValue) => {
            // Get the current parameters directly to avoid synchronicity issues.
            const { search } = window.location
            const currentParams = new URLSearchParams(search)

            if (newValue === nullValue) {
                currentParams.delete(safeParamName)
            } else {
                currentParams.set(safeParamName, newValue)
            }
            currentParams.sort()
            setParams(currentParams, { replace: true })
        },
        [nullValue, safeParamName, setParams],
    )

    // Reset invalid parameters to validated param (if validation happened)
    if (validParam !== thisParam) {
        setThisParam(validParam)
    }

    return [validParam, setThisParam] as [string, (value: string) => void]
}
