import { Form } from 'react-final-form'
import { Button, TextButton } from '../elements/Buttons'

const FormWindow = ({
    onSubmit,
    initialValues = undefined,
    mutators = undefined,
    isSaving,
    saveMessage,
    saveButtonText = 'Save',
    // bottomLabel can either be a React element or a component that accepts
    // the values prop from Form's render function
    bottomLabel: BottomLabel = null,
    // children can either be a React element per usual or a component that
    // accepts the values prop from Form's render function.
    // This renaming is to allow children to be used as a component.
    children: Children,
    className = 'h-full',
    validate = undefined,
}) => (
    <Form
        onSubmit={onSubmit}
        mutators={mutators}
        initialValues={initialValues}
        validate={validate}
        render={({
            form,
            handleSubmit,
            invalid,
            pristine,
            submitting,
            values,
        }) => (
            <form
                noValidate={true}
                onSubmit={handleSubmit}
                className={`flex flex-col ${className}`}
            >
                <div className="min-h-0 flex-1">
                    {typeof Children === 'function' ? (
                        <Children values={values} />
                    ) : (
                        Children
                    )}
                </div>
                <div className="mt-4 flex min-h-0 flex-initial">
                    <div className="min-w-0 flex-1">
                        <Button
                            disabled={
                                submitting || invalid || pristine || isSaving
                            }
                            type="submit"
                        >
                            {saveButtonText}
                        </Button>
                        <TextButton
                            className="ml-4"
                            onClick={form.reset}
                            disabled={submitting || pristine || isSaving}
                        >
                            Reset
                        </TextButton>
                        {saveMessage.message && (
                            <span
                                className={`ml-3 rounded-full px-3 py-1 ${
                                    saveMessage.type === 'success'
                                        ? 'bg-green-100 text-green-900'
                                        : 'bg-orange-200 text-orange-900'
                                }`}
                            >
                                {saveMessage.message}
                            </span>
                        )}
                    </div>
                    <div className="min-w-0 flex-initial">
                        {typeof BottomLabel === 'function' ? (
                            <BottomLabel values={values} />
                        ) : (
                            BottomLabel
                        )}
                    </div>
                </div>
            </form>
        )}
    />
)

export default FormWindow
