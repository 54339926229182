import { useEffect, useRef, useState } from 'react'
import {
    getDate,
    getDaysInMonth,
    getMonth,
    isSameMonth,
    isWeekend,
    parseISO,
    setDate,
} from 'date-fns'
import { LineItem } from '../../apis/pearApi'
import {
    advertisingDaysInInterval,
    advertisingDaysLeftInInterval,
    budgetInterval,
    dateToUse,
    DayPacing,
    latestWeekdayInInterval,
    today,
} from '../../utility/BudgetCalculations'
import { dollarFormat, percentageFormat } from '../../utility/Formatting'
import { PacingByDateChart } from './PacingByDate'
import { SpendsByDateChart } from './SpendsByDate'
import { range } from '../../utility/Functions'
import { isWithin } from '../../utility/Time'
import { classNames } from '../../utility/Components'

export const dayOnly = (dateString: string): number =>
    parseInt(dateString.substring(8, 10), 10)

export const SpendsByDateTooltip = ({
    day,
    spend,
    average,
    pacingPercent,
    latestDay,
    month,
}: {
    day: number
    spend: number
    average: number
    pacingPercent: number
    latestDay: number
    month: string
}) => {
    const isLatestDay = day === latestDay
    const monthNumber = getMonth(parseISO(month)) + 1
    const monthISO =
        monthNumber < 10
            ? '0' + monthNumber.toString(10)
            : monthNumber.toString(10)
    const dayISO = day < 10 ? '0' + day.toString(10) : day.toString(10)
    const displayDate = monthISO + '-' + dayISO

    return (
        <div
            className={`pointer-events-none w-64 overflow-hidden rounded-lg bg-white opacity-95 shadow-md`}
            role="alert"
        >
            <div
                className={`${
                    isLatestDay ? 'bg-cyan-300' : 'bg-gray-300'
                } relative rounded-t-lg py-2 px-4`}
            >
                <div
                    className={`${
                        isLatestDay ? 'text-cyan-900' : 'text-gray-800'
                    } text-lg`}
                    aria-hidden="true"
                >
                    Pacing & Spend
                </div>
                <div className="absolute right-4 -bottom-6 z-10 flex h-12 flex-col content-center justify-center rounded-full border-2 border-gray-300 bg-white px-3 shadow-sm">
                    <div className="text-center text-lg font-bold tracking-wide text-gray-600">
                        {displayDate}
                    </div>
                </div>
            </div>
            <div className="px-4 pt-4 pb-2">
                <span className="text-2xl font-bold text-gray-600">
                    {pacingPercent !== -200
                        ? percentageFormat(pacingPercent, true, 1)
                        : '–'}
                </span>
            </div>
            <div className="px-4 pb-2">
                <span className="text-2xl font-bold text-gray-600">
                    {spend !== 0 ? dollarFormat(spend) : '–'}
                </span>
            </div>
            <div className="px-4 pb-4">
                <span className="font-semibold text-gray-600">
                    {average !== 0 ? dollarFormat(average) : '–'}
                </span>
                <span className="text-gray-700"> baseline average</span>
            </div>
        </div>
    )
}

const chartTrappedKeys = [
    'ArrowLeft',
    'ArrowRight',
    'ArrowUp',
    'ArrowDown',
    'Home',
    'End',
    'Escape',
]

export const NO_DAY_SELECTED = 0

const SpendAndPacingCharts = ({
    lineItem,
    month,
    pacingDetails,
}: {
    pacingDetails: DayPacing[]
    month: string
    lineItem: LineItem
}) => {
    const [selectedDay, unsafeSetSelectedDay] =
        useState<number>(NO_DAY_SELECTED)
    const {
        dailySpends: spends,
        budget,
        adsOnWeekends,
        startDate,
        endDate,
    } = lineItem
    const [firstDate, lastDate] = budgetInterval(month, startDate, endDate)
    const setSelectedDay = (possibleDay) =>
        unsafeSetSelectedDay((prevDay) => {
            if (possibleDay === NO_DAY_SELECTED) {
                return possibleDay
            }
            let dayToCheck = possibleDay
            if (typeof possibleDay === 'function') {
                dayToCheck = possibleDay(prevDay)
            }
            const possibleDate = setDate(firstDate, dayToCheck)
            const safeDate =
                possibleDate < firstDate
                    ? firstDate
                    : possibleDate > lastDate
                    ? lastDate
                    : possibleDate
            const safeDay = getDate(safeDate)
            return safeDay
        })
    const lastDay = getDaysInMonth(parseISO(month))

    const doKeyNavigation = (event) => {
        const { key } = event

        if (chartTrappedKeys.includes(key)) {
            event.preventDefault()
        }

        setSelectedDay((prevDay) =>
            (key === 'ArrowLeft' || key === 'ArrowUp') && prevDay > 1
                ? prevDay - 1
                : (key === 'ArrowRight' || key === 'ArrowDown') &&
                  prevDay < lastDay
                ? prevDay + 1
                : key === 'Home'
                ? 1
                : key === 'End'
                ? lastDay
                : key === 'Escape'
                ? 0
                : prevDay,
        )
    }

    const graphPos = useRef({
        left: 0,
        top: 0,
        width: 0,
    })
    // These are to make the useRef value act like useState - without updating the component.
    const graphPosition = graphPos.current
    const setGraphPosition = (value) => {
        graphPos.current = value
    }

    const graphRef = useRef(null)
    useEffect(() => {
        if (graphRef.current) {
            const currentRect = graphRef.current.getBoundingClientRect()
            const { left, top, width } = currentRect
            setGraphPosition({
                left,
                top,
                width,
            })
        }
    })

    const [mousePoint, setMousePoint] = useState({ x: 0, y: 0 })
    const updateMousePoint = (event) => {
        const left = event.nativeEvent.clientX - graphPosition.left
        const top = event.nativeEvent.clientY - graphPosition.top
        setMousePoint({
            x: left,
            y: top,
        })
    }
    const resetMousePoint = () =>
        setMousePoint({
            x: graphPosition.width - 128,
            y: 0,
        })

    const dayToUse = dateToUse(adsOnWeekends, firstDate, lastDate)
    const day = adsOnWeekends
        ? dayToUse
        : latestWeekdayInInterval(firstDate, lastDate, dayToUse)
    const currentDayNumber = getDate(day)

    const spendDaysInMonth = advertisingDaysInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
    )
    const baselineAverage = budget / spendDaysInMonth

    // Determine if we need to fill future days or not.
    const daysInMonth = getDaysInMonth(day)
    const datesToChart = range(daysInMonth, 1)
    const spendsSoFar = spends.filter(isWithin(firstDate, day))
    const totalSpent = spendsSoFar.reduce((acc, spend) => acc + spend.spend, 0)
    const spendDaysLeftInMonth = advertisingDaysLeftInInterval(
        firstDate,
        lastDate,
        adsOnWeekends,
        dayToUse,
    )
    const suggestedGoal =
        totalSpent >= budget ? 0 : (budget - totalSpent) / spendDaysLeftInMonth
    const spendsMap = datesToChart.reduce((map, dayNumber) => {
        const thisDaysSpend = spendsSoFar.find(
            (spend) => getDate(parseISO(spend.date)) === dayNumber,
        )
        map[dayNumber] =
            thisDaysSpend?.spend ??
            ((dayNumber > currentDayNumber ||
                (dayNumber === getDate(today()) &&
                    isSameMonth(today(), firstDate))) &&
            dayNumber <= getDate(lastDate)
                ? suggestedGoal
                : 0)
        return map
    }, {})

    return (
        <div
            ref={graphRef}
            className="relative flex h-full flex-col rounded-md focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            tabIndex={0}
            onKeyDown={doKeyNavigation}
            onMouseMove={updateMousePoint}
            onMouseLeave={resetMousePoint}
            onBlur={() => setSelectedDay(0)}
            aria-label="Pacing and Spend Charts"
        >
            <div
                className={classNames(
                    'absolute z-10',
                    selectedDay !== NO_DAY_SELECTED ? '' : 'hidden',
                )}
                style={{ left: mousePoint.x + 20, top: mousePoint.y - 100 }}
            >
                <SpendsByDateTooltip
                    day={selectedDay}
                    spend={spendsMap[selectedDay] ?? 0}
                    average={
                        !adsOnWeekends &&
                        isWeekend(setDate(dayToUse, selectedDay))
                            ? 0
                            : baselineAverage
                    }
                    pacingPercent={
                        pacingDetails.find(
                            (pacing) => getDate(pacing.date) === selectedDay,
                        )?.pacingPercent ?? -200
                    }
                    latestDay={currentDayNumber}
                    month={month}
                />
            </div>
            <div className="max-h-32 flex-1">
                <PacingByDateChart
                    pacingDetails={pacingDetails}
                    lineItem={lineItem}
                    month={month}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                />
            </div>
            <div className="max-h-80 min-h-0 flex-1">
                <SpendsByDateChart
                    lineItem={lineItem}
                    month={month}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                />
            </div>
        </div>
    )
}

export default SpendAndPacingCharts
