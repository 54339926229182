/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useMemo, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Option } from '../forms/Fields'
import { haveSameContents } from '../../utility/Arrays'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const PeopleGrouping = {
    NO_SELECTION: {
        id: -2,
        name: 'No Selection',
        avatar: '',
    },
    ALL: {
        id: -1,
        name: 'Anyone',
        avatar: '',
    },
    MY_TEAM: {
        id: -3,
        name: 'My Team',
        avatar: '',
    },
    NONE: {
        id: 0,
        name: 'No One',
        avatar: '',
    },
}

/**
 * Takes a list of people objects and an optional id from a person in that list to be
 * initially selected in a dropdown with a search feature. Requires an onChange handler
 * which gets a newly selected person object. className is also supported, but not required.
 *
 * @param props
 * people (the list of objects),
 * initiallySelectedId (must point to an object in the list),
 * onChange (gets the newly selected person's object),
 * className
 * @returns An element for selecting a person from a list with a search feature.
 */
export function PersonSelectWithSearch({
    onChange,
    people = [],
    initiallySelectedId = PeopleGrouping.ALL.id,
    className = undefined,
    includeNoOne = false,
}) {
    const peopleGroupings = useMemo(
        () =>
            includeNoOne
                ? [PeopleGrouping.ALL, PeopleGrouping.NONE]
                : [PeopleGrouping.ALL],
        [includeNoOne],
    )
    const initialList = peopleGroupings.concat(
        people.sort((a, b) => {
            if (a.name < b.name) {
                return -1
            }
            if (a.name > b.name) {
                return 1
            }
            return 0
        }),
    )
    const [listWithGroupSelects, setListWithGroupSelects] =
        useState(initialList)

    const [search, setSearch] = useState('')

    useEffect(() => {
        const newList = peopleGroupings.concat(
            people
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
                .filter((person) => person.name.toLowerCase().includes(search)),
        )
        setListWithGroupSelects(newList)
    }, [people, search, peopleGroupings])

    const [selected, setSelected] = useState(listWithGroupSelects[0])

    const changeIt = (person) => {
        setSelected(person)
        onChange(person)
    }

    useEffect(() => {
        if (initiallySelectedId !== PeopleGrouping.ALL.id) {
            const selectedPerson = listWithGroupSelects.find(
                (person) => person.id === initiallySelectedId,
            )
            if (selectedPerson) {
                setSelected(selectedPerson)
            }
        }
    }, [initiallySelectedId, listWithGroupSelects])

    return (
        <Combobox
            as="div"
            className={className}
            value={selected}
            onChange={changeIt}
        >
            <Combobox.Label className="block text-sm font-medium text-gray-700">
                Assigned to
            </Combobox.Label>
            <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 flex w-12 items-center justify-center">
                    {selected.name === PeopleGrouping.ALL.name ||
                    !selected.avatar ? (
                        <div
                            className="h-6 w-6 shrink-0 rounded-full bg-gray-300"
                            aria-hidden="true"
                        >
                            {' '}
                        </div>
                    ) : (
                        <img
                            src={selected.avatar}
                            alt=""
                            className="h-6 w-6 shrink-0 rounded-full brightness-105 grayscale"
                        />
                    )}
                </div>
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-12 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                    displayValue={(option: Option) => {
                        return option.name
                    }}
                    onChange={(event) => {
                        setSearch(event.target.value.toLowerCase())
                    }}
                    onFocus={(event) => {
                        // Cause input text to be selected when clicked to
                        // allow for easy removal of existing text like
                        // the keyboard focus works by default. */}
                        requestAnimationFrame(() => {
                            event.target.setSelectionRange(
                                0,
                                event.target.value.length,
                            )
                        })
                    }}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </Combobox.Button>

                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {listWithGroupSelects.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                className={({ active }) =>
                                    classNames(
                                        active
                                            ? 'bg-cyan-600 text-white'
                                            : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                    )
                                }
                                value={person}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <div className="flex items-center">
                                            {person.name ===
                                                PeopleGrouping.ALL.name ||
                                            !person.avatar ? (
                                                <div className="h-6 w-6 shrink-0 rounded-full bg-gray-300">
                                                    {' '}
                                                </div>
                                            ) : (
                                                <img
                                                    src={person.avatar}
                                                    alt=""
                                                    className="h-6 w-6 shrink-0 rounded-full brightness-105 grayscale"
                                                />
                                            )}
                                            <span
                                                className={classNames(
                                                    selected
                                                        ? 'font-semibold'
                                                        : 'font-normal',
                                                    'ml-3 block truncate',
                                                )}
                                            >
                                                {person.name}
                                            </span>
                                        </div>

                                        {selected ? (
                                            <span
                                                className={classNames(
                                                    active
                                                        ? 'text-white'
                                                        : 'text-cyan-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                )}
                                            >
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}

/**
 * Takes a list of people objects and an optional id array from a people in that list to be
 * initially selected in a dropdown with a search feature. Requires an onChange handler
 * which gets a newly selected person object array. className is also supported, but not required.
 *
 * @param props
 * people (the list of objects),
 * initiallySelectedIds (must point to objects in the list),
 * myTeam (list of ids to use for "My Team" option),
 * onChange (gets the newly selected people objects),
 * className
 * @returns An element for selecting people from a list with a search feature.
 */
export function PersonMultiselectWithSearch({
    onChange,
    people = [],
    initiallySelectedIds = null as any[] | null,
    myTeamIds = [],
    className = undefined,
}) {
    if (initiallySelectedIds === null) {
        initiallySelectedIds = people
    }

    const hasTeam = myTeamIds.length > 0
    const initialList = people.sort((a, b) => {
        if (a.name < b.name) {
            return -1
        }
        if (a.name > b.name) {
            return 1
        }
        return 0
    })
    const [list, setList] = useState(initialList)

    const [search, setSearch] = useState('')

    useEffect(() => {
        const newList = people
            .sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
            .filter((person) => person.name.toLowerCase().includes(search))

        setList(newList)
    }, [people, search])

    const [selected, setSelected] = useState(list)

    const changeIt = (newPeople) => {
        // Catch id codes for all, my team, and none options.
        const updatedPeople = newPeople.some(
            ({ id }) => id === PeopleGrouping.ALL.id,
        )
            ? people
            : newPeople.some(({ id }) => id === PeopleGrouping.MY_TEAM.id)
            ? people.filter(({ id }) => myTeamIds.includes(id))
            : newPeople.some(({ id }) => id === PeopleGrouping.NONE.id)
            ? []
            : newPeople

        // Update internal state and run external onChange function
        setSelected(updatedPeople)
        onChange(updatedPeople)
    }

    useEffect(() => {
        const selectedPeople = list.filter((person) =>
            initiallySelectedIds.includes(person.id),
        )
        setSelected(selectedPeople)
    }, [initiallySelectedIds, list])

    return (
        <Combobox
            as="div"
            className={className}
            value={selected}
            onChange={changeIt}
            multiple={true}
        >
            <Combobox.Label className="block text-sm font-medium text-gray-700">
                Assigned to
            </Combobox.Label>
            <div className="relative mt-1">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-4 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-sm"
                    displayValue={(
                        selectedPeople: {
                            id: number
                            name: string
                            avatar: string
                        }[],
                    ) => {
                        if (search !== '') {
                            return search
                        }
                        if (selectedPeople.length === 0) {
                            return 'No one'
                        }

                        const selectedIds = selectedPeople.map(
                            (person) => person.id,
                        )

                        if (
                            hasTeam &&
                            haveSameContents(selectedIds, myTeamIds)
                        ) {
                            return 'My Team'
                        }

                        const peopleIds = people.map((person) => person.id)
                        if (haveSameContents(selectedIds, peopleIds)) {
                            return 'Anyone'
                        }

                        const numOfPeople = selectedPeople.length
                        return `${
                            numOfPeople === 1
                                ? '1 person'
                                : `${numOfPeople} people`
                        } selected`
                    }}
                    onChange={(event) => {
                        setSearch(event.target.value.toLowerCase())
                    }}
                    onFocus={(event) => {
                        // Cause input text to be selected when clicked to
                        // allow for easy removal of existing text like
                        // the keyboard focus works by default. */}
                        requestAnimationFrame(() => {
                            event.target.setSelectionRange(
                                0,
                                event.target.value.length,
                            )
                        })
                    }}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                </Combobox.Button>

                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.values(PeopleGrouping)
                            .filter(
                                ({ id }) =>
                                    id !== PeopleGrouping.NO_SELECTION.id &&
                                    (hasTeam ||
                                        id !== PeopleGrouping.MY_TEAM.id),
                            )
                            .map((grouping) => (
                                <Combobox.Option
                                    key={grouping.id}
                                    className={({ active }) =>
                                        classNames(
                                            active
                                                ? 'bg-cyan-600 text-white'
                                                : 'text-gray-900',
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                        )
                                    }
                                    value={grouping}
                                >
                                    <div className="flex items-center">
                                        <div className="h-6 w-6 shrink-0 rounded-full bg-gray-300">
                                            {' '}
                                        </div>
                                        <span
                                            className={classNames(
                                                'font-normal',
                                                'ml-3 block truncate',
                                            )}
                                        >
                                            {grouping.name}
                                        </span>
                                    </div>
                                </Combobox.Option>
                            ))}
                        {list.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                className={({ active }) =>
                                    classNames(
                                        active
                                            ? 'bg-cyan-600 text-white'
                                            : 'text-gray-900',
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                    )
                                }
                                value={person}
                            >
                                {({ selected, active }) => (
                                    <>
                                        <div className="flex items-center">
                                            {person.name ===
                                                PeopleGrouping.ALL.name ||
                                            !person.avatar ? (
                                                <div className="h-6 w-6 shrink-0 rounded-full bg-gray-300">
                                                    {' '}
                                                </div>
                                            ) : (
                                                <img
                                                    src={person.avatar}
                                                    alt=""
                                                    className="h-6 w-6 shrink-0 rounded-full brightness-105 grayscale"
                                                />
                                            )}
                                            <span
                                                className={classNames(
                                                    selected
                                                        ? 'font-semibold'
                                                        : 'font-normal',
                                                    'ml-3 block truncate',
                                                )}
                                            >
                                                {person.name}
                                            </span>
                                        </div>

                                        {selected ? (
                                            <span
                                                className={classNames(
                                                    active
                                                        ? 'text-white'
                                                        : 'text-cyan-600',
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                )}
                                            >
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}
