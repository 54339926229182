import { memo, useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { Form } from 'react-final-form'
import { useTable } from 'react-table'
import { SectionCloud } from '../components/Layout'
import { CirclePlusButton, TrashButton } from '../components/elements/Buttons'
import {
    Option,
    SelectField,
    SelectFieldWithSearch,
    TextField,
} from '../components/forms/Fields'
import { classNames } from '../utility/Components'
import StatusDisplay from '../components/StatusDisplay'
import {
    invalidateInventoryTranslations,
    useAutoClients,
    useInventoryTranslations,
} from '../hooks/apiHooks'
import PearApi, { FeedAndClientInventoryTranslations } from '../apis/pearApi'

const spacings = {
    clientId: 'flex-1 min-w-0',
    feed: 'w-48',
    field: 'w-36',
    fourbotValue: 'w-56',
    matchType: 'w-36',
    replacement: 'w-56',
}

const columns = [
    {
        Header: 'Client',
        accessor: 'clientId',
        Cell: ({ value: clientId, clientInfoIndex }) =>
            clientId === null
                ? 'All Clients'
                : clientInfoIndex?.[clientId] ?? clientId.toString(),
    },
    {
        Header: 'Feed',
        accessor: 'feed',
        Cell: ({ value: feed }) => (feed === '' ? 'All Feeds' : feed),
    },
    {
        Header: 'Field',
        accessor: 'field',
    },
    {
        Header: 'Fourbot Value',
        accessor: 'fourbotValue',
    },
    {
        Header: 'Match Type',
        accessor: 'matchType',
    },
    {
        Header: 'Replacement',
        accessor: 'replacement',
    },
]

const allClientsOption = { id: 0, name: 'All Clients' } as Option

const InventoryTranslations = () => {
    const { data: autoClients = [] } = useAutoClients()
    const { data: translations = [], status } = useInventoryTranslations()

    const clientInfoIndex = useMemo(() => {
        const index = {}

        autoClients.forEach(({ id, dealerCode, name, fourbotId }) => {
            index[id] = `${dealerCode ? `${dealerCode}: ` : ''}${name}${
                fourbotId ? ` (Fourbot ID: ${fourbotId})` : ''
            }`
        })

        return index
    }, [autoClients])

    const clientOptions = useMemo(() => {
        return autoClients.map(({ id }) => ({
            id,
            name: clientInfoIndex[id],
        }))
    }, [autoClients, clientInfoIndex])

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: translations,
        })

    const { mutate: saveTranslations, isLoading: isSavingTranslations } =
        useMutation(PearApi.updateInventoryTranslations, {
            onSettled: () => {
                invalidateInventoryTranslations()
            },
        })

    const RenderRow = useCallback(
        ({ index }) => {
            const row = rows[index]
            prepareRow(row)
            return (
                <Row
                    row={row}
                    {...row.getRowProps()}
                    saveTranslations={saveTranslations}
                    isSavingTranslations={isSavingTranslations}
                    clientInfoIndex={clientInfoIndex}
                />
            )
        },
        [
            prepareRow,
            rows,
            saveTranslations,
            isSavingTranslations,
            clientInfoIndex,
        ],
    )

    return (
        <SectionCloud className="flex h-full flex-col py-6 px-8">
            <h1 className="mb-2 flex-none text-3xl font-extrabold tracking-tight text-gray-600">
                Inventory Translations
            </h1>
            <p className="flex-none text-sm text-gray-700">
                For each combination of client, feed, field, and Fourbot value,
                only one translation can be stored. (Duplicates will overwrite
                existing values.) Fourbot value and replacement are case
                sensitive and cannot be blank.
            </p>
            <Form
                onSubmit={({
                    clientId,
                    feed,
                    field,
                    fourbotValue,
                    matchType,
                    replacement,
                }) =>
                    saveTranslations({
                        clientId:
                            clientId === allClientsOption.id ? null : clientId,
                        feed: feed === 0 ? '' : feed,
                        translations: [
                            { field, fourbotValue, matchType, replacement },
                        ],
                    })
                }
                initialValues={{
                    clientId: allClientsOption.id, // Guard value (will become null)
                    feed: 0, // Guard value (will become '')
                    field: 'Make',
                }}
                render={({ handleSubmit, invalid, pristine, submitting }) => (
                    <form
                        noValidate={true}
                        onSubmit={handleSubmit}
                        className="mt-8 flex flex-none items-end space-x-6"
                    >
                        <SelectFieldWithSearch
                            className={spacings['clientId']}
                            name="clientId"
                            label="Client"
                            options={clientOptions}
                            required={true}
                            alwaysOption={allClientsOption}
                        />
                        <SelectField
                            className={spacings['feed']}
                            name="feed"
                            label="Feed"
                            options={[
                                { id: 0, name: 'All Feeds' },
                                {
                                    id: 'Automatic Pricing',
                                    name: 'Automatic Pricing',
                                },
                                { id: 'Used Tool', name: 'Used Tool' },
                            ]}
                            useIdAsValue={true}
                        />
                        <SelectField
                            className={spacings['field']}
                            name="field"
                            label="Field"
                            options={[
                                {
                                    id: 'Model',
                                    name: 'Model',
                                },
                                { id: 'Make', name: 'Make' },
                                { id: 'Trim', name: 'Trim' },
                            ]}
                            useIdAsValue={true}
                        />
                        <TextField
                            className={classNames(
                                'mb-5',
                                spacings['fourbotValue'],
                            )}
                            label="Fourbot Value"
                            name="fourbotValue"
                        />
                        <SelectField
                            className={spacings['matchType']}
                            name="matchType"
                            label="Match Type"
                            options={[
                                { id: 'EXACT', name: 'EXACT' },
                                { id: 'CONTAINS', name: 'CONTAINS' },
                            ]}
                            useIdAsValue={true}
                        />
                        <TextField
                            className={'w-50 mb-5'}
                            label="Replacement"
                            name="replacement"
                        />
                        <div className="pb-5">
                            <CirclePlusButton
                                alt="Submit Translation"
                                disabled={
                                    invalid ||
                                    pristine ||
                                    submitting ||
                                    isSavingTranslations
                                }
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            />
            <div className="relative min-h-0 flex-auto">
                <div className="h-full overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block h-full min-w-full align-middle sm:px-6 lg:px-8">
                        <div
                            className="h-full overflow-hidden border-b border-t border-gray-200 bg-gray-50 shadow sm:rounded-lg"
                            {...getTableProps()}
                        >
                            <div className="flex h-full min-w-full flex-col divide-y divide-gray-200">
                                <div
                                    className="flex-none bg-gray-50 pl-2"
                                    style={{ width: 'calc(100% - 0.9em)' }}
                                >
                                    {headerGroups.map((headerGroup) => (
                                        <div
                                            className="flex gap-6"
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => {
                                                    const { id } = column ?? {}
                                                    const header =
                                                        column.render('Header')
                                                    return (
                                                        <div
                                                            className={classNames(
                                                                'group py-2 text-xs font-medium uppercase tracking-wider text-gray-700 focus:text-cyan-900 focus:outline-none',
                                                                spacings[id],
                                                            )}
                                                            {...column.getHeaderProps()}
                                                            title={undefined}
                                                        >
                                                            {header}
                                                        </div>
                                                    )
                                                },
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div
                                    className="flex-1 overflow-y-scroll bg-white py-1"
                                    {...getTableBodyProps()}
                                >
                                    {rows.length > 0 ? (
                                        rows.map(RenderRow)
                                    ) : status !== 'success' ? (
                                        <div className="h-full">
                                            <StatusDisplay status={status} />
                                        </div>
                                    ) : (
                                        <div className="block w-full">
                                            <div className="block w-full">
                                                <div className="p-16 text-center text-lg">
                                                    There's nothing here.
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SectionCloud>
    )
}

export default InventoryTranslations

const Row = memo(
    ({
        row,
        saveTranslations,
        isSavingTranslations,
        clientInfoIndex,
    }: {
        row: any
        saveTranslations: (
            translations: FeedAndClientInventoryTranslations,
        ) => void
        isSavingTranslations: boolean
        clientInfoIndex: { [clientId: string]: string }
    }) => {
        return (
            <div className="flex items-center gap-6 rounded-md pt-1 pl-2 text-sm">
                {row.cells.map((cell) => {
                    const { id } = cell?.column ?? {}

                    return (
                        <div
                            className={classNames(
                                '-my-3 flex items-center justify-between truncate py-2 pr-5 text-gray-700',
                                spacings[id],
                            )}
                            {...cell.getCellProps()}
                        >
                            {cell.render('Cell', { clientInfoIndex })}{' '}
                            {id === 'replacement' ? (
                                <TrashButton
                                    className="ml-4"
                                    alt="Remove Translation"
                                    onClick={() => {
                                        const {
                                            clientId,
                                            feed,
                                            field,
                                            fourbotValue,
                                            matchType,
                                        } = cell?.row?.original ?? {}
                                        saveTranslations({
                                            clientId,
                                            feed,
                                            translations: [
                                                {
                                                    field,
                                                    fourbotValue,
                                                    matchType,
                                                    replacement: '',
                                                },
                                            ],
                                        })
                                    }}
                                    disabled={isSavingTranslations}
                                />
                            ) : null}
                        </div>
                    )
                })}
            </div>
        )
    },
)
