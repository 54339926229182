import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

function FileDialog({
    isOpen,
    closeModal,
    title,
    msg,
    requiredParams = [],
    children,
}) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-lg bg-white p-5 text-left align-middle shadow-xl transition-all">
                                <div className="flex flex-row justify-between">
                                    <Dialog.Title
                                        as="h3"
                                        className="mb-4 text-lg font-medium leading-8 text-gray-900"
                                    >
                                        {title}
                                    </Dialog.Title>
                                    <XMarkIcon
                                        className="h-6 w-6 cursor-pointer"
                                        aria-hidden="true"
                                        onClick={closeModal}
                                    />
                                </div>
                                {msg && !requiredParams.length ? (
                                    <div className="mb-2 flex flex-row items-start justify-between gap-2 rounded border border-cyan-200 bg-cyan-50 p-2 text-sm">
                                        <div>
                                            <InformationCircleIcon
                                                className="h-5 w-5 text-cyan-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div>{msg}</div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {msg && requiredParams.length > 0 ? (
                                    <div className="mb-2 rounded border  border-cyan-200 bg-cyan-50 p-2 text-sm">
                                        <div className="flex flex-row items-start">
                                            <div>
                                                <InformationCircleIcon
                                                    className="h-5 w-5 text-cyan-600"
                                                    aria-hidden="true"
                                                />
                                            </div>
                                            <div className="ml-2">{msg}</div>
                                        </div>
                                        <div className="w-full pl-7 text-left text-sm font-thin">
                                            {requiredParams ? (
                                                <ul>
                                                    {requiredParams.map(
                                                        (param) => {
                                                            return (
                                                                <li>{param}</li>
                                                            )
                                                        },
                                                    )}
                                                </ul>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                                <div className="mt-2">{children}</div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default FileDialog
