import { useInfiniteQuery, useQuery } from 'react-query'
import { queryClient } from '../App'
import PearApi, { Client } from '../apis/pearApi'
import { latestMonth } from '../utility/BudgetCalculations'
import { isoMonth } from '../utility/Formatting'

// All durations are in milliseconds for react-query
const ONE_SECOND = 1000
const ONE_MINUTE = 60 * ONE_SECOND
const FIVE_MINUTES = 5 * ONE_MINUTE
const ONE_HOUR = 60 * ONE_MINUTE
const ONE_DAY = 24 * ONE_HOUR

export const useBudgetList = (month) => {
    const currentMonth = latestMonth()
    return useQuery(['Budget List', month], () => PearApi.budgetList(month), {
        staleTime: month === currentMonth ? ONE_HOUR : ONE_DAY,
        cacheTime: month === currentMonth ? ONE_HOUR : ONE_DAY,
    })
}
export const prefetchBudgetList = async (month) => {
    const currentMonth = latestMonth()
    await queryClient.prefetchQuery(
        ['Budget List', month],
        () => PearApi.budgetList(month),
        {
            staleTime: month === currentMonth ? ONE_HOUR : ONE_DAY,
            cacheTime: month === currentMonth ? ONE_HOUR : ONE_DAY,
        },
    )
}

export const useSpendBreakdown = (item, month: string) =>
    useQuery(
        ['Spend Breakdown', { itemId: item.id, month }],
        () => PearApi.spendBreakdown(item.id, month),
        {
            placeholderData: [],
        },
    )

export const prefetchSpendBreakdown = async (item, month: string) => {
    await queryClient.prefetchQuery(
        ['Spend Breakdown', { itemId: item.id, month }],
        () => PearApi.spendBreakdown(item.id, month),
    )
}

export const useClient = (clientId) =>
    useQuery(['Client', clientId], () => PearApi.client(clientId), {
        placeholderData: {} as Client,
    })

export const useClients = () => useQuery('Clients', PearApi.clients)

export const invalidateClients = () => queryClient.invalidateQueries('Clients')

export const useClientCampaigns = (clientId: string | number) =>
    useQuery(
        ['Client Campaigns', clientId],
        () => PearApi.clientCampaigns(clientId),
        {
            staleTime: 60 * (60 * 1000), // 60 mins
            cacheTime: 60 * (60 * 1000), // 60 mins
            placeholderData: [],
        },
    )

export const useClientWithCars = (clientId) =>
    useQuery(['Client Cars', clientId], () => PearApi.clientWithCars(clientId))

export const useClientFeedValues = (clientId) =>
    useQuery(['Client Feed Values', clientId], () =>
        PearApi.clientFeedValues(clientId),
    )
export const invalidateClientFeedValues = (clientId) =>
    queryClient.invalidateQueries(['Client Feed Values', clientId])

export const useFourbotClients = () =>
    useQuery('Fourbot Clients', PearApi.fourbotClients)

export const usePromoAds = () =>
    useQuery('Promo Ads', PearApi.promoAds, {
        placeholderData: [],
    })

export const invalidatePromoAds = () =>
    queryClient.invalidateQueries('Promo Ads')

export const usePromoAdBuilderClients = () =>
    useQuery('Promo Ad Builder Clients', PearApi.promoAdBuilderClients, {
        placeholderData: [],
    })

export const invalidatePromoAdBuilderClients = () =>
    queryClient.invalidateQueries('Promo Builder Clients')

/** Get previews of a promo ad for 1 or more clients. This uses useInfiniteQuery
 * from react-query. Documentation can be found here:
 * https://react-query-v3.tanstack.com/reference/useInfiniteQuery
 *
 * The return type for each page in data.pages is an object like this:
 * { clientAdPreviews: {... api result ...}, pageParam: 1}
 */
export const useClientAdPreviews = (
    promoAdId: number,
    clientIdList: number[],
    pageSize: number = 10,
) => {
    // This allows us to request 10 clients at a time even though the API is
    // looking for an array of client IDs. It also packages the API result in
    // an object like this: { clientAdPreviews: {... api result ...}, pageParam: 1}
    const pagedQueryFunction = async ({ pageParam = 1 }) => {
        const apiResult = await PearApi.clientAdPreviews(
            promoAdId,
            clientIdList.slice(
                (pageParam - 1) * pageSize,
                pageParam * pageSize,
            ),
        )
        return { clientAdPreviews: apiResult, pageParam }
    }

    return useInfiniteQuery(
        ['Client Ad Previews', promoAdId, clientIdList],
        pagedQueryFunction,
        {
            getNextPageParam: ({ pageParam: lastParam }) =>
                lastParam * pageSize <= clientIdList.length
                    ? lastParam + 1
                    : undefined,
            placeholderData: { pages: [], pageParams: [] },
        },
    )
}

export const useUsers = () => useQuery('Users', PearApi.users)

export const useClientBudgets = (clientId) =>
    useQuery(['Budgets', clientId], () => PearApi.budget(clientId), {
        staleTime: 60 * (60 * 1000), // 60 mins
        cacheTime: 60 * (60 * 1000), // 60 mins
    })
export const invalidateClientBudgets = (clientId) =>
    queryClient.invalidateQueries(['Budgets', clientId])

export const useProductLineItems = (shouldFetch) =>
    useQuery(['Product Line Items'], () => PearApi.productLineItems(), {
        placeholderData: [],
        enabled: shouldFetch,
    })

export const usePromoLineItems = (shouldFetch) =>
    useQuery(['Promo Line Items'], () => PearApi.promoLineItems(), {
        placeholderData: [],
        enabled: shouldFetch,
    })

export const useClientNote = (clientId) =>
    useQuery(['Client Note', clientId], () => PearApi.clientNote(clientId), {
        placeholderData: { clientId, note: '' },
        retry: false,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: FIVE_MINUTES,
    })

export const invalidateClientNote = (clientId) =>
    queryClient.invalidateQueries(['Client Note', clientId])

export const useClientUsedToolUrls = (clientId) =>
    useQuery(['Client Used Tool Urls', clientId], () =>
        PearApi.clientUsedToolUrls(clientId),
    )

export const invalidateClientUsedToolUrls = (clientId) =>
    queryClient.invalidateQueries(['Client Used Tool Urls', clientId])

export const useUsedToolStatus = () =>
    useQuery(['Used Tool Status'], () => PearApi.usedToolStatus())

export const useAutomaticPricingClients = () =>
    useQuery(['Automatic Pricing Clients'], () =>
        PearApi.automaticPricingClients(),
    )

export const useAutomaticPricingModels = () =>
    useQuery(['Automatic Pricing Models'], () =>
        PearApi.automaticPricingModels(),
    )

export const useClientExcludedTerms = (clientId) =>
    useQuery(['Client Excluded Terms', clientId], () =>
        PearApi.clientExcludedTerms(clientId),
    )

export const invalidateClientExcludedTerms = (clientId) =>
    queryClient.invalidateQueries(['Client Excluded Terms', clientId])

export const prefetchClientNote = async (clientId) => {
    await queryClient.prefetchQuery(
        ['Client Note', clientId],
        () => PearApi.clientNote(clientId),
        {
            staleTime: FIVE_MINUTES,
            retry: false,
        },
    )
}

export const useBudgetSpendWatchdogs = (yearMonth = isoMonth(new Date())) =>
    useQuery(
        ['Budget Spend Watchdogs', yearMonth],
        () => PearApi.budgetSpendWatchdogs(yearMonth),
        { notifyOnChangeProps: ['data', 'error'] },
    )
export const useClientBudgetSpendWatchdogs = (
    yearMonth = isoMonth(new Date()),
    clientSourceId = '',
) =>
    useQuery(
        ['Budget Spend Watchdogs', yearMonth, clientSourceId],
        () => PearApi.budgetSpendWatchdogs(yearMonth, clientSourceId),
        { notifyOnChangeProps: ['data', 'error'] },
    )

export const invalidateBudgetSpendWatchdogs = (yearMonth) =>
    queryClient.invalidateQueries(['Budget Spend Watchdogs', yearMonth])

export const useInventoryTranslations = () =>
    useQuery(['Inventory Translations'], () => PearApi.inventoryTranslations())

export const invalidateInventoryTranslations = () =>
    queryClient.invalidateQueries(['Inventory Translations'])

export const useAutoClients = () =>
    useQuery(['Auto Clients'], () => PearApi.autoClients())

export const useSafeguardAlerts = (enabled = true) =>
    useQuery(['Safeguard Alerts'], () => PearApi.safeguardAlerts(), {
        placeholderData: [],
        staleTime: ONE_HOUR,
        cacheTime: ONE_HOUR,
        enabled,
    })

export const invalidateSafeguardAlerts = () =>
    queryClient.invalidateQueries(['Safeguard Alerts'])

export const useGetClientCoopReport = (clientId) =>
    useQuery(['Client Coop Report', clientId], () =>
        PearApi.getClientCoopReport(clientId),
    )
