import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { DocumentIcon } from '@heroicons/react/24/outline'
import { formatBytes } from '../../utility/Formatting'

function CustomDropzone({ onFileSelect }) {
    const onDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0]
            onFileSelect(file)
        },
        [onFileSelect],
    )
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
        useDropzone({
            onDrop,
            maxFiles: 1,
            accept: {
                'text/csv': ['.csv'],
            },
        })
    let files = []

    if (acceptedFiles && acceptedFiles.length) {
        files = acceptedFiles.map((file) => (
            <li
                key={file.name}
                className="flex flex-row items-center justify-between"
            >
                <DocumentIcon
                    className="mr-4 h-5 w-5 flex-none text-gray-300"
                    aria-hidden="true"
                />
                <div className="flex-1">
                    <span className="font-medium">{file.name}</span>
                    <br />
                    <span>{formatBytes(file.size)}</span>
                </div>
            </li>
        ))
    }

    return (
        <>
            <div
                className="group flex h-56 cursor-pointer select-none flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-300 bg-white px-20 text-center text-gray-900 hover:border-cyan-300 hover:bg-cyan-50 hover:text-cyan-700"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <DocumentIcon
                    className="mb-4 h-10 w-10 text-gray-300 group-hover:text-cyan-500"
                    aria-hidden="true"
                />
                {isDragActive ? (
                    <p>Drop the file here ...</p>
                ) : (
                    <p>
                        Select a CSV file to upload,
                        <br />
                        or drag and drop it here
                    </p>
                )}
            </div>

            {!!files && files.length ? (
                <aside className="my-4">
                    <h4 className="text-md mb-2 font-medium text-gray-700">
                        File Selected
                    </h4>
                    <ul className="rounded-md border border-gray-100 px-4 py-2 text-sm">
                        {files}
                    </ul>
                </aside>
            ) : (
                ''
            )}
        </>
    )
}

export default CustomDropzone
