import { classNames } from '../../utility/Components'

export const TextInput = ({ className = '', ...otherProps }) => (
    <input
        type="text"
        className={classNames(
            'rounded-md border-gray-300 focus:border-cyan-500 focus:ring-2 focus:ring-cyan-500 sm:text-sm',
            className,
        )}
        {...otherProps}
    />
)
