import { useCallback, useReducer, useEffect, useRef, createRef } from 'react'

const reducer = (state: UseDataListState, action: Action): UseDataListState => {
    const { length, pageDistance, focus: prevFocus } = state
    const { type, index, key, initialFocus, initialSelection } = action
    const firstIndex = length > 0 ? 0 : -1
    const lastIndex = length - 1
    switch (type) {
        case 'UPDATE_LENGTHS':
            return {
                ...state,
                length: action.length ?? length,
                pageDistance: action.pageDistance ?? pageDistance,
            }
        case 'SYNC_INITIAL_FOCUS':
            if (0 <= initialFocus && initialFocus < length) {
                return {
                    ...state,
                    focus: initialFocus,
                }
            } else {
                return {
                    ...state,
                    focus: -1,
                }
            }
        case 'SYNC_INITIAL_SELECTION':
            if (-1 <= initialSelection && initialSelection < length) {
                return {
                    ...state,
                    selection: initialSelection,
                    selectionSource: 'INITIAL',
                }
            } else {
                throw new Error(
                    'Initial selection must be set to an item in the list or -1 for no selection.',
                )
            }
        case 'SELECT':
            if (0 <= index && index < length) {
                return {
                    ...state,
                    selection: index,
                    selectionSource: 'USER',
                    hasFocus: true,
                }
            } else {
                throw new Error('Selection must be set to an item in the list.')
            }
        case 'DESELECT':
            return { ...state, selection: -1, selectionSource: 'USER' }
        case 'FOCUS':
            if (0 <= index && index < length) {
                return {
                    ...state,
                    focus: index,
                    hasFocus: true,
                }
            } else {
                throw new Error('Focus must be set to an item in the list.')
            }
        case 'LOSE_FOCUS':
            return { ...state, hasFocus: false }
        case 'KEY_PRESS':
            switch (key) {
                case 'Enter':
                case ' ': // (Spacebar)
                    return {
                        ...state,
                        selection: prevFocus,
                        selectionSource: 'USER',
                        hasFocus: true,
                    }
                case 'ArrowUp':
                case 'ArrowLeft':
                    return {
                        ...state,
                        focus:
                            prevFocus === -1
                                ? lastIndex
                                : prevFocus === 0
                                ? prevFocus
                                : prevFocus - 1,
                        hasFocus: true,
                    }
                case 'ArrowDown':
                case 'ArrowRight':
                    return {
                        ...state,
                        focus:
                            prevFocus === -1
                                ? firstIndex
                                : prevFocus === lastIndex
                                ? prevFocus
                                : prevFocus + 1,
                        hasFocus: true,
                    }
                case 'PageUp':
                    return {
                        ...state,
                        focus:
                            prevFocus === -1
                                ? lastIndex
                                : prevFocus < pageDistance
                                ? 0
                                : prevFocus - pageDistance,
                        hasFocus: true,
                    }
                case 'PageDown':
                    return {
                        ...state,
                        focus:
                            prevFocus === -1
                                ? firstIndex
                                : prevFocus > lastIndex - pageDistance
                                ? lastIndex
                                : prevFocus + pageDistance,
                        hasFocus: true,
                    }
                case 'Home':
                    return {
                        ...state,
                        focus: firstIndex,
                        hasFocus: true,
                    }
                case 'End':
                    return {
                        ...state,
                        focus: lastIndex,
                        hasFocus: true,
                    }
                default:
                    return state
            }
        default:
            throw new Error('Unknown action type for focusReducer.')
    }
}
const trappedKeys = [
    'Enter',
    ' ',
    'ArrowUp',
    'ArrowLeft',
    'ArrowDown',
    'ArrowRight',
    'PageUp',
    'PageDown',
    'Home',
    'End',
]

interface UseDataListState {
    length: number
    focus: number
    hasFocus: boolean
    selection: number
    selectionSource: 'INITIAL' | 'USER'
    pageDistance: number
}

interface Action {
    type: string
    [key: string]: any
}

interface UseDataListProps {
    /** Length of the list */
    length: number
    /** 0-based index of item to be selected initially - this can be updated
     * later and will be re-synced.
     */
    initialSelection?: number
    /** 0-based index of item to be focussed initially - this can be changed
     *  and will be re-synced.
     */
    initialFocus?: number
    /** Number of items that will be skipped when using Page Up/Down keys - this
     *  is ideally something that is automatically updated based on any changes
     *  to the list display area.
     */
    pageDistance?: number
    /** Function to run when there's a selection change via input devices (like
     *  the mouse and keyboard). It's provided the 0-based index of the new
     *  selection. */
    onSelect?: (index: number) => void
}

/** Hook to provide ways to move through a list with keyboard and mouse and control
 * focus and selection. Returns a variety of things including current focus and
 * selection and several functions to control how those are changed. */
const useDataList = ({
    length,
    initialSelection = -1,
    initialFocus = -1,
    pageDistance = 5,
    onSelect = () => {},
}: UseDataListProps) => {
    const appliedInitialFocus =
        initialFocus === -1 && initialSelection !== -1
            ? initialSelection
            : initialFocus === -1 && initialSelection === -1
            ? length > 0
                ? 0
                : -1
            : initialFocus
    const [state, dispatch] = useReducer<
        (state: UseDataListState, action: Action) => UseDataListState
    >(reducer, {
        focus: appliedInitialFocus,
        hasFocus: false,
        selection: initialSelection,
        selectionSource: 'INITIAL',
        length,
        pageDistance,
    })
    const { focus, hasFocus, selection, selectionSource } = state
    const refArray = new Array(length)
        .fill(1)
        .map(() => createRef<HTMLLIElement>())
    const refs = useRef(refArray)

    // Ensure there are always enough refs, but don't remove them if there are too many
    useEffect(() => {
        const refsLength = refs.current.length
        if (length > refsLength) {
            const additionalRefs = new Array(length - refsLength)
                .fill(1)
                .map(() => createRef<HTMLLIElement>())
            refs.current.push(...additionalRefs)
        }
        // TODO: Maybe shrink the ref array, too, to save memory?
    }, [length])

    // Keep state up to date with any changes to length or pageDistance
    useEffect(() => {
        dispatch({
            type: 'UPDATE_LENGTHS',
            length,
            pageDistance,
        })
    }, [length, pageDistance])

    // Keep state up to date with any changes to initial values
    useEffect(() => {
        dispatch({
            type: 'SYNC_INITIAL_FOCUS',
            initialFocus: appliedInitialFocus,
        })
    }, [appliedInitialFocus])

    useEffect(() => {
        dispatch({ type: 'SYNC_INITIAL_SELECTION', initialSelection })
    }, [initialSelection])

    // Set focus for currently focussed item
    const listLength = refs.current?.length
    useEffect(() => {
        if (hasFocus && focus !== -1 && refs.current?.[focus]?.current) {
            refs.current[focus].current.focus({ preventScroll: true })
            refs.current[focus].current.scrollIntoView?.({
                behavior: 'smooth',
                block: 'nearest',
            })
        }
    }, [focus, listLength, hasFocus])

    // Call onSelect if the trigger is the user
    useEffect(() => {
        if (selectionSource === 'USER') {
            onSelect(selection)
        }
    }, [onSelect, selection, selectionSource])

    const select = useCallback((index: number) => {
        dispatch({ type: 'SELECT', index })
    }, [])

    const deselect = useCallback(() => dispatch({ type: 'DESELECT' }), [])

    /** Change focus by key press */
    const moveFocus = useCallback((event) => {
        const { key } = event
        if (trappedKeys.includes(key)) {
            event.preventDefault()
        }
        dispatch({ type: 'KEY_PRESS', key })
    }, [])

    /** Set the focus directly */
    const focusOn = useCallback(
        (index: number) => dispatch({ type: 'FOCUS', index }),
        [],
    )

    const loseFocus = useCallback(() => dispatch({ type: 'LOSE_FOCUS' }), [])

    const listProps = useCallback(
        () => ({
            tabIndex: -1,
            onKeyDown: moveFocus,
            onBlur: loseFocus,
        }),
        [moveFocus, loseFocus],
    )

    const itemProps = useCallback(
        (index) => {
            return {
                tabIndex:
                    focus === index ? 0 : focus === -1 && index === 0 ? 0 : -1,
                ref: refs.current?.[index],
                onClick: () => select(index),
                onBlur: loseFocus,
            }
        },
        [focus, select, loseFocus],
    )

    return {
        focus: state.focus,
        selection: state.selection,
        deselect,
        focusOn,
        listProps,
        itemProps,
    } as const
}

export default useDataList
