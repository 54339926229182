import { useEffect } from 'react'

// Based on code and ideas from Dave found here: https://stackoverflow.com/a/67924817/6451307
// License: CC BY-SA 4.0 (https://creativecommons.org/licenses/by-sa/4.0/)

// Definition so Typescript knows we're adding to the window global object.
declare global {
    interface Window {
        pearAppGlobals: {
            preloadedImages?: Record<string, HTMLImageElement[]>
        }
    }
}

/** A hook for preloading a list of image sources.
 *
 * @param imageSources A string array of image sources (e.g. URL's)
 */
export const useImagePreloading = (imageSources: string[]): void => {
    useEffect(() => {
        const uniqueIdentifier =
            Math.random().toString(32).slice(2) + Date.now()
        window.pearAppGlobals = window.pearAppGlobals ?? {}
        window.pearAppGlobals.preloadedImages =
            window.pearAppGlobals.preloadedImages ?? {}
        window.pearAppGlobals.preloadedImages[uniqueIdentifier] = []

        imageSources.forEach((imageSource) => {
            const newImage = new Image()
            newImage.src = imageSource
            window.pearAppGlobals.preloadedImages[uniqueIdentifier].push(
                newImage,
            )
        })

        return () => {
            delete window.pearAppGlobals?.preloadedImages?.[uniqueIdentifier]
        }
    }, [imageSources])
}
