export const areEquivalent = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) {
        return false
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false
        }
    }

    return true
}

export const haveSameContents = (arr1: any[], arr2: any[]) => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)

    return (
        set1.size === set2.size &&
        Array.from(set1).every((item) => set2.has(item))
    )
}

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index
}
