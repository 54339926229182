import { useState } from 'react'
import { useMutation } from 'react-query'
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import PearApi from '../../apis/pearApi'
import { useClientAdPreviews } from '../../hooks/apiHooks'
import { NO_PROMO_AD_SELECTION } from '../../pages/PromoAdBuilder'
import { classNames } from '../../utility/Components'
import { Button } from '../elements/Buttons'
import { LoadingIcon } from '../StateIcons'

const gridClasses = (previewAd) => {
    const { height, width } = previewAd
    return width === 728
        ? 'row-start-1 col-start-3 col-span-3'
        : width === 160
        ? 'row-start-1 col-start-2 row-span-3'
        : height === 600
        ? 'row-start-1 row-span-3'
        : width === 300
        ? 'row-start-2 col-start-3'
        : 'row-start-2 col-start-4'
}

const PAGE_SIZE = 10

const Step3 = ({
    selectedPromoAd,
    selectedClients,
}: {
    selectedPromoAd: typeof NO_PROMO_AD_SELECTION
    selectedClients: Set<number>
}) => {
    const numberOfClients = selectedClients.size
    const {
        data: { pages: clientAdPreviewsPages },
        hasNextPage,
        fetchNextPage,
        status,
    } = useClientAdPreviews(
        selectedPromoAd.id,
        Array.from(selectedClients),
        PAGE_SIZE,
    )
    const clientAdPreviews = clientAdPreviewsPages.flatMap(
        ({ clientAdPreviews }) => clientAdPreviews,
    )
    const numberOfPagesFetched = clientAdPreviewsPages.length

    // User facing client number, 1 through number of clients selected
    const [clientNumber, setClientNumber] = useState(1)
    const incrementClientNumber = () => {
        if (clientNumber < numberOfClients) {
            setClientNumber((prevNumber) => prevNumber + 1)
            const lastClientNumberFetched = numberOfPagesFetched * PAGE_SIZE
            if (lastClientNumberFetched - clientNumber <= 5 && hasNextPage) {
                fetchNextPage()
            }
        }
    }
    const decrementClientNumber = () => {
        if (clientNumber > 1) {
            setClientNumber((prevNumber) => prevNumber - 1)
        }
    }

    const clientInfo = clientAdPreviews[clientNumber - 1]?.client ?? {}

    const generateZip = useMutation(
        () =>
            PearApi.generatePromoAdsZip(
                selectedPromoAd.id,
                Array.from(selectedClients),
            ),
        {
            onSuccess: (data) => {
                window.open(data.zipUrl, '_blank', 'noreferrer')
            },
        },
    )

    return (
        <div className="flex min-h-0 flex-1 flex-col gap-4 pb-5">
            <div className="flex flex-none items-baseline gap-16">
                <h2 className="mt-3.5 flex-none text-3xl font-medium text-gray-700">
                    <span className="sr-only">Step</span>
                    <span className="mr-4 inline-flex h-12 w-12 items-center justify-center rounded-full bg-gray-700 text-white">
                        3
                    </span>
                    Preview and Download Ads
                </h2>
                <div className="relative flex flex-none items-baseline text-lg">
                    <Button
                        className="flex items-center gap-1"
                        onClick={generateZip.mutate}
                        disabled={generateZip.isLoading}
                    >
                        Download{' '}
                        <ArrowTopRightOnSquareIcon
                            className="inline-block h-5 align-top"
                            aria-hidden="true"
                        />
                    </Button>
                    {generateZip.isLoading && (
                        <div className="h-8 pt-1">
                            <LoadingIcon />
                        </div>
                    )}
                    {generateZip.isError && (
                        <div className="ml-2 text-sm text-orange-600">
                            There was an error.
                        </div>
                    )}
                </div>
                <div className="flex min-w-0 flex-auto items-end gap-4">
                    <div className="min-w-0 flex-auto truncate text-right text-lg text-gray-700">
                        {clientInfo.name}
                    </div>
                    <div className="flex-none text-lg text-gray-700">
                        ({clientNumber} of {numberOfClients})
                    </div>
                    <div className="flex-none">
                        {
                            <Button
                                onClick={decrementClientNumber}
                                disabled={clientNumber <= 1}
                            >
                                <span aria-hidden="true">{'—'}</span>
                                <span className="sr-only">Previous Client</span>
                            </Button>
                        }
                    </div>
                    <div className="flex-none">
                        {
                            <Button
                                onClick={incrementClientNumber}
                                disabled={clientNumber >= numberOfClients}
                            >
                                <span aria-hidden="true">{'+'}</span>
                                <span className="sr-only">Next Client</span>
                            </Button>
                        }
                    </div>
                </div>
            </div>
            <div className="min-h-0 flex-1 overflow-clip rounded-lg border border-gray-200">
                <div className="h-full overflow-auto bg-gray-50 p-4 pl-16">
                    {status === 'success' ? (
                        <div className="" aria-label="Ad Previews">
                            {clientAdPreviews.map(({ adPreviews }, index) => (
                                <div
                                    className={classNames(
                                        'grid auto-cols-min auto-rows-min gap-6',
                                        index === clientNumber - 1
                                            ? 'block'
                                            : 'hidden',
                                    )}
                                >
                                    {adPreviews.map((testPreview) => {
                                        return (
                                            <div
                                                key={
                                                    testPreview.height +
                                                    'x' +
                                                    testPreview.width
                                                }
                                                className={classNames(
                                                    'flex-none',
                                                    gridClasses(testPreview),
                                                )}
                                            >
                                                <iframe
                                                    height={testPreview.height}
                                                    width={testPreview.width}
                                                    srcDoc={testPreview.html}
                                                    title={
                                                        testPreview.height +
                                                        'x' +
                                                        testPreview.width
                                                    }
                                                    loading="eager"
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            ))}
                        </div>
                    ) : status === 'loading' ? (
                        <LoadingIcon />
                    ) : (
                        <div className="flex h-full w-full items-center justify-center">
                            <div className="">
                                <p className="mb-4">
                                    We couldn't load the ad previews.
                                </p>
                                <p>
                                    You can try again by going back a page and
                                    then returning here or you can try
                                    downloading the ads and previewing them
                                    manually.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Step3
