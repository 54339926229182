import { Cell, Tooltip, Legend, PieChart, Pie } from 'recharts'
import AutoSizer from 'react-virtualized-auto-sizer'

import StatusDisplay, { Status } from '../StatusDisplay'
import { percentageFormat, dollarFormat } from '../../utility/Formatting'
import theme from '../../theme'

const spendBreakdownTooltipColors = [
    {
        bg: 'bg-cyan-100',
        text: 'text-cyan-600',
    },
    {
        bg: 'bg-cyan-200',
        text: 'text-cyan-700',
    },
    {
        bg: 'bg-cyan-300',
        text: 'text-cyan-800',
    },
    {
        bg: 'bg-cyan-400',
        text: 'text-cyan-900',
    },
    {
        bg: 'bg-cyan-500',
        text: 'text-cyan-50',
    },
    {
        bg: 'bg-cyan-600',
        text: 'text-cyan-50',
    },
    {
        bg: 'bg-cyan-700',
        text: 'text-cyan-100',
    },
    {
        bg: 'bg-cyan-800',
        text: 'text-cyan-100',
    },
    {
        bg: 'bg-cyan-900',
        text: 'text-cyan-200',
    },
]

const spendBreakdownChartColors = [
    theme.colors.cyan[100],
    theme.colors.cyan[200],
    theme.colors.cyan[300],
    theme.colors.cyan[400],
    theme.colors.cyan[500],
    theme.colors.cyan[600],
    theme.colors.cyan[700],
    theme.colors.cyan[800],
    theme.colors.cyan[900],
]

/**
 * This function tries to return indexes for colors that average out to cyan-500, \
 * but are also spaced evenly and give as close to a 3:1 contrast ration between
 * colors as possible. Full accessibility is not attempted as the chart information
 * is provided in text as well. */
const colorIndexFunctions = {
    0: (i) => 4,
    1: (i) => 4,
    2: (i) => (i === 0 ? 2 : 6),
    3: (i) => (i === 0 ? 0 : i === 1 ? 4 : 7),
    4: (i) => (i === 0 ? 0 : i === 1 ? 3 : i === 2 ? 5 : 7),
    5: (i) => i * 2,
    6: (i) => (i === 2 ? 3 : i === 3 ? 5 : i === 4 ? 7 : i === 5 ? 8 : i),
    7: (i) => i + 1,
    8: (i) => i,
    9: (i) => i,
}

const goodColorIndex = (categoryIndex, numberOfCategories) => {
    const colorIndex =
        numberOfCategories >= 0 && numberOfCategories <= 9
            ? colorIndexFunctions[numberOfCategories]
            : colorIndexFunctions[9]
    return colorIndex(categoryIndex)
}

const spendBreakdownTooltipColorPair = (categoryIndex, numberOfCategories) => {
    const index = goodColorIndex(categoryIndex, numberOfCategories)
    return spendBreakdownTooltipColors[index]
}

const spendBreakdownColor = (categoryIndex, numberOfCategories) => {
    const index = goodColorIndex(categoryIndex, numberOfCategories)
    return spendBreakdownChartColors[index]
}

export const SpendBreakdownTooltip =
    (categoryNames: string[]) =>
    ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload
            const numberOfCategories = categoryNames.length
            const categoryIndex = Math.max(
                0,
                categoryNames.findIndex((catName) => catName === data.type),
            )
            const { bg, text } = spendBreakdownTooltipColorPair(
                categoryIndex,
                numberOfCategories,
            )
            return (
                <div className="relative z-10 w-72 overflow-hidden rounded-lg bg-white shadow-md">
                    <div className={`${bg} relative rounded-t-lg py-2 px-4`}>
                        <div className={`${text} text-lg`}>{data.type}</div>
                        <div className="absolute right-6 -bottom-6 z-10 flex h-12 flex-col content-center justify-center rounded-full border-2 border-gray-300 bg-white px-3 shadow-sm">
                            <div className="text-center text-lg font-bold tracking-wide text-gray-600">
                                {percentageFormat(data.percentage ?? 0, false)}
                            </div>
                        </div>
                    </div>
                    <div className="py-4 px-4">
                        <span className="text-2xl font-bold text-gray-600">
                            {dollarFormat(data.spend ?? 0)}
                        </span>
                    </div>
                    {data.sis ? (
                        <div className="px-4 pb-4">
                            <>
                                <span className="font-semibold text-gray-700">
                                    {percentageFormat(data.sis, false)}
                                </span>
                                <span className="text-gray-700">
                                    {' '}
                                    Search Impression Share
                                </span>
                            </>
                        </div>
                    ) : null}
                </div>
            )
        }
        return null
    }

export const SpendBreakdownChart = ({
    data = [],
    status = 'idle' as Status,
}) => {
    const totalSpend = data.reduce((total, datum) => total + datum.spend, 0)
    const fullerData = data.map((datum) => ({
        ...datum,
        name: datum.type,
        percentage: (datum.spend / totalSpend) * 100,
    }))
    const numberOfCategories = data.length
    return (
        <StatusDisplay status={status}>
            <div className="h-full w-full">
                <AutoSizer>
                    {({ height, width }) => (
                        <PieChart
                            className=""
                            width={350 <= width ? 350 : width}
                            height={200 <= height ? 200 : height}
                        >
                            <Pie
                                data={fullerData}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={65}
                                startAngle={90}
                                endAngle={-270}
                                dataKey="spend"
                                legendType="circle"
                                paddingAngle={2}
                                minAngle={5}
                                animationDuration={400}
                                isAnimationActive={true}
                            >
                                {fullerData.map((datum, index) => (
                                    <Cell
                                        key={datum.name}
                                        fill={spendBreakdownColor(
                                            index,
                                            numberOfCategories,
                                        )}
                                    />
                                ))}
                            </Pie>
                            <Tooltip
                                allowEscapeViewBox={{ x: false, y: true }}
                                content={SpendBreakdownTooltip(
                                    data.map((category) => category.type),
                                )}
                            />
                            {/* content = function; https://recharts.org/en-US/api/Legend */}
                            <Legend
                                layout="vertical"
                                align="right"
                                verticalAlign="middle"
                                payload={fullerData.map((datum, index) => ({
                                    id: datum.name,
                                    type: 'circle',
                                    value: `${datum.name}: ${percentageFormat(
                                        datum.percentage,
                                        false,
                                    )}`,
                                    color: spendBreakdownColor(
                                        index,
                                        numberOfCategories,
                                    ),
                                }))}
                                formatter={(value) => (
                                    <span className="text-xs tracking-wide text-gray-900">
                                        {value}
                                    </span>
                                )}
                            />
                        </PieChart>
                    )}
                </AutoSizer>
            </div>
        </StatusDisplay>
    )
}
